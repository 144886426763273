import React, { useLayoutEffect, useRef, useState } from 'react'
import "./PanelDeleteRequest.css"
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Alert from '../../componement/Alert';
import Panel from '../../componement/Panel';

import validator from 'validator';
import { useUser, useUserUpdate } from '../../context/UserProvider';
import { useRondaModal } from '../../context/ModalProvider';
import { SoundManager } from '../../manager/SoundManager';
import useRondaFetch from '../../hook/useRondaFetch';
import IndicateurValidation from '../../componement/IndicateurValidation';
import useLocalStorage from '../../hook/useLocalStorage';
import { useRondaServer } from '../../context/ServerProvider';
import { InfosUser } from '../../data/InfosUser';
import i18next from 'i18next';
import DataManager from '../../manager/DataManager';
import { SceneManager } from '../../manager/SceneManager';

type Props = {}

export default function AlertDeleteRequest({ }: Props) {

    let navigate = useNavigate();
    let userInfos = useUser()!;
    const { t } = useTranslation();
    let emailRef = useRef<HTMLInputElement | null>(null);
    const { handleModal } = useRondaModal()!;


    const [errorEmailMessage, setErrorEmailMessage] = useState('')


    function validateForm() {
        if (errorEmailMessage == 'ok')
            ForgotUserPassword((emailRef.current as HTMLInputElement).value);
        else
            SoundManager.play(SoundManager.CANCEL_DISMISS);

    }

    const { post, cache, response } = useRondaFetch()


    async function ForgotUserPassword(email: string) {
        cache.clear();
        cache.delete();

        const newTodo = await post('/api/deletion-request', {
            email: email,
        })
        if (response.ok) {


            handleModal(`Your account data will be deleted under 48 hours.`, "green");

            setTimeout(() => logOut(), 500);
        }
    }

    let [token, setToken] = useLocalStorage("RondaToken");
    let { disconnect } = useRondaServer()!;
    const updateUserInfos = useUserUpdate()!;


    function logOut() {
        setToken("");
        disconnect();
        let infos = new InfosUser();
        updateUserInfos(infos);
        i18next.changeLanguage(DataManager.DATA_LANGUAGES.find(i => i.id == infos.infosProfil.infosSettings.idInfosLanguage)?.locale);
        SoundManager.updateVolume(infos.infosPreferences.soundVolume / 100, infos.infosPreferences.musicVolume / 100);
        SceneManager.self.updateAssets(DataManager.DEFAULT_SCENE_THEME, DataManager.DEFAULT_CARD_THEME);

        navigate("/", { replace: true });
    }


    const validateEmail = (value: string) => {

        if (validator.isEmail(value)) {
            setErrorEmailMessage('ok');
        } else {
            setErrorEmailMessage('cancel')
        }
    }

    return (
        <>
            <Panel title={t('Delete Request')} className={"deleteRequest"} returnTo={"/"} >
                <div className='layer'>
                    <div className='layerContainer'>

                        <span className="sousTitre">{t('Please provide your email if you wish to request deletion of your account.')}</span>

                        <form action="">
                            <i className="formIcon icon-email"></i>
                            <IndicateurValidation validationType={errorEmailMessage} messages={[
                                { name: "ok", value: t('Valid field!') },
                                { name: "cancel", value: <p>{t('The field must be a valid email.')}</p> }
                            ]} />
                            <input ref={emailRef} onChange={e => validateEmail(e.target.value)} placeholder={t('Email')} ></input>
                            <a href='#' className='bouton red' onClick={(e) => { validateForm() }}>{t('Permanently remove my data')}</a>
                        </form>
                    </div>
                </div>
                <div className='perso'></div>
            </Panel>
        </>
    )
}
