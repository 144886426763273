import React, { ReactNode } from 'react'

import "./Tooltip.css"

type Props = {
    color: string,
    children?: ReactNode
}

function Tooltip({ color, children }: Props) {
    return (
        <div className={'tooltip ' + color}>
            <span className='triangle'></span>
            <div className='tooltipContainer'>
                <span className='message'>{children}</span>
            </div>
        </div>
    )
}

export default Tooltip