import React, { useEffect, useState } from 'react'

import { Outlet, useNavigate, useParams } from "react-router-dom";
import { SoundManager } from '../../manager/SoundManager';

import "./PanelSettings.css";
import { useTranslation } from 'react-i18next';
import Panel from '../../componement/Panel';
import TabProfilSettings from './TabProfilSettings';
import TabSoundSettings from './TabSoundSettings';
import TabThemeSettings from './TabThemeSettings';

type Props = {}

export default function PanelSettings(props: Props) {

    let navigate = useNavigate();
    const { t } = useTranslation();

    let { type } = useParams();

    const tabs: Array<string> = ["profil-settings", "sound-&-music", "themes-&-gameplay"]
    const tabsTranslated: Array<string> = ["Profil's Settings", "Sound & Music", "Themes & Speed"]
    const [active, setActive] = useState<string>(tabs[2]);


    function openTabs(tabsName: string) {
        setActive(tabsName);


        switch (tabsName) {
            case 'themes-&-gameplay':
                break;
            case 'sound-&-music':
                break;
            case 'profil-settings':
                break;
        }
        SoundManager.play(SoundManager.BUTTON_CLICK);
    }

    useEffect(() => {
        if (type == undefined) type = tabs[2];
        openTabs(type);
        return () => {
        }
    }, [type])





    /*const validate = (value) => {

        if (validator.isStrongPassword(value, {
            minLength: 8, minLowercase: 1,
            minUppercase: 1, minNumbers: 1, minSymbols: 1
        })) {
            setErrorMessage('Is Strong Password')
        } else {
            setErrorMessage('Is Not Strong Password')
        }
    }*/

    return (
        <>
            <Outlet />
            <Panel className='settings' title={t('Options')} returnTo="/home">
                <ol className='onglet'>
                    {tabs.map((name: string, index: number) => {
                        return (
                            <li key={name} className={active === name ? "active" : ""} onClick={() => /*openTabs(name)*/ navigate("/options/" + name)}>{t(tabsTranslated[index])}</li>
                        );
                    })}
                </ol>
                {(active === tabs[2]) &&
                    <TabThemeSettings></TabThemeSettings>
                }
                {(active === tabs[1]) &&
                    <TabSoundSettings></TabSoundSettings>
                }
                {(active === tabs[0]) &&
                    <TabProfilSettings></TabProfilSettings>
                }
            </Panel>
        </>
    )
}
