import { InfosStore } from "./InfosStore";

export class InfosPowerUp extends InfosStore {

    public type: number;
    public static parse: any;

    public constructor(details: any = null) {
        super(details);
        this.type = Number(details.type);
        this.infinite = true;
    }

    override parse(details: any = null): void {
        this.type = Number(details.type);
        super.parse(details);
    }
}
