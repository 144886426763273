import React, { useEffect, useRef, useState } from 'react'
import { InfosLanguage } from '../data/InfosLanguage';
import "./ToogleLanguage.css"
import TooltipLanguage from './TooltipLanguage';
import DataManager from '../manager/DataManager';
type Props = {
    language: InfosLanguage | undefined;
    useTooltip?: boolean;
    callBack?: Function;
}

function ToogleLanguage({ language, useTooltip, callBack }: Props) {

    let Target = useRef<HTMLAnchorElement>(null);

    const [showToolTip, setShowToolTip] = useState(false);

    const onGetID = (id: InfosLanguage) => language = id;

    function onRollOver() {
        if (useTooltip)
            setShowToolTip(true);
    }

    function onRollOut() {
        setShowToolTip(false);
    }

    function clickLanguageManager(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {

        if (callBack != undefined)
            callBack(language);

    }

    return (
        <>
            <div className='toogleLanguage' onMouseOver={onRollOver} onMouseLeave={onRollOut} >

                <a ref={Target} href='#' className='bouton-icone language' onClick={clickLanguageManager}>
                    <span />
                    <img src={language?.image} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = DataManager.DATA_PRELOAD_IMAGES[0];;
                    }} />

                </a>
                {
                    (showToolTip && useTooltip) ? <TooltipLanguage callBack={callBack}></TooltipLanguage> : <></>
                }

            </div>

        </>
    )
}

export default ToogleLanguage