import { InfosAssets } from "./InfosAssets";
import { InfosStore } from "./InfosStore";

export class InfosCardTheme extends InfosStore {
    private _assets: Array<InfosAssets>;

    public backs: string;
    public batons: string;
    public coupes: string;
    public deniers: string;
    public epees: string;
    public genders: string;
    public isPub: boolean;
    
    constructor(details?:any) {
        super(details);

        this.backs = details?.backs;
        this.batons = details?.batons;
        this.coupes = details?.coupes;
        this.deniers = details?.deniers;
        this.epees = details?.epees;
        this.genders = details?.genders;
        this.isPub = false;
        this.infinite = false;
        this._assets=[];
    }

    public get assets(): Array<InfosAssets> {
        this._assets = [];
        this._assets.push(new InfosAssets({ id: "backs", url: this.backs }));
        this._assets.push(new InfosAssets({ id: "batons", url: this.batons }));
        this._assets.push(new InfosAssets({ id: "coupes", url: this.coupes }));
        this._assets.push(new InfosAssets({ id: "deniers", url: this.deniers }));
        this._assets.push(new InfosAssets({ id: "epees", url: this.epees }));
        this._assets.push(new InfosAssets({ id: "genders", url: this.genders }));

        return this._assets;
    }

    override parse(details?:any): void {
        if (details != null) {
            this.backs = details.backs;
            this.batons = details.batons;
            this.coupes = details.coupes;
            this.deniers = details.deniers;
            this.epees = details.epees;
            this.genders = details.genders;
            this.isPub = (details.is_pub == "1") ? true : false;
        }
        super.parse(details);
    }
}
