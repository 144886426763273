import React, { useLayoutEffect, useRef } from 'react'
import Fleche from './Fleche'

import gsap from "gsap";

import "./Slider.css";
import { SoundManager } from '../manager/SoundManager';
import DataManager from '../manager/DataManager';


type Props = {
    title: string;
    getPercent: Function;
    initial?:number;
}

function Slider({ title, getPercent, initial }: Props) {
    const Slide = useRef<HTMLSpanElement>(null)

    if(initial==undefined) initial=1;
    let percent: number = initial;


    useLayoutEffect(() => {
        // if(initial==undefined) initial=1;
        // console.log("The Initials "+SoundManager.sfxVolume+ " "+initial);
        gsap.set(Slide.current, { scaleX: initial, transformOrigin:"0px 0px"  });
        return () => {
        };
    }, []);

    const handleSliderAction = (e: MouseEvent) => {

        switch ((e.currentTarget as HTMLElement).id) {
            case "next":
                percent += 0.1*DataManager.DIRECTION;
                break;
            case "prev":
                percent -= 0.1*DataManager.DIRECTION;
                break;
            default:
                //return null
                break;
        }

        if (percent < 0) percent = 0;
        if (percent > 1) percent = 1;

        gsap.to(Slide.current, { duration: 0.5, scaleX: percent, transformOrigin:"0px 0px" });
        getPercent(percent);

        SoundManager.play(SoundManager.BUTTON_CLICK);

    };

    return (
        <div className="slider">
            <Fleche name="prev" href="#" styleName="bleue" direction="left" callBack={handleSliderAction}> </Fleche>
            <div className='container'>
                <span className='sousTitre'>{title}</span>
                <span ref={Slide} className='slide' />
            </div>
            <Fleche name="next" href="#" styleName="bleue" callBack={handleSliderAction}> </Fleche>
        </div>)
}

export default Slider