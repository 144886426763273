import { InfosStore } from "./InfosStore";

export class InfosGame extends InfosStore {

    public name: string;

    public help: string;
    public minplayers: number;
    public maxplayers: number;

    public teams: boolean;

    public constructor(details?: any) {
        super(details);

        this.name = details?.name ? details.name : "";;
        this.help = details?.help ? details.help : "";
        this.minplayers = details?.minplayers ? details.minplayers : 2;
        this.maxplayers = details?.maxplayers ? details.maxplayers : 4;

        this.teams = (details?.teams) ? true : false;

    }

    public get state(): boolean { return this.count > 0 }


}
