import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import IndicateurValidation from '../../componement/IndicateurValidation';
import Panel from '../../componement/Panel';
import { useUser } from '../../context/UserProvider';
import { SoundManager } from '../../manager/SoundManager';
import validator from 'validator';


import './PanelResetPassword.css';
import useRondaFetch from '../../hook/useRondaFetch';
import { useRondaModal } from '../../context/ModalProvider';

type Props = {}

function PanelResetPassword({ }: Props) {

    let navigate = useNavigate();
    let userInfos = useUser()!;


    const { t } = useTranslation();


    let passwordRef = useRef<HTMLInputElement | null>(null);
    let confirmPasswordRef = useRef<HTMLInputElement | null>(null);

    const { handleModal } = useRondaModal()!;


    const [errorPasswordMessage, setErrorPasswordMessage] = useState('')


    const [errorConfirmPasswordMessage, setErrorConfirmPasswordMessage] = useState('')


    const [searchParams] = useSearchParams();
    const code = searchParams.get("code")

    //console.log("code " + code);

    function validateForm() {

        console.log("code " + code);

        console.log(((passwordRef.current as HTMLInputElement).value == (confirmPasswordRef.current as HTMLInputElement).value)
            + " " + (errorPasswordMessage == 'ok')
            + " " + (errorConfirmPasswordMessage == 'ok')
            + " " + (code != undefined))
        if ((passwordRef.current as HTMLInputElement).value == (confirmPasswordRef.current as HTMLInputElement).value
            && errorPasswordMessage == 'ok'
            && errorConfirmPasswordMessage == 'ok'
            && code != undefined)
            resetUserPassword((passwordRef.current as HTMLInputElement).value, (confirmPasswordRef.current as HTMLInputElement).value, code);
        else
            SoundManager.play(SoundManager.CANCEL_DISMISS);

        if ((passwordRef.current as HTMLInputElement).value != (confirmPasswordRef.current as HTMLInputElement).value)
            handleModal(`The two passwords entered are not identical`, "red");

        else if (errorPasswordMessage != 'ok')
            handleModal(`Please enter a valid password`, "red");

        else if (errorConfirmPasswordMessage != 'ok')
            handleModal(`Please enter a valid confirmation password`, "red");

        else if (code == undefined)
            handleModal(`Invalid code: Please access this page from the link received on your email`, "red");


    }

    const { post, get, cache, response } = useRondaFetch()


    async function resetUserPassword(password: string, passwordConfirmation: string, code: string) {
        cache.clear();
        cache.delete();


        await post('/api/auth/reset-password', {
            password: password,
            passwordConfirmation: passwordConfirmation,
            code: code
        })
        if (response.ok) {
            handleModal(`Modified password succefully, you can log in again`, "green");
            navigate("/login", { replace: true });
        }
    }




    const validatePassword = (value: string) => {

        if (validator.isStrongPassword(value, {
            minLength: 6,
            minUppercase: 1, minNumbers: 1
        })) {
            setErrorPasswordMessage('ok');
        } else {
            setErrorPasswordMessage('cancel')
        }
    }

    const validateConfirmPassword = (value: string) => {

        if (validator.isStrongPassword(value, {
            minLength: 6,
            minUppercase: 1, minNumbers: 1
        })) {
            setErrorConfirmPasswordMessage('ok');
        } else {
            setErrorConfirmPasswordMessage('cancel')
        }
    }


    return (
        <>
            <Panel className='reset' title={t('Password')} returnTo="/login">
                <div className='layer'>
                    <div className='layerContainer'>
                        <span className="sousTitre">{t('Enter and confirm your new password')}</span>

                        <form action="">

                            <i className="formIcon icon-password"></i>
                            <IndicateurValidation validationType={errorPasswordMessage} messages={[
                                { name: "ok", value: t('Valid field!') },
                                { name: "cancel", value: <p>{t('The field must be a valid password')}</p> }
                            ]} />
                            <input ref={passwordRef} type="password" onChange={e => validatePassword(e.target.value)} placeholder={t('Password')} >


                            </input>

                            <i className="formIcon icon-password"></i>
                            <IndicateurValidation validationType={errorConfirmPasswordMessage} messages={[
                                { name: "ok", value: t('Valid field!') },
                                { name: "cancel", value: <p>{t('The field must be a valid password')}</p> }
                            ]} />
                            <input ref={confirmPasswordRef} type="password" onChange={e => validateConfirmPassword(e.target.value)} placeholder={t('Password')} ></input>


                            <a href='#' className='bouton blue' onClick={(e) => { validateForm() }}>{t('Reset')}</a>

                        </form>
                    </div>
                </div>
            </Panel>
        </>
    )
}

export default PanelResetPassword