import React, { useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useNavigate } from 'react-router-dom'
import { InfosGame } from '../../data/InfosGame'
import DataManager from '../../manager/DataManager'
import { SoundManager } from '../../manager/SoundManager'
import "./ItemGame.css"
import TooltipDescription from '../../componement/TooltipDescription'
type Props = {
    infos: InfosGame;
    index: number;
    selected?: boolean;
    cursor?: boolean;
    callBack: Function;
    background?: string;
}

function ItemGame({ infos, index, callBack, selected, cursor, background }: Props) {
    let navigate = useNavigate();

    let Target = useRef<HTMLDivElement>(null);
    const [coordinates, setCoordinates] = useState(Target.current?.getBoundingClientRect());
    function onRollOver() {
        setCoordinates(Target.current?.getBoundingClientRect());

    }
    function onRollOut() {
        setCoordinates(undefined);
    }
    return (
        <div className='itemGameContainer'>
            <div ref={Target} className={'itemGame ' + (selected ? "selected " : "") + (cursor ? "cursor" : "")} onMouseOver={onRollOver} onMouseLeave={onRollOut} onClick={() => { callBack(index); SoundManager.play(SoundManager.BUTTON_CLICK) }}>
                {
                    (document.getElementById("App-Container")!)
                        ? createPortal((coordinates) ? <TooltipDescription coordinates={coordinates} message={infos.description} title={infos.title} ></TooltipDescription> : <></>, document.getElementById("App-Container")!)
                        : <></>
                }
                {
                    infos.state
                        ? <><img src={infos.image} onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = DataManager.DATA_PRELOAD_IMAGES[0];
                            currentTarget.setAttribute("style", "left:10px");
                        }} />
                            <img className={"back"} src={background} onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = DataManager.DATA_PRELOAD_IMAGES[1];;
                            }} /></>
                        : <img className={"lock"} src={DataManager.ABSOLUT_PATH + 'assets/icons/Lock.png'} />
                }
            </div>
        </div>

    )
}

export default ItemGame