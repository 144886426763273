import React, { Component, LegacyRef, useEffect, useLayoutEffect, useRef } from 'react'

import { useLocation, useNavigate } from "react-router-dom";
import Stepper from '../../componement/Stepper';

import "./PanelModeSolo.css"
import { InfosParametres } from '../../data/InfosParametres';
import { SoundManager } from '../../manager/SoundManager';

import { useTranslation } from 'react-i18next';
import Panel from '../../componement/Panel';
import { InfosGame } from '../../data/InfosGame';
import { useUser } from '../../context/UserProvider';
import { InfosModes } from '../../data/InfosModes';
import { LocationState } from '../../data/CustomInterfaces';
import { log } from '../../manager/FirebaseManager';

type Props = {
}

export default function PanelModeSolo({ }: Props) {

    let navigate = useNavigate();
    let userInfos = useUser()!;

    let location = useLocation();

    const infosGame = (location.state as LocationState)?.infosGame;
    useEffect(() => {
        if (!infosGame) {
            navigate('/home');
        }
    }, []);

    let checkboxref = useRef<HTMLInputElement | null>(null);

    const { t } = useTranslation();

    var numberOfPlayers: number = 0;
    var numberOfRounds: number = 0;

    const onGetNumberOfPlayer = (id: number) => { numberOfPlayers = id + 2; }
    const onGetNumberOfRound = (id: number) => { numberOfRounds = id + 1; }



    function updatePreferences() {

        log("update_game_preferences", { type: "Training" })

        userInfos.infosParametres = new InfosParametres({ speed: userInfos.infosPreferences.speed, numberOfPlayers: numberOfPlayers, numberOfRounds: numberOfRounds, cheatMode: (checkboxref.current as HTMLInputElement).checked })

        /*setUserInfos((prevIU: InfosUser) => ({
            ...prevIU,
            InfosParametres: {
                ...prevIU.InfosParametres,
                numberOfPlayers: numberOfPlayers,
                numberOfRounds: numberOfRounds,
                cheatMode: (checkboxref.current as HTMLInputElement).checked
            }

        }));*/
        let mode = new InfosModes({ Parametres: userInfos.infosParametres });

        navigate("/" + infosGame.name, { state: { infosMode: mode } });

        SoundManager.play(SoundManager.VALIDATE_STARTGAME);
    }

    function setItem(start: number, limit: number) {
        let row: Array<JSX.Element> = [];
        for (var i = start; i <= limit; i++) {
            row.push(<span key={"Key" + (start) + "-" + (limit) + '-' + ((start * limit) + i)} className="numero">{i}</span>);
        }
        return row;
    }

    return (
        <>
            <Panel className='modeSolo' title={t('Training Mode')} returnTo="/home">
                <div className='layer'>
                    <div className='layerContainer'>
                        <span className="sousTitre">{t('Number of players')}</span>
                        <Stepper className="small" initial={userInfos.infosParametres.numberOfPlayers - 2} getID={onGetNumberOfPlayer}>
                            {
                                setItem(infosGame?.minplayers, infosGame?.maxplayers)
                            }
                        </Stepper>
                        <span className="sousTitre">{t('Number of rounds')}</span>
                        <Stepper className="small" initial={userInfos.infosParametres.numberOfRounds - 1} getID={onGetNumberOfRound}>
                            {
                                setItem(1, 99)
                            }
                        </Stepper>
                        <label className='checkBox' onClick={e => SoundManager.play(SoundManager.BUTTON_CLICK)}>
                            <input ref={checkboxref} type="checkbox" defaultChecked={userInfos.infosParametres.cheatMode} />
                            <span className="knob">{t('Special Mode')}</span>
                            <span className="slideDeck"></span>
                        </label>
                    </div>
                </div>
                <a href='#' className='bouton' onClick={(e) => { updatePreferences(); }}>{t('Play')}</a>
            </Panel>
        </>
    )
}
