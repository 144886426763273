import { Texture, Scene, ActionManager, Nullable, IAction, ExecuteCodeAction, Color3, DirectionalLight, PointerInfo, Observer, Mesh, SubMesh, Vector3, StandardMaterial, MultiMaterial, DynamicTexture, ShadowGenerator, VideoTexture, PointerEventTypes, Vector4, BaseTexture, MirrorTexture, Plane } from "@babylonjs/core";
import * as BEVELEDCARDINFOS from '../data/BeveledCardInfos';
import * as earcut from "earcut";
import { MeshBuilder } from '@babylonjs/core';

import gsap from "gsap";

import DataManager from '../manager/DataManager';
import { InfosTheme } from "../data/InfosTheme";
import { InfosCardTheme } from "../data/InfosCardTheme";
import { Material } from "cannon";

interface TextureCoordinates {
    x: number;
    y: number;
}

interface TextureInfos {
    textureCoordinates: TextureCoordinates;
    src: string;
}

export class SceneManager {

    static cardsList: Array<Mesh> = [];
    static cardsTextureList: Array<BaseTexture> = [];
    static scene: Scene;
    static self: SceneManager;
    private observer: Nullable<Observer<PointerInfo>>;

    onReady: Function;
    onLoad: Function;
    onUpdate: Function;
    private actionMouseUp: Nullable<IAction>;
    private static useAd: boolean;

    noAd: boolean;

    EightStarBranchShape = [new Vector3(1.5, 0, 0),
    new Vector3(2, 0, 0.5),
    new Vector3(2.5, 0, 0.5),
    new Vector3(2.5, 0, 1),
    new Vector3(3, 0, 1.5),
    new Vector3(2.5, 0, 2),
    new Vector3(2.5, 0, 2.5),
    new Vector3(2, 0, 2.5),
    new Vector3(1.5, 0, 3),
    new Vector3(1, 0, 2.5),
    new Vector3(0.5, 0, 2.5),
    new Vector3(0.5, 0, 2),
    new Vector3(0, 0, 1.5),
    new Vector3(0.5, 0, 1),
    new Vector3(0.5, 0, 0.5),
    new Vector3(1, 0, 0.5)];

    EightStarBranchFaceUV = [new Vector4(0, 0, 0.5, 0.5),
    new Vector4(0, 0, 0, 0),
    new Vector4(0, 1, 1, 0)];

    private static imageCache: Record<string, HTMLImageElement> = {}; // Cache to store loaded images

    constructor(scene: Scene, onReady: Function, onLoad: Function, onUpdate: Function) {
        SceneManager.scene = scene;


        SceneManager.useAd = true;
        this.noAd = false;

        this.observer = null;
        this.onReady = onReady;

        this.onLoad = onLoad;

        this.onUpdate = onUpdate;

        this.actionMouseUp = null;

        this.buildCards();

        // let polygon = MeshBuilder.ExtrudePolygon("star", { shape: this.EightStarBranchShape }, scene, earcut);

        // let on = Mesh.CreateDisc("circle", 1.5, 16, scene);

        // polygon.position.y = 10;
        // on.position.y = 10;

        // let mat = new StandardMaterial("matVideo", scene);
        // mat.specularColor = Color3.Black();

        // polygon.material = mat;
        // on.material = mat;
    }



    private buildCards(): void {


        //console.log("this.props.scene" + this.props.scene);
        //console.log("buildCards CartaScene " + scene +"  BeveledCardShape  "+BeveledCardShape+"  BeveledCardFaceUV  "+BeveledCardFaceUV);

        if (DataManager.CARDS_LIST.length == 0 && SceneManager.scene && ((SceneManager.scene.getLightByName("ShadowLight") as DirectionalLight).getShadowGenerator())) {

            let macarte = MeshBuilder.ExtrudePolygon("card", { shape: BEVELEDCARDINFOS.BeveledCardShape, depth: 0.1, sideOrientation: Mesh.FRONTSIDE, faceUV: BEVELEDCARDINFOS.BeveledCardFaceUV, updatable: true, wrap: true }, SceneManager.scene, earcut);

            //let macarte = MeshBuilder.ExtrudeShape("card", {shape: BEVELEDCARDINFOS.BeveledCardShape, path:BEVELEDCARDINFOS.BeveledCardPath,sideOrientation: Mesh.DOUBLESIDE, updatable: true, cap: Mesh.CAP_ALL },scene);

            //macarte.doNotSyncBoundingInfo = true;
            macarte.subMeshes = [];

            macarte.position = new Vector3(0, 1, 0);

            var verticesCount = macarte.getTotalVertices();

            new SubMesh(0, 0, verticesCount, 0, 55, macarte);
            new SubMesh(1, 0, verticesCount, 60, 168, macarte);

            var frontMaterial = new StandardMaterial("frontMaterial", SceneManager.scene as Scene);
            var backMaterial = new StandardMaterial("backMaterial", SceneManager.scene as Scene);

            /*
                  frontMaterial.backFaceCulling=false;
                  frontMaterial.fogEnabled=true;
            
                  backMaterial.backFaceCulling=true;
                  backMaterial.fogEnabled=true;
            */

            var multimat = new MultiMaterial("multi", SceneManager.scene as Scene);
            multimat.subMaterials.push(frontMaterial);
            multimat.subMaterials.push(backMaterial);

            macarte.material = multimat;

            macarte.scaling = new Vector3(0.25, 0.25, 0.25);
            //macarte.rotation = new Vector3 (0,0,100* (Math.PI / 180));

            var textureResolution = 1024;
            var frontTexture = new DynamicTexture("frontTexture", textureResolution, SceneManager.scene, false);
            //var textureContext = frontTexture.getContext();
            //var frontTexture = new Texture("http://jerome.bousquie.fr/BJS/images/spriteAtlas.png", scene);
            frontMaterial.diffuseTexture = frontTexture;
            frontMaterial.specularPower = 2000;
            frontMaterial.specularColor = Color3.White();

            //frontMaterial.diffuseColor = Color3.Gray();

            //   var backTexture = new DynamicTexture("backTexture", [192, 294], scene, false); 

            backMaterial.specularPower = 2000;
            backMaterial.specularColor = Color3.White();


            /*var backTexture = new Texture(DataManager.DEFAULT_CARD_THEME.backs, SceneManager.scene);
            backMaterial.diffuseTexture = backTexture;*/

            this.setBackImage(DataManager.DEFAULT_CARD_THEME.backs);


            //backMaterial.diffuseColor = Color3.Red();

            //  this.setTextureBackImage(macarte,DATAMANAGER.THEME_BACKS,1);

            //this.setTextureImage(macarte, DataManager.DEFAULT_CARD_THEME.backs);

            // let cardInfosList = new Array();

            var macartess;
            var lng: number = DataManager.CARD_GENDERS.length;
            //var zOffset: number = 0;
            for (let i = 0; i < lng; i++) {
                for (let j = 1; j <= 10; j++) {
                    //zOffset--;
                    macartess = macarte.clone("card" + ((i + 1) * j));

                    ((SceneManager.scene.getLightByName("ShadowLight") as DirectionalLight).getShadowGenerator() as ShadowGenerator).addShadowCaster(macartess);

                    SceneManager.cardsList.push(macartess);
                    if (macartess.material) {

                        macartess.material = macartess.material.clone("mat" + ((i + 1) * j));
                        /*
                        if (macartess.material) {
                            macartess.material.zOffset = zOffset;
                            //macartess.material.useLogarithmicDepth
                        }
                        */
                        let mat: StandardMaterial = (macartess.material as MultiMaterial).subMaterials[0] as StandardMaterial;
                        if (mat) {
                            (macartess.material as MultiMaterial).subMaterials[0] = mat.clone("frontMaterial" + ((i + 1) * j));
                            //mat.zOffset = zOffset;

                            //(macartess.material as MultiMaterial).subMaterials[1] = backMaterial;
                            if (mat.diffuseTexture) mat.diffuseTexture = mat.diffuseTexture?.clone();
                        }
                    }

                    //console.log("zOffset "+macartess.material?.zOffset+" - "+(macartess.material as MultiMaterial).subMaterials[0]?.zOffset);
                    macartess.metadata = { gender: DataManager.CARD_GENDERS[i], number: j };

                    var ti: TextureInfos = this.setType(DataManager.CARD_GENDERS[i], DataManager.DEFAULT_CARD_THEME, j);

                    this.setTextureImage(macartess, ti.src, ti.textureCoordinates);

                    macartess.position = new Vector3((j * 3) - 16.5, 1, (i * 5) - 7.5);
                }
            }
            SceneManager.scene.removeMesh(macarte);

            SceneManager.cardsList.forEach((pcarte: Mesh, index: number) => {
                gsap.from(pcarte.position, { duration: 1, delay: 1 + (0.05 * index), y: 40, z: 30, overwrite: 'auto', ease: "power2.out" });
                gsap.from(pcarte.rotation, { duration: 1, delay: 1 + (0.05 * index), x: 180 * (Math.PI / 180), overwrite: 'auto', ease: "power2.out" });
            });

            //DataManager.CARDS_LIST = SceneManager.cardsList;
            this.onReady(SceneManager.cardsList);
            //document.dispatchEvent( new CustomEvent(CardEvent.CARDS_CREATED, {detail : {CARDS_LIST :cardsList}, bubbles:true }));
        }
    }







    updateAssets(infosTheme?: InfosTheme, infosCardTheme?: InfosCardTheme): void {

        // 
        this.onLoad(true, "full");

        if (infosTheme != undefined) {
            this.setMosaicImage(infosTheme.mosaic);
            this.setFloorImage(infosTheme.floor);
        }


        if (infosCardTheme != undefined) {

            this.setBackImage(infosCardTheme.backs);

            var lng: number = DataManager.CARD_GENDERS.length;
            var ti: TextureInfos;

            SceneManager.cardsTextureList = [];
            for (var i: number = 0; i < lng; i++) {
                for (var j: number = 1; j <= 10; j++) {
                    ti = this.setType(DataManager.CARD_GENDERS[i], infosCardTheme, j);
                    let cardNumber = (i * 10) + (j - 1);
                    this.setTextureImage(SceneManager.cardsList[cardNumber], ti.src, ti.textureCoordinates, cardNumber);
                }
            }
        }

    }
    private setMosaicImage(image: string): void {
        var mat: StandardMaterial = SceneManager.scene.getMaterialByName("Mosaic") as StandardMaterial;
        var texture = new Texture(image, SceneManager.scene);
        texture.hasAlpha = true;
        mat.diffuseTexture = texture;

        // var reflector = new Plane(0, -1.0, 0, -2.0);

        // mat.reflectionTexture = new MirrorTexture("mirror", 1024, SceneManager.scene, true);
        // (mat.reflectionTexture as MirrorTexture).mirrorPlane = reflector;
        // (mat.reflectionTexture as MirrorTexture).renderList = SceneManager.cardsList;
        // (mat.reflectionTexture as MirrorTexture).level = 1;



    }

    private setFloorImage(image: string): void {
        var mat: StandardMaterial = SceneManager.scene.getMaterialByName("Floor") as StandardMaterial;
        var texture = new Texture(image, SceneManager.scene);
        mat.diffuseTexture = texture;

        // mat.reflectionTexture = new MirrorTexture("mirror", { ratio: 0.5 }, SceneManager.scene, true);
        // (mat.reflectionTexture as MirrorTexture).mirrorPlane = new Plane(0, -1.0, 0, -2.0);
        // (mat.reflectionTexture as MirrorTexture).renderList = SceneManager.cardsList;
        // (mat.reflectionTexture as MirrorTexture).level = 1.0;
        // (mat.reflectionTexture as MirrorTexture).adaptiveBlurKernel = 32;
    }

    private setType(gender: string, infosCardTheme: InfosCardTheme, _number: number): TextureInfos {
        var numY: number = 0;
        var numX: number = _number;
        if (_number > 5) numY = 1;
        if (_number > 5) numX = _number - 5;
        numX -= 1;


        var tc: TextureCoordinates = { x: numX, y: numY };
        var src: string = "";

        switch (gender) {
            case "denier":
                src = infosCardTheme.deniers;//DataManager.THEME_DENIERS;
                break;
            case "coupe":
                src = infosCardTheme.coupes;//DataManager.THEME_COUPES;
                break;
            case "epee":
                src = infosCardTheme.epees;//DataManager.THEME_EPEES;
                break;
            case "baton":
                src = infosCardTheme.batons;//DataManager.THEME_BATONS;
                break;
        }

        return { textureCoordinates: tc, src: src };
    }

    private setTextureImage(macartes: Mesh, src: string, option: TextureCoordinates = { x: 0, y: 0 }, cardNumber: number = 0): void {
        const textureResolution = 1024;

        if (SceneManager.imageCache[src]) {
            this.applyTexture(macartes, SceneManager.imageCache[src], option, textureResolution, cardNumber);
        } else {


            // var targetTexture = ((macartes.material as MultiMaterial).subMaterials[0] as StandardMaterial).diffuseTexture as DynamicTexture;
            // var textureContext = targetTexture.getContext();

            let img = new Image();
            let self = this;
            img.src = src;
            img.crossOrigin = "anonymous";
            img.onload = function () {

                // Add the loaded image to the cache
                SceneManager.imageCache[src] = img;
                // Apply the texture
                self.applyTexture(macartes, img, option, textureResolution, cardNumber);


                //Add image to dynamic texture
                // textureContext.drawImage(img, 192 * option.x, 294 * option.y, 192, 294, 0, textureResolution / 2, textureResolution / 2, textureResolution / 2)
                // targetTexture.update();
                // SceneManager.cardsTextureList.push(targetTexture);

                // if (cardNumber == 39) self.onLoad(false)
            }
            img.onerror = function () {
                self.onLoad(false);
            }
        }
    }


    private applyTexture(macartes: Mesh, img: HTMLImageElement, option: TextureCoordinates, textureResolution: number, cardNumber: number): void {

        macartes.material!.unfreeze();

        var targetTexture = ((macartes.material as MultiMaterial).subMaterials[0] as StandardMaterial).diffuseTexture as DynamicTexture;
        var textureContext = targetTexture.getContext();

        // Add the image to the dynamic texture at the specified coordinates
        textureContext.drawImage(img, 192 * option.x, 294 * option.y, 192, 294, 0, textureResolution / 2, textureResolution / 2, textureResolution / 2);

        // Update the dynamic texture
        targetTexture.update();

        macartes.material!.freeze();
        // Store the dynamic texture in a global list
        SceneManager.cardsTextureList.push(targetTexture);

        // If the cardNumber is 39, trigger the onLoad function
        if (cardNumber === 39) this.onLoad(false);
    }






















    private getAd(list: Array<any>) {

        let element = list[Math.floor((Math.random() * list.length | 0))];
        if (element && !this.noAd) {
            this.onUpdate(element);
            return element;
        } else {
            return ""
        }
    }

    private setBackImage(image: string): void {

        let ad = this.getAd(DataManager.DATA_ADVERSOS);
        let self = this;
        if (ad) {
            image = ad.image;

            this.observer = SceneManager.scene.onPointerObservable.add((pointerInfo: PointerInfo) => {
                switch (pointerInfo.type) {
                    case PointerEventTypes.POINTERUP:
                        if (SceneManager.useAd && pointerInfo.pickInfo && pointerInfo.pickInfo.pickedMesh && pointerInfo.pickInfo.pickedMesh.name.includes("card") && Math.abs(pointerInfo.pickInfo.pickedMesh?.rotation.z).toFixed(2) === Math.abs(180 * (Math.PI / 180)).toFixed(2)) {
                            window.open(ad.link, "_blank");
                            //console.log(ad.link);
                            SceneManager.scene.onPointerObservable.remove(self.observer);
                        }
                        break;
                }
            });
        } else {
            SceneManager.scene.onPointerObservable.remove(this.observer);
        }

        var backMaterial: StandardMaterial = SceneManager.scene.getMaterialByName("backMaterial") as StandardMaterial;
        var texture = new Texture(image, SceneManager.scene);
        backMaterial.diffuseTexture = texture;


    }




























    setAdVideo(callBack: Function): void {

        let ad = this.getAd(DataManager.DATA_ADVIDEOS);
        //console.log("setAdVideo " + ad.video);
        if (ad && !this.noAd) {
            let video = ad.video;
            var AdMaterial: StandardMaterial = SceneManager.scene.getMaterialByName("Ad") as StandardMaterial;
            var videoTexture = new VideoTexture("video", video, SceneManager.scene as Scene, true);


            const endVideo = (e: any) => {

                //console.log("Video Ended " + e);

                //AdMaterial.alpha = 0;
                this.removeAdVideo();
                //this.disposeAdVideo(videoTexture);
                callBack();

            };
            videoTexture.video.loop = false;
            //videoTexture.video.play();
            videoTexture.video.onended = endVideo;
            videoTexture.video.onabort = endVideo;
            videoTexture.video.onerror = endVideo;
            //videoTexture.video.onsuspend = endVideo;


            AdMaterial.diffuseTexture = videoTexture;
            AdMaterial.alpha = 1;

            this.AddInteractivitytoAdOverlay(ad.link);




        } else {
            callBack();
        }
    }


    private AddInteractivitytoAdOverlay(link: string) {

        let VideoAd = SceneManager.scene.getMeshByName("videoad");

        if (VideoAd == null) return;

        VideoAd.actionManager = new ActionManager(SceneManager.scene);

        this.actionMouseUp = VideoAd.actionManager.registerAction(
            new ExecuteCodeAction(
                {
                    trigger: ActionManager.OnPickDownTrigger,
                },
                function (e) {
                    window.open(link, "_blank");
                    ((VideoAd?.material as StandardMaterial).diffuseTexture as VideoTexture).video.play();
                }
            )
        );
        gsap.set(VideoAd.scaling, { x: 1, z: 1 });
        gsap.from(VideoAd.scaling, { duration: 0.5, x: 0, z: 0, overwrite: 'auto', ease: "power2.out" });

    }

    removeAdVideo() {

        let VideoAd = SceneManager.scene.getMeshByName("videoad");
        if (VideoAd == null) return;

        gsap.to(VideoAd.scaling, { duration: 0.5, x: 0, z: 0, onComplete: this.disposeAdVideo.bind(this), overwrite: 'auto', ease: "power3.out" });
        //console.log("removeAdVideo");
        //this.complete();
    }

    disposeAdVideo() {

        let VideoAd = SceneManager.scene.getMeshByName("videoad");

        if (VideoAd == null) return;

        VideoAd?.actionManager?.unregisterAction(this.actionMouseUp as IAction);

        var AdMaterial: StandardMaterial = SceneManager.scene.getMaterialByName("Ad") as StandardMaterial;
        AdMaterial.alpha = 0;

        const videoTexture = (AdMaterial.diffuseTexture as VideoTexture);

        if (videoTexture == null) return;

        const videoEl = videoTexture?.video;

        // Dispose texture
        videoTexture.dispose();

        // Remove any <source> elements, etc.
        while (videoEl.firstChild) {
            videoEl.removeChild(videoEl.lastChild as Node);
        }

        // Set a blank src
        videoEl.src = ''

        // Prevent non-important errors in some browsers
        videoEl.removeAttribute('src')

        // Get certain browsers to let go
        videoEl.load()

        videoEl.remove()
    }















    private setPickable(value: boolean) {
        SceneManager.cardsList.forEach((element: Mesh) => {
            element.isPickable = true;
            element.getChildMeshes().forEach((mesh) => {
                mesh.isPickable = true;
            });
        });
    }




    static initAd(callBack: Function) {
        this.self.setAdVideo(callBack);
        this.useAd = false;
        //this.self.setPickable(this.useAd);
    }


    static disposeAd() {
        this.self.disposeAdVideo();
        this.useAd = true;
        // this.self.setPickable(this.useAd);

    }

    /*setTextureBackImage(macartes: Mesh, src: string, target: number, option: TextureCoordinates = { x: 0, y: 0 }): void {
        var textureResolution = 512;

        var targetTexture = ((macartes.material as MultiMaterial).subMaterials[1] as StandardMaterial).diffuseTexture as DynamicTexture;

        //targetTexture.invertY = true;
        targetTexture.scaleTo(-1, 1);

        var textureContext = targetTexture.getContext();

        var img = new Image();
        img.src = "assets/themes/cards/classique/Deniers.gif";
        img.onload = function () {
            //Add image to dynamic texture
            textureContext.drawImage(img, 192 * option.x, 294 * option.y, 192, 294, 0, 0, 200, 200)
            targetTexture.update();
        }
    }*/
}