import { ILoadingScreen } from "@babylonjs/core";

import gsap from "gsap";

export class LoadingScreenManager implements ILoadingScreen {

    element: HTMLElement | null;
    public loadingUIBackgroundColor: string

    constructor(public loadingUIText: string) {
        this.loadingUIBackgroundColor = ""
        this.element = null;
    }


    public displayLoadingUI() {
        this.element = document.getElementById("loadingScreen");
        if (this.element) {
            //this.element.style.display = "flex";
            // Do not add a loading screen if there is already one
            return;
        } else {
            let _loadingDiv: HTMLDivElement = document.createElement("div");
            _loadingDiv.id = "loadingScreen";

            let loader: HTMLSpanElement = document.createElement("span");
            loader.id = "spin";
            loader.innerHTML = this.loadingUIText;

            _loadingDiv.appendChild(loader);
            document.body.prepend(_loadingDiv);
            this.element = document.getElementById("loadingScreen");

        }
        // console.log("displayLoadingUI");

        let spin = document.getElementById("spin");

        gsap.from(this.element, { duration: 0.5, autoAlpha: 1, ease: "power2" });
        gsap.from(spin, { delay: 0.3, duration: 1, autoAlpha: 1, scale: 0, ease: "power2" });

    }


    public hideLoadingUI() {

        this.element = document.getElementById("loadingScreen");
        //if (this.element) this.element.style.display = "none";

        let spin = document.getElementById("spin");

        gsap.to(this.element, { duration: 0.5, autoAlpha: 0, ease: "power2" });
        gsap.to(spin, { duration: 1, autoAlpha: 0, scale: 0, ease: "power2" });

    }
}