import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useUser, useUserUpdate } from '../../context/UserProvider';
import InfosProfil from '../../data/InfosProfil';

import { SoundManager } from '../../manager/SoundManager';
import Fleche from '../../componement/Fleche';
import Panel from '../../componement/Panel'


import "./PanelConditions.css"
import useRondaFetch from '../../hook/useRondaFetch';
import { InfosUser } from '../../data/InfosUser';

type Props = {

}

function PanelConditions({ }: Props) {
    let navigate = useNavigate();

    const { t } = useTranslation();
    let { type } = useParams();

    let userInfos = useUser()!;
    let setUserInfos = useUserUpdate()!;

    const [data, setData] = useState<string>("");

    const tabs: Array<string> = ["terms-of-use", "privacy-policy"]

    const tabsTranslated: Array<string> = ["Terms Of Use", "Privacy Policy"]


    const [active, setActive] = useState<string>(tabs[1]);

    const Carousel = useRef<HTMLDivElement>(null)

    const { get, cache, response } = useRondaFetch();


    async function updateTermsOfUse() {
        await get('api/rule/');

        if (response.ok) {

            setData(response.data?.data?.attributes?.TermsOfUse);
        }
    }

    async function updatePrivacyPolicy() {
        await get('api/rule/');

        if (response.ok) {

            setData(response.data?.data?.attributes?.PrivacyPolicy);
        }
    }


    function openTabs(tabsName: string) {

        setActive(tabsName);

        switch (tabsName) {
            default:
            case 'privacy-policy':
                updatePrivacyPolicy();
                break;
            case 'terms-of-use':
                updateTermsOfUse();
                break;
        }
        SoundManager.play(SoundManager.BUTTON_CLICK);
        index = 0;
        Carousel.current!.scrollTop = 0;
    }

    useEffect(() => {
        if (type == undefined) type = tabs[1];
        openTabs(type);

        return () => {

        }
    }, [type])

    let index = 0;

    const handleCarouselAction = (e: MouseEvent) => {
        e.preventDefault();

        //let indexRef = index;
        switch ((e.currentTarget as HTMLElement).id) {
            case "next":
                index += 1;
                break;
            case "prev":
                index -= 1;
                break;
            default:
                break;
        }

        if (index < 0) index = 0;
        Carousel.current!.scrollTop = index * 100;

    }

    return (
        <Panel className='conditions' title={t('Legal Notices')} returnTo="/home">
            <ol className='onglet'>
                {
                    tabs.map((name: string, index: number) => {
                        return (
                            <li key={name} className={active === name ? "active" : ""} onClick={() => /*openTabs(name)*/ navigate('/legal-notices/' + name)}>{t(tabsTranslated[index])}</li>
                        );
                    })
                }
            </ol>
            <Fleche name="next" href="#" styleName="green" callBack={handleCarouselAction} > </Fleche>
            <Fleche name="prev" href="#" styleName="green" callBack={handleCarouselAction} direction="left"> </Fleche>

            <div className='layer'>
                <div className='layerContainer'>
                    {
                        <div ref={Carousel} className='textScroll' dangerouslySetInnerHTML={{ __html: data }} />
                    }
                </div>
            </div>
        </Panel>
    )
}

export default PanelConditions