import React, { ReactNode } from 'react'
import { TextManager } from '../manager/TextManager';

import "./Indicateur.css";

type Props = {
  buck: number;
  children?: ReactNode;
}

function IndicateurBuck({ buck, children }: Props) {

  return (
    <div className='indicateur'>
      <span className='numero vert'>{TextManager.formatScore(buck, 7)}</span>
      <span className='iconBuck' />
      {
        children
      }
    </div>
  )
}

export default IndicateurBuck