import React, { ReactNode, useEffect, useLayoutEffect, useRef, useState } from 'react'

import "./Modal.css"
import gsap from 'gsap';
import { SoundManager } from '../manager/SoundManager';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

type Props = {
    type: string;
    trigger: boolean;
    autoClose: boolean
    content: string;
    children?: ReactNode;
}

function Modal({ children, trigger, content, type, autoClose }: Props) {
    const container = useRef<HTMLDivElement>(null);

    const { t } = useTranslation();

    const [text, setText] = useState<string>("");

    useLayoutEffect(() => {
        //console.log("useLayoutEffect autoClose " + autoClose)

        switch (type) {
            case "red":
                SoundManager.play(SoundManager.CANCEL_DISMISS);
                break;
            case "green":
                SoundManager.play(SoundManager.VALIDATE_STANDARD);
                break;
        }
        if (content != "") {
            gsap.killTweensOf(container.current);
            gsap.set(container.current, { display: "block", scale: 1, visibility: "visible", autoAlpha: 1, y: 0 });
            gsap.from(container.current, { duration: 1, x: 0, y: -50, onComplete: onComplete, autoAlpha: 1, ease: "bounce.out" });
        } else {
            gsap.set(container.current, { display: "none" });
        }

    }, [trigger]);

    function onComplete() {
        //console.log("onComplete autoClose " + autoClose)

        if (autoClose) {
            hideModal();
        }
    }

    function hideModal(delay: number = 5) {
        gsap.to(container.current, { delay: delay, duration: 0.3, autoAlpha: 0, scale: 0, onComplete: erase, ease: "power2" });
    }
    function erase() {
        gsap.set(container.current, { display: "none" });
    }

    useEffect(() => {

        let messages = content.split("|");
        let message = "";

        messages.forEach((msg) => {
            //if (t) message += t(msg); else
            message += t(msg)
        })

        setText(message)
    }, [content])

    return (
        <div ref={container} className={'modal ' + type}>
            <span dangerouslySetInnerHTML={{ __html: text }}></span>
            <a className='bouton-icon copper icon-cancel' onClick={() => hideModal(0)}></a>
        </div>)
}

export default Modal