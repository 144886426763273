export class TextManager {


    static formatScore(nbr: number = 0, length: number = 6): string {

        //return nbr.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        return nbr + "";
        //return nbr + "";
        // var i: number;
        // var points: string = "";
        // var lng: number = (length - String(nbr).length);
        // if (lng < 0) lng = 0;
        // for (i = 0; i < lng; i++) {
        //     points += "0";
        // }
        // points += String(nbr);
        // return points;
    }

}