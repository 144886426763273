export class InfosPreferences {

    public soundVolume: number;
    public musicVolume: number;
    public speed: number;

    id: number;

    public constructor(details?: any) {
        if (details) {
            this.id = details.id;

            this.soundVolume = Math.floor(details.soundVolume);
            this.speed = details?.speed;
            this.musicVolume = Math.floor(details.musicVolume);
        } else {
            this.id = 0;
            this.soundVolume = 100;
            this.musicVolume = 50;
            this.speed = 0.5;
        }
    }

}
