import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Panel from '../../componement/Panel';

import jwt_decode from 'jwt-decode';

import "./PanelSubscription.css"
import IndicateurValidation from '../../componement/IndicateurValidation';
import validator from 'validator';
import { useUser, useUserUpdate } from '../../context/UserProvider';
import { SoundManager } from '../../manager/SoundManager';
import { useRondaModal } from '../../context/ModalProvider';
import useRondaFetch from '../../hook/useRondaFetch';
import { log } from '../../manager/FirebaseManager';
import FacebookLogin from '@greatsumini/react-facebook-login';

type Props = {}

function PanelSubscription({ }: Props) {


    let nameRef = useRef<HTMLInputElement | null>(null);

    let emailRef = useRef<HTMLInputElement | null>(null);
    let passwordRef = useRef<HTMLInputElement | null>(null);


    let navigate = useNavigate();
    let userInfos = useUser()!;
    let updateUserInfos = useUserUpdate()!;

    const { t } = useTranslation();

    const signInDiv = useRef<HTMLDivElement>(null);






    useEffect(() => {

        /* global google */
        if ((window as any).google)
            (window as any).google.accounts.id.initialize({
                client_id: process.env.REACT_APP_GOOGLE_CREDENTIALS,
                callback: onGetResponse,
            })

        function onGetResponse(response: any) {
            let obj: any = jwt_decode(response.credential);

            log("sign_up", { type: "google" });

            subscribeUser(obj.email,
                obj.name,
                obj.sub,
                obj.picture);

        }

        return () => {

        }
    }, [])


    useEffect(() => {
        /* global google */
        if ((window as any).google)
            (window as any).google.accounts.id.renderButton(
                signInDiv.current,
                {
                    type: 'standard',//icon 
                    theme: 'outline',// outline filled_black
                    size: 'large', //large
                })

        return () => {

        }
    }, [])

    const responseFacebook = (response: any) => {
        console.log(response);
        log("sign_up", { type: "facebook" });

        subscribeUser(response.email,
            response.name,
            response.id,
            response.picture.data.url);
    }

    const [errorMessage, setErrorMessage] = useState('')
    const [errorEmailMessage, setErrorEmailMessage] = useState('')

    const [errorNameMessage, setErrorNameMessage] = useState('')



    const fetchName = useRondaFetch(undefined, undefined, undefined, "mini");

    async function checktUserName(name: string) {
        fetchName.cache.delete();
        const newTodo = await fetchName.post('api/checkuser/', {
            username: name,
        })
        if (fetchName.response.ok) {
            console.log("is User Name availble ?" + fetchName.response.data);
            if (!fetchName.response.data) {
                setErrorNameMessage('notavailable')
            }
        }
    }

    const validateName = (value: string) => {

        if (validator.isLength(value, { min: 3, max: 20 }) && validator.matches(value, /^[a-zA-Z0-9 ]*$/)) {
            checktUserName(value);
            setErrorNameMessage('ok');
        } else {
            setErrorNameMessage('cancel')
        }
    }


    async function checktUserEmail(email: string) {
        fetchName.cache.delete();
        const newTodo = await fetchName.post('api/checkuser/', {
            email: email,
        })
        if (fetchName.response.ok) {
            if (!fetchName.response.data) {
                setErrorEmailMessage('notavailable')
            }
        }
    }

    const validateEmail = (value: string) => {

        if (validator.isEmail(value)) {
            checktUserEmail(value)
            setErrorEmailMessage('ok');
        } else {
            setErrorEmailMessage('cancel')
        }
    }

    const validatePassword = (value: string) => {

        if (validator.isStrongPassword(value, {
            minLength: 6,
            minUppercase: 1, minNumbers: 1
        })) {
            setErrorMessage('ok');
        } else {
            setErrorMessage('cancel')
        }
    }



    function validateForm() {
        log("sign_up", { type: "web" });

        if (errorMessage == 'ok' && errorEmailMessage == 'ok' && errorNameMessage == 'ok')
            subscribeUser((emailRef.current as HTMLInputElement).value.toLocaleLowerCase(),
                (nameRef.current as HTMLInputElement).value,
                (passwordRef.current as HTMLInputElement).value);
        else
            SoundManager.play(SoundManager.CANCEL_DISMISS);

    }

    const { get, post, cache, response } = useRondaFetch()

    async function subscribeUser(email: string, username: string, password: string, picture?: string) {


        const newTodo = await post('api/setuser/', {
            email: email,
            username: username,
            password: password,
            picture: picture,
            language: userInfos.infosProfil.infosSettings.idInfosLanguage
        })
        if (response.ok) {
            //let infos = new InfosUser(response.data.infosUser);
            //updateUserInfos(infos);
            //i18next.changeLanguage(DataManager.DATA_LANGUAGES.find(i => i.id == infos.infosProfil.infosSettings.idInfosLanguage)?.locale);
            //SoundManager.updateVolume(infos.infosPreferences.soundVolume / 100, infos.infosPreferences.musicVolume / 100);
            navigate("/home", { replace: true });


            //handleModal(`Weldome ${infos.infosProfil.name} !`, "green");

            cache.clear();
        }


    }

    const { handleModal } = useRondaModal()!;









    return (
        <>
            {
                //loading && <LoadingScreen />
            }
            <Panel className='subscription' title={t('Register')} returnTo="/">
                <div className='layer first'>
                    <div className='layerContainer'>
                        <span className='message'>{t('Please fill in the following fields to create your account.')}</span>
                        <form action="">

                            <i className="formIcon icon-user"></i>
                            <IndicateurValidation validationType={errorNameMessage} messages={[
                                { name: "ok", value: t('Valid field!') },
                                {
                                    name: "cancel", value: <><p>{t('The field must contain:')}</p>
                                        <ol>
                                            <li>{t('At least 3 characters,')}</li>
                                            <li>{t('No special character,')}</li>
                                        </ol>
                                    </>
                                },
                                { name: "notavailable", value: t('This name is already taken!') }]} />

                            <input ref={nameRef} onChange={e => validateName(e.target.value)} placeholder={t('Name')} ></input>




                            <i className="formIcon icon-email"></i>
                            <IndicateurValidation validationType={errorEmailMessage} messages={[
                                { name: "ok", value: t('Valid field!') },
                                { name: "cancel", value: <p>{t('The field must be a valid email.')}</p> },
                                { name: "notavailable", value: t("This email is already taken!") }]} />
                            <input ref={emailRef} onChange={e => validateEmail(e.target.value)} placeholder={t('Email')} ></input>




                            <i className="formIcon icon-password"></i>
                            <IndicateurValidation validationType={errorMessage} messages={[
                                { name: "ok", value: t('Valid field!') },
                                {
                                    name: "cancel", value: <><p>{t('The field must contain:')}</p>
                                        <ol>
                                            <li>{t('At least 4 characters,')}</li>
                                            <li>{t('One uppercase letter,')}</li>
                                            <li>{t('One special character,')}</li>
                                            <li>{t('One number')}</li>
                                        </ol>
                                    </>
                                }
                            ]} />
                            <input ref={passwordRef} type="password" onChange={e => validatePassword(e.target.value)} placeholder={t('Password')} ></input>
                        </form>
                        <a className='bouton  green' onClick={validateForm}>{t('Sign up')}</a>


                        <a className='bouton small blue' onClick={() => { navigate("/login") }}>{t('Sign in')}</a>

                    </div>
                </div>
                <div className='layer'>
                    <div className='layerContainer'>

                        <span className='message'>{t('Or sign up quickly using one of your social accounts:')}</span>
                        <div className='googleButton' ref={signInDiv} ></div>

                        <FacebookLogin
                            appId={String(process.env.REACT_APP_FACEBOOK_CREDENTIALS)}
                            fields="name, email, picture"
                            scope="email, user_friends"
                            //language="fr_FR"
                            //icon="element"
                            //onClick={componentClicked}
                            onProfileSuccess={responseFacebook}
                            render={(renderProps) => (
                                <a className='boutonFacebook' onClick={renderProps.onClick}>{t("Register with FaceBook")}</a>
                            )}
                        />

                        <hr className='separateur' />
                        <span className='conditions'><a href="#" onClick={() => navigate("/legal-notices/privacy-policy")}>{t("Privacy Policy")}</a> - <a href="#" onClick={() => navigate("/legal-notices/terms-of-use")}>{t("Terms Of Use")}</a></span>
                    </div>
                </div>

            </Panel>
        </>
    )
}

export default PanelSubscription
