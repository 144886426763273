import React, { useEffect } from 'react'
import Alert from '../../componement/Alert'
import { useTranslation } from 'react-i18next';

import "./AlertBuy.css";
import { useNavigate, useParams } from 'react-router-dom';
import DataManager from '../../manager/DataManager';
import { InfosStore } from '../../data/InfosStore';

import { TextManager } from '../../manager/TextManager';

import { SoundManager } from '../../manager/SoundManager';
import { useUser, useUserUpdate } from '../../context/UserProvider';
import { useRondaModal } from '../../context/ModalProvider';
import { InfosUser } from '../../data/InfosUser';
import { InfosStyle } from '../../data/InfosStyle';
import { InfosCardTheme } from '../../data/InfosCardTheme';
import { InfosPowerUp } from '../../data/InfosPowerUp';
import { InfosTheme } from '../../data/InfosTheme';
import { InfosCoin } from '../../data/InfosCoin';
import { InfosGame } from '../../data/InfosGame';
import useRondaFetch from '../../hook/useRondaFetch';
import i18next from 'i18next';
import { InfosVoice } from '../../data/InfosVoice';
import { InfosEmojis } from '../../data/InfosEmojis';

type Props = {
    path?: string
    moneyType?: string
}

function AlertBuy({ path = "", moneyType = "coin" }: Props) {
    const { t } = useTranslation();
    let navigate = useNavigate();
    let { type, id } = useParams();

    let userInfos = useUser()!;
    let setUserInfos = useUserUpdate()!;

    let data: Array<InfosStore> = []

    if (type == undefined) type = "";
    switch (type) {
        case 'boosters':
            data = (userInfos.dataPowerUps);
            break;
        case 'themes':
            data = (userInfos.dataSceneThemes);
            break;
        case 'cards-themes':
            data = (userInfos.dataCardThemes);
            break;
        case 'styles':
            data = (userInfos.dataStyleThemes);
            break;
        case 'coins':
            data = (userInfos.dataCoins);
            break;
        case 'voices':
            data = (userInfos.dataVoices);
            break;
        case 'emojis':
            data = (userInfos.dataEmojis);
            break;
        case 'games':
            data = (userInfos.dataGames);
            break;
    }

    //console.log(type + " " + id + " . " + JSON.stringify(data));

    let infos: InfosStore | undefined = data.find((is) => is.id === Number(id))!;




    const { post, cache, response } = useRondaFetch();

    async function purchaseItem() {
        if (infos == null) return;

        cache.delete();
        cache.clear();


        await post('api/orders/?locale=' + i18next.language, {
            item: infos?.id,
            category: type
        })

        if (response.ok) {
            if (type == undefined) type = "";

            //console.log("userInfos " + JSON.stringify(userInfos));

            parseData(type, response.data.data);
            userInfos.infosProfil.coin = response.data.profil.coin;


            setUserInfos((prevIU: InfosUser) => ({
                ...prevIU
            }));

            switch (type) {
                case 'boosters':
                    data = (userInfos.dataPowerUps);
                    break;
                case 'themes':
                    data = (userInfos.dataSceneThemes);
                    break;
                case 'cards-themes':
                    data = (userInfos.dataCardThemes);
                    break;
                case 'styles':
                    data = (userInfos.dataStyleThemes);
                    break;
                case 'coins':
                    data = (userInfos.dataCoins);
                    break;
                case 'voices':
                    data = (userInfos.dataVoices);
                    break;
                case 'emojis':
                    data = (userInfos.dataEmojis);
                    break;
                case 'games':
                    data = (userInfos.dataGames);
                    break;
            }
            SoundManager.play(SoundManager.VALIDATE_STANDARD);
            navigate(path + "/store/" + type);

            /*let targetInfos;
            


            if (infos && targetInfos) {
                console.log(targetInfos.infinite + " || " + targetInfos.count + " || ");
                if (userInfos.infosProfil.Coin > infos.price && (targetInfos.infinite || targetInfos.count == 0)) {
                    userInfos.infosProfil.Coin -= infos.price;
                    targetInfos.count++;



                    SoundManager.play(SoundManager.VALIDATE_STANDARD);
                    navigate(path + "/store/" + type);
                } else {
                    SoundManager.play(SoundManager.CANCEL_DISMISS);
                }
            }*/



        }


    }


    function parseData(type: string, data: Array<any>) {
        switch (type) {
            case 'boosters':
                userInfos.dataPowerUps = [];
                for (let element of data)
                    userInfos.dataPowerUps.push(new InfosPowerUp(element));
                break;
            case 'themes':
                userInfos.dataSceneThemes = [];
                for (let element of data)
                    userInfos.dataSceneThemes.push(new InfosTheme(element));
                break;
            case 'cards-themes':
                userInfos.dataCardThemes = [];
                for (let element of data)
                    userInfos.dataCardThemes.push(new InfosCardTheme(element));
                break;
            case 'styles':
                userInfos.dataStyleThemes = [];
                for (let element of data)
                    userInfos.dataStyleThemes.push(new InfosStyle(element));
                break;
            case 'coins':
                userInfos.dataCoins = [];
                for (let element of data)
                    userInfos.dataCoins.push(new InfosCoin(element));
                break;
            case 'voices':
                userInfos.dataVoices = [];
                for (let element of data)
                    userInfos.dataVoices.push(new InfosVoice(element));
                break;
            case 'emojis':
                userInfos.dataEmojis = [];
                for (let element of data)
                    userInfos.dataEmojis.push(new InfosEmojis(element));
                break;
            case 'games':
                userInfos.dataGames = [];
                for (let element of data)
                    userInfos.dataGames.push(new InfosGame(element));
                break;
        }
    }


    const { handleModal } = useRondaModal()!;


    return (
        <Alert title={t('Confirm the purchase !')} className='buy' callBack={() => navigate(path + "/store/" + type)}>

            <div className='layer'>
                {infos && <div className='layerContainer'>

                    <span className='description'>{infos?.description}</span>


                    <span className={"itemStore " + moneyType}>
                        <span className={"titre"}> {infos?.title}</span>

                        <a className={'priceTag'}>{TextManager.formatScore(infos.price, 7)}<span className='iconCoin' /></a>
                        <img src={infos.image} onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = DataManager.DATA_PRELOAD_IMAGES[0];;
                        }} />

                    </span>

                    <a className={'bouton ' + ((moneyType == "buck") ? "green" : "bleu-green")} onClick={purchaseItem}>{t('Buy')}</a>
                    <span className='shine'></span>

                </div>
                }
            </div>
            <div className='perso'></div>

        </Alert>
    )
}

export default AlertBuy