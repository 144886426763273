import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import IndicateurPlayer from '../../componement/IndicateurPlayer';
import Panel from '../../componement/Panel';
import { useRondaModal } from '../../context/ModalProvider';
import { useUser } from '../../context/UserProvider';
import { InfosGame } from '../../data/InfosGame';
import { InfosModes } from '../../data/InfosModes';
import InfosPlayer from '../../data/InfosPlayer';
import DataManager from '../../manager/DataManager';
import { TextManager } from '../../manager/TextManager';
import gsap from 'gsap';

import "./PanelVersus.css"
import { SoundManager } from '../../manager/SoundManager';
import { useRondaServer } from '../../context/ServerProvider';
import { Room } from 'colyseus.js';
import { LocationState } from '../../data/CustomInterfaces';
import { InfosRoom } from '../../data/InfosRoom';

type Props = {
}

interface State {
    event: string,
    card?: number
}


function PanelVersus({ }: Props) {

    let navigate = useNavigate();
    let userInfos = useUser()!;
    let location = useLocation();
    let { invitation } = useParams();

    const [infosGame, setInfosGame] = useState<InfosGame>((location.state as LocationState)?.infosGame);

    const infosChallenger = (location.state as LocationState)?.infosChallenger;


    const mode: InfosModes = DataManager.DATA_MODES.find((obj: any) => obj.id === userInfos.infosProfil.infosSettings.idInfosMode)!;
    let infosRoom: InfosRoom = new InfosRoom(mode);

    const { t } = useTranslation();
    let { client, lounge, room, setRoom } = useRondaServer()!;

    let state = { event: "idle" };
    const [link, setLink] = useState<string>("");
    //const [infosServerRoom, setInfosServerRoom] = useState<InfosRoom>(infosRoom);
    const [dummy, setDummy] = useState<InfosPlayer>(DataManager.DUMMY_INFOSPLAYER);

    const generateDummy = (maxplayers: number) => {
        let arr: Array<InfosPlayer> = [];
        for (let i = 0; i < maxplayers; i++) {
            console.log("infosGame.maxplayers " + infosGame.maxplayers);
            arr.push(dummy)
        }
        return arr;
    }

    const [players, setPlayers] = useState<Array<InfosPlayer>>([]);
    const { handleModal } = useRondaModal()!;



    const [bet, setBet] = useState<number>(infosRoom.bet);
    const [reward, setReward] = useState<number>(0);




    let values = { bet: infosRoom.bet, reward: 0 }



    function updateData(state?: State) {


        //setPlayers([]);
        let arr: Array<InfosPlayer> = [];
        room?.state.players.forEach((element: any) => {
            arr.push(new InfosPlayer(element));
        });
        while (arr.length < infosGame?.maxplayers) {
            arr.push(dummy);
        }
        setPlayers([...arr]);
    }


    function sendInvitation(invitationLink: string) {
        lounge?.send("invitation", {
            from: userInfos.infosProfil,
            to: infosChallenger,
            type: "invitation",
            link: invitationLink
        });

    }
    //let pingInterval: NodeJS.Timeout;

    useEffect(() => {

        let isMounted = true;
        let inviteGame: InfosGame = infosGame;
        if (invitation) {
            let invitationInfos: Array<string> = invitation?.split("|");
            let testinviteGame = userInfos.dataGames.find((g) => g.name == invitationInfos[0])

            if (testinviteGame) {//setInfosGame(inviteGame);
                inviteGame = testinviteGame;
                setInfosGame(inviteGame);
                console.log("I found a ", testinviteGame);
                infosRoom = new InfosRoom();
                infosRoom.roomId = invitationInfos[1];
                infosRoom.password = invitationInfos[2];
            } else {
                invitation = undefined;
            }
        }
        setPlayers(generateDummy(inviteGame.maxplayers));




        if (!infosGame && !invitation) {
            navigate('/home');
        } else if (infosRoom && userInfos.infosProfil.coin < infosRoom.bet) {
            navigate('/modeVersus', { state: { infosGame: infosGame } });
        } else if (userInfos.infosProfil.id != 0) {
            if (inviteGame) {
                if (infosChallenger) {
                    //infosRoom.password = "private";
                }
                init().then(() => {
                    setRoom(room);
                    if (room == undefined) return;
                    if (isMounted) {





                        room.onStateChange.once((state) => {
                            let ir = new InfosRoom(state.mode)

                            if (invitation)
                                infosRoom = ir;

                            console.log("########################################");
                            console.log("########################################");
                            console.log("state.mode " + JSON.stringify(state.mode));
                            console.log("########################################");
                            console.log("########################################");


                            //setInfosServerRoom(ir);
                            setLink(DataManager.ABSOLUT_PATH + "versus/" + inviteGame.name + "|" + ir.roomId + "|" + ir.password);

                            if (infosChallenger) {

                                let ic = new InfosPlayer(infosChallenger);
                                ic.img = dummy.img;

                                setDummy(ic);

                                sendInvitation("battle/" + inviteGame.name + "|" + ir.roomId + "|" + ir.password);
                            }

                            updateData();
                        });


                        room.onStateChange(
                            updateData
                            /*(state: State) => {
                            room?.state.players.forEach((player: any) => {
                                updateData();
                            })}*/
                        );



                        const update = (infosPlayer: any) => {
                            let arr: Array<InfosPlayer> = [];
                            room?.state.players.forEach((element: any) => {
                                arr.push(new InfosPlayer(element));
                            });
                            while (arr.length < infosGame?.maxplayers) {
                                arr.push(dummy);
                            }
                            setPlayers(arr);
                        }

                        room.onMessage("ping", (timeStamp) => {
                            room?.send("pong", timeStamp);
                        });

                        room.onMessage("state", (newstate: State) => {
                            if (newstate.event == "start") {
                                state = newstate;

                                let r = infosRoom.reward;
                                SoundManager.play(SoundManager.COIN_DOWN);

                                gsap.to(values, {
                                    duration: 1, bet: 0, reward: r, onComplete: () => {
                                        setTimeout(() => {
                                            navigate("/" + inviteGame.name, { state: { infosMode: infosRoom as InfosModes } });
                                            SoundManager.play(SoundManager.VALIDATE_STARTGAME);
                                        }, 1000);
                                    }
                                    , onUpdate: () => { setReward(Math.round(values.reward)); setBet(Math.round(values.bet)) }
                                });

                            }
                        });

                        room.state.players.onAdd = update;
                        room.state.players.onRemove = update;

                        // room.state.players.onChange = (player:any)=>{
                        //     changes.forEach(change => {
                        //         console.log(change.field);
                        //         console.log(change.value);
                        //         console.log(change.previousValue);
                        //     })
                        // };

                        room.onError(errorRoom);
                        room.onLeave(leaveRoom);
                    }

                })
            };
        }

        return () => {
            if (state.event == "idle") room?.leave();
            room?.onError.remove(errorRoom);
            room?.onLeave.remove(leaveRoom);
            room?.onStateChange.remove(updateData);

            // clearTimeout(pingInterval);
            isMounted = false;
        }
    }, [])

    function errorRoom() {
        handleModal("Someting went wrong, you are exiting the Battle Mode", "red", false)
    }

    function leaveRoom(code: number) {
        switch (code) {
            case 4001:
                handleModal("Time's Up ! You didn't play on time, you were kicked from the room", "red", false)
                break;
            default:
                handleModal("You are quitting the Battle Mode", "green");
                break;
        }
    }
    async function init() {
        console.log("client", client)
        try {
            if (infosChallenger) {

                room = await client.create<any>(infosGame.name + "Battle", { game: infosGame, mode: infosRoom, infosPlayer: new InfosPlayer(userInfos.infosProfil), dummy: dummy });
            } else if (!infosChallenger && (infosRoom.roomId && infosRoom.roomId != "")) {

                room = await client.joinById(infosRoom.roomId, { game: infosGame, mode: infosRoom, infosPlayer: new InfosPlayer(userInfos.infosProfil) });
            } else if (!infosChallenger && !infosRoom.roomId) {

                room = await client.joinOrCreate<any>(infosGame.name + "Battle", { game: infosGame, mode: infosRoom, infosPlayer: new InfosPlayer(userInfos.infosProfil), dummy: dummy });
            } else {
                //console.log("D");
            }
            //room?.onLeave(() => console.log('Connection lost'));
            return;
        } catch (e) {
            console.log(`Error: ${(e as any).message}`);
            errorRoom();
            navigate('/home');
            //throw e
        }
    }










    return (
        <>
            <Panel className='versus' title={t('Battle')} returnTo="/home">
                <div className='titleIcon' >
                    <img src={infosRoom.image} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = DataManager.DATA_PRELOAD_IMAGES[0];;
                    }} />
                    <div className="mode"></div>

                </div>
                <div className='vscontainer'>
                    <div className='vs calque'>
                        <span className='reward'>{TextManager.formatScore(reward)}<span className='iconBuck' /></span>
                    </div>
                </div>
                <div className='layer'>
                    <div className='layerContainer'>
                        <span className="sousTitre">{t('Your game starts soon!')}</span>
                        <div className='contaninders'>
                            {
                                players.map((player: InfosPlayer, index: number) => {
                                    return <IndicateurPlayer bet={bet} key={player.sessionId} infos={player}></IndicateurPlayer>
                                })
                            }
                        </div>
                    </div>
                </div>
                <a href='#' className={'bouton ' + ((reward == 0) ? "red" : "desactivate")} onClick={(e) => { navigate("/home"); }}>{t('Cancel the game')}</a>
            </Panel>
        </>
    )
}

export default PanelVersus