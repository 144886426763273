import React, { createContext, useContext, ReactNode } from "react";
import useServer from "../hook/useServer";
import { Client, Room } from "colyseus.js";
import { InfosUser } from "../data/InfosUser";

const ServerContext = createContext<{ client: Client, room: Room | undefined, lounge: Room | undefined, setRoom: (room: Room | undefined) => void, lobby: Room | undefined, setLobby: (room: Room | undefined) => void, connect: (userInfos: InfosUser) => void, disconnect: () => void, updateOnlineFriends: () => void } | undefined>(undefined);

export function useRondaServer() {
    return useContext(ServerContext);
}

type Props = {
    children: ReactNode;
}

let ServerProvider = ({ children }: Props) => {
    let { client, room, lounge, setRoom, lobby, setLobby, connect, disconnect, updateOnlineFriends } = useServer();

    return (
        <ServerContext.Provider value={{ client, room, lounge, setRoom, lobby, setLobby, connect, disconnect, updateOnlineFriends }}>
            {children}
        </ServerContext.Provider>
    );
};

export { ServerContext, ServerProvider };
