import React, { createContext, useContext, ReactNode } from "react";
import Modal from "../componement/Modal";
import useModal from "../hook/useModal";

const ModalContext = createContext<{ modal: string, trigger: boolean, handleModal: (content: string, type: string, autoClose?: boolean) => void, modalContent: string, autoClose?: boolean } | undefined>(undefined);

export function useRondaModal() {
    return useContext(ModalContext);
}

type Props = {
    children: ReactNode;
}

let ModalProvider = ({ children }: Props) => {
    let { modal, trigger, handleModal, modalContent, autoClose } = useModal();

    return (
        <ModalContext.Provider value={{ trigger, modal, handleModal, modalContent, autoClose }}>
            <Modal type={modal} trigger={trigger} autoClose={autoClose} content={modalContent}></Modal>
            {children}
        </ModalContext.Provider>
    );
};

export { ModalContext, ModalProvider };
