import React, { createContext, useContext, ReactNode } from "react";
import Messenger from "../componement/Messenger";
import useMessenger, { Message } from "../hook/useMessenger";

const MessengerContext = createContext<{ handleMessage: (content: Message) => void, messengerContent: Message | undefined, trigger: boolean } | undefined>(undefined);

export function useRondaMessenger() {
    return useContext(MessengerContext);
}

type Props = {
    children: ReactNode;
}

let MessengerProvider = ({ children }: Props) => {
    let { handleMessage, messengerContent, trigger } = useMessenger();

    return (
        <MessengerContext.Provider value={{ handleMessage, messengerContent, trigger }}>
            <Messenger trigger={trigger} content={messengerContent}></Messenger>
            {children}
        </MessengerContext.Provider>
    );
};

export { MessengerContext, MessengerProvider };
