import React from 'react'

import "./TooltipStore.css"

type Props = {
    title:string
    coordinates:DOMRect|undefined

    message:string
}

function TooltipStore({message,title, coordinates}: Props) {

   // console.log(coordinates?.top, coordinates?.right, coordinates?.bottom, coordinates?.left);

  return (
    <div className="storeTooltip" style={{top:coordinates?.top ,left:coordinates?.right}} >
            <span className="triangle" />
            <span className="triangleMosaique"></span>
            <span className='titre'>{title}</span>
            <div className='boutiqueContainer'>
                {
                    message
                }
            </div>
        </div>  
    )
}

export default TooltipStore