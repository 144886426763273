
export class InfosStore
{
    public count:number;
    public price:number;
    public infinite:boolean=false;
    
    public id:number;
    public title:string;
    public description:string;
    public image:string;
    

    
    public constructor(details:any = null) {
        this.price = details?.price;
        this.count = details?.count;
        this.id = details?.id;
        this.title = details?.title;
        this.description = details?.description;
        this.image = details?.image;
    }
    
    public parse(details:any = null):void {
        this.price = details?.price;
        this.count = details?.count;
        this.id = details?.id;
        this.title = details?.title;
        this.description = details?.description;
        this.image = details?.image;
    }
}
