import React, { useLayoutEffect, useRef } from 'react'
import "./LoadingScreen.css";
import gsap from 'gsap';

type Props = {
  type?: string
  trigger?: boolean
}

function LoadingScreen({ trigger = true, type = "" }: Props) {

  const container = useRef<HTMLDivElement>(null);
  const spin = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    //console.log("###################################################");
    //console.log("LOADING TRIGGERED " + trigger + " " + type);

    if (trigger) {
      gsap.killTweensOf(spin.current);

      gsap.set(spin.current, { scale: 1, y: 0, autoAlpha: 1, overwrite: 'auto', visibility: "visible" });
      gsap.from(spin.current, { duration: 1, scale: 0, overwrite: 'auto', ease: "elastic.out(0.8, 0.8)" });

      gsap.killTweensOf(container.current);

      // if (type == "mini")
      //   gsap.set(container.current, { visibility: "visible", autoAlpha: 1 });
      // else {
      gsap.set(container.current, { visibility: "visible", overwrite: 'auto', autoAlpha: 1 });
      gsap.from(container.current, { duration: 1, autoAlpha: 1, overwrite: 'auto', ease: "power2" });
      // }
    } else {
      gsap.to(spin.current, { duration: 1, scale: 0, overwrite: 'auto', ease: "elastic.out(0.8, 0.8)" });
      gsap.to(container.current, { duration: 1, autoAlpha: 0, overwrite: 'auto', ease: "power2" });
    }
  }, [trigger]);

  function hideModal() {
    if (!trigger) {

    }
  }


  return (
    <div ref={container} id="loadingScreen" className={type}>
      <div ref={spin} id="spin">
        <span></span>
      </div>
    </div>
  )
}

export default LoadingScreen