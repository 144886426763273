import React, { useEffect, useState } from 'react'
import { Scene } from '@babylonjs/core/scene';

import ToogleFullScreen from '../../componement/ToogleFullScreen';
import ToogleViews from '../../componement/ToogleViews';
import ToogleResolution from '../../componement/ToogleResolution';

import ToogleSound from '../../componement/ToogleSound';
import IndicateurLevel from '../../componement/IndicateurLevel';
import IndicateurCoin from '../../componement/IndicateurCoin';
import IndicateurBuck from '../../componement/IndicateurBuck';
import { useNavigate } from 'react-router-dom';
import InfosProfil from '../../data/InfosProfil';


import "./Header.css";
import { useUser, useUserUpdate } from '../../context/UserProvider';
import IndicateurPremium from '../../componement/IndicateurPremium';
import useLocalStorage from '../../hook/useLocalStorage';
import { InfosUser } from '../../data/InfosUser';
import i18next from 'i18next';
import { SoundManager } from '../../manager/SoundManager';
import DataManager from '../../manager/DataManager';
import { SceneManager } from '../../manager/SceneManager';
import { useRondaServer } from '../../context/ServerProvider';


type Props = {
  infos: InfosProfil;
  scene: Scene;
}

const Header = ({ scene }: Props) => {

  let navigate = useNavigate();
  let [token, setToken] = useLocalStorage("RondaToken");
  let { disconnect } = useRondaServer()!;

  const userInfos = useUser()!;
  const updateUserInfos = useUserUpdate()!;

  let [profil, setProfil] = useState<InfosProfil>(userInfos.infosProfil);

  function logOut() {
    setToken("");
    disconnect();
    let infos = new InfosUser();
    updateUserInfos(infos);
    i18next.changeLanguage(DataManager.DATA_LANGUAGES.find(i => i.id == infos.infosProfil.infosSettings.idInfosLanguage)?.locale);
    SoundManager.updateVolume(infos.infosPreferences.soundVolume / 100, infos.infosPreferences.musicVolume / 100);
    SceneManager.self.updateAssets(DataManager.DEFAULT_SCENE_THEME, DataManager.DEFAULT_CARD_THEME);

    navigate("/", { replace: true });
  }

  useEffect(() => {

    setProfil(userInfos.infosProfil);

    //console.log(userInfos.infosProfil);

  }, [userInfos])



  return (
    <div className="entete">
      <div className='profil'>
        {
          <IndicateurPremium id={profil.infosSettings.idInfosStyle}></IndicateurPremium>
        }
        <div className='profilContainer'>
          <span className={'online ' + ((profil.online) ? 'on' : 'off')}></span>
          <span className="refrac"></span>
          <img className="image" src={profil.img} onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = DataManager.DATA_PRELOAD_IMAGES[0];;
          }} />
          <a className='bouton-icone bleu icon-profil' onClick={() => navigate("/profil/" + profil.name)}></a>
        </div>
      </div>
      <div className="enteteContainer">

        {
          //<ToogleViews scene={scene} className='bouton-icone copper icon-vue'></ToogleViews>
        }



        <a className='bouton-icone red icon-logout' onClick={() => logOut()}></a>

        <ToogleFullScreen className='bouton-icone copper '></ToogleFullScreen>
        <ToogleSound className='bouton-icone copper' track="" ></ToogleSound>
        <ToogleResolution scene={scene} className='bouton-icone copper'></ToogleResolution>
        <a className='bouton-icone bleu-green icon-parametre' onClick={() => navigate("/options")}></a>
        <a className='bouton-icone violet icon-acheter' onClick={() => navigate("/store")}></a>

        <IndicateurBuck buck={profil.buck}>
          <a className='bouton-icone copper icon-plus' onClick={() => navigate("/store/coins")}></a>
        </IndicateurBuck>
        <IndicateurLevel rate={profil.rate}></IndicateurLevel>
        <IndicateurCoin coin={profil.coin}>
          <a className='bouton-icone copper icon-plus' onClick={() => navigate("/store/coins")}></a>
        </IndicateurCoin>

      </div>
    </div>)
}

export default Header