import React, { useRef } from 'react'
import { Html, useScene } from 'react-babylonjs';
import { Vector3 } from '@babylonjs/core';

import "../../layout/game/HUDComponement"

type Props = {
    visibility: boolean;
}

function FPS(props: Props) {

    let scene = useScene();
    let fpsContainer = useRef<HTMLDivElement>(null);

    if (scene && props.visibility) {
        scene.registerBeforeRender(function () {
            if (fpsContainer.current) fpsContainer.current.innerHTML = scene?.getEngine().getFps().toFixed() + " fps";
        });
    }


    return (
        <>
            {
                (props.visibility)
                    ? (<transformNode name="transform-node" position={new Vector3(15, 0, -10)} >
                        <Html name="fps" center occlude={false} >
                            {<div ref={fpsContainer} className='cardige'> fps</div>}
                        </Html>
                    </transformNode>)
                    : <></>
            }
        </>
    )
}

export default FPS