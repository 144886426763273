import React, { useLayoutEffect, useState } from 'react'
import './PanelOffline.css';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Alert from '../../componement/Alert';

type Props = {

}

export default function PageOffline(props: Props) {
  let navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <Alert className='offline' title={t('No internet!')} autoClose={30} animation={true} callBack={() => navigate("/", { replace: true })}>
      <div className='layer'>
        <div className='layerContainer'>
          <span className="sousTitre">{t("Whoops! It seems your internet connection missing. Reconnect and let the data flow resume.")}</span>
          <a className='bouton red' onClick={() => window.location.reload()} >{t('Reload !')}</a>
          <a className='bouton small bleu' onClick={() => navigate(-1)} >{t('Go back !')}</a>
        </div>
      </div>
      <div className='perso'></div>
    </Alert>
  )
}