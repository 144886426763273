import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useUser, useUserUpdate } from '../../context/UserProvider';
import InfosProfil from '../../data/InfosProfil';

import { SoundManager } from '../../manager/SoundManager';
import Fleche from '../../componement/Fleche';
import ItemRoom from './ItemRoom';
import Panel from '../../componement/Panel'


import "./PanelListing.css"
import { InfosRoom } from '../../data/InfosRoom';
import { InfosGame } from '../../data/InfosGame';
import { useRondaServer } from '../../context/ServerProvider';
import { LocationState } from '../../data/CustomInterfaces';
import { log } from '../../manager/FirebaseManager';

type Props = {
}

function PanelListing({ }: Props) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { client, room, setRoom } = useRondaServer()!;

    const location = useLocation();
    const infosGame = (location.state as LocationState)?.infosGame;




    let availableRooms: Array<InfosRoom> = [];
    const [data, setData] = useState<Array<InfosRoom>>(availableRooms);

    useEffect(() => {
        if (!infosGame) {
            navigate('/home');
        } else {
            getAvailableRoom();
        }
    }, []);

    const getAvailableRoom = () => {

        log("update_rooms", { game: infosGame.id + "" });

        SoundManager.play(SoundManager.EVENT_REFRESH)
        let arr: Array<InfosRoom> = [];
        client.getAvailableRooms("Friendly").then(rooms => {
            rooms.forEach((room) => {
                // console.log(room.roomId);
                // console.log(room.clients);
                // console.log(room.maxClients);
                // console.log(room.metadata);
                arr.push(new InfosRoom(room.metadata))
            });
            availableRooms = arr;
            setData(availableRooms);
        }).catch(e => {
            console.error(e);
        });
    }




    const Carousel = useRef<HTMLDivElement>(null)
    let index = 0;
    const filterRooms = (value: string) => {

        if (value.length > 3) log("filter_rooms", { value: value, game: infosGame.id + "" });

        setData(availableRooms.filter((r) => r.name.toLowerCase().includes(value.toLowerCase())));
    }
    const handleClickRoom = (infosRoom: InfosRoom) => {
        navigate("/friendly", { state: { infosGame: infosGame, infosRoom: infosRoom } })
        log("join_room", { game: infosGame.id + "" });
    };
    const handleCarouselAction = (e: MouseEvent) => {
        e.preventDefault();
        switch ((e.currentTarget as HTMLElement).id) {
            case "next":
                index += 1;
                break;
            case "prev":
                index -= 1;
                break;
            default:
                break;
        }

        if (index < 0) index = 0;
        Carousel.current!.scrollTop = (Carousel.current?.children.item(0)?.clientHeight!) * index;
    }

    return (
        <Panel className='listing' title={t('Friendly games')} returnTo="/home">
            <a className='bouton-icone red icon-search' onClick={() => { getAvailableRoom() }}></a>
            <input className='inputFilter' type="input" placeholder={t('Filter by name')} onChange={e => filterRooms(e.target.value)} defaultValue={""} ></input>

            <div className='layer'>
                <div className='layerContainer'>
                    {
                        <div ref={Carousel} className='roomScroll'>
                            {
                                data.map((infos: InfosRoom, index: number) => {
                                    return <ItemRoom key={"ir" + index} index={index + 1} infos={infos} callBack={handleClickRoom}></ItemRoom>
                                })
                            }
                        </div>
                    }
                </div>
            </div>
            <Fleche name="next" href="#" styleName="green" callBack={handleCarouselAction} > </Fleche>
            <Fleche name="prev" href="#" styleName="green" callBack={handleCarouselAction} direction="left"> </Fleche>
            <a href='#' className='bouton create' onClick={() => { navigate("/friendlymode", { state: { infosGame: infosGame } }) }}>{t('Create a game !')}</a>
            <a href='#' className='bouton bleu-green refresh' onClick={() => { getAvailableRoom() }}>{t('Refresh the list !')}</a>

        </Panel>
    )
}

export default PanelListing