import React, { Component } from 'react'

type Props = {
  className: string;
  target: HTMLDivElement | null;
}

function ToogleDrag(props: Props) {

  let offsetX: number, offsetY: number;

  let MyPanel = props.target;

  const move = (e: MouseEvent) => {
    if (MyPanel) {
      MyPanel.style.position = "absolute";
      MyPanel.style.left = `${e.x + offsetX}px`;
      MyPanel.style.top = `${e.y + offsetY}px`;
    }
  }

  const add = (e: any) => {
    remove();
    const element = e.target;
    var rect = element.getBoundingClientRect();
    var rect2 = MyPanel?.getBoundingClientRect();

    if (rect2) {
      offsetX = rect2.left - rect.left + ((rect.left - rect.right) / 2);
      offsetY = rect2.top - rect.top + ((rect.top - rect.bottom) / 2);
    }

    element?.addEventListener('dragstart', function (e: any) {
      e.dataTransfer.setDragImage(element, -99999, -99999);
    }, false);

    document?.addEventListener('pointermove', move);
    MyPanel?.addEventListener('pointerup', remove);
    document.addEventListener('pointerup', remove);
    document.addEventListener('click', remove);


  }
  const remove = () => {

    MyPanel?.removeEventListener('pointermove', move);
    MyPanel?.removeEventListener('pointerup', remove);
    document.removeEventListener('pointerup', remove);
    document?.removeEventListener('pointermove', move);
    document.removeEventListener('click', remove);


  }

  return (
    <a className={props.className} onPointerDown={add} ></a>
  )
}

export default ToogleDrag