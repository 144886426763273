import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useUser, useUserUpdate } from '../../context/UserProvider';
import { SoundManager } from '../../manager/SoundManager';
import Slider from '../../componement/Slider';
import ToogleMusic from '../../componement/ToogleMusic';
import ToogleSound from '../../componement/ToogleSound';
import { useRondaModal } from '../../context/ModalProvider';
import { InfosUser } from '../../data/InfosUser';
import useRondaFetch from '../../hook/useRondaFetch';
import { InfosVoice } from '../../data/InfosVoice';
import InfosProfil from '../../data/InfosProfil';
import DataManager from '../../manager/DataManager';

type Props = {}

function TabSoundSettings({ }: Props) {



    const { post, response } = useRondaFetch();
    const { handleModal } = useRondaModal()!;


    let navigate = useNavigate();
    const { t } = useTranslation();

    let userInfos = useUser()!;
    let setUserInfos = useUserUpdate()!;

    const onGetMusicVolume = async (percent: number) => {
        await post("api/updateprofil", {
            data: {
                Preferences: {
                    id: userInfos.infosPreferences.id,
                    musicVolume: Math.round(percent * 100)
                }
            }
        })

        if (response.ok) {
            handleModal("Music Volume is Updated", "green");
            SoundManager.musicVolume = percent;
            setUserInfos((prevIU: InfosUser) => ({
                ...prevIU,
                infosPreferences: {
                    ...prevIU.infosPreferences,
                    musicVolume: Math.floor(percent * 100)
                }
            }));
        }
    }
    const onGetSFXVolume = async (percent: number) => {
        await post("api/updateprofil", {
            data: {
                Preferences: {
                    id: userInfos.infosPreferences.id,
                    soundVolume: Math.floor(percent * 100)
                }
            }
        })

        if (response.ok) {
            handleModal("Sound Volume is Updated", "green");
            SoundManager.sfxVolume = percent;
            setUserInfos((prevIU: InfosUser) => ({
                ...prevIU,
                infosPreferences: {
                    ...prevIU.infosPreferences,
                    soundVolume: Math.floor(percent * 100)
                }
            }));
        }
    }

    const dataVoices = userInfos.dataVoices.filter((i: InfosVoice) => { return i.count > 0 });

    const [voice, setVoice] = useState<InfosVoice | undefined>(dataVoices.find(infos => infos.id == userInfos.infosProfil.infosSettings.idInfosVoice));


    async function onSelectVoice(event: React.ChangeEvent<HTMLSelectElement>) {
        event.preventDefault();

        let voiceEntry = dataVoices.find((e) => e.id === Number(event.target.value));

        if (voiceEntry)
            await post("api/updateprofil", {
                data: {
                    Settings: {
                        id: userInfos.infosProfil.infosSettings.id,
                        idInfosVoice: voiceEntry.id
                    }
                }
            })

        if (response.ok) {
            setVoice(voiceEntry);
            handleModal("Voices preference is updated", "green");
            let infosProfil = new InfosProfil(response.data)
            setUserInfos((prevIU: InfosUser) => ({
                ...prevIU,
                infosProfil
            }));
            SoundManager.setVoice(voiceEntry?.type);

        }
    }


    return (
        <div className='layer'>
            <div className='layerContainer'>
                <div className='calque volume'>
                    <span className='sousTitre'>{t('Sounds & Music volume')}</span>
                    <hr />
                    <Slider title={t('Sound')} getPercent={onGetSFXVolume} initial={SoundManager.sfxVolume}></Slider>
                    <Slider title={t('Music')} getPercent={onGetMusicVolume} initial={SoundManager.musicVolume}></Slider>
                </div>
                <div className='calque sound'>
                    <span className='sousTitre'>{t('Select Music')}</span>
                    <hr />
                    <div className='soundOptions'>
                        <ToogleSound className='bouton-icone bleu-green ' track="sfx"></ToogleSound>
                        <ToogleMusic></ToogleMusic>
                    </div>
                </div>

                <div className='calque voice'>
                    <span className='sousTitre'>{t('Voices preference')}</span>
                    <hr />
                    <div className='voiceSelector'>
                        {
                            <img src={voice?.image} onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = DataManager.DATA_PRELOAD_IMAGES[0];;
                            }} />
                        }
                        <select id={"voice"} onChange={(e) => onSelectVoice(e)} defaultValue={voice?.id}>
                            {
                                dataVoices.map((element: InfosVoice, index) => {
                                    return <option key={index} value={element.id}>{element.title}</option>
                                })
                            }
                        </select>
                    </div>

                </div>
            </div>
        </div>)
}

export default TabSoundSettings