import gsap from 'gsap';

import React, { ReactNode } from 'react'
import { Transition, TransitionGroup } from 'react-transition-group';

type Props = {
    id: string;

    children?: ReactNode
}

const TransitionPanel = ({ children, id }: Props) => {


    const completeCall = (target: Node, parent: Node) => {
        gsap.set(target, { clearProps: "position," });
        parent && gsap.set(parent, { clearProps: "overflow" });
    };


    const onEnterHandler = (node: Node) => {
        gsap.killTweensOf(node);
        // Set initial position and styles
        gsap.set(node, {
            y: -1000
        });
        // Create the animation for the incoming component
        gsap.to(node, {
            position: "absolute",
            duration: 1,
            y: 0,
            onComplete: completeCall,
            ease: "elastic.out(0.8, 0.8)",

            onCompleteParams: [node]
        });
    };

    const onExitHandler = (node: Node) => {

        gsap.killTweensOf(node);
        // Set initial position and styles
        gsap.set(node, {
            position: "absolute"
        });
        // Create the animation for the incoming component
        gsap.to(node, {
            ease: "elastic.out(0.7, 0.8)",
            duration: 1,
            y: 1000
        });

    };

    return (
        <TransitionGroup component={null}>

            <Transition
                timeout={1000}
                key={id}
                onEnter={onEnterHandler}
                onExit={onExitHandler}
            >
                {
                    children
                }
            </Transition>
        </TransitionGroup>
    )
}

export default TransitionPanel