import React, { useEffect, useRef, useState } from 'react'

import { useLocation, useNavigate } from "react-router-dom";
import Stepper from '../../componement/Stepper';

import "./PanelModeVersus.css"
import { SoundManager } from '../../manager/SoundManager';

import { useTranslation } from 'react-i18next';
import Panel from '../../componement/Panel';
import { InfosGame } from '../../data/InfosGame';
import { useUser } from '../../context/UserProvider';
import { InfosModes } from '../../data/InfosModes';
import DataManager from '../../manager/DataManager';

import ItemMode from './ItemMode';
import useRondaFetch from '../../hook/useRondaFetch';
import { useRondaModal } from '../../context/ModalProvider';
import { LocationState } from '../../data/CustomInterfaces';
import { log } from '../../manager/FirebaseManager';

type Props = {
}

export default function PanelModeVersus({ }: Props) {

    let navigate = useNavigate();
    let userInfos = useUser()!;

    const { post, response } = useRondaFetch();
    const { handleModal } = useRondaModal()!;

    let location = useLocation();

    const infosGame = (location.state as LocationState)?.infosGame;
    useEffect(() => {
        if (!infosGame) {
            navigate('/home');
        }
    }, []);

    let checkboxref = useRef<HTMLInputElement | null>(null);

    const { t } = useTranslation();

    const [modeID, setModeID] = useState<number>(userInfos.infosProfil.infosSettings.idInfosMode);

    let mode: InfosModes = new InfosModes();

    const [canBuy, setCanBuy] = useState<boolean>(false);

    useEffect(() => {
        if (!infosGame) {

            navigate('/home');

        }
    }, []);

    const onGetMode = (id: number) => {

        mode = DataManager.DATA_MODES.filter((m) => m.type == "Battle")[id];
        if (mode == undefined) return;

        setModeID(mode.id);
        //userInfos.infosProfil.infosSettings.id
        if (mode && userInfos.infosProfil.coin >= mode.bet) {

            setCanBuy(true);
        } else {
            setCanBuy(false);

        }
    }


    function chooseMode() {
        //if (mode) {
        navigate('/battle', { state: { infosGame: infosGame } });
        SoundManager.play(SoundManager.VALIDATE_STARTGAME);
        //}
    }

    function findIndexFromID(id: number, arr: Array<any>) {
        let index = arr.findIndex((obj: any) => obj.id === id);
        if (index < 0) index = 0;
        return index;
    }


    async function save(id: number) {
        log("update_mode", { type: "Battle", mode: String(id) })

        await post("api/updateprofil", {
            data: {
                Settings: {
                    id: userInfos.infosProfil.infosSettings.id,
                    idInfosMode: id,
                }
            }
        })

        if (response.ok) {
            handleModal("Battle mode preference updated", "green");
            userInfos.infosProfil.infosSettings.idInfosMode = id;
            chooseMode();
        }

    }


    return (
        <>
            <Panel className='modeVersus' title={t('Battle Mode')} returnTo="/home">
                <div className='layer'>
                    <div className='layerContainer'>
                        <span className="sousTitre">{t('Choose a mode')}</span>
                        <Stepper className="versus" initial={findIndexFromID(userInfos.infosProfil.infosSettings.idInfosMode, DataManager.DATA_MODES.filter((m) => m.type == "Battle"))} getID={onGetMode}>
                            {
                                DataManager.DATA_MODES.filter((m) => m.type == "Battle").map((infosMode, index) => {
                                    return <ItemMode key={index} infos={infosMode} />
                                })
                            }
                        </Stepper>
                    </div>
                </div>
                <a href='#' className={'bouton ' + (canBuy ? "" : "desactivate")} onClick={(e) => { save(modeID); }}>{t('Select')}</a>
            </Panel>
        </>
    )
}

