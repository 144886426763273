export class InfosParametres {
    numberOfRounds: number = 1;
    numberOfPlayers: number = 4;
    cheatMode: boolean = false;
    demoMode: boolean = false;
    multiplayer: boolean = false;
    // speed: number = 1;

    id: number;

    public constructor(details?: any) {
        if (details) {
            this.id = details.id;

            this.numberOfRounds = Number(details?.numberOfRounds);
            this.numberOfPlayers = Number(details?.numberOfPlayers);
            this.cheatMode = details?.cheatMode ? true : false;
            this.demoMode = details?.demoMode ? true : false;
            this.multiplayer = details?.multiplayer ? true : false;
            //   this.speed = details?.speed;


        } else {
            this.id = 0;

            this.numberOfRounds = 1;
            this.numberOfPlayers = 4;
            this.cheatMode = true;
            this.demoMode = false;
            this.multiplayer = false;
            //  this.speed = 1;

        }
    }

}
