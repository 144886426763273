import { InfosCondition } from "./InfosCondition";

export class InfosAchievement {
    //public props: Array<InfosCondition>;

    public state: boolean;
    public image: string;
    public description: string;
    public title: string;
    //public condition: string;
    public id: number;
    //public url: string;
    //public image_fb: string;
    //public name: string;
    public reward: number;

    constructor(pinfos: any) {
        //this.name = pinfos.name;
        this.id = pinfos.id;
        //this.condition = pinfos.condition;
        this.state = pinfos.state;
        //this.props = this.parse(this.condition);
        this.title = pinfos.title;
        this.description = pinfos.description;
        this.image = pinfos.image;

        this.reward = pinfos.reward;

        //this.image_fb = pinfos.image_fb;
        //this.url = pinfos.url;
    }

    // private parse(condition: string): Array<InfosCondition> {

    //     var rex: RegExp = /[\s\r\n]*/gim;
    //     condition = condition.replace(rex, '');

    //     var arrInitial: Array<string> = condition.split(",");
    //     var arrFinal: Array<InfosCondition> = new Array();
    //     function parseProp(str: string, index: number, arr: Array<string>) {
    //         let obj: InfosCondition = { property: "", condition: "", value: 0 };
    //         let ar: Array<string>;
    //         if (str.indexOf(">") != -1) {
    //             ar = str.split(">");
    //             obj = { property: ar[0], condition: ">", value: Number(ar[1]) };
    //         } else if (str.indexOf("<") != -1) {
    //             ar = str.split("<");
    //             obj = { property: ar[0], condition: "<", value: Number(ar[1]) };
    //         } else if (str.indexOf("=") != -1) {
    //             ar = str.split("=");
    //             obj = { property: ar[0], condition: "=", value: Number(ar[1]) };
    //         }
    //         arrFinal.push(obj);
    //     }
    //     arrInitial.forEach(parseProp);
    //     return arrFinal;
    // }

    // public tostring(): string {
    //     return "[Achivement " + this.name + "]";
    // }

    // public set unlocked(v: boolean) {
    //     this.state = v;
    // }


    // public get unlocked(): boolean { return this.state; }

}
