export class Timer {

    tick: number;
    interval: number;
    originalInterval: number;

    intervalId: any;
    toTick: Function;
    callBack: Function;

    private isHidden: boolean;

    static TIMER: string = 'Timer.TIMER';
    isStarted: boolean = false;

    constructor(public ptick: number, pinterval: number = 1000, ptoTick: Function, pcallBack: Function = () => { }) {

        this.interval = pinterval;
        this.originalInterval = pinterval;

        this.tick = ptick;
        this.toTick = ptoTick;
        this.callBack = pcallBack;

        this.isHidden = false;

        window.addEventListener("visibilitychange", this.onVisibilitychange.bind(this), true);

    }

    start() {
        this.isStarted = true;

        clearInterval(this.intervalId);

        this.intervalId = setInterval(() => {
            this.interval -= 1;
            this.toTick();

            if (this.interval === 0) {
                this.callBack();
                this.stop();
            }

        }, this.tick);



    }

    onVisibilitychange(e: Event) {
        if (!this.isStarted) return;

        //console.log("document.hidden " + document.hidden + "  " + Date.now());

        this.isHidden = document.hidden;
        if (this.isHidden) {
            clearInterval(this.intervalId);
        } else {
            this.start();
        }


    }


    reset() {
        this.interval = this.originalInterval;
    }

    stop() {
        this.isStarted = false;
        clearInterval(this.intervalId);
    }

    destroy() {
        window.removeEventListener("visibilitychange", this.onVisibilitychange.bind(this), true);
        clearInterval(this.intervalId);
    }
}
