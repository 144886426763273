import React from 'react'
import InfosPlayer from '../../data/InfosPlayer'
import DataManager from '../../manager/DataManager'
import "./ItemMode.css";
import { useTranslation } from 'react-i18next';
import { InfosModes } from '../../data/InfosModes';
import { TextManager } from '../../manager/TextManager';
import { useUser } from '../../context/UserProvider';

type Props = {
    infos: InfosModes
}

function ItemMode({ infos }: Props) {

    const { t } = useTranslation();

    return (
        <span className="itemMode" >

            <img src={infos.image} onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = DataManager.DATA_PRELOAD_IMAGES[0];;
            }} />
            <hr />
            <span className="sousTitre">{t('Cost')}</span>
            <span className={'bouton-bet '}>{TextManager.formatScore(infos.bet, 7)}<span className='iconCoin' /></span>
            {/* <label className='checkBox' >
                <input type="checkbox" defaultChecked={infos.cheatMode} />
                <span className="knob">{t('Special')}</span>
                <span className="slideDeck"></span>
            </label> */}
            <span className="sousTitre">{t('Reward')}</span>

            <span className={'bouton-reward '}>{TextManager.formatScore(infos.reward, 7)}<span className='iconBuck' /></span>




        </span>
    )
}

export default ItemMode