import React, { Component, LegacyRef, useEffect, useLayoutEffect, useRef, useState } from 'react'

import { useLocation, useNavigate } from "react-router-dom";
import Stepper from '../../componement/Stepper';

import "./PanelModeMulti.css"
import { InfosParametres } from '../../data/InfosParametres';
import { SoundManager } from '../../manager/SoundManager';

import { useTranslation } from 'react-i18next';
import Panel from '../../componement/Panel';
import { InfosGame } from '../../data/InfosGame';
import { useUser } from '../../context/UserProvider';
import { InfosModes } from '../../data/InfosModes';
import IndicateurValidation from '../../componement/IndicateurValidation';

import validator from 'validator';
import useRondaFetch from '../../hook/useRondaFetch';
import { useRondaModal } from '../../context/ModalProvider';
import DataManager from '../../manager/DataManager';
import { LocationState } from '../../data/CustomInterfaces';
import { InfosRoom } from '../../data/InfosRoom';
import InfosProfil from '../../data/InfosProfil';
import { log } from '../../manager/FirebaseManager';

type Props = {
}


export default function PanelModeMulti({ }: Props) {

    const navigate = useNavigate();
    const userInfos = useUser()!;
    const location = useLocation();
    const { t } = useTranslation();

    const [infosGame] = useState<InfosGame>((location.state as LocationState)?.infosGame);

    const { post, response } = useRondaFetch();
    const { handleModal } = useRondaModal()!;
    useEffect(() => {
        if (!infosGame) {
            navigate('/home');
        }
    }, []);

    const onGetNumberOfPlayer = (id: number) => { numberOfPlayers = id + 2; }
    const onGetNumberOfRound = (id: number) => { numberOfRounds = id + 1; }

    let nameRef = useRef<HTMLInputElement | null>(null);
    let passwordRef = useRef<HTMLInputElement | null>(null);
    let checkboxref = useRef<HTMLInputElement | null>(null);
    var numberOfPlayers: number = 0;
    var numberOfRounds: number = 0;
    const [errorMessage, setErrorMessage] = useState('')


    const validatePassword = (value: string) => {
        if (value != "") {
            if (validator.isLength(value, {
                min: 4, max: 4
            })) {
                setErrorMessage('ok');
            } else {
                setErrorMessage('cancel')
            }
        } else {
            setErrorMessage('ok');
        }
    }

    let infosMode = DataManager.DATA_MODES.find((m) => m.type == "Friendly")!;


    function setItem(start: number, limit: number) {
        let row: Array<JSX.Element> = [];
        for (var i = start; i <= limit; i++) {
            row.push(<span key={"Key" + (start) + "-" + (limit) + '-' + ((start * limit) + i)} className="numero">{i}</span>);
        }
        return row;
    }

    function limit(n: number) {
        n = Math.max(numberOfPlayers, infosGame?.minplayers);
        return Math.min(numberOfPlayers, infosGame?.maxplayers);
    }

    function onClickCreateParty() {

        log("update_game_preferences", { type: "Friendly" })

        let infosRoom = new InfosRoom({
            ...infosMode,
            name: (nameRef.current as HTMLInputElement).value,
            password: (passwordRef.current as HTMLInputElement).value,

            numberOfPlayers: limit(numberOfPlayers),
            numberOfRounds: numberOfRounds,
            cheatMode: (checkboxref.current as HTMLInputElement).checked,

            profil: userInfos.infosProfil.name,
            country: userInfos.infosProfil.country,
        });

        SoundManager.play(SoundManager.VALIDATE_STANDARD);
        navigate("/friendly", { state: { infosGame: infosGame, infosRoom: infosRoom } })
    }

    return (
        <>
            <Panel className='modeMulti' title={t('Friendly Mode')} returnTo="/listing">
                <div className='layer'>
                    <div className='layerContainer'>
                        <span className="sousTitre">{t('Number of players')}</span>
                        <Stepper className="small" initial={userInfos.infosParametres.numberOfPlayers - 2} getID={onGetNumberOfPlayer}>
                            {
                                setItem(infosGame?.minplayers, infosGame?.maxplayers)
                            }
                        </Stepper>
                        <hr />
                        <span className="sousTitre">{t('Number of rounds')}</span>
                        <Stepper className="small" initial={userInfos.infosParametres.numberOfRounds - 1} getID={onGetNumberOfRound}>
                            {
                                setItem(1, 10)
                            }
                        </Stepper>
                        <hr />

                        <label className='checkBox' onClick={e => SoundManager.play(SoundManager.BUTTON_CLICK)}>
                            <input ref={checkboxref} type="checkbox" defaultChecked={userInfos.infosParametres.cheatMode} />
                            <span className="knob">{t('Special Mode')}</span>
                            <span className="slideDeck"></span>
                        </label>
                    </div>

                </div>
                <div className='layer second'>

                    <div className='layerContainer'>

                        <div className='calque form'>
                            <span className='sousTitre'>{t('Game settings')}</span>
                            <hr />
                            <div className='formContainer'>
                                <form action="">
                                    <i className="formIcon icon-user"></i>

                                    <input ref={nameRef} placeholder={t('Nick name')} readOnly={true} defaultValue={userInfos.infosProfil.name + "." + Math.floor(Math.random() * 100000)} ></input>

                                    <i className="formIcon icon-password"></i>
                                    <IndicateurValidation validationType={errorMessage} messages={[
                                        { name: "ok", value: t('Valid field!') },
                                        {
                                            name: "cancel", value: <><p>{t('The field must contain:')}</p>
                                                <ol>
                                                    <li>{t('At least 4 characters,')}</li>
                                                </ol>
                                            </>
                                        }
                                    ]} />
                                    <input ref={passwordRef} type="password" placeholder={t('Password')} maxLength={4} onChange={e => validatePassword(e.target.value)} defaultValue={""} ></input>


                                </form>
                            </div>

                            <hr />

                            <span className="message">{t('To create a friendly game, players must have confirmed their email address. Please check your email to confirm your email address.')}</span>

                            {/* <a className={'bouton-store green ' + (canBuy ? "" : "desactivate")}>{infosMode.bet}<span className='iconCoin' /></a> */}
                            <hr />

                            <a href='#' className={'bouton bleu-green ' + (userInfos.infosProfil.confirmed ? "" : "desactivate")} onClick={() => onClickCreateParty()}>{t('Create this game !')}</a>
                        </div>

                    </div>


                </div>
            </Panel>
        </>
    )
}
