import React from 'react'
import InfosProfil from '../../data/InfosProfil'
import IndicateurPremium from '../../componement/IndicateurPremium'

import "./ItemRank.css"
import DataManager from '../../manager/DataManager'

type Props = {
  index: number
  infos: InfosProfil
  callBack: Function
}



function ItemRank({ index, infos, callBack }: Props) {

  let flag = (DataManager.DATA_COUNTRIES.find((e) => e.code === infos?.country));

  return (
    <div className='itemRank'>
      <div className='itemRankContainer'>
        <span className='index'>{index}</span>


        <span className='imageContainer'>
          <span className={'online ' + ((infos.online) ? 'on' : 'off')}></span>
          <img className="image" src={infos.img} onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = DataManager.DATA_PRELOAD_IMAGES[0];;
          }} />
          <img className='flag' src={flag!.flag_4x3}></img>

        </span>
        <span className='name'>{infos.name}

        </span>

        <div className='vr'></div>
        <span className='Level'><span className='ico' />{infos.level}</span>
        <div className='vr'></div>
        <span className='Win'><span className='ico' />{infos.win}</span>
        <div className='vr'></div>
        <span className='Achievement'><span className='ico' />{infos.achievements}</span>
        <div className='vr'></div>
        <span className='Coin'><span className='ico' />{infos.coin}</span>
        <div className='vr'></div>
        <IndicateurPremium id={infos.infosSettings.idInfosStyle}></IndicateurPremium>
        <div className='vr'></div>


        <a className='bouton-icone red icon-profil' onClick={() => { callBack(infos.name) }}></a>
      </div>
    </div>
  )
}

export default ItemRank