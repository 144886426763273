import React, { ReactNode, useEffect, useLayoutEffect, useRef, useState } from 'react'

import gsap from 'gsap';
import { SoundManager } from '../manager/SoundManager';
import { Message } from '../hook/useMessenger';

import "./Messenger.css"
import IndicateurPremium from './IndicateurPremium';
import InfosProfil from '../data/InfosProfil';
import DataManager from '../manager/DataManager';
import { useUser } from '../context/UserProvider';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type Props = {
    trigger: boolean;
    content?: Message;
    children?: ReactNode;
}

type MessengerText = {
    message: string;
    button: string;
}

function Messenger({ content, trigger }: Props) {
    const container = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();

    const [libelle, setLibelle] = useState<MessengerText>()


    useEffect(() => {

        if (content) {
            SoundManager.play(SoundManager.SEND_MESSAGE);

            gsap.killTweensOf(container.current);
            gsap.set(container.current, { display: "block", scale: 1, visibility: "visible", autoAlpha: 1, x: 0 });
            gsap.from(container.current, { duration: 2, x: -300, autoAlpha: 1, ease: "elastic.out" });

            switch (content?.type) {
                case "friendship":
                    setLibelle({
                        message: t("want to connect with you"),
                        button: t("Look")
                    });
                    break;
                case "invitation":
                    setLibelle({
                        message: t("invite you to a game"),
                        button: t("Play")
                    });
                    break;
                case "notification":
                    setLibelle({
                        message: content?.message + "",
                        button: t("Close")
                    });
                    break;
                case "installation":
                    setLibelle({
                        message: content?.message + "",
                        button: t("Install")
                    });
                    break;
            }


        } else {
            gsap.set(container.current, { display: "none" });
        }

    }, [trigger, content,]);

    function erase() {
        gsap.set(container.current, { display: "none" });
    }

    function closeMessenger() {
        SoundManager.play(SoundManager.ALERT_CANCEL);
        gsap.to(container.current, { duration: 0.3, x: -300, onComplete: erase, ease: "power2" });
    }



    const navigate = useNavigate();

    function play() {
        //console.log("content.link " + content?.link)
        if (content)
            if (content.callback) content.callback?.()
            else if (content.link) navigate(content.link + "");

        closeMessenger();
    }

    function close() {
        closeMessenger();
    }

    return (
        <div ref={container} className={'messenger'} style={{ display: "none" }}>

            {
                content?.from &&
                <>
                    <IndicateurPremium id={content?.from.infosSettings?.idInfosStyle}></IndicateurPremium>
                    <div className='container'>
                        <div className='profilContainer'>
                            <span className={'online ' + ((content?.from?.online) ? 'on' : 'off')}></span>
                            <img className="image" src={content?.from?.img} onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = DataManager.DATA_PRELOAD_IMAGES[0];;
                            }} />
                        </div>
                    </div>
                </>
            }
            <div className='cardige'>

                {
                    content?.from &&
                    <>
                        <span className="triangle" />
                    </>
                }
                <span className='titre'>{(content?.from) ? content?.from.name : content?.title}</span>
                <span className="message">{(libelle?.message + "")}</span>
                <a className='bouton small blue simple ' onClick={() => play()}>{(libelle?.button + "")}</a>
                <a className='bouton-icon copper icon-cancel' onClick={() => close()}></a>
            </div>

        </div >)
}

export default Messenger