import React, { useRef, useState } from 'react'
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router-dom';
import { InfosAchievement } from '../../data/InfosAchievement'
import DataManager from '../../manager/DataManager';

import "./ItemAchievement.css";
import TooltipAchievement from '../../componement/TooltipDescription';


type Props = {
    infos: InfosAchievement
}


function ItemAchievement({ infos }: Props) {
    let navigate = useNavigate();

    let Target = useRef<HTMLSpanElement>(null);

    const [coordinates, setCoordinates] = useState(Target.current?.getBoundingClientRect());

    function onClick() {
        if (infos.state) navigate("/achievements/" + infos.title, { state: { infosAchievement: infos } });
    }
    function onRollOver() {
        setCoordinates(Target.current?.getBoundingClientRect());
    }
    function onRollOut() {
        setCoordinates(undefined);
    }

    return (
        <>
            <span ref={Target} style={{ cursor: ((infos.state) ? "pointer" : "auto") }} className="itemAchievement" onMouseOver={onRollOver} onMouseLeave={onRollOut} onClick={() => onClick()}>
                <img src={(infos.state) ? infos.image : DataManager.ABSOLUT_PATH + './assets/icons/Lock.png'} onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = DataManager.DATA_PRELOAD_IMAGES[0];;
                }} />
                {
                    (document.getElementById("App-Container")!)
                        ? createPortal((coordinates) ? <TooltipAchievement coordinates={coordinates} message={infos.description} title={infos.title} ></TooltipAchievement> : <></>, document.getElementById("App-Container")!)
                        : <></>
                }
            </span>


        </>
    )
}

export default ItemAchievement