import React, { ReactNode, useEffect, useState } from 'react'
import DataManager from './manager/DataManager';
import i18next, { i18n } from 'i18next';
import initInternationalization from './manager/InternationalizationManager';
import useFetch from 'use-http';
import { useRondaLoader } from './context/LoaderProvider';
import { useUser } from './context/UserProvider';


type Props = {
  children: ReactNode;
}

function InitLocalization({ children }: Props) {

  let i18next: i18n;

  const { get, loading, response } = useFetch();

  const { handleLoader } = useRondaLoader()!;

  const [ready, setReady] = useState<boolean>(false)

  useEffect(() => {
    getDataInfos()
  }, []);

  useEffect(() => {
    handleLoader(loading, "full");
  }, [loading])

  async function getDataInfos() {
    const newTodo = await get('api/getdatainfos/')
    if (response.ok) {
      DataManager.parse(response.data);
      i18next = initInternationalization(DataManager.DATA_LANGUAGES, (onIniti18next));
    }
  }

  const onIniti18next = () => {
    setReady(true);
  }

  return (
    <>
      {
        (ready) && <>
          {children}
        </>
      }
    </>
  )
}

export default InitLocalization