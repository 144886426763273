import React, { useState } from "react";
import InfosProfil from "../data/InfosProfil";

export type Message = {
    from?: InfosProfil,
    to?: InfosProfil,
    type: string,
    message?: string,
    title?: string,
    link?: string,
    callback?: Function
}

export type MessengerContextType = {
    handleMessage: (content: Message) => void;
    messengerContent: Message | undefined;
    trigger: boolean;
};

export default () => {
    let [messengerContent, setMessengerContent] = useState<Message | undefined>();
    let [trigger, setTrigger] = useState(true);

    let handleMessage = (content: Message) => {

        if (content)
            setMessengerContent(content);

        setTrigger(!trigger)
    };


    return { handleMessage, messengerContent, trigger };
};
