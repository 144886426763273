import i18next from 'i18next';
import { ReactNode, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

import { useRondaModal } from '../context/ModalProvider';
import { useRondaServer } from '../context/ServerProvider';
import { useUser, useUserUpdate } from '../context/UserProvider';
import InfosProfil from '../data/InfosProfil';
import { InfosUser } from '../data/InfosUser';
import useLocalStorage, { isExpired } from '../hook/useLocalStorage';
import useRondaFetch from '../hook/useRondaFetch';
import AlertPendingInvitation from '../layout/pending/AlertPendingInvitation';
import DataManager from '../manager/DataManager';
import { SceneManager } from '../manager/SceneManager';
import { SoundManager } from '../manager/SoundManager';
import AlertNotificationPermission from '../layout/notificationPermission/AlertNotificationPermission';
import { defineUser } from '../manager/FirebaseManager';

type Props = {
    children?: ReactNode;
}

function Auth({ children }: Props) {

    let { connect, disconnect, updateOnlineFriends } = useRondaServer()!;


    let userInfos = useUser()!;
    let location = useLocation();
    let navigate = useNavigate();
    let updateUserInfos = useUserUpdate()!;
    let [token, setToken] = useLocalStorage("RondaToken");

    const { get, cache, response, error } = useRondaFetch();

    let [showContent, setShowContent] = useState<boolean>(true);
    let infos: InfosUser;
    const NO_AUTH_ROUTES: Array<string> = ['/', '/login', '/signup', '/forgotpassword', '/reset', '/conditions', '/conditions/terms-of-use', '/conditions/privacy-policy'];



    const No_Auth_Access: boolean = NO_AUTH_ROUTES.find(o => o.split("/")[1] === location.pathname.split("/")[1]) ? true : false;


    // console.log("NO_AUTH_ROUTES.find(o => location.pathname)  " + NO_AUTH_ROUTES.find(o => location.pathname));


    function getPath() {
        return NO_AUTH_ROUTES.find(o => o === location.pathname) ? "/home" : location.pathname;
    }

    const reset = () => {
        setToken("");
        disconnect();
        let infos = new InfosUser();
        updateUserInfos(infos);
        i18next.changeLanguage(DataManager.DATA_LANGUAGES.find(i => i.id == infos.infosProfil.infosSettings.idInfosLanguage)?.locale);
        SoundManager.updateVolume(infos.infosPreferences.soundVolume / 100, infos.infosPreferences.musicVolume / 100);
        if (SceneManager.self) {
            SceneManager.self.noAd = infos.infosProfil.premium;
            SceneManager.self.updateAssets(DataManager.DEFAULT_SCENE_THEME, DataManager.DEFAULT_CARD_THEME);
        }
    }

    const [pendingFriendShip, setPendingFriendShip] = useState<Array<InfosProfil>>();
    const checkPendingFriendShip = (infos: InfosUser) => {
        setPendingFriendShip(infos.dataFriends.filter((pf) => pf.friendshipStatus == "watingYourConfirmation"));

    }


    async function getUser() {

        await get('api/getuser/');

        if (response.ok) {
            infos = new InfosUser(response.data);

            updateUserInfos(infos);
            i18next.changeLanguage(DataManager.DATA_LANGUAGES.find(i => i.id == infos.infosProfil.infosSettings.idInfosLanguage)?.locale);
            SoundManager.setVoice(infos.dataVoices.find(i => i.id == infos.infosProfil.infosSettings.idInfosVoice)?.type);
            SoundManager.updateVolume(infos.infosPreferences.soundVolume / 100, infos.infosPreferences.musicVolume / 100);
            SceneManager.self.noAd = infos.infosProfil.premium;
            SceneManager.self.updateAssets(infos.dataSceneThemes.find((its) => its.id == infos.infosProfil.infosSettings.idInfosTheme), infos.dataCardThemes.find((itc) => itc.id == infos.infosProfil.infosSettings.idInfosCardTheme));
            handleModal(`Welcome back |${infos.infosProfil.name}| !`, "green");

            connect(infos);

            defineUser(infos.infosProfil)


            if (No_Auth_Access) {
                navigate("/home", { replace: true });
            } else {
                setShowContent(true);
            }

            checkPendingFriendShip(infos);

        }
    }

    useEffect(() => {

        if (error) {
            if (No_Auth_Access)
                setShowContent(true);
            else
                navigate("/login", { state: { path: getPath() } });

        }
    }, [error])

    useEffect(() => {

        if (isExpired(token as string)) {

            if (token) {
                handleModal(`Your session has expired, please log in again!`, "red");
                reset();
            }


            if (No_Auth_Access) {
                setShowContent(true);
            } else {
                setShowContent(true);
                navigate("/login", { state: { path: getPath() } });
            }

        } else {

            if (userInfos.infosProfil.id == 0) {
                setShowContent(false);
                getUser();
            } else if (No_Auth_Access) {
                navigate("/home", { replace: true });
            } else if (userInfos.infosProfil.online === false) {
                connect(userInfos);
            }

            updateOnlineFriends();

        }
    }, [token])


    let [askForNotification, setAskForNotification] = useState<boolean>(true);


    useEffect(() => {

        let permission = false;
        try {
            permission = (Notification?.permission === 'default')
        } catch (e) {
            console.log(e);
        }
        if (permission && DataManager.SHOW_NOTIFICATION) {
            setAskForNotification(true);
        } else {
            setAskForNotification(false)
        }

    }, [])

    const { handleModal } = useRondaModal()!;



    if (showContent) return (
        <>
            {
                children
            }
            {
                (pendingFriendShip && pendingFriendShip.length > 0) && <AlertPendingInvitation path={location.pathname} data={pendingFriendShip} />
            }
            {
                (askForNotification && userInfos.infosProfil.id != 0) && <AlertNotificationPermission path={location.pathname} />
            }
        </>
    )
    else return (<></>)
}

export default Auth


