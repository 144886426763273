import React, { Suspense, useEffect, useState } from 'react'
import { Routes, Route, useLocation, useNavigate, Outlet } from 'react-router-dom';
import gsap from 'gsap';

import { Scene } from '@babylonjs/core/scene';
import { DefaultRenderingPipeline } from "@babylonjs/core";
import ShowReelManager from '../manager/ShowReelManager';
import LoadingScreen from '../componement/LoadingScreen';


import PanelEntrance from '../layout/entrance/PanelEntrance';
import Header from '../layout/header/Header';
import Footer from '../layout/footer/Footer';
import PanelModeSolo from '../layout/modesolo/PanelModeSolo';
import PanelSettings from '../layout/settings/PanelSettings';
import DataManager from '../manager/DataManager';
import PanelStore from '../layout/store/PanelStore';
import PanelProfil from '../layout/profil/PanelProfil';
import AlertBuy from '../layout/store/AlertBuy';
import PanelRanking from '../layout/ranking/PanelRanking';
import PanelAchievement from '../layout/achievement/PanelAchievement';
import { useUser, useUserUpdate } from '../context/UserProvider';
import AlertAchievement from '../layout/achievement/AlertAchievement';
import TransitionPanel from './TransitionPanel';
import TransitionHeader from './TransitionHeader';
import TransitionFooter from './TransitionFooter';
import PanelHome from '../layout/home/PanelHome';
import PanelHelp from '../layout/help/PanelHelp';
import { useTranslation } from 'react-i18next';
import Auth from './Auth';
import PanelForgotPassword from '../layout/forgotpassword/PanelForgotPassword';
import PanelResetPassword from '../layout/resetpassword/PanelResetPassword';
import AlertConfirmation from '../layout/confirmation/AlertConfirmation';
import PanelVerification from '../layout/verification/PanelVerification';
import PanelVersus from '../layout/versus/PanelVersus';
import PanelModeVersus from '../layout/modeversus/PanelModeVersus';
import PanelModeMulti from '../layout/modemulti/PanelModeMulti';
import PanelListing from '../layout/listing/PanelListing';
import PanelMulti from '../layout/multi/PanelMulti';
import AlertInvitation from '../layout/multi/AlertInvitation';
import PanelReport from '../layout/report/PanelReport';
import PanelTournament from '../layout/tournament/PanelTournament';
import PanelModeTournament from '../layout/modetournament/PanelModeTournament';
import GameComponement from '../games/core/GameComponement';
import PanelReward from '../layout/reward/PanelReward';
import PanelArchives from '../layout/archives/PanelArchives';
import PanelLog from '../layout/log/PanelLog';
import AlertGift from '../layout/store/AlertGift';
import AlertCheckOut from '../layout/store/AlertCheckOut';
import PanelConnexion from '../layout/connexion/PanelConnexion';
import PanelSubscription from '../layout/subscription/PanelSubscription';
import PageNotFound from '../layout/notfound/PanelNotFound';
import TransitionMenu from './TransitionMenu';
import PanelOffline from '../layout/offline/PanelOffline';
import ErrorBoundary from '../componement/ErrorBoundary';
import { useRondaModal } from '../context/ModalProvider';
import { log } from '../manager/FirebaseManager';
import PanelConditions from '../layout/conditions/PanelConditions';
import PanelSneakPeek from '../layout/sneakpeek/PanelSneakPeek';
import PanelDeleteRequest from '../layout/deleterequest/PanelDeleteRequest';
import PanelQuit from '../layout/game/AlertQuit';

/*
const Panel = React.lazy(() => { return import('./Panel')});
const PanelEntrance = React.lazy(() => { return import('./PanelEntrance')});
const Header = React.lazy(() => { return import('./Header')});
const Footer = React.lazy(() => { return import('./Footer')});
const RondaComponement = React.lazy(() => { return import('../games/ronda/RondaComponement')});
*/
type Props = {
  scene: Scene;
  pipeline: DefaultRenderingPipeline | undefined;
  srm: ShowReelManager | undefined;
}



export default function Routing(props: Props) {



  let pipeline: DefaultRenderingPipeline | undefined = props.pipeline;
  let navigate = useNavigate();
  const { handleModal } = useRondaModal()!;

  let userInfos = useUser()!;
  //let setUserInfos = useUserUpdate();
  /*
  function onCardReady(e:any){
    //srm.init(e.detail.CARDS_LIST);
    document.removeEventListener(CardEvent.CARDS_CREATED, onCardReady);
  }
  */

  const ROUTES: Array<string> = ['home', 'options', 'store', 'achievements', 'profil', 'ranking'];

  const GAMES: Array<string> = ['ronda', 'quinza', 'hazzouj', 'kdoub', 'kbirsghir', 'sota', 'rondaserka', 'rondarchim', 'touti', 'kappilla', 'ribis', 'shkanba', 'triss', 'attkozer', 'bonjour', 'cartecopie'];


  let previousLocation = "";
  let location = useLocation();
  let showMenu: boolean = ROUTES.find(o => location.pathname.indexOf(o) == 1) ? true : false;

  if (userInfos.infosProfil && userInfos.infosProfil.id == 0) showMenu = false;

  const [showMenuForMobile, setShowMenuForMobile] = useState<boolean>(true);
  const [showToogleMenu, setShowToogleMenu] = useState<boolean>(false);



  const currentLanguage = DataManager.DATA_LANGUAGES.find((l) => l.id === userInfos.infosProfil.infosSettings.idInfosLanguage);
  const { t } = useTranslation();

  useEffect(() => {
    //i18next.changeLanguage(currentLanguage?.locale)
    //console.log("currentLanguage is updating");
    //console.log("i18next.language " + i18next.language)

    if (currentLanguage) {
      document.dir = currentLanguage.dir;

      DataManager.DIRECTION = (currentLanguage.dir === "rtl") ? -1 : 1;

      document.body.dir = currentLanguage.dir;

      document.body.style.direction = currentLanguage.dir;
      document.body.style.textAlign = (currentLanguage.dir === "rtl") ? 'right' : 'left'; //(currentLanguageCode == "ar" || currentLanguageCode == "ma") ? 'left' : 'right';
      document.title = t('Ronda.games: Play your favorite card games alone or with friends for free.')
    }
  }, [currentLanguage, t])




  useEffect(() => {
    updateOnAppSizeChange();
    window.addEventListener("online", onOnLine);
    window.addEventListener("offline", onOffLine);
    window.addEventListener("resize", updateOnAppSizeChange);
    return () => {
      window.removeEventListener("resize", updateOnAppSizeChange);
      window.removeEventListener("offline", onOffLine);
      window.removeEventListener("online", onOnLine);

    }
  }, [])


  function onOffLine() {
    handleModal("You are in offline mode, check your internet connection", "red");
    //navigate("/offline");
  }
  function onOnLine() {
    handleModal("You are connected to the web again !", "green");
    //navigate("/offline");
  }

  function updateOnAppSizeChange() {
    if (window.innerWidth < 800 || window.innerHeight < 475) {
      setShowToogleMenu(true);
      setShowMenuForMobile(false);
    } else {
      setShowToogleMenu(false);
      setShowMenuForMobile(true);
    }
  }













  if (pipeline && pipeline.isSupported)
    if (showMenu) {
      gsap.to(pipeline.depthOfField, {
        ease: "power3.InOut",
        duration: 1, focalLength: 180
      });
    } else {
      gsap.to(pipeline.depthOfField, {
        ease: "power3.InOut",
        duration: 1, focalLength: 0
      });
    }

  function isGame(name: string): boolean {
    let result = false
    GAMES.forEach(s => {
      if ((name.indexOf(s) > -1)) result = true;
    })
    return result;
  }

  useEffect(() => {
    if (isGame(location.pathname)) {
      if (props.srm) props.srm.stopShow();
    } else {
      if (props.srm?.isPlaying === false) {
        if (props.srm) props.srm.startShow();
      }
    }

    log("screen_view", { page: location.pathname + location.search })

  }, [location]);

  function getTransitionID() {

    let result = location.pathname;

    let arr = result.split('/');
    if (arr.length > 0) {
      previousLocation = arr[1];
      if (arr[1] == previousLocation && arr[1] != "profil") {
        //console.log(arr[0]+" result location "+arr[1]);
        result = arr[1];
      }
    }


    return result;
  }

  return (
    <div id="App-Container" className='AppContainer'>


      <TransitionPanel id={getTransitionID()}>
        <>
          <Suspense fallback={<LoadingScreen />}>
            <Routes location={location}>

              <Route key="a" path={'/'} element={<Auth><PanelEntrance scene={props.scene} /></Auth>} />


              <Route key="b" path={'/home'} element={<Auth><PanelHome /></Auth>} >
                <Route key="b1" path={':type'} element={<></>} />
                <Route key="b2" path={'/home/store/games'} element={<></>} />
                <Route key="b3" path={'/home/store/:type/:id'} element={<Auth><AlertBuy moneyType='buck' path="/home" /></Auth>} />
              </Route>

              <Route key="c" path={'/help'} element={<Auth><PanelHelp /></Auth>} />


              <Route key="d" path={'/signup'} element={<Auth><PanelSubscription /></Auth>} />
              <Route key="e" path={'/login'} element={<Auth><PanelConnexion /></Auth>} />



              {

                GAMES.map((g, index) => {
                  return <Route key={index + "f"} path={'/' + g} element={<Auth>
                    <ErrorBoundary fallback={<PanelOffline />}>
                      <GameComponement scene={props.scene} game={g}></GameComponement>
                    </ErrorBoundary>
                  </Auth>}>
                    <Route key={index + "f1"} path={'/' + g + '/profil/:id'} element={<Auth><PanelProfil animation={true} path={'/' + g} /></Auth>} />
                    <Route key={index + "f2"} path={'/' + g + '/store'} element={<Auth><PanelStore animation={true} path={"/" + g} /></Auth>}>
                      <Route key={index + "f3"} path={':type'} element={<></>} />
                      <Route key={index + "f4"} path={'/' + g + '/store/:type/:id'} element={<Auth><AlertBuy path={"/" + g} /></Auth>} />
                    </Route>
                    <Route key={index + "f6"} path={'/' + g + '/help'} element={<Auth><PanelHelp animation={true} path={"/" + g} /></Auth>} />
                    <Route key={index + "f7"} path={'/' + g + '/report/:id'} element={<Auth><PanelReport animation={true} path={"/" + g} /></Auth>} />
                  </Route>
                })
              }

              <Route key="peek" path={'/sneak-peek'} element={<PanelSneakPeek />} >
                {
                  GAMES.map((g, index) => {
                    return <Route key={index + "sp"} path={'/sneak-peek/' + g} element={
                      <ErrorBoundary fallback={<PanelOffline />}>
                        <GameComponement scene={props.scene} game={g} demoMode={true}></GameComponement>
                      </ErrorBoundary>
                    }>
                      <Route key={index + "f1"} path={'/sneak-peek/' + g + '/profil/:id'} element={<></>} />
                      <Route key={index + "f7"} path={'/sneak-peek/' + g + '/report/:id'} element={<></>} />
                    </Route>
                  })
                }
              </Route>


              <Route key="h" path={'/options'} element={<Auth><PanelSettings /></Auth>} >
                <Route key="h1" path={':type'} element={<></>} />
              </Route>

              <Route key="i" path={'/profil'} element={<Auth><PanelProfil /></Auth>}>
                <Route key="i1" path={':id'} element={<Auth><PanelProfil /></Auth>} />
              </Route>

              <Route key="l" path={'/achievements'} element={<Auth><PanelAchievement /></Auth>} >
                <Route key="l1" path={'/achievements/:type'} element={<Auth><AlertAchievement /></Auth>} />
              </Route>

              <Route key="xb" path={'/archives'} element={<Auth><PanelArchives /></Auth>} >
                <Route key="xb1" path={':type'} element={<></>} />
                <Route key="xb3" path={'/archives/:type/log'} element={<Auth><PanelLog animation={true} path={'/archives'} /></Auth>} />
                <Route key="xb4" path={'/archives/:type/log/:id'} element={<Auth><PanelLog animation={true} path={'/archives'} /></Auth>} />
              </Route>



              <Route key="j" path={'/store'} element={<Auth><PanelStore useButton={true} /></Auth>} >
                <Route key="j1" path={':type'} element={<></>} />
                <Route key="j2" path={'/store/:type/:id'} element={<Auth><AlertBuy /></Auth>} />
                <Route key="j3" path={'/store/:type/gift'} element={<Auth><AlertGift /></Auth>} />
                <Route key="j4" path={'/store/:type/checkout'} element={<Auth><AlertCheckOut /></Auth>} />

              </Route>

              <Route key="k" path={'/ranking'} element={<Auth><PanelRanking /></Auth>}>
                <Route key="k1" path={'/ranking/:type/'} element={<Auth><PanelRanking /></Auth>} />
              </Route>



              <Route key="n" path={'/trainingmode'} element={<Auth><PanelModeSolo ></PanelModeSolo></Auth>} />
              <Route key="s" path={'/battlemode'} element={<Auth><PanelModeVersus ></PanelModeVersus></Auth>} />
              <Route key="z" path={'/tournamentmode'} element={<Auth><PanelModeTournament ></PanelModeTournament></Auth>} />

              <Route key="t" path={'/battle'} element={<Auth><PanelVersus ></PanelVersus></Auth>} >
                <Route key="t1" path={':invitation'} element={<></>} />
              </Route>

              <Route key="y" path={'/tournament'} element={<Auth><PanelTournament ></PanelTournament></Auth>} >
                <Route key="y1" path={':invitation'} element={<></>} />
              </Route>

              <Route key="u" path={'/friendlymode'} element={<Auth><PanelModeMulti ></PanelModeMulti></Auth>} />

              <Route key="v" path={'/listing'} element={<Auth><PanelListing ></PanelListing></Auth>} />

              <Route key="w" path={'/friendly'} element={<Auth><PanelMulti ></PanelMulti></Auth>}>
                <Route key="w1" path={':invitation'} element={<></>} />
                <Route key="w2" path={'/friendly/invite'} element={<Auth><AlertInvitation /></Auth>}>
                  <Route key="w3" path={':invitation'} element={<></>} />
                </Route>
              </Route>

              <Route key="p" path={'/forgotpassword'} element={<Auth><PanelForgotPassword /></Auth>} />

              <Route key="r" path={'/confirmation'} element={<AlertConfirmation />} />

              <Route key="q" path={'/verification'} element={<PanelVerification />} />

              <Route key="o" path={'/reset'} element={<Auth><PanelResetPassword /></Auth>} >
                <Route key="o1" path={':token'} element={<></>} />
              </Route>

              <Route key="x" path={'/report/:id'} element={<Auth><PanelReport /></Auth>} />

              <Route key="z" path={'/reward'} element={<Auth><PanelReward /></Auth>} />

              <Route key="zb" path={'/offline'} element={<Auth><PanelOffline /></Auth>} />

              <Route key="za" path="*" element={<PageNotFound />} />

              <Route key="zc" path={'/legal-notices'} element={<PanelConditions />}>
                <Route key="zc1" path={'/legal-notices/:type/'} element={<PanelConditions />} />
              </Route>

              <Route key="zd" path="/account-delete-request" element={<Auth><PanelDeleteRequest /></Auth>} />


            </Routes>
          </Suspense>
        </>
      </TransitionPanel >



      <TransitionHeader id={showMenu + '' + showMenuForMobile + 'Header'}>
        <>
          {
            showMenu && showMenuForMobile && <Header scene={props.scene} infos={userInfos.infosProfil}></Header>
          }
        </>
      </TransitionHeader>



      <TransitionFooter id={showMenu + '' + showMenuForMobile + 'Footer'}>
        <>
          {
            showMenu && showMenuForMobile && <Footer></Footer>
          }
        </>
      </TransitionFooter>


      <TransitionMenu id={showMenu + '' + showToogleMenu + 'ToogleMenuForMobile'}>
        <>
          {
            showMenu && showToogleMenu && <a onClick={() => setShowMenuForMobile(!showMenuForMobile)} className='bouton-icone menuForMobile copper icon-menu2'></a>
          }
        </>
      </TransitionMenu>



    </div >
  )
}