
export default class InfosPlayer {

    result: string;
    previousRate: number;
    pauseEvent: number;
    // diffRate: number;

    premium: boolean;

    team: number;
    teamPoint: number;

    id: number;
    index: number;
    img: string;
    name: string;
    point: number;
    win: number;
    win_in_serie: number;
    rate: number;
    level: number;
    block: boolean;
    country: string;

    style: number;
    replay: boolean;
    pause: boolean;
    disconnection: boolean;
    bot: boolean;


    sessionId: string;
    latency: number;
    host: boolean;
    camera: boolean;

    group: number;
    showbot: boolean;

    constructor(details: any) {

        this.id = details.id;

        this.result = details.result ? details.result : "";

        this.style = details.style;
        this.bot = details.bot ? true : false;
        this.showbot = details.showbot ? true : false;
        this.country = details.country;
        this.index = details.index;
        this.img = details.img;
        this.name = details.name;
        this.rate = details.rate ? details.rate : 0;
        this.previousRate = details.previousRate ? details.previousRate : 0;

        this.level = details.level;
        this.latency = details.latency;
        this.block = details.block;
        this.host = details.host;
        this.sessionId = details.sessionId;
        this.disconnection = details.disconnection;

        this.team = details.team;
        this.teamPoint = details.teamPoint ? details.teamPoint : 0;

        this.point = details.point ? details.point : 0;
        this.win = details.win ? details.win : 0;
        this.win_in_serie = details.win_in_serie ? details.win_in_serie : 0;

        this.replay = details.replay ? true : false;
        this.pause = details.pause ? true : false;
        this.camera = details.camera ? true : false;

        this.premium = details.premium ? true : false;

        this.pauseEvent = details.pauseEvent;
        this.group = details.group;
    }

}

