import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useUser, useUserUpdate } from '../../context/UserProvider';
import { InfosCardTheme } from '../../data/InfosCardTheme';
import { InfosStore } from '../../data/InfosStore';
import { InfosStyle } from '../../data/InfosStyle';
import { InfosTheme } from '../../data/InfosTheme';
import { InfosUser } from '../../data/InfosUser';
import { SceneManager } from '../../manager/SceneManager';
import { SoundManager } from '../../manager/SoundManager';
import Slider from '../../componement/Slider';
import Stepper from '../../componement/Stepper';
import ToogleStyle from '../../componement/ToogleStyle';
import { useRondaModal } from '../../context/ModalProvider';
import useRondaFetch from '../../hook/useRondaFetch';
import DataManager from '../../manager/DataManager';
import useMessenger, { MessengerContextType } from '../../hook/useMessenger';
import { MessengerProvider, useRondaMessenger } from '../../context/MessengerProvider';
import { log } from '../../manager/FirebaseManager';
import { InfosEmojis } from '../../data/InfosEmojis';
import ToogleEmoji from '../../componement/ToogleEmoji';

type Props = {}

function TabThemeSettings({ }: Props) {
    let navigate = useNavigate();
    const { t } = useTranslation();


    const { post, response } = useRondaFetch();
    const { handleModal } = useRondaModal()!;


















    let userInfos = useUser()!;
    let setUserInfos = useUserUpdate()!;


    let sceneThemeID: number = userInfos.infosProfil.infosSettings.idInfosTheme;
    let cardThemeID: number = userInfos.infosProfil.infosSettings.idInfosCardTheme;

    const dataCardThemes = userInfos.dataCardThemes.filter((i: InfosStore) => { return i.count > 0 });
    const dataSceneThemes = userInfos.dataSceneThemes.filter((i: InfosStore) => { return i.count > 0 });


    const [infosStyle, setInfosStyle] = useState<InfosStyle>(userInfos.dataStyleThemes.find((is) => is.id === userInfos.infosProfil.infosSettings.idInfosStyle)!);

    const [infosEmoji, setInfosEmoji] = useState<InfosEmojis>(userInfos.dataEmojis.find((is) => is.id === userInfos.infosProfil.infosSettings.idInfosEmoji)!);

    const onGetSceneThemeID = (id: number) => { sceneThemeID = dataSceneThemes[id]?.id; }
    const onGetCardThemeID = (id: number) => { cardThemeID = dataCardThemes[id]?.id; }

    let percent = 1 - percentRange(userInfos.infosPreferences.speed);

    function percentRange(input: number): number {
        const min = 0;//0.15;
        const max = 1.5;

        return (((input - min) * 100) / (max - min)) / 100;
    }

    const onGetSpeed = async (percent: number) => {

        //console.log("userInfos.infosPreferences")
        //console.log(userInfos.infosPreferences)

        let value = Number(Number((1 - percent) * 1.5).toFixed(2));

        log("update_speed", { value: String(value) })

        // Number((((1 - percent)) * 1.35) + 0.15).toFixed(2);
        await post("api/updateprofil", {
            data: {
                Preferences: {
                    id: userInfos.infosPreferences.id,
                    speed: value
                }
            }
        })

        if (response.ok) {
            handleModal("Speed is Updated", "green");
            setUserInfos((prevIU: InfosUser) => ({
                ...prevIU,
                infosPreferences: {
                    ...prevIU.infosPreferences,
                    speed: value
                }
            }));

            //console.log("userInfos" + userInfos.infosProfil.id)
        }
    }




    const onSelectStyle = async (e: InfosStyle) => {

        log("update_style", { value: String(e.id) })


        await post("api/updateprofil", {
            data: {
                Settings: {
                    id: userInfos.infosProfil.infosSettings.id,
                    idInfosStyle: e.id
                }
            }
        })

        if (response.ok) {
            handleModal("Style is Updated", "green");

            setInfosStyle(e);
            setUserInfos((prevIU: InfosUser) => ({
                ...prevIU,
                infosProfil: {
                    ...prevIU.infosProfil,
                    stye: e.id,
                    infosSettings: {
                        ...prevIU.infosProfil.infosSettings,
                        idInfosStyle: e.id
                    }
                }
            }));
        }

    };


    const onSelectEmoji = async (e: InfosEmojis) => {

        log("update_emoji", { value: String(e.id) })


        await post("api/updateprofil", {
            data: {
                Settings: {
                    id: userInfos.infosProfil.infosSettings.id,
                    idInfosEmoji: e.id
                }
            }
        })

        if (response.ok) {
            handleModal("Emoji is Updated", "green");

            setInfosEmoji(e);
            setUserInfos((prevIU: InfosUser) => ({
                ...prevIU,
                infosProfil: {
                    ...prevIU.infosProfil,
                    stye: e.id,
                    infosSettings: {
                        ...prevIU.infosProfil.infosSettings,
                        idInfosEmoji: e.id
                    }
                }
            }));
        }

    };

    //const { handleMessage } = useRondaMessenger()!;

    async function save() {

        // console.log("handleMessage");
        // handleMessage({
        //      from: userInfos.infosProfil,
        //     // to: userInfos.infosProfil,
        //     title: "New notification",
        //     message: "new notification received !new notification received !new notification received !new notification received !new notification received !",
        //     type: "notification",
        //     link: "store"
        // });

        if (cardThemeID !== userInfos.infosProfil.infosSettings.idInfosCardTheme || sceneThemeID !== userInfos.infosProfil.infosSettings.idInfosTheme) {

            if (cardThemeID !== userInfos.infosProfil.infosSettings.idInfosCardTheme)
                log("update_card_theme", { value: String(cardThemeID) })

            if (sceneThemeID !== userInfos.infosProfil.infosSettings.idInfosTheme)
                log("update_theme", { value: String(sceneThemeID) })

            await post("api/updateprofil", {
                data: {
                    Settings: {
                        id: userInfos.infosProfil.infosSettings.id,
                        idInfosCardTheme: cardThemeID,
                        idInfosTheme: sceneThemeID
                    }
                }
            })
            if (response.ok) {
                handleModal("Theme is Updated", "green");
                userInfos.infosProfil.infosSettings.idInfosTheme = sceneThemeID;
                userInfos.infosProfil.infosSettings.idInfosCardTheme = cardThemeID;
                SceneManager.self.updateAssets(userInfos.dataSceneThemes.find((its) => its.id == sceneThemeID), userInfos.dataCardThemes.find((itc) => itc.id == cardThemeID));
                //SoundManager.play(SoundManager.VALIDATE_STANDARD);
                //         handleLoader(loading);

            }
        }



    }

    function findIndexFromID(id: number, arr: Array<any>) {
        let index = arr.findIndex((obj: any) => obj.id === id);
        if (index < 0) index = 0;
        return index;
    }

    return (
        <div className='layer first'>
            <div className='layerContainer'>
                <div className='section theme'>

                    <Stepper className="compact" getID={onGetCardThemeID} initial={findIndexFromID(cardThemeID, dataCardThemes)}>
                        {
                            dataCardThemes.map((infos: InfosCardTheme, index: number) => {
                                return <span key={"ts" + index} className="item"><img src={infos.image} onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = DataManager.DATA_PRELOAD_IMAGES[0];;
                                }} /></span>
                            })
                        }
                    </Stepper>

                    <span className='sousTitre'>{t('Cards Theme')}</span>
                    <span className='message'>{t('Select a cards theme')}</span>
                    <a href='#' className='bouton small bleu-green' onClick={() => save()}>{t('Save')}</a>

                </div>
                <div className='section'>

                    <Stepper className="compact" getID={onGetSceneThemeID} initial={findIndexFromID(sceneThemeID, dataSceneThemes)}>
                        {
                            dataSceneThemes.map((infos: InfosTheme, index: number) => {
                                return <span key={"tc" + index} className="item"><img src={infos.image} onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = DataManager.DATA_PRELOAD_IMAGES[0];;
                                }} /></span>
                            })
                        }
                    </Stepper>

                    <span className='sousTitre'>{t('Theme')}</span>
                    <span className='message'>{t('Select a scene theme')}</span>
                    <a href='#' className='bouton small bleu' onClick={() => save()}>{t('Save')}</a>

                </div>
                <hr />
                <div className='calque style'>
                    <span className='sousTitre'>{t('Style')}</span>
                    <hr />
                    <span className='toogleContainer'><ToogleStyle infosStyle={infosStyle} useTooltip={true} callBack={onSelectStyle} /></span>
                </div>
                <div className='calque emoji style'>
                    <span className='sousTitre'>{t('Emojis')}</span>
                    <hr />
                    <span className='toogleContainer'><ToogleEmoji infosEmoji={infosEmoji} useTooltip={true} callBack={onSelectEmoji} /></span>
                </div>
                <div className='calque vitesse'>
                    <span className='sousTitre'>{t('Game speed')}</span>
                    <hr />
                    <Slider title={Math.floor(percent * 100) + "%"} getPercent={onGetSpeed} initial={percent}></Slider>
                </div>
            </div>
        </div>
    )
}

export default TabThemeSettings