import React from 'react'
import { useTranslation } from 'react-i18next';

import "./TooltipInvite.css";

type Props = {
    title: string
    coordinates: DOMRect | undefined
    callBack: Function
}

function TooltipInvite({ callBack, title, coordinates }: Props) {
    const { t } = useTranslation();

    return (
        <div className="inviteTooltip" style={{ top: coordinates?.top, left: coordinates?.right }} >
            <span className="triangle" />
            <span className="triangleMosaique"></span>
            <span className='titre'>{title}</span>
            <div className='inviteContainer'>
                <span className='message'>{
                    t('Invite a friend to the game?')
                }</span>
                <a className='bouton small blue simple ' onClick={() => callBack()}>{t('Challenge')}</a>
            </div>
        </div>
    )
}


export default TooltipInvite