import React, { useLayoutEffect, useRef } from 'react'

import gsap from "gsap";

import "./Indicateur.css";

type Props = {
    achievement:number
}

function IndicateurAchievement({achievement}: Props) {
    const Slide = useRef<HTMLSpanElement>(null)

    let percent = achievement;

    useLayoutEffect(() => {
        gsap.set(Slide.current, { scaleX: percent/100, transformOrigin:"0px 0px"  });
        return () => {
        };
    }, []);


  return (
    <div className='indicateur achievement'>
        <span ref={Slide} className='slide' />
        <span className='percent'>{percent+"%"}</span>
        <span className='iconAchievement' />

    </div>
  )
}

export default IndicateurAchievement