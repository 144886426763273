import React from 'react'

import "./TooltipDescription.css"

type Props = {
    title: string
    coordinates: DOMRect | undefined

    message: string
}

function TooltipDescription({ message, title, coordinates }: Props) {

    // console.log(coordinates?.top, coordinates?.right, coordinates?.bottom, coordinates?.left);

    return (
        <div className="achievementTooltip" style={{ top: coordinates?.top, left: coordinates?.right }} >
            <span className="triangle" />
            <span className="triangleMosaique"></span>
            <span className='titre'>{title}</span>
            <div className='boutiqueContainer'>
                {
                    message
                }
            </div>
        </div>
    )
}

export default TooltipDescription