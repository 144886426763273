import React, { Children, ReactNode, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { SoundManager } from '../manager/SoundManager';
import "./Alert.css"
import gsap from 'gsap';

import ToogleDrag from './ToogleDrag'

import { useNavigate } from 'react-router-dom';
import ToogleChrono from './ToogleChrono';

type Props = {
    title?: string,
    children?: ReactNode;
    autoClose?: number | null;
    callBack?: Function;
    className?: string;
    dragButton?: boolean;
    animation?: boolean;
    close?: boolean;
}

function Alert({ title, children, autoClose, callBack, className, dragButton = true, animation = true, close = false }: Props) {

    let Target = useRef<HTMLDivElement>(null);
    let navigate = useNavigate();


    useLayoutEffect(() => {
        if (close) {
            closeMe();
        }
    }, [close]);

    useLayoutEffect(() => {
        //SoundManager.play(SoundManager.ALERT_SHOW);

        if (animation) {
            gsap.set(Target.current, {
                y: -1000
            });
            gsap.to(Target.current, {
                position: "absolute",
                duration: 1,
                y: 0,
                ease: "elastic.out(0.8, 0.8)",
            });
        }
        return () => {

        };
    }, []);

    function closeMe() {
        SoundManager.play(SoundManager.ALERT_CANCEL);

        if (animation) {
            gsap.to(Target.current, {
                ease: "elastic.out(0.7, 0.8)",
                duration: 1,
                y: 1000,
                onComplete: () => callBack!()
            });
        } else {
            if (callBack) callBack();
        }
    }


    return (
        <div ref={Target} className={'panel alert ' + ((className != undefined) ? className : "")}>
            <div className='panelContainer'>
                <span className="titre">{title}</span>
                {
                    (autoClose && callBack) ? <ToogleChrono autoClose={autoClose} callBack={callBack}></ToogleChrono>
                        : <a className='bouton-icone bleu icon-fermer' onClick={() => closeMe()}></a>
                }
                {dragButton && <ToogleDrag target={Target.current} className='bouton-icone green icon-glisser' />}
                {
                    children
                }
            </div>
        </div >
    )
}

export default Alert