import React, { MouseEventHandler, useEffect, useState } from 'react'
import Panel from '../../componement/Panel'
import { useTranslation } from 'react-i18next';

import "./PanelProfil.css";
import InfosProfil from '../../data/InfosProfil';
import { useNavigate, useParams } from 'react-router-dom';
import IndicateurBuck from '../../componement/IndicateurBuck';
import IndicateurLevel from '../../componement/IndicateurLevel';
import IndicateurCoin from '../../componement/IndicateurCoin';
import IndicateurAchievement from '../../componement/IndicateurAchievement';
import DataManager from '../../manager/DataManager';
import { useUser, useUserUpdate } from '../../context/UserProvider';
import IndicateurPremium from '../../componement/IndicateurPremium';
import { InfosUser } from '../../data/InfosUser';
import { useRondaModal } from '../../context/ModalProvider';
import useRondaFetch from '../../hook/useRondaFetch';
import { useRondaServer } from '../../context/ServerProvider';
import InfosPlayer from '../../data/InfosPlayer';
import { log } from '../../manager/FirebaseManager';


type Props = {
  animation?: boolean,
  path?: string;
}

function PanelProfil({ animation = false, path = "" }: Props) {


  const { handleModal } = useRondaModal()!;

  let returnTo = path
  if (returnTo == "") returnTo = "/home"

  const { t } = useTranslation();

  let { id } = useParams();

  let navigate = useNavigate();

  let userInfos = useUser()!;
  let setUserInfos = useUserUpdate()!;



  const [infos, setInfos] = useState<InfosProfil | undefined>();

  const [flag, setFlag] = useState<any>(DataManager.DATA_COUNTRIES.find((e) => e.code === ""));

  const { post, response } = useRondaFetch();

  async function getUser(name: string) {
    await post('api/profiluser/', {
      username: name
    })

    if (response.ok) {
      setInfos(new InfosProfil(response.data));
    }
  }


  useEffect(() => {
    getUser(String(id));
  }, [id])





  useEffect(() => {
    if (infos) {
      // friend = userInfos.dataFriends.find((ip) => ip.id === infos?.id);
      // if (friend != undefined) {
      //   setIsFriend(true);
      //   friend = infos;
      // }


      // if (infos.id == userInfos.infosProfil.id) {
      //   isSelf = true;
      // }

      setFlag(DataManager.DATA_COUNTRIES.find((e) => e.code === infos?.country));
    }
  }, [infos])




  let { lounge } = useRondaServer()!;

  const notifyFriendship = () => {
    lounge?.send("friendship", {
      from: userInfos.infosProfil,
      to: infos,
      type: "friendship",
      link: "/profil/" + userInfos.infosProfil.name,
    });

    console.log("from: " + userInfos.infosProfil.id + "to: " + infos?.id + "message: " + " friendship " + "link: " + "/profil/" + userInfos.infosProfil.name);
  };


  function processData(data: Array<any>) {

    let dataFriends: Array<InfosProfil> = [];
    for (let element of data)
      dataFriends.push(new InfosProfil(element));

    setUserInfos((prevIU: InfosUser) => ({
      ...prevIU,
      dataFriends: dataFriends
    }));

    let friend = dataFriends.find((ipf: InfosProfil) => ipf.id === infos?.id);
    if (friend) setInfos(friend);
    else getUser(infos?.name + "");

  }



  async function handleFriend(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    const type = (e.currentTarget as HTMLElement).id;
    await post('api/updatefriendship/', {
      friend: infos?.id
    })
    if (response.ok) {
      processData(response.data);
      switch (type) {
        case "add":
          if (infos?.online) {
            notifyFriendship();
          }
          handleModal("Friend demand Sent", "green");
          log("add_friendship", { friend: infos?.id + "" });

          break;
        case "remove":
          handleModal("Friend removed form list", "green");
          log("remove_friendship", { friend: infos?.id + "" });

          break;
        case "unblock":
          handleModal("User unblocked", "green");
          log("unblock_friendship", { friend: infos?.id + "" });

          break;
      }
    }
  }

  async function confirmFriendship(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    log("confirm_friendship", { friend: infos?.id + "" });

    await post('api/confirmfriendship/', {
      friend: infos?.id
    })
    if (response.ok) {
      processData(response.data);
      handleModal("New Friend Added", "green");
    }
  }

  async function unblockFriendship(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    log("unblock_friendship", { friend: infos?.id + "" });

    await post('api/blockfriendship/', {
      offender: infos?.id
    })
    if (response.ok) {
      processData(response.data.dataFriends);
      handleModal("User unblocked", "green");
    }
  }

  function setButtons() {
    if (!(infos?.id == userInfos.infosProfil.id || infos?.bot)) {
      switch (infos?.friendshipStatus) {
        case "friend":
          return <a id='remove' className='bouton small red simple ' onClick={(e) => handleFriend(e)}>{t('Remove')}</a>
        case "watingYourConfirmation":
          return <a id='confirm' className='bouton small blue simple ' onClick={(e) => confirmFriendship(e)}>{t('Confirm')}</a>
        case "watingHisConfirmation":
          return <a id='pending' className='bouton small copper simple ' onClick={(e) => handleFriend(e)}>{t('Waiting')}</a>
        case "blockedByYou":
          return <a id='unblock' className='bouton small blue simple ' onClick={(e) => unblockFriendship(e)}>{t('Unblock ?')}</a>
        case "blockedByHim":
          return <span id='block' className='bouton small simple desactivate'>{t('Block !')}</span>
        default:
          return <a id='add' className='bouton small blue simple ' onClick={(e) => handleFriend(e)}>{t('Add')}</a>
      }
    }
  }


  return (

    <>
      <Panel className='profil' title={t('Profil')} animation={animation} returnTo={returnTo}>
        {
          (path == "")//infos?.id == userInfos.infosProfil.id && 
            ? <a className='bouton-icone yellow icon-classement' onClick={() => navigate(path + "/achievements", { state: { infosProfil: infos } })}></a>
            : <></>
        }
        {
          (infos?.id != userInfos.infosProfil.id)
            ? <a className='bouton-icone red icon-report' onClick={() => navigate(path + "/report/" + infos?.name)}></a>
            : <></>
        }
        {
          (infos && path == "") && <a className='bouton-icone bleu-green icon-archive' onClick={() => navigate("/archives/all", { state: { infosProfil: infos } })}></a>
        }

        <div className='layer'>
          {
            infos &&
            <div className='layerContainer'>
              <div className="calque">

                <div className='profile'>
                  <IndicateurPremium id={infos.infosSettings.idInfosStyle}></IndicateurPremium>
                  <div className='profilContainer'>
                    <span className={'online ' + ((infos.online) ? 'on' : 'off')}></span>

                    <span className="refrac"></span>
                    <img className="image" src={infos.img} onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = DataManager.DATA_PRELOAD_IMAGES[0];;
                    }} />

                  </div>
                </div>
                <span className='sousTitre name'>{infos.name}</span>
                <img className='flag' src={flag?.flag_4x3}></img>

                {
                  setButtons()
                }
                <div className='indicatorSection'>
                  <IndicateurCoin coin={infos.coin}></IndicateurCoin>

                  <IndicateurBuck buck={infos.buck}></IndicateurBuck>

                  <IndicateurLevel rate={infos.rate}></IndicateurLevel>

                  <IndicateurAchievement achievement={infos.achievements}></IndicateurAchievement>

                </div>


              </div>
              <div className='section'>

                <div >
                  <span className='sousTitre'>{t('Title')}</span>
                  <span className='titre levelTitle'>{infos.levelTitle}</span>
                </div>

                <div >
                  <span className='sousTitre'>{t('Total games')}</span>
                  <span className='titre'>{infos.rounds}</span>
                </div>
                <div >
                  <span className='sousTitre'>{t('Achievements')}</span>
                  <span className='titre'>{infos.achievements}</span>
                </div>
              </div>

              <hr />
              <div className='section'>

                <div >
                  <span className='sousTitre'>{t('Rank')}</span>
                  <span className='titre'>{infos.rank}</span>
                </div>
                <div >
                  <span className='sousTitre'>{t('Total Wins')}</span>
                  <span className='titre'>{infos.win}</span>
                </div>
                <div >
                  <span className='sousTitre'>{t('Level')}</span>
                  <span className='titre'>{infos.rate}</span>
                </div>

              </div>

            </div>
          }
        </div>
      </Panel>
    </>
  )
}

export default PanelProfil