import { InfosParametres } from "./InfosParametres";

export class InfosModes extends InfosParametres {
    type: string;
    bet: number;
    image: string;
    id: number;
    reward: number;

    public constructor(details?: any) {
        if (details?.Parametres)
            super(details?.Parametres);
        else
            super(details)

        if (details) {
            this.id = details.id;
            this.type = details.type;
            this.bet = Number(details.bet);
            this.reward = Number(details.reward);

            this.image = details.image;
        } else {
            this.id = 0;
            this.type = "";
            this.bet = 0;

            this.reward = 0;
            this.image = "";
        }
    }

}
