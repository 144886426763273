import React, { useEffect, useRef, useState } from 'react'
import CircleProgressBar from './CircleProgressBar';

type Props = {
    autoClose: number;
    callBack: Function;
}

function ToogleChrono({autoClose,callBack}: Props) {

    const [chrono, setChrono] = useState<number>(0);
    const [delay] = useState<number>(1000);

    useInterval(()=>{
        if(chrono===autoClose) {
            callBack();
            autoClose=0;
            setChrono(0);
        }else{
            setChrono(chrono+1);
        }
    }, (chrono<=(autoClose-1))?delay:null)



    function useInterval(callback:any, delay:number|null) {
        
        if(delay==null) delay=1000;
        
        const savedCallback = useRef();

        useEffect(()=>{
            savedCallback.current = callback;
        }, [callback, (chrono<delay)?delay:null]);

        useEffect(() => {
            function tick() {
                if(savedCallback.current) (savedCallback.current as Function)();
            }
            if (delay !== null) {
                let id = setInterval(tick, delay);
                return () => clearInterval(id);
            }
        }, [delay])
    }
  return (
    <CircleProgressBar color={"#077BBB"} percent={(chrono / autoClose) * 100} />  )
}

export default ToogleChrono