import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useUser, useUserUpdate } from '../../context/UserProvider';
import { InfosAchievement } from '../../data/InfosAchievement';
import ItemAchievement from './ItemAchievement';
import Panel from '../../componement/Panel'

import "./PanelAchievement.css";
import Stepper from '../../componement/Stepper';
import useRondaFetch from '../../hook/useRondaFetch';
import { LocationState } from '../../data/CustomInterfaces';
import InfosProfil from '../../data/InfosProfil';
import i18next from 'i18next';


type Props = {}

function PanelAchievement({ }: Props) {

    let navigate = useNavigate();
    const { t } = useTranslation();

    let location = useLocation();
    const infosProfil = (location.state as LocationState)?.infosProfil;


    const { post, response } = useRondaFetch();
    const [loading, isLoading] = useState<boolean>(true);

    const [data, setData] = useState<Array<InfosAchievement>>([]);

    const [name, setName] = useState<string>("");

    async function updateAchievement(infosProfil: InfosProfil) {
        await post('api/getachievements?locale=' + i18next.language, {
            user: infosProfil
        });
        if (response.ok) {

            let dataResponse: Array<InfosAchievement> = [];
            response.data.forEach((element: any) => {
                dataResponse.push(new InfosAchievement(element));
            });

            setData(dataResponse);
            isLoading(false);

        }
    }

    useEffect(() => {
        if (infosProfil) {
            updateAchievement(infosProfil);
            setName(infosProfil.name)
        }
        return () => {

        }
    }, [infosProfil])

    return (
        <>
            <Outlet></Outlet>
            <Panel className='Achievement' title={t('Achievements') + " " + name}>
                <div className='layer'>
                    <div className='layerContainer'>
                        <span className='sousTitre' >{t('Discover the unlocked achieveents !')}</span>
                        <Stepper className="store " initial={0} max={8} step={8} getID={(e: number) => { }} >
                            {
                                (loading === true)
                                    ? <>
                                        <div className="dummyItem animated-background "></div>
                                        <div className="dummyItem animated-background "></div>
                                        <div className="dummyItem animated-background "></div>
                                        <div className="dummyItem animated-background "></div>
                                        <div className="dummyItem animated-background "></div>
                                        <div className="dummyItem animated-background "></div>
                                        <div className="dummyItem animated-background "></div>
                                        <div className="dummyItem animated-background "></div>
                                        <div className="dummyItem animated-background "></div>
                                        <div className="dummyItem animated-background "></div>
                                        <div className="dummyItem animated-background "></div>
                                        <div className="dummyItem animated-background "></div>
                                        <div className="dummyItem animated-background "></div>
                                        <div className="dummyItem animated-background "></div>
                                        <div className="dummyItem animated-background "></div>
                                        <div className="dummyItem animated-background "></div>
                                        <div className="dummyItem animated-background "></div>
                                        <div className="dummyItem animated-background "></div>
                                    </>
                                    : (data.length !== 0)
                                        ? data.map((infos: InfosAchievement, index: number) => {
                                            return <ItemAchievement key={"ia" + index} infos={infos} ></ItemAchievement>
                                        })
                                        : <div className="nodata">
                                            <div className="perso"></div>
                                            <span className="sousTitre">{t("No data available.")}</span>
                                        </div>
                            }
                        </Stepper>
                    </div>
                </div>
            </Panel>
        </>
    )
}

export default PanelAchievement