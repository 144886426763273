import { Scene, Sound, SoundTrack, AudioEngine, Engine } from "@babylonjs/core";
import gsap from "gsap";

export class SoundManager {
	private static _mute: boolean;
	private static music: SoundTrack;
	private static sfx: SoundTrack;

	private static _musicVolume: number;
	private static _sfxVolume: number;
	private static masterMusic: string;

	private static _to: any;

	public static ready: boolean;

	public static ALERT_CANCEL: string = 'ALERT_CANCEL';
	public static ALERT_SHOW: string = 'ALERT_SHOW';
	public static BUTTON_MOUSE_OUT: string = 'BUTTON_MOUSE_OUT';
	public static BUTTON_MOUSE_OVER: string = 'BUTTON_MOUSE_OVER';
	public static CAMERA_MOVE: string = 'CAMERA_MOVE';
	public static CANCEL_DISMISS: string = 'CANCEL_DISMISS';
	public static COIN_DOWN: string = 'COIN_DOWN';
	public static COIN_UP: string = 'COIN_UP';
	public static EVENT_ADD_PLAYER: string = 'EVENT_ADD_PLAYER';
	public static EVENT_KICK_PLAYER: string = 'EVENT_KICK_PLAYER';
	public static EVENT_REFRESH: string = 'EVENT_REFRESH';
	public static EVENT_SEARCH: string = 'EVENT_SEARCH';
	public static LOADING_LOOP: string = 'LOADING_LOOP';
	public static NOTIFICATION_DECONNEXION: string = 'NOTIFICATION_DECONNEXION';
	public static OVERLAY_SHOW: string = 'OVERLAY_SHOW';
	public static PANEL_HIDE: string = 'PANEL_HIDE';
	public static PANEL_SHOW: string = 'PANEL_SHOW';
	public static SEND_MESSAGE: string = 'SEND_MESSAGE';
	public static SEND_WIZZ: string = 'SEND_WIZZ';
	public static SPECIAL_ACTIVATED_LOOP: string = 'SPECIAL_ACTIVATED_LOOP';
	public static SPECIAL_BLOCK_DENIED: string = 'SPECIAL_BLOCK_DENIED';
	public static SPECIAL_USE: string = 'SPECIAL_USE';
	public static TCHAT_CLOSE: string = 'TCHAT_CLOSE';
	public static TCHAT_OPEN: string = 'TCHAT_OPEN';
	public static TOOLTIPS_SHOW: string = 'TOOLTIPS_SHOW';
	public static VALIDATE_BLOCK_PLAYER: string = 'VALIDATE_BLOCK_PLAYER';
	public static VALIDATE_LANGUAGE: string = 'VALIDATE_LANGUAGE';
	public static VALIDATE_SOUND: string = 'VALIDATE_SOUND';
	public static VALIDATE_STANDARD: string = 'VALIDATE_STANDARD';
	public static VALIDATE_STARTGAME: string = 'VALIDATE_STARTGAME';
	public static XP_DOWNGRADE: string = 'XP_DOWNGRADE';
	public static XP_UPGRADE: string = 'XP_UPGRADE';
	public static CLOCK_TICKING: string = 'CLOCK_TICKING';

	public static VOICE_HAZ_2: string = 'VOICE_HAZ_2';
	public static VOICE_HAZ_4: string = 'VOICE_HAZ_4';
	public static VOICE_HAZ_6: string = 'VOICE_HAZ_6';
	public static VOICE_HAZ_8: string = 'VOICE_HAZ_8';
	public static VOICE_7AKAM_JBABEN: string = 'VOICE_7AKAM_JBABEN';
	public static VOICE_7AKAM_SYOUF: string = 'VOICE_7AKAM_SYOUF';
	public static VOICE_7AKAM_FLOUS: string = 'VOICE_7AKAM_FLOUS';
	public static VOICE_7AKAM_ZRAWAT: string = 'VOICE_7AKAM_ZRAWAT';
	public static VOICE_BWA7AD: string = 'VOICE_BWA7AD';
	public static VOICE_B5AMSA: string = 'VOICE_B5AMSA';
	public static VOICE_B3ACHRA: string = 'VOICE_B3ACHRA';
	public static VOICE_RONDA: string = 'VOICE_RONDA';
	public static VOICE_TRINGA: string = 'VOICE_TRINGA';
	public static VOICE_MISSA: string = 'VOICE_MISSA';
	public static VOICE_JMA3_L9A3A: string = 'VOICE_JMA3_L9A3A';
	public static VOICE_KDOUB: string = 'VOICE_KDOUB';
	public static VOICE_FOU9HOUM_3: string = 'VOICE_FOU9HOUM_3';
	public static VOICE_FOU9HOUM_2: string = 'VOICE_FOU9HOUM_2';
	public static VOICE_FOU9HOUM_1: string = 'VOICE_FOU9HOUM_1';
	public static VOICE_WA7AD: string = 'VOICE_WA7AD';
	public static VOICE_ZOUJ: string = 'VOICE_ZOUJ';
	public static VOICE_TLATA: string = 'VOICE_TLATA';
	public static VOICE_LAS: string = 'VOICE_LAS';
	public static VOICE_DOS: string = 'VOICE_DOS';
	public static VOICE_3_TRESS: string = 'VOICE_3_TRESS';
	public static VOICE_4_QUATRO: string = 'VOICE_4_QUATRO';
	public static VOICE_5_CINQO: string = 'VOICE_5_CINQO';
	public static VOICE_6: string = 'VOICE_6';
	public static VOICE_7: string = 'VOICE_7';
	public static VOICE_SOTA: string = 'VOICE_SOTA';
	public static VOICE_CABALE: string = 'VOICE_CABALE';
	public static VOICE_REI: string = 'VOICE_REI';
	public static VOICE_TAR7_SALA: string = 'VOICE_TAR7_SALA';
	public static VOICE_BDA_TAR7: string = 'VOICE_BDA_TAR7';
	public static VOICE_5: string = 'VOICE_5';
	public static VOICE_QUINZA: string = "VOICE_QUINZA";
	public static VOICE_SOTAGHAMLA: string = "VOICE_SOTAGHAMLA";
	public static VOICE_BATAILLE: string = "VOICE_BATAILLE";
	public static VOICE_ASSASSIN: string = "VOICE_ASSASSIN";
	public static VOICE_BONJOUR_MONSIEUR: string = "VOICE_BONJOUR_MONSIEUR";
	public static VOICE_BONJOUR_MADAME: string = "VOICE_BONJOUR_MADAME";
	public static VOICE_FLOUS: string = "VOICE_FLOUS";
	public static VOICE_CANTCOPY: string = "VOICE_CANTCOPY";
	public static BUTTON_CLICK: string = "BUTTON_CLICK";



	public static VOICE_ZADTAKAACHRA: string = "VOICE_ZADTAKAACHRA";
	public static VOICE_PASSE: string = "VOICE_PASSE";
	public static VOICE_ALLA: string = "VOICE_ALLA";
	public static VOICE_GHNA: string = "VOICE_GHNA";
	public static VOICE_MACHARICH: string = "VOICE_MACHARICH";
	public static VOICE_MZAYDA: string = "VOICE_MZAYDA";

	public static VOICE_TATRAK: string = "VOICE_TATRAK";

	public static VOICE_ATOUT_DHAB: string = "VOICE_ATOUT_DHAB";
	public static VOICE_ATOUT_SYOUF: string = "VOICE_ATOUT_SYOUF";
	public static VOICE_ATOUT_JBABEN: string = "VOICE_ATOUT_JBABEN";
	public static VOICE_ATOUT_ZRAWAT: string = "VOICE_ATOUT_ZRAWAT";



	public static VOICE_70: string = "VOICE_70";
	public static VOICE_80: string = "VOICE_80";
	public static VOICE_90: string = "VOICE_90";
	public static VOICE_100: string = "VOICE_100";
	public static VOICE_110: string = "VOICE_110";
	public static VOICE_120: string = "VOICE_120";
	public static VOICE_130: string = "VOICE_130";
	public static VOICE_140: string = "VOICE_140";
	public static VOICE_150: string = "VOICE_150";
	public static VOICE_160: string = "VOICE_160";
	public static VOICE_170: string = "VOICE_170";
	public static VOICE_180: string = "VOICE_180";
	public static VOICE_190: string = "VOICE_190";
	public static VOICE_200: string = "VOICE_200";
	public static VOICE_210: string = "VOICE_210";
	public static VOICE_220: string = "VOICE_220";
	public static VOICE_230: string = "VOICE_230";


	public static VOICE_BAHIA: string = "VOICE_BAHIA";
	public static VOICE_BRELON: string = "VOICE_BRELON";
	public static VOICE_CARRE: string = "VOICE_CARRE";

	public static VOICE_GARDEAVOUS: string = "VOICE_GARDEAVOUS";
	public static VOICE_MAINSURLETAS: string = "VOICE_MAINSURLETAS";



	public static currentMusic: number;

	static scene: Scene;
	private static _muteMusic: boolean;

	static monVolume = { volume: 0 };
	static monSfx = { volume: 0 };


	private static _muteSfx: boolean;

	private static dataSoundInfos: Array<any> = [];


	public static get VOICE_WIN(): string { return "VOICE_WIN_" + this.randomRange(1, 11); }
	public static get VOICE_LOSE(): string { return "VOICE_LOSE_" + this.randomRange(1, 11); }

	public static get CARD_SHUFFLE(): string { return "CARD_SHUFFLE_" + this.randomRange(1, 7); }
	public static get CARD_PLACE(): string { return "CARD_PLACE_" + this.randomRange(1, 4); }
	public static get CARD_SHOVE(): string { return "CARD_SHOVE_" + this.randomRange(1, 4); }
	public static get CARD_SLIDE(): string { return "CARD_SLIDE_" + this.randomRange(1, 8); }
	public static get CARD_FAN(): string { return "CARD_FAN_" + this.randomRange(1, 2); }

	static isLoop: boolean = false;

	private static voice: string = "male";

	public static init(pscene: Scene) {
		this.scene = pscene;
		SoundManager.music = new SoundTrack(this.scene);
		SoundManager.sfx = new SoundTrack(this.scene);

		//this.scene.audioEnabled = true;
		//audioEngine
		if (Engine.audioEngine) {
			Engine.audioEngine.useCustomUnlockedButton = true
		}

		this.masterMusic = "";
		this._mute = true;
		this._muteMusic = true;
		this._muteSfx = true;

		this.ready = false;


		SoundManager.currentMusic = 0;

		SoundManager.sfxVolume = 0;
		SoundManager.musicVolume = 0;
	}

	static setVoice(v: string = "man") {
		this.voice = v;
	}
	static updateVolume(sfxV: number, mV: number) {
		SoundManager.sfxVolume = sfxV;
		SoundManager.musicVolume = mV;
	}

	public static playVoice(s: string) {
		if (!this.ready) return;

		if (this.voice == "") this.voice = "man";
		s = s + "_" + this.voice;

		//console.log(s);

		let myVoice = this.sfx.soundCollection.find(son => son.name == s);

		if (myVoice == undefined) {

			let soundInfos = this.dataSoundInfos.find(son => son.name == s);

			if (soundInfos) {
				let sound = new Sound(soundInfos.name, soundInfos.sound, this.scene, () => {
					sound.play();
				}, {
					loop: false,
					autoplay: true,
					spatialSound: false
				});
				this.sfx.addSound(sound);
			}
			return;
		} else {
			this.sfx.soundCollection.find(son => son.name == s)?.stop();
			this.sfx.soundCollection.find(son => son.name == s)?.play();
		}
	}

	public static play(s: string) {
		if (!this.ready) return;
		this.sfx.soundCollection.find(son => son.name == s)?.stop();
		this.sfx.soundCollection.find(son => son.name == s)?.play();

	}
	public static playLoop(s: string) {
		if (!this.ready) return;
		let sound = this.sfx.soundCollection.find(son => son.name == s);
		if (sound) {
			sound.loop = true;
			sound?.stop();
			sound?.play();
		}
		//this.music.soundCollection.find(son=> son.name == s)?.play();
	}
	public static stopLoop(s: string) {
		if (!this.ready) return;
		let sound = this.sfx.soundCollection.find(son => son.name == s);
		if (sound) {
			sound.loop = false;
			sound?.stop();
		}
		//this.music.soundCollection.find(son=> son.name == s)?.play();
	}

	public static stop(s?: string | undefined) {
		if (!this.ready) return;
		//this.sfx.stop(s);

		if (s == undefined) {
			//this.music.soundCollection[SoundManager.currentMusic]?.stop();
			s = this.dataSoundInfos[SoundManager.currentMusic].name
		}

		this.music.soundCollection.find(son => son.name == s)?.stop();
	}
	private static randomRange(minNum: number, maxNum: number): number {
		return (Math.floor(Math.random() * (maxNum - minNum + 1)) + minNum);
	}

	public static playAmbiance() {
		SoundManager.jukeBox(SoundManager.music.soundCollection[SoundManager.currentMusic].name);
	}

	private static jukeBox(s: string) {
		if (!this.ready) return;

		let ancienneMusic: Sound;
		let maMusic = this.music.soundCollection.find(son => son.name == s);

		if (maMusic == undefined) {
			let soundInfos = this.dataSoundInfos.find(son => son.name == s);
			if (soundInfos) {
				let sound = new Sound(soundInfos.name, soundInfos.sound, this.scene, () => {
					// This callback is executed when the sound is ready to play.
					//if (onSoundLoaded) {
					this.jukeBox(s); // Call the provided callback when the sound is loaded.
					//}
				}, {
					loop: false,
					autoplay: false,
					spatialSound: false
				});
				// sound.onended = () => {
				// 	if (SoundManager.isLoop) sound.play();
				// 	//					  SoundManager.playLoop(sound.name)
				// 	else SoundManager.switchMusic();
				// };
				this.music.addSound(sound);
			}
			return;
		}

		if (this.masterMusic != "" && this.masterMusic != s) {
			ancienneMusic = this.music.soundCollection.find(son => son.name == this.masterMusic) as Sound;

			gsap.to(this.monVolume, {
				duration: 1, volume: 0,
				overwrite: 'auto',
				onUpdate: function () { ancienneMusic.setVolume(SoundManager.monVolume.volume); },
				onComplete: onReady.bind(this)
			});

		} else if (this.masterMusic == s) {
			// Replay the Music - Do nothing else
		} else {
			onReady();
		}

		function onReady() {
			SoundManager.masterMusic = s;

			ancienneMusic?.stop();

			SoundManager.monVolume.volume = SoundManager._musicVolume;

			ancienneMusic?.setVolume(SoundManager._musicVolume);
			maMusic?.play();
		}
	}

	public static add(pname: string, psound: any, pgroup: string = "sfx", ptype: string = "", onSoundLoaded?: () => void) {
		if (pgroup == "voice") {
			this.dataSoundInfos.push({ name: pname + "_" + ptype, sound: psound, group: pgroup });
			//console.log(pname + "_" + ptype);
			if (onSoundLoaded) {
				onSoundLoaded(); // Call the provided callback when the sound is loaded.
			}
		} else if (pgroup == "music") {
			this.dataSoundInfos.push({ name: pname, sound: psound, group: pgroup });
			if (onSoundLoaded) {
				onSoundLoaded(); // Call the provided callback when the sound is loaded.
			}
			/*let sound = new Sound(pname, psound, this.scene, () => {
				// This callback is executed when the sound is ready to play.
				if (onSoundLoaded) {
					onSoundLoaded(); // Call the provided callback when the sound is loaded.
				}
			}, {
				loop: false,
				autoplay: false,
				spatialSound: false
			});
			sound.onended = () => {
				if (SoundManager.isLoop) sound.play();
				//					  SoundManager.playLoop(sound.name)
				else SoundManager.switchMusic();
			};
			this.music.addSound(sound);*/
		} else if (pgroup == "sfx") {
			let sound = new Sound(pname, psound, this.scene, () => {
				// This callback is executed when the sound is ready to play.
				if (onSoundLoaded) {
					onSoundLoaded(); // Call the provided callback when the sound is loaded.
				}
			}, {
				loop: false,
				autoplay: false,
				spatialSound: false
			});
			this.sfx.addSound(sound);
		} else {
			if (onSoundLoaded) {
				onSoundLoaded(); // Call the provided callback when the sound is loaded.
			}
		}
	}

	public static switchMusic(dir: number = 1) {
		if (this._muteMusic) return;

		/*if (SoundManager.music.soundCollection.length <= 0) return;

		SoundManager.currentMusic += dir;

		if (SoundManager.currentMusic < 0)
			SoundManager.currentMusic = SoundManager.music.soundCollection.length + SoundManager.currentMusic;

		const mdl: number = SoundManager.currentMusic % SoundManager.music.soundCollection.length;
		SoundManager.currentMusic = mdl;

		SoundManager.jukeBox(SoundManager.music.soundCollection[SoundManager.currentMusic].name);*/


		let dataMusicInfos = this.dataSoundInfos.filter((s) => s.group == "music")

		if (dataMusicInfos.length <= 0) return;

		SoundManager.currentMusic += dir;

		if (SoundManager.currentMusic < 0)
			SoundManager.currentMusic = dataMusicInfos.length + SoundManager.currentMusic;

		const mdl: number = SoundManager.currentMusic % dataMusicInfos.length;
		SoundManager.currentMusic = mdl;

		SoundManager.jukeBox(dataMusicInfos[SoundManager.currentMusic].name);
	}

	public static remove(type: string) {
		//SoundAS.group(type).removeAll();
	}



	public static get sfxVolume(): number {
		//this._sfxVolume =  this.sfx.soundCollection[0].getVolume();
		return SoundManager._sfxVolume;
	}

	public static set sfxVolume(value: number) {
		this._sfxVolume = value;
		this.sfx.setVolume(value);
	}

	public static get musicVolume(): number {
		//this._musicVolume = this.music.soundCollection[0].getVolume();
		return this._musicVolume;
	}

	public static set musicVolume(value: number) {
		this._musicVolume = value;
		this.monVolume.volume = this._musicVolume;
		this.music.setVolume(value);
	}

	public static get mute(): boolean {
		return this._mute;
	}

	public static set mute(value: boolean) {
		this._mute = value;
		if (value) {
			this.sfx.setVolume(0);
			this.music.setVolume(0);
		} else {
			this.sfx.setVolume(this._sfxVolume);
			this.music.setVolume(this._musicVolume);
		}
	}

	public static get muteSfx(): boolean {
		return this._muteSfx;
	}

	public static set muteSfx(value: boolean) {
		if (value) {
			SoundManager.sfx.setVolume(SoundManager._sfxVolume);
			this._muteSfx = true;
		} else {
			SoundManager.sfx.setVolume(0);
			this._muteSfx = false
		}

	}

	public static get muteMusic(): boolean {
		return this._muteMusic;//(SoundManager.music.soundCollection[0]?.getVolume()==0)?true:false;
	}

	public static set muteMusic(value: boolean) {
		this._muteMusic = value;



		if (this._muteMusic) {
			SoundManager.stop();
		} else {
			SoundManager.switchMusic();

		}
	}


}
