import { Vector3, Vector4 } from "@babylonjs/core";

var offX = 0;
var offY = 2.5;

//Array of paths to construct extrusion
export const EightStarBranchShape = [new Vector3(1.5, 0, 0),
new Vector3(2, 0, 0.5),
new Vector3(2.5, 0, 0.5),
new Vector3(2.5, 0, 1),
new Vector3(3, 0, 1.5),
new Vector3(2.5, 0, 2),
new Vector3(2.5, 0, 2.5),
new Vector3(2, 0, 2.5),
new Vector3(1.5, 0, 3),
new Vector3(1, 0, 2.5),
new Vector3(0.5, 0, 2.5),
new Vector3(0.5, 0, 2),
new Vector3(0, 0, 1.5),
new Vector3(0.5, 0, 1),
new Vector3(0.5, 0, 0.5),
new Vector3(1, 0, 0.5)];

export const EightStarBranchFaceUV = [new Vector4(0, 0, 0.5, 0.5),
new Vector4(0, 0, 0, 0),
new Vector4(0, 1, 1, 0)
]


export const BeveledCardShape = [
    new Vector3(5 + offX, 0, 4 + offY),
    new Vector3(4.9 + offX, 0, 4.6 + offY),
    new Vector3(4.75 + offX, 0, 4.75 + offY),
    new Vector3(4.55 + offX, 0, 4.9 + offY),
    new Vector3(4 + offX, 0, 5 + offY),

    new Vector3(-4 - offX, 0, 5 + offY),
    new Vector3(-4.55 - offX, 0, 4.9 + offY),
    new Vector3(-4.75 - offX, 0, 4.75 + offY),
    new Vector3(-4.9 - offX, 0, 4.6 + offY),
    new Vector3(-5 - offX, 0, 4 + offY),

    new Vector3(-5 - offX, 0, -4 - offY),
    new Vector3(-4.9 - offX, 0, -4.6 - offY),
    new Vector3(-4.75 - offX, 0, -4.75 - offY),
    new Vector3(-4.55 - offX, 0, -4.9 - offY),
    new Vector3(-4 - offX, 0, -5 - offY),

    new Vector3(4 + offX, 0, -5 - offY),
    new Vector3(4.55 + offX, 0, -4.9 - offY),
    new Vector3(4.75 + offX, 0, -4.75 - offY),
    new Vector3(4.9 + offX, 0, -4.6 - offY),
    new Vector3(5 + offX, 0, -4 - offY)
];


export const BeveledCardPath = [
    new Vector3(0, 0, 0),
    new Vector3(0, 0, 1)
];

export const BeveledCardFaceUV = [new Vector4(0, 0, 0.5, 0.5),
new Vector4(0, 0, 0, 0),
new Vector4(0, 1, 1, 0)
]


//Create extrusion with updatable parameter set to true for later changes
//var extrusion = MeshBuilder.ExtrudeShape("extruded", {shape: BeveledCardInfos.myShape, path: BeveledCardInfos.myPath, sideOrientation: Mesh.DOUBLESIDE, cap: Mesh.CAP_ALL});
