import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Panel from '../../componement/Panel';

import jwt_decode from 'jwt-decode';

import "./PanelConnexion.css"
import IndicateurValidation from '../../componement/IndicateurValidation';
import validator from 'validator';
import { useUser, useUserUpdate } from '../../context/UserProvider';
import { InfosUser } from '../../data/InfosUser';
import DataManager from '../../manager/DataManager';
import i18next from 'i18next';
import { SoundManager } from '../../manager/SoundManager';
//import useFetch from 'use-http';
import LoadingScreen from '../../componement/LoadingScreen';
import { useRondaModal } from '../../context/ModalProvider';
import { SceneManager } from '../../manager/SceneManager';
import useRondaFetch from '../../hook/useRondaFetch';
import { log } from '../../manager/FirebaseManager';
import FacebookLogin from '@greatsumini/react-facebook-login';

type Props = {}

function PanelConnexion({ }: Props) {

    let emailRef = useRef<HTMLInputElement | null>(null);
    let passwordRef = useRef<HTMLInputElement | null>(null);

    let location = useLocation();

    const redirectionPath = (location.state as any)?.path || "/home";


    let navigate = useNavigate();
    let userInfos = useUser()!;
    let updateUserInfos = useUserUpdate()!;

    const { t } = useTranslation();

    const signInDiv = useRef<HTMLDivElement>(null);

    useEffect(() => {

        /* global google */
        if ((window as any).google)
            (window as any).google.accounts.id.initialize({
                client_id: process.env.REACT_APP_GOOGLE_CREDENTIALS,
                callback: onGetResponse,
            })

        function onGetResponse(response: any) {
            let obj: any = jwt_decode(response.credential);
            log("sign_in", { type: "google" });

            connectUser(obj.email, obj.sub);
        }

        return () => {

        }
    }, [])


    useEffect(() => {
        /* global google */
        if ((window as any).google)
            (window as any).google.accounts.id.renderButton(
                signInDiv.current,
                {
                    type: 'standard',//icon 
                    theme: 'outline',// outline filled_black
                    size: 'large', //large
                })

        return () => {

        }
    }, [])

    const responseFacebook = (response: any) => {
        console.log(response);

        log("sign_in", { type: "facebook" });

        connectUser(response.email,
            response.id, response.picture.data.url);
    }



    let email: string;
    let password: string;

    const [errorMessage, setErrorMessage] = useState('')
    const [errorEmailMessage, setErrorEmailMessage] = useState('')





    const validateEmail = (value: string) => {

        if (validator.isEmail(value)) {
            setErrorEmailMessage('ok');
            email = value;
        } else {
            setErrorEmailMessage('cancel')
        }
    }



    const validatePassword = (value: string) => {

        if (validator.isLength(value, { min: 6 })) {
            setErrorMessage('ok');
            password = value;
        } else {
            setErrorMessage('cancel')
        }
    }



    function validateForm() {
        log("sign_in", { type: "web" });

        if (errorMessage == 'ok' && errorEmailMessage == 'ok')
            connectUser((emailRef.current as HTMLInputElement).value, (passwordRef.current as HTMLInputElement).value);
        else
            SoundManager.play(SoundManager.CANCEL_DISMISS);

    }

    const { post, cache, response } = useRondaFetch()

    async function connectUser(identifier: string, password: string, picture?: string) {
        cache.clear();
        cache.delete();

        const newTodo = await post('api/connectuser/', {
            identifier,
            password,
            picture
        })
        if (response.ok) {
            /*let infos = new InfosUser(response.data.infosUser);
            updateUserInfos(infos);
            i18next.changeLanguage(DataManager.DATA_LANGUAGES.find(i => i.id == infos.infosProfil.infosSettings.idInfosLanguage)?.locale);
            SoundManager.updateVolume(infos.InfosParametres.soundVolume / 100, infos.InfosParametres.musicVolume / 100);
            SceneManager.self.updateAssets(infos.dataSceneThemes.find((its) => its.id == infos.infosProfil.infosSettings.idInfosTheme), infos.dataCardThemes.find((itc) => itc.id == infos.infosProfil.infosSettings.idInfosCardTheme));


            handleModal(`Weldome back ${infos.infosProfil.name} !`, "green");

            console.log("redirectionPath" + redirectionPath);*/

            navigate(redirectionPath, { replace: true });

        }
    }


    const { handleModal } = useRondaModal()!;




    let num: number = 2;
    //{"data":null,"error":{"status":400,"name":"ValidationError","message":"Invalid identifier or password","details":{}}}


    return (
        <>
            {
                // loading && <LoadingScreen />
            }
            <Panel className='connexion' title={t('Log in')} returnTo="/">
                <div className='layer first'>
                    <div className='layerContainer'>
                        <span className='message title'>{t('Enter your email and password to log in.')}</span>
                        <form action="">

                            <i className="formIcon icon-email"></i>
                            <IndicateurValidation validationType={errorEmailMessage} messages={[
                                { name: "ok", value: t('Valid field!') },
                                { name: "cancel", value: <p>{t('The field must be a valid email.')}</p> }
                            ]} />
                            <input ref={emailRef} onChange={e => validateEmail(e.target.value)} placeholder={t('Email')} ></input>




                            <i className="formIcon icon-password"></i>
                            <IndicateurValidation validationType={errorMessage} messages={[
                                { name: "ok", value: t('Valid field!') },
                                {
                                    name: "cancel", value: <><p>{t('The field must contain:')}</p>
                                        <ol>
                                            <li>{t('At least 4 characters,')}</li>
                                            <li>{t('One uppercase letter,')}</li>
                                            <li>{t('One special character,')}</li>
                                            <li>{t('One number')}</li>
                                        </ol></>
                                }
                            ]} />
                            <input ref={passwordRef} type="password" onChange={e => validatePassword(e.target.value)} placeholder={t('Password')} ></input>


                        </form>
                        <a href='#' className='bouton bleu' onClick={validateForm}>{t('Sign in')}</a>


                        <span className='message'>{t("or create your account")}</span>

                        <a href='#' className='bouton small green' onClick={() => { navigate("/signup") }}>{t("Sign up")}</a>



                    </div>
                </div>
                <div className='layer'>
                    <div className='layerContainer'>

                        <span className='message title'>{t('Or log-in with one of your social accounts:')}</span>
                        <div className='googleButton' ref={signInDiv} ></div>

                        <FacebookLogin
                            appId={String(process.env.REACT_APP_FACEBOOK_CREDENTIALS)}
                            fields="name,email,picture"
                            scope="public_profile,user_friends"
                            //language="fr_FR"
                            //icon="element"
                            //onClick={componentClicked}
                            onProfileSuccess={responseFacebook}
                            render={(renderProps) => (
                                <a className='boutonFacebook' onClick={renderProps.onClick}>{t('Connect with FaceBook')}</a>
                            )}
                        />
                        <hr className='separateur' />
                        <span className='message'>{t("Forgot your password?")}</span>
                        <a href='#' className='bouton small bleu-green' onClick={() => { navigate("/forgotpassword") }}>{t("Recover my password")}</a>

                    </div>
                </div>

            </Panel>
        </>

    )
}

export default PanelConnexion
