import { InfosStore } from "./InfosStore";

export class InfosCoin extends InfosStore {
    public social: boolean;
    public devise: string;
    public url: string;
    public value: number;
    public type: string;

    constructor(details?: any) {
        super(details);

        this.type = details.type;
        this.social = (this.type == "social") ? true : false;
        this.url = details.url;
        this.value = details.value;
        this.devise = details.devise;

        this.infinite = true;

    }
}
