import React, { ReactNode } from 'react'
import { TextManager } from '../manager/TextManager';

import "./Indicateur.css";

type Props = {
  coin: number;
  children?: ReactNode;
}

function IndicateurCoin({ coin, children }: Props) {

  return (
    <div className='indicateur'>
      <span className='numero'>{TextManager.formatScore(coin, 7)}</span>
      <span className='iconCoin' />
      {
        children
      }
    </div>
  )
}

export default IndicateurCoin