import React from 'react'
import { Vector3, Color3, Tools } from '@babylonjs/core'
import { exit } from 'process';
import { TransformNode } from 'react-babylonjs';

type Props = {
  visibility: boolean;
}


export function Arrows(props: any) {

  const size = 4;
  const shade = 0;

  if (props.visibility)

    return (
      <>
        <lines name="red-line"
          points={
            [
              Vector3.Zero(),
              new Vector3(size, 0, 0),
              new Vector3(size * 0.95, 0.05 * size, 0),
              new Vector3(size, 0, 0),
              new Vector3(size * 0.95, -0.05 * size, 0)
            ]
          }
          color={new Color3(1, shade, shade)}
        />
        <lines
          name="green-line"
          points={
            [
              Vector3.Zero(),
              new Vector3(0, size, 0),
              new Vector3(-0.05 * size, size * 0.95, 0),
              new Vector3(0, size, 0),
              new Vector3(0.05 * size, size * 0.95, 0)
            ]
          }
          color={new Color3(shade, 1, shade)}
        />
        <lines
          name="blue-line"
          points={
            [
              Vector3.Zero(),
              new Vector3(0, 0, size),
              new Vector3(0, -0.05 * size, size * 0.95),
              new Vector3(0, 0, size),
              new Vector3(0, 0.05 * size, size * 0.95)
            ]
          }
          color={new Color3(shade, shade, 1)}
        />;

      </>

    )
  else
    return (<></>);

}



export default function Repere(props: Props) {




  return (
    <>
      <transformNode name="transform-node" setEnabled={props.visibility} >
        <transformNode name="special" position={new Vector3(15, 0, -10)} >
          <Arrows key="a1" visibility={props.visibility} />
        </transformNode>
        <transformNode name="hud" position={new Vector3(-15, 0, -10)} >
          <Arrows key="a2" visibility={props.visibility} />
        </transformNode>
        <transformNode name="player1" position={new Vector3(15, 0.3, 5)} rotation={new Vector3(Tools.ToRadians(0), Tools.ToRadians(-112.5), 0)} >
          <Arrows key="a3" visibility={props.visibility} />
        </transformNode>
        <transformNode name="player2" position={new Vector3(0, 0.3, 10)} rotation={new Vector3(Tools.ToRadians(0), Tools.ToRadians(180), 0)} >
          <Arrows key="a4" visibility={props.visibility} />
        </transformNode>
        <transformNode name="player3" position={new Vector3(-15, 0.3, 5)} rotation={new Vector3(Tools.ToRadians(0), Tools.ToRadians(112.5), 0)} >
          <Arrows key="a5" visibility={props.visibility} />
        </transformNode>
        <transformNode name="table" position={new Vector3(0, 0.1, 0)} scaling={new Vector3(1.5, 1.5, 1.5)}>
          <Arrows key="a6" visibility={props.visibility} />
        </transformNode>
        <transformNode name="deck" position={new Vector3(13, 1, -5)} rotation={new Vector3(Tools.ToRadians(0), Tools.ToRadians(-45), 0)} >
          <Arrows key="a7" visibility={props.visibility} />
        </transformNode>
        <transformNode name="player0" position={new Vector3(0, 3, -10)} scaling={new Vector3(1.5, 1.5, 1.5)} rotation={new Vector3(Tools.ToRadians(-33), 0, 0)} >
          <Arrows key="a8" visibility={props.visibility} />
        </transformNode>
      </transformNode>
    </>
  )
}


