import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useUser, useUserUpdate } from '../../context/UserProvider';

import { SoundManager } from '../../manager/SoundManager';
import Fleche from '../../componement/Fleche';
import Panel from '../../componement/Panel'


import "./PanelArchives.css"
import useRondaFetch from '../../hook/useRondaFetch';
import ItemArchives from './ItemArchives';
import { InfosArchives } from '../../data/InfosArchives';
import { LocationState } from '../../data/CustomInterfaces';
import InfosProfil from '../../data/InfosProfil';

type Props = {

}

function PanelArchives({ }: Props) {
    let navigate = useNavigate();

    let location = useLocation();

    const { t } = useTranslation();
    let { type } = useParams();

    let userInfos = useUser()!;
    let setUserInfos = useUserUpdate()!;

    const [infosProfil] = useState<InfosProfil>((location.state as LocationState)?.infosProfil);

    const [data, setData] = useState<Array<InfosArchives>>([]);

    const tabs: Array<string> = ["friendly", "battle", "tournament", "all",]

    const tabsTranslated: Array<string> = ["Friendly", "Battle", "Tournament", "All"]


    const [active, setActive] = useState<string>(tabs[tabs.length - 1]);

    const [loading, isLoading] = useState<boolean>(false);


    const Carousel = useRef<HTMLDivElement>(null)

    const { post, cache, response } = useRondaFetch();



    async function updateArchives(filter: string) {
        await post('api/getarchives/', {
            filter,
            user: infosProfil
        });
        if (response.ok) {

            let dataResponse: Array<InfosArchives> = [];
            response.data.forEach((element: any) => {
                dataResponse.push(new InfosArchives(element));
            });

            setData(dataResponse);
            isLoading(false);

        }
    }


    function openTabs(tabsName: string) {

        setActive(tabsName);
        setData([]);
        isLoading(true);

        updateArchives(tabsName);

        SoundManager.play(SoundManager.BUTTON_CLICK);
        index = 0;
        Carousel.current!.scrollTop = 0;
    }

    useEffect(() => {
        if (!infosProfil) {
            navigate("/home");
        }
        if (type == undefined) type = tabs[tabs.length - 1];
        openTabs(type);

        return () => {

        }
    }, [type])

    let index = 0;


    const handleClickProfil = (id: string) => navigate("/profil/" + id);

    const handleClickLog = (infosArchives: InfosArchives) => navigate(active + "/log/" + infosArchives.log, { state: { infosArchives } });


    const handleCarouselAction = (e: MouseEvent) => {
        e.preventDefault();

        //let indexRef = index;
        switch ((e.currentTarget as HTMLElement).id) {
            case "next":
                index += 1;
                break;
            case "prev":
                index -= 1;
                break;
            default:
                break;
        }

        if (index < 0) index = 0;
        Carousel.current!.scrollTop = (Carousel.current?.children.item(0)?.clientHeight!) * index;
        //console.log(index);
    }


    return (
        <>
            <Panel className='archives' title={t('Archives') + " " + infosProfil?.name} returnTo="/home">
                <ol className='onglet'>
                    {tabs.map((name: string, index: number) => {
                        return (
                            <li key={name} className={active === name ? "active" : ""} onClick={() => /*openTabs(name)*/ navigate('/archives/' + name, { state: { infosProfil } })}>{t(tabsTranslated[index])}</li>
                        );
                    })}
                </ol>
                <Fleche name="next" href="#" styleName="green" callBack={handleCarouselAction} > </Fleche>
                <Fleche name="prev" href="#" styleName="green" callBack={handleCarouselAction} direction="left"> </Fleche>

                <div className='layer'>
                    <div className='layerContainer'>
                        <ol className='tableTitle'>
                            <li style={{ width: "44px" }}>{t('Date')}</li>
                            <li style={{ width: "63px" }}>{t('Game')}</li>
                            <li style={{ width: "94px" }}>{t('Mode')}</li>
                            <li style={{ width: "257px" }}>{t('Players')}</li>
                            <li style={{ width: "73px" }}>{t('Reward')}</li>
                            <li style={{ width: "85px" }}>{t('Bet')}</li>
                            <li style={{ width: "44px" }}>{t('Log')}</li>
                        </ol>
                        <div ref={Carousel} className='rankScroll'>
                            {
                                (loading === true)
                                    ? <>
                                        <div className="dummyItem animated-background "></div>
                                        <div className="dummyItem animated-background "></div>
                                        <div className="dummyItem animated-background "></div>
                                        <div className="dummyItem animated-background "></div>
                                        <div className="dummyItem animated-background "></div>
                                        <div className="dummyItem animated-background "></div>
                                    </>
                                    : (data.length !== 0)
                                        ? data.map((infos: InfosArchives, index: number) => {
                                            let self = infos.results.find((user) => user.profil.id == userInfos.infosProfil.id && user.result == "Win")
                                            return <ItemArchives key={"ipr" + index} self={self} index={index + 1} infos={infos} handleClickProfil={handleClickProfil} handleClickLog={handleClickLog}></ItemArchives>
                                        })
                                        : <div className="nodata">
                                            <div className="perso"></div>
                                            <span className="sousTitre">{t("No data available.")}</span>
                                        </div>


                            }
                        </div>

                    </div>
                </div>
            </Panel>
            <Outlet />

        </>

    )
}

export default PanelArchives