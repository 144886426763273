import InfosPlayer from '../data/InfosPlayer';
import InfosProfil from '../data/InfosProfil';
import { Mesh } from '@babylonjs/core';
import { InfosSound } from '../data/InfosSound';
import { InfosSettings } from '../data/InfosSettings';
import { InfosCardTheme } from '../data/InfosCardTheme';
import { InfosTheme } from '../data/InfosTheme';
import { InfosLanguage } from '../data/InfosLanguage';
import { InfosCoin } from '../data/InfosCoin';
import { InfosUser } from '../data/InfosUser';
import { InfosModes } from '../data/InfosModes';
import { Client, Room } from 'colyseus.js';
import { InfosGame } from '../data/InfosGame';


export default class DataManager {



   public static PROP_CHANGE_THEME: string = 'CHANGE_THEME';
   public static PROP_CHANGE_THEMECARD: string = 'CHANGE_THEMECARD';
   public static PROP_DAILY: string = 'DAILY';
   public static PROP_REPLAY: string = 'REPLAY';
   public static PROP_APP: string = 'APP';
   public static PROP_WEB: string = 'WEB';
   public static PROP_TROPHEE_MARRAKECH: string = 'TROPHEE_MARRAKECH';
   public static PROP_TROPHEE_CHEFCHAOUEN: string = 'TROPHEE_CHEFCHAOUEN';
   public static PROP_TROPHEE_ESSAOUIRA: string = 'TROPHEE_ESSAOUIRA';
   public static PROP_TROPHEE_TANGER: string = 'TROPHEE_TANGER';
   public static PROP_TROPHEE_FES: string = 'TROPHEE_FES';
   public static PROP_TROPHEE_AGADIR: string = 'TROPHEE_AGADIR';
   public static PROP_TROPHEE_CASABLANCA: string = 'TROPHEE_CASABLANCA';
   public static PROP_DECLARE_LIE: string = 'DECLARE_LIE';
   public static PROP_DECLARE_TRUTH: string = 'DECLARE_TRUTH';
   public static PROP_TAKE_2_CARDS: string = 'TAKE_2_CARDS';
   public static PROP_TAKE_4_CARDS: string = 'TAKE_4_CARDS';
   public static PROP_TAKE_6_CARDS: string = 'TAKE_6_CARDS';
   public static PROP_TAKE_8_CARDS: string = 'TAKE_8_CARDS';
   public static PROP_COUNTER_1_CARD: string = 'COUNTER_1_CARD';
   public static PROP_COUNTER_7_CARD: string = 'COUNTER_7_CARD';
   public static PROP_THROW_7: string = 'THROW_7';
   public static PROP_TAKING_8_CARDS: string = 'TAKING_8_CARDS';
   public static PROP_DECLARE_TRINGA: string = 'DECLARE_TRINGA';
   public static PROP_DECLARE_RONDA: string = 'DECLARE_RONDA';
   public static PROP_BWA7AD: string = 'BWA7AD';
   public static PROP_BKHAMSA: string = 'BKHAMSA';
   public static PROP_B3ACHRA: string = 'B3ACHRA';
   public static PROP_T9AL9ILA: string = 'T9AL9ILA';
   public static PROP_QUINZA: string = 'QUINZA';
   public static PROP_QUINZA_FLOUS: string = 'QUINZA_FLOUS';
   public static PROP_GHAMLA: string = 'GHAMLA';
   public static PROP_MOUNTIF: string = 'MOUNTIF';
   public static PROP_BATAILLE_WIN: string = 'BATAILLE_WIN';
   public static PROP_BATAILLE_LOSE: string = 'BATAILLE_LOSE';
   public static PROP_BATAILLE_AS: string = 'BATAILLE_AS';
   public static PROP_MADAME: string = 'MADAME';
   public static PROP_MONSIEUR: string = 'MONSIEUR';
   public static PROP_MAINS_SUR_LE_TAS: string = 'MAINS_SUR_LE_TAS';
   public static PROP_GARDE_A_VOUS: string = 'GARDE_A_VOUS';
   public static PROP_VOL: string = 'VOL';
   public static PROP_MZAYDA: string = 'MZAYDA';
   public static PROP_MACHARICH: string = 'MACHARICH';
   public static PROP_ALLA: string = 'ALLA';
   public static PROP_PASSE: string = 'PASSE';
   public static PROP_ZADTAKAACHRA: string = 'ZADTAKAACHRA';
   public static PROP_GHNA: string = 'GHNA';
   public static PROP_BAHIA: string = 'BAHIA';
   public static PROP_BRELON: string = 'BRELON';
   public static PROP_CAREE: string = 'CAREE';
   public static PROP_TAPIS: string = 'TAPIS';
   public static PROP_TAPIS_REY: string = 'TAPIS_REY';
   public static PROP_TAPIS_LAS: string = 'TAPIS_LAS';
   public static PROP_WIN_CPU: string = 'WIN_CPU';
   public static PROP_WIN_VERSUS: string = 'WIN_VERSUS';
   public static PROP_WIN_TOURNAMENT: string = 'WIN_TOURNAMENT';
   public static PROP_WIN_MULTI: string = 'WIN_MULTI';
   public static PROP_WIN_IN_SERIE: string = 'WIN_IN_SERIE';
   public static PROP_WIN: string = 'WIN';
   public static PROP_FORFAIT: string = 'FORFAIT';
   public static PROP_COIN: string = 'COIN';
   public static PROP_BUCK: string = 'BUCK';
   public static PROP_FREE_COINS: string = 'FREE_COINS';
   public static PROP_POWERUPS_AWINA: string = 'POWERUPS_AWINA';
   public static PROP_POWERUPS_ZAHRAWI: string = 'POWERUPS_ZAHRAWI';
   public static PROP_POWERUPS_KHAMYA: string = 'POWERUPS_KHAMYA';
   public static PROP_POWERUPS_KARKAB: string = 'POWERUPS_KARKAB';
   public static PROP_LEVEL: string = 'LEVEL';
   public static PROP_INVITATION: string = 'INVITATION';
   public static PROP_FRIEND: string = 'FRIEND';
   public static PROP_CAM: string = 'CAM';
   public static PROP_BIRTHDAY: string = 'BIRTHDAY';
   public static PROP_PICTURE: string = 'PICTURE';
















   static TEST_MODE: boolean = (process.env.REACT_APP_TEST_MODE == "true") ? true : false;



   static CARDS_LIST: Array<Mesh> = [];

   static ABSOLUT_PATH: string = process.env.REACT_APP_PUBLIC_PATH ? process.env.REACT_APP_PUBLIC_PATH : "";
   static API_PATH: string = process.env.REACT_APP_API_PATH ? process.env.REACT_APP_API_PATH : "";

   static ENDPOINT: string = process.env.REACT_APP_ENDPOINT ? process.env.REACT_APP_ENDPOINT : "ws://127.0.0.1:2567";
   static WEBRTC_HOST: string = process.env.REACT_APP_WEBRTC_HOST ? process.env.REACT_APP_WEBRTC_HOST : "127.0.0.1";
   static WEBRTC_PORT: number | undefined = process.env.REACT_APP_WEBRTC_PORT ? Number(process.env.REACT_APP_WEBRTC_PORT) : undefined;
   static WEBRTC_SECURE: boolean = process.env.REACT_APP_WEBRTC_SECURE ? true : false;
   static WEBRTC_PATH: string = process.env.REACT_APP_WEBRTC_PATH ? process.env.REACT_APP_WEBRTC_PATH : "/peerjs/webrtc";

   static BOT_DELAY_TIMER: number = process.env.REACT_APP_BOT_DELAY_TIMER ? Number(process.env.REACT_APP_BOT_DELAY_TIMER) : 2;
   static PLAYING_DELAY_TIMER: number = process.env.REACT_APP_PLAYING_DELAY_TIMER ? Number(process.env.REACT_APP_PLAYING_DELAY_TIMER) : 20;

   static DUMMY_INFOSPLAYER: InfosPlayer = new InfosPlayer({
      id: 0,
      name: "-",
      country: "",
      img: this.ABSOLUT_PATH + "assets/avatars/randomplayer.gif"
   })

   static DATA_PRELOAD_IMAGES = ["assets/interfaces/mockup.png",
      "assets/interfaces/spacer.png",
      "assets/icons/Style.png",
      "assets/icons/Lock.png",


      "assets/interfaces/offline.png",

      'static/media/GameLocked.9b2fb944d7587d2fad38.png',
      'static/media/GameMenu01.badd9159a8bf96b8f1d0.png',
      'static/media/GameMenu02.9ca0a4145116668f9625.png',
      'static/media/GameMenu03.4e7a4ac725a5edc8596b.png',
      'static/media/GameMenu04.eeeef8844249f3e27c47.png',
      'static/media/facebook.c7762abf52603562e6dd.svg',
      'static/media/fondMosaique-header.63c4506239d144ec405c.png',
      'static/media/fondMosaique.a3936f545b2b162be115.png',
      'static/media/loading.6c2382be88fbc7ef8cd1.png',
      'static/media/logo.6cb4a0658ed9cad1fe28.png',
      'static/media/perso.16772c90e2b815922c32.png',
      'static/media/persoMan.d6adfb52ad554236f86a.png',
      'static/media/persoMouche.29a08f0901e0a899d544.png',



      'assets/interfaces/ParticalStar-00.png',
      'assets/interfaces/ParticalStar-01.png',
      'assets/interfaces/ParticalStar-02.png',
      'assets/interfaces/ParticalStar-03.png',
      'assets/interfaces/ParticalStar-04.png',
      'assets/interfaces/Vector.svg',
      'assets/interfaces/aim---4.png',
      'assets/interfaces/aim-0.png',
      'assets/interfaces/aim-1.png',
      'assets/interfaces/aim-2.png',
      'assets/interfaces/aim-3.png',
      'assets/interfaces/aim-4.png',
      'assets/interfaces/aim.png',
      'assets/interfaces/target-0.png',
      'assets/interfaces/target-1.png',
      'assets/interfaces/target-2.png',
      'assets/interfaces/target-3.png',
      'assets/interfaces/target.png',

      'assets/avatars/randomplayer.gif'];

   static RESOLUTION: string = "hd";

   static DIRECTION: number = 1;

   static CARD_GENDERS: Array<string> = ["denier", "coupe", "epee", "baton"];
   static DATA_PLAYER_COLOR: Array<string> = ["#1B57A0", "#A11A28", "#2D9A1B", "#B39415"];
   static DATA_PLAYER_GLOWED_COLOR: Array<string> = ["#a9fcfd", "#eb4f5c", "#8cfb5e", "#fffe62"];

   static DATA_COUNTRIES: Array<any> = [];

   static DATA_ADVERSOS: Array<any> = [];
   static DATA_ADVIDEOS: Array<any> = [];

   static DATA_PLAYERS: Array<InfosPlayer> = [];

   static DEFAULT_SCENE_THEME: InfosTheme = new InfosTheme();

   static DEFAULT_CARD_THEME: InfosCardTheme = new InfosCardTheme();

   static DATA_SOUNDS: Array<InfosSound> = [];

   static DATA_LANGUAGES: Array<InfosLanguage> = [];

   static DATA_MODES: Array<InfosModes> = [];
   static DATA_GAMES: Array<InfosGame> = [];

   static SHOW_NOTIFICATION: boolean = true;

   static DATA_SMILEYS: Array<any> = [
      { id: 1, image: this.ABSOLUT_PATH + "assets/smileys/smiley-1.svg" },
      { id: 2, image: this.ABSOLUT_PATH + "assets/smileys/smiley-2.svg" },
      { id: 3, image: this.ABSOLUT_PATH + "assets/smileys/smiley-3.svg" },
      { id: 4, image: this.ABSOLUT_PATH + "assets/smileys/smiley-4.svg" },
      { id: 5, image: this.ABSOLUT_PATH + "assets/smileys/smiley-5.svg" },
      { id: 6, image: this.ABSOLUT_PATH + "assets/smileys/smiley-6.svg" },
      { id: 7, image: this.ABSOLUT_PATH + "assets/smileys/smiley-7.svg" },
      { id: 8, image: this.ABSOLUT_PATH + "assets/smileys/smiley-8.svg" },
      { id: 9, image: this.ABSOLUT_PATH + "assets/smileys/smiley-9.svg" },
      { id: 10, image: this.ABSOLUT_PATH + "assets/smileys/smiley-10.svg" },
      { id: 11, image: this.ABSOLUT_PATH + "assets/smileys/smiley-11.svg" },
      { id: 12, image: this.ABSOLUT_PATH + "assets/smileys/smiley-12.svg" },
      { id: 13, image: this.ABSOLUT_PATH + "assets/smileys/smiley-13.svg" },
      { id: 14, image: this.ABSOLUT_PATH + "assets/smileys/smiley-14.svg" },
      { id: 15, image: this.ABSOLUT_PATH + "assets/smileys/smiley-15.svg" },
      { id: 16, image: this.ABSOLUT_PATH + "assets/smileys/smiley-16.svg" },
      { id: 17, image: this.ABSOLUT_PATH + "assets/smileys/smiley-17.svg" },
      { id: 18, image: this.ABSOLUT_PATH + "assets/smileys/smiley-18.svg" },
      { id: 19, image: this.ABSOLUT_PATH + "assets/smileys/smiley-19.svg" },
      { id: 20, image: this.ABSOLUT_PATH + "assets/smileys/smiley-20.svg" },
      { id: 21, image: this.ABSOLUT_PATH + "assets/smileys/smiley-21.svg" },
      { id: 22, image: this.ABSOLUT_PATH + "assets/smileys/smiley-22.svg" },
      { id: 23, image: this.ABSOLUT_PATH + "assets/smileys/smiley-23.svg" },
      { id: 24, image: this.ABSOLUT_PATH + "assets/smileys/smiley-24.svg" },
      { id: 25, image: this.ABSOLUT_PATH + "assets/smileys/smiley-25.svg" }
   ];

   static parse(details: any) {

      this.DATA_PLAYERS = [];
      for (let element of details.dataPlayers)
         this.DATA_PLAYERS.push(new InfosPlayer(element));

      this.DATA_LANGUAGES = [];
      for (let element of details.dataLanguages)
         this.DATA_LANGUAGES.push(new InfosLanguage(element));

      this.DATA_SOUNDS = [];
      for (let element of details.dataSounds)
         this.DATA_SOUNDS.push(new InfosSound(element));

      this.DEFAULT_SCENE_THEME = new InfosTheme(details.defaultSceneTheme);

      this.DEFAULT_CARD_THEME = new InfosCardTheme(details.defaultCardTheme);

      this.DATA_COUNTRIES = details.dataCountries;

      this.DATA_ADVERSOS = details.dataAdVersos;

      this.DATA_ADVIDEOS = details.dataAdVideos;

      this.DATA_MODES = [];
      for (let element of details.dataModes)
         this.DATA_MODES.push(new InfosModes(element));

      this.DATA_GAMES = [];
      for (let element of details.dataGames)
         this.DATA_GAMES.push(new InfosGame(element));
   }
}
