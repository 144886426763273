import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate } from 'react-router-dom'
import { useUser } from '../../context/UserProvider'
import { InfosGame } from '../../data/InfosGame'
import { InfosModes } from '../../data/InfosModes'
import { SoundManager } from '../../manager/SoundManager'
import { TextManager } from '../../manager/TextManager'

import "./ItemGameMenu.css"
import { InfosStyle } from '../../data/InfosStyle'
import { InfosTheme } from '../../data/InfosTheme'
import DataManager from '../../manager/DataManager'
import { log } from '../../manager/FirebaseManager'

type Props = {
    infos: InfosGame;
    background?: string;
}

function ItemGameMenu({ infos, background }: Props) {
    const { t } = useTranslation();
    let navigate = useNavigate();
    const userInfos = useUser()!;
    let mode = new InfosModes({ Parametres: userInfos.infosParametres });
    let modeDemo = new InfosModes({ Parametres: userInfos.infosParametres });
    modeDemo.demoMode = true;

    return (
        <div className={'itemGameMenu ' + ((infos.state) ? "" : "locked")}>

            <div className={'imageContainer'}>
                {
                    (infos.help != "") && <a className='bouton-icone green icon-info' onClick={() => { navigate('/help', { state: { infosGame: infos } }); SoundManager.play(SoundManager.BUTTON_CLICK); log("help", { type: "home" }); }}></a>
                }
                <span className={"refrac"}></span>
                <img src={infos.image} onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = DataManager.DATA_PRELOAD_IMAGES[0];
                    currentTarget.setAttribute("style", "top:-30px");
                }} />
                <img className={"back"} src={background} onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = DataManager.DATA_PRELOAD_IMAGES[1];;
                }} />

            </div>
            {
                (infos.state)
                    ? <div className='boutonsMenu'>
                        <span className='boutonMenu '>
                            <a className='bouton-icone yellow icon-parametre' onClick={() => { navigate('/tournamentmode', { state: { infosGame: infos } }); SoundManager.play(SoundManager.BUTTON_CLICK); }}></a>
                            <a className='play yellow' onClick={() => { navigate('/tournament', { state: { infosGame: infos } }); SoundManager.play(SoundManager.BUTTON_CLICK); }} >
                                <span className='vsOnlinePlayers'><span className='text'>{t('Tournament Mode')}</span></span>
                            </a>
                        </span>
                        <span className='boutonMenu '>
                            <a className='bouton-icone red icon-parametre' onClick={() => { navigate('/battlemode', { state: { infosGame: infos } }); SoundManager.play(SoundManager.BUTTON_CLICK); }}></a>
                            <a className='play red' onClick={() => { navigate('/battle', { state: { infosGame: infos } }); SoundManager.play(SoundManager.BUTTON_CLICK); }} >
                                <span className='vsOnlinePlayer'><span className='text'>{t('Battle Mode')}</span></span>
                            </a>
                        </span>
                        <span className='boutonMenu '>
                            <a className='bouton-icone bleu-green icon-parametre' onClick={() => { navigate('/listing', { state: { infosGame: infos } }); SoundManager.play(SoundManager.BUTTON_CLICK); }}></a>
                            <a className='play bleu-green' onClick={() => { navigate('/friendly', { state: { infosGame: infos } }); SoundManager.play(SoundManager.BUTTON_CLICK); }}  >
                                <span className='vsFriends'><span className='text'>{t('Friendly Mode')}</span></span>
                            </a>
                        </span>
                        <span className='boutonMenu '>
                            <a className='bouton-icone bleu icon-parametre' onClick={() => { navigate('/trainingmode', { state: { infosGame: infos } }); SoundManager.play(SoundManager.BUTTON_CLICK); }}></a>
                            <a className='play bleu' onClick={() => { navigate('/' + infos.name, { state: { infosMode: mode } }); SoundManager.play(SoundManager.VALIDATE_STARTGAME); }}>
                                <span className='vsbots'><span className='text'>{t('Training Mode')}</span></span>
                            </a>
                        </span>
                    </div>
                    : <div className='unLockGame'>
                        <div className='perso'></div>
                        <div className='calque'>
                            <span className='sousTitre'>{t('Unlock this game ?')}</span>
                            <a className='priceTag'>{TextManager.formatScore(infos.price, 7)}<span className='iconBuck' /></a>
                            <a className={'bouton bleu-green  ' + ((userInfos.infosProfil.buck >= infos.price) ? "" : "desactivate")} onClick={() => {
                                navigate('/home/store/games/' + infos.id);
                                log("buy_game", { type: "home" });
                            }}>{t('Buy now !')}</a>
                            <a className='bouton gold small ' onClick={() => {
                                navigate('/' + infos.name, { state: { infosMode: modeDemo } });
                                SoundManager.play(SoundManager.VALIDATE_STARTGAME);
                                log("preview_game", { type: "home" });
                            }}>{t('Preview')}</a>

                        </div>
                    </div>
            }
        </div>
    )
}

export default ItemGameMenu