import React from "react";

export default () => {
    let [modal, setModal] = React.useState("");
    let [modalContent, setModalContent] = React.useState("");
    let [trigger, setTrigger] = React.useState(true);
    let [autoClose, setAutoClose] = React.useState(true);

    let handleModal = (content: string, type: string = "green", pautoClose: boolean = true) => {
        setAutoClose(pautoClose);

        if (type)
            setModal(type);

        if (content)
            setModalContent(content);

        setTrigger(!trigger)
    };

    return { handleModal, modal, modalContent, trigger, autoClose };
};
