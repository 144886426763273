import React, { useEffect, useRef, useState } from 'react'
import Alert from '../../componement/Alert'
import { useTranslation } from 'react-i18next';

import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useUser, useUserUpdate } from '../../context/UserProvider';
import Fleche from '../../componement/Fleche';
import InfosProfil from '../../data/InfosProfil';
import ItemInvitation from './ItemPendingInvitation';
import { useRondaServer } from '../../context/ServerProvider';
import ItemPendingInvitation from './ItemPendingInvitation';

import "./AlertPendingInvitation.css";
import { useRondaModal } from '../../context/ModalProvider';
import useRondaFetch from '../../hook/useRondaFetch';
import { InfosUser } from '../../data/InfosUser';
import { log } from '../../manager/FirebaseManager';

type Props = {
    data: Array<InfosProfil>
    path?: string
}

function AlertPendingInvitation({ data, path = "" }: Props) {
    const { t } = useTranslation();
    let navigate = useNavigate();

    const { handleModal } = useRondaModal()!;

    let userInfos = useUser()!;
    let setUserInfos = useUserUpdate()!;

    const { post, response } = useRondaFetch();

    const [dataPending, setDataPending] = useState(data);

    const Carousel = useRef<HTMLDivElement>(null)

    let index = 0;

    const handleCarouselAction = (e: MouseEvent) => {
        e.preventDefault();

        //let indexRef = index;
        switch ((e.currentTarget as HTMLElement).id) {
            case "next":
                index += 1;
                break;
            case "prev":
                index -= 1;
                break;
            default:
                break;
        }

        if (index < 0) index = 0;
        Carousel.current!.scrollTop = (Carousel.current?.children.item(0)?.clientHeight!) * index;
        //console.log(index);
    }

    const handleClickProfil = (val: boolean, infos: InfosProfil) => {
        if (val) {
            confirmFriendship(infos);
        } else {
            removeFriendShip(infos);
        }
    };


    async function removeFriendShip(infos: InfosProfil) {
        log("remove_friendship", { type: "pending", friend: infos?.id + "" });

        await post('api/updatefriendship/', {
            friend: infos?.id
        })

        if (response.ok) {

            let dataFriends: Array<InfosProfil> = [];
            for (let element of response.data)
                dataFriends.push(new InfosProfil(element));

            setUserInfos((prevIU: InfosUser) => ({
                ...prevIU,
                dataFriends: dataFriends
            }));

            setDataPending(response.data.filter((pf: InfosProfil) => pf.friendshipStatus == "watingYourConfirmation"));

            handleModal("Friend demand ignored", "red");
        }
    }
    async function confirmFriendship(infos: InfosProfil) {
        log("confirm_friendship", { type: "pending", friend: infos?.id + "" });


        await post('api/confirmfriendship/', {
            friend: infos?.id
        })

        if (response.ok) {

            let dataFriends: Array<InfosProfil> = [];
            for (let element of response.data)
                dataFriends.push(new InfosProfil(element));

            setUserInfos((prevIU: InfosUser) => ({
                ...prevIU,
                dataFriends: dataFriends
            }));

            setDataPending(response.data.filter((pf: InfosProfil) => pf.friendshipStatus == "watingYourConfirmation"));

            handleModal("New Friend Added", "green");
        }
    }


    return (
        <Alert title={t("Friendship request !")} className='pendinginvitation' callBack={() => navigate(path)}>

            <div className='layer'>
                <div className='layerContainer'>


                    <div ref={Carousel} className='invitationScroll'>
                        {
                            dataPending.map((infos: InfosProfil, index: number) => {
                                return <ItemPendingInvitation key={"ipr" + index} infos={infos} callBack={handleClickProfil}></ItemPendingInvitation>
                            })
                        }
                    </div>
                    <div className='fleches'>
                        <Fleche name="next" href="#" styleName="green" callBack={handleCarouselAction} > </Fleche>
                        <Fleche name="prev" href="#" styleName="green" callBack={handleCarouselAction} direction="left"> </Fleche>
                    </div>
                </div>

            </div>
            <div className='perso'></div>

        </Alert>
    )
}

export default AlertPendingInvitation