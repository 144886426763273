import React, { useEffect } from "react";
import useFetch, { IncomingOptions, OverwriteGlobalOptions, UseFetch } from "use-http";
import { useRondaLoader } from "../context/LoaderProvider";
import { useRondaModal } from "../context/ModalProvider";

export default (args_0?: string | IncomingOptions | OverwriteGlobalOptions | undefined, args_1?: any[] | IncomingOptions | OverwriteGlobalOptions | undefined, args_2?: any[] | undefined, typeOfLoader?: string | undefined) => {

    const { post, get, put, cache, response, loading, error } = useFetch(args_0, args_1, args_2);
    const { handleModal } = useRondaModal()!;
    const { handleLoader } = useRondaLoader()!;

    //if (typeOfLoader == undefined) typeOfLoader = "";

    cache.clear();
    cache.delete();


    // const customPost = async (url: string, body?: any) => {
    //     // Votre logique personnalisée ici
    //     // ...

    //     // Appeler la fonction post originale
    //     url += url.includes("?") ? "&" : "?";
    //     url += `locale=fr`;
    //     return originalPost(url, body);
    // };

    // const customGet = async (url: string) => {
    //     // Votre logique personnalisée ici
    //     // ...
    //     url += url.includes("?") ? "&" : "?";
    //     url += `locale=fr`;
    //     // Appeler la fonction get originale
    //     return originalGet(url);
    // };



    useEffect(() => {
        if (error) {

            let customErrorMessage = `<p>Oups ! someting went wrong | `;

            if (response.data && response.data.error && response.data.error.message)
                customErrorMessage += response.data.error.message;
            else
                customErrorMessage += error.message;

            //if (response.data && response.data.error)
            //    customErrorMessage += response.data.error.message;

            customErrorMessage += "</p>"
            let messages = []
            if (response.data && response.data.error && response.data.error.details) messages = response.data.error.details.errors;

            if (messages) {
                const list: Array<string> = [];
                messages.map((error: any, i: number) => {
                    let item = "";
                    if (i === 0) item += `<ul>`;

                    item += `<li>${error.message}</li>`;

                    if (i === messages.length - 1) item += `</ul>`
                    list.push(item);
                });
                customErrorMessage += list.join(``);
            }
            handleModal(customErrorMessage, "red");

        }
    }, [error])

    useEffect(() => {
        if (typeOfLoader != "none") {
            handleLoader(loading, typeOfLoader);
        }
    }, [loading])

    return { post, get, put, cache, response, error }
};
