import React from 'react'

import "./CircleProgressBar.css"

type Props = {
    percent:number,
    color:string
}

export default function CircleProgressBar(props: Props) {

  return (
    <div className="circle-wrap">
        <div className="circle">
          <div className="outside-circle"></div>

          <div className="mask full" style={{ transform:"rotate("+180*props.percent/100+"deg)" }}>
            <div className="fill" style={{backgroundColor:props.color, transform:"rotate("+180*props.percent/100+"deg)" }}></div>
          </div>
          <div className="mask half">
            <div className="fill" style={{backgroundColor:props.color, transform:"rotate("+180*props.percent/100+"deg)"}}></div>
          </div>
        </div>
        <div className="inside-circle"></div>
      </div>
  )
}