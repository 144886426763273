import { InfosStore } from "./InfosStore";

export class InfosStyle  extends InfosStore {
    
    public type:number;
    public static parse:any;
    
    public constructor(details:any = null) {
        super(details);
        this.type = details.type;
    }
    
    override parse(details:any = null):void {
        this.type = details?.type;    
        super.parse(details);
    }
}
