import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getAnalytics, logEvent, setUserId, setUserProperties } from "firebase/analytics";
import InfosProfil from '../data/InfosProfil';

var firebaseConfig = {
    apiKey: process.env.REACT_APP_FIRE_BASE_API_KEY,
    authDomain: process.env.REACT_APP_FIRE_BASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIRE_BASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIRE_BASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIRE_BASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIRE_BASE_APP_ID,
    measurementId: process.env.REACT_APP_FIRE_BASE_MEASUREMENT_ID
};


const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
const analytics = getAnalytics(firebaseApp);

export const fetchToken = (callBack: Function) => {
    //return new Promise((resolve, reject) => {

    getToken(messaging, { vapidKey: process.env.REACT_APP_FIRE_BASE_VAPID_KEY }).then((currentToken) => {
        if (currentToken) {
            console.log('current token for client: ', currentToken);
            callBack(currentToken, true);
            //      resolve(currentToken);
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
        } else {
            console.log('No registration token available. Request permission to generate one.');
            callBack("No registration token available. Request permission to generate one.", false);
            //          reject('No registration token available. Request permission to generate one.');
            // shows on the UI that permission is required 
        }
    }).catch((err) => {
        //        reject('An error occurred while retrieving token.')
        callBack("An error occurred while retrieving token.", false);

        console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
    });

    //  });
}

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });

export const log = (eventName: string, eventParams: { [key: string]: string } | undefined) => logEvent(analytics, eventName, eventParams);

export const defineUser = (infosProfil: InfosProfil) => {
    setUserId(analytics, String(infosProfil.id));
    setUserProperties(analytics, { ...infosProfil.infosSettings });
}