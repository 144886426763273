import React from "react";

export default () => {


    let [type, setType] = React.useState("");
    let [triggerLoader, setTriggerLoader] = React.useState(false);

    let handleLoader = (triggerLoader: boolean, type: string = "mini") => {
        if (type)
            setType(type);


        setTriggerLoader(triggerLoader)
    };

    return { handleLoader, type, triggerLoader };
};
