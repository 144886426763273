import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Panel from '../../componement/Panel'
import { useUser, useUserUpdate } from '../../context/UserProvider';
import InfosProfil from '../../data/InfosProfil';
import useRondaFetch from '../../hook/useRondaFetch';

import "./PanelReward.css";
import Stepper from '../../componement/Stepper';
import { InfosAchievement } from '../../data/InfosAchievement';
import { InfosUser } from '../../data/InfosUser';
import Alert from '../../componement/Alert';
import i18next from 'i18next';

type Props = {
    animation?: boolean,
}

const PanelReward = ({ animation = false }: Props) => {

    let navigate = useNavigate();
    let { path } = useParams();


    let userInfos = useUser()!;
    let updateUserInfos = useUserUpdate()!;
    const [dataAchievement, setDataAchievement] = useState<Array<InfosAchievement>>();

    const { t } = useTranslation();


    const [returnTo, setReturnTo] = useState<string | undefined>(path);
    if (!returnTo) setReturnTo("/home");


    const { post, error, response } = useRondaFetch();


    useEffect(() => {
        if (error) {
            navigate(returnTo + "", { replace: true })
        }
    }, [error]);

    async function getAchievement() {
        await post('api/updateachievement?locale=' + i18next.language);

        if (response.ok && response.data) {

            let infosProfil = new InfosProfil(response.data.profil);

            updateUserInfos((prevIU: InfosUser) => ({
                ...prevIU,
                infosProfil: infosProfil,
            }));

            if ((response.data.unlockedDataAchievements && response.data.unlockedDataAchievements.length > 0)) {
                let dataAchievements: Array<InfosAchievement> = [];
                for (let achievement of response.data.unlockedDataAchievements) {
                    dataAchievements.push(achievement as InfosAchievement);
                    // let ia = userInfos.dataAchievements.find((ia) => ia.id == ia.id);
                    // if (ia) ia.state = true;
                }
                setDataAchievement(dataAchievements);
            } else {
                setTimeout(() => navigate(returnTo + "", { replace: true }), 1000);
                //navigate(returnTo + "", { replace: true });
            }


        }
    }


    useEffect(() => {
        //if (path == undefined) path = "/home";
        getAchievement();
    }, [])

    let id = 0;
    const onSelectAchievement = (id: number) => {
        id = id;
    };

    return (
        <>
            {
                dataAchievement &&
                <>
                    <Alert className='panelreward' title={t('Unlocked achievements')} autoClose={15} animation={true} callBack={() => navigate(returnTo + "", { replace: true })}>
                        <div className='layer'>

                            <div className='layerContainer'>
                                <span className="sousTitre">{t('You have unlocked new achievements')}</span>

                                <Stepper className="versus" initial={0} getID={onSelectAchievement}>
                                    {
                                        dataAchievement?.map((infosAchievement, index) => {
                                            return <div className='unlockedAchievement'>
                                                <img src={infosAchievement?.image} alt={infosAchievement?.title} />

                                                <div className='description'>
                                                    {infosAchievement?.description}
                                                </div>
                                                <span className={'bouton-reward '}>{"+ " + infosAchievement?.reward}<span className='iconCoin' /></span>

                                            </div>
                                        })
                                    }
                                </Stepper>

                                <span className='shine'></span>


                            </div>



                        </div>
                        <div className='perso'></div>
                        {/* <a className='bouton bleu-green' onClick={() => { if (dataAchievement) console.log(dataAchievement[id]) }} >{t('Partager ce badge !')}</a> */}
                        <a className='bouton bleu' onClick={() => navigate(returnTo + "", { replace: true })} >{t('Continue !')}</a>
                    </Alert>
                </>
            }
        </>
    )
}

export default PanelReward