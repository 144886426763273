import { InfosStore } from "./InfosStore";

export class InfosVoice extends InfosStore {

    public type: string;
    public static parse: any;

    public constructor(details: any = null) {
        super(details);
        this.type = (details.type);
        this.infinite = false;
    }

    override parse(details: any = null): void {
        this.type = "";
        super.parse(details);
    }
}
