import React, { useRef, useState } from 'react'
import { InfosStyle } from '../data/InfosStyle';
import DataManager from '../manager/DataManager';

import "./ToogleStyle.css";
import TooltipStyle from './TooltipStyle';
import { useUser } from '../context/UserProvider';
import TooltipSmiley from './TooltipElement';


type Props = {
    infosStyle: InfosStyle;
    useTooltip?: boolean;
    callBack?: Function;
}

function ToogleStyle({ infosStyle, useTooltip, callBack }: Props) {

    let Target = useRef<HTMLAnchorElement>(null);

    const [showToolTip, setShowToolTip] = useState(false);


    function onRollOver() {
        if (useTooltip)
            setShowToolTip(true);
    }

    function onRollOut() {
        setShowToolTip(false);
    }

    function clickStyleManager(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {

        if (callBack != undefined)
            callBack(infosStyle);

    }
    let userInfos = useUser()!;

    return (
        <>
            <div className='toogleStyle' onMouseOver={onRollOver} onMouseLeave={onRollOut} >

                <a ref={Target} href='#' className='bouton-style' onClick={clickStyleManager}>
                    <span />
                    <img src={(infosStyle?.image) ? infosStyle?.image : (DataManager.ABSOLUT_PATH + "assets/icons/Style.png")} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = DataManager.DATA_PRELOAD_IMAGES[0];;
                    }} />

                </a>
                {

                    (showToolTip && useTooltip) ? <TooltipSmiley title={"Style"} data={userInfos.dataStyleThemes.filter((i: InfosStyle) => { return i.count > 0 })} callBack={callBack!}></TooltipSmiley> : <></>
                }

            </div>

        </>
    )
}

export default ToogleStyle