import React from 'react';
import { TaskType, useAssetManager } from 'react-babylonjs';
import { TextureAssetTask, Color3, Mesh } from '@babylonjs/core';
import DataManager from '../../manager/DataManager';

export default function CartaBackground() {


  const assetManagerResult = useAssetManager(
    [{ taskType: TaskType.Texture, url: DataManager.DEFAULT_SCENE_THEME.mosaic, name: 'Mosaique' },
    { taskType: TaskType.Texture, url: DataManager.DEFAULT_SCENE_THEME.floor, name: 'Floor' }], { useDefaultLoadingScreen: true });

  var grid = {
    'h': 4,
    'w': 4
  }
  var res = 60;

  return (
    <>

      <ground name="mosaic" width={30} height={20} receiveShadows={true} isPickable={false} position-y={-0.1}>
        <standardMaterial zOffset={0} name={`Mosaic`} alpha={1} specularPower={3800} specularColor={Color3.White()} sideOrientation={Mesh.BACKSIDE} needDepthPrePass={true} backFaceCulling={true}>
          <baseTexture fromInstance={(assetManagerResult.taskNameMap['Mosaique'] as TextureAssetTask).texture} assignTo="diffuseTexture" hasAlpha={true} />
        </standardMaterial>

      </ground>


      <ground name="videoad" width={26.6} height={16.5} receiveShadows={true} isPickable={true} position-y={-0.2}>
        <standardMaterial name={`Ad`} alpha={0} specularPower={3800} specularColor={Color3.White()} sideOrientation={Mesh.BACKSIDE} needDepthPrePass={true} backFaceCulling={true}>
        </standardMaterial>
      </ground>

      <tiledGround name="Floor" xmin={-res} zmin={-res} xmax={res} zmax={res} subdivisions={grid} isPickable={false} receiveShadows={true} position-y={-5.3} >
        <standardMaterial zOffset={2} name={`Floor`} alpha={1} specularPower={400} specularColor={Color3.White()} sideOrientation={Mesh.BACKSIDE}  >
          <baseTexture fromInstance={(assetManagerResult.taskNameMap['Floor'] as TextureAssetTask).texture} assignTo="diffuseTexture" hasAlpha={false} />
        </standardMaterial>

      </tiledGround>
    </>
  );
}
