
export class InfosSound {
    public name: string;
    public group: string;
    public link: string;
    public type: string;

    public constructor(details: any) {
        this.name = details?.name;
        this.type = details?.type;

        this.group = details?.group;
        this.link = details?.link;
    }

}
