import React, { useLayoutEffect, useState } from 'react'
import './PanelEntrance.css';

import ToogleFullScreen from '../../componement/ToogleFullScreen';
import ToogleViews from '../../componement/ToogleViews';
import ToogleResolution from '../../componement/ToogleResolution';
import { Scene } from '@babylonjs/core/scene';

import { useNavigate } from "react-router-dom";
import ToogleSound from '../../componement/ToogleSound';
import { SoundManager } from '../../manager/SoundManager';
import i18next from 'i18next';

import { useTranslation } from 'react-i18next';
import ToogleLanguage from '../../componement/ToogleLanguage';
import { InfosLanguage } from '../../data/InfosLanguage';
import DataManager from '../../manager/DataManager';
import { useUser, useUserUpdate } from '../../context/UserProvider';
import { InfosModes } from '../../data/InfosModes';
import { log } from '../../manager/FirebaseManager'
import { InfosParametres } from '../../data/InfosParametres';

type Props = {
  scene: Scene;
}

export default function PanelEntrance(props: Props) {
  let navigate = useNavigate();

  const { t } = useTranslation();

  let userInfos = useUser()!;
  let setUserInfos = useUserUpdate();

  const [selectedLanguage, setSelectedLanguage] = useState<InfosLanguage | undefined>(DataManager.DATA_LANGUAGES.find(infos => infos.id == userInfos.infosProfil.infosSettings.idInfosLanguage))

  const onSelectLanguage = (e: InfosLanguage) => { i18next.changeLanguage(e.locale); setSelectedLanguage(e); userInfos.infosProfil.infosSettings.idInfosLanguage = e.id; }

  const GAMES: Array<string> = ['ronda', 'quinza', 'hazzouj', 'kdoub', 'kbirsghir', 'sota', 'rondaserka', 'rondarchim', 'touti', 'kappilla', 'ribis', 'shkanba', 'triss', 'attkozer', 'bonjour'];



  const gameName = () => {
    const randomIndex = Math.floor(Math.random() * GAMES.length);
    return GAMES[randomIndex];
  }

  useLayoutEffect(() => {
    SoundManager.play(SoundManager.PANEL_SHOW);
    return () => {
      //SoundManager.play(SoundManager.PANEL_HIDE);
    };
  }, []);


  return (
    <div className='entranceContainer'>
      <div className='boutonsIcons'>
        <ToogleViews scene={props.scene} className='bouton-icone copper icon-vue'></ToogleViews>
        <ToogleFullScreen className='bouton-icone copper '></ToogleFullScreen>
        <ToogleResolution scene={props.scene} className='bouton-icone copper'></ToogleResolution>
        <ToogleSound className='bouton-icone copper' track="" ></ToogleSound>
        <ToogleLanguage useTooltip={true} callBack={onSelectLanguage} language={selectedLanguage}></ToogleLanguage>
      </div>
      <span className='logo' />
      <div className='boutons'>
        <a href='#' className='bouton green' onClick={() => {
          const modeDemo = new InfosModes({
            type: "sneakpeak",
            Parametres: new InfosParametres({
              numberOfRounds: 1,
              numberOfPlayers: 4,
              cheatMode: true,
              demoMode: true,
              multiplayer: false
            })
          });
          console.log("modeDemo", modeDemo);
          navigate('/sneak-peek/' + gameName(), { state: { infosMode: modeDemo } });
          SoundManager.play(SoundManager.VALIDATE_STARTGAME);
          log("sneak_peek", { type: "entrance", game: gameName() });
        }}>{t('Sneak peek')}</a>
        <a href='#' className='bouton bleu-green' onClick={() => navigate("/login")}>{t('Sign in')}</a>
        <a href='#' className='bouton' onClick={() => navigate("/signup")}>{t('Sign up')}</a>
      </div>
    </div>
  )
}