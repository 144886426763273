import React from 'react'
import { InfosLanguage } from '../data/InfosLanguage';
import DataManager from '../manager/DataManager';
import ToogleLanguage from './ToogleLanguage';
import "./TooltipLanguage.css"

import { useTranslation } from 'react-i18next';


type Props = {
    callBack: Function | undefined
}

function TooltipLanguage({ callBack }: Props) {
    const { t } = useTranslation();

    return (
        <div className="languageToolTip" /*style={{top:coordinates?.top ,inset-inline-end:coordinates?.right}}*/>
            <span className="triangle" />
            <span className="triangleMosaique"></span>
            <span className='titre'>{t('Language')}</span>
            <div className='languageContainer'>
                {
                    DataManager.DATA_LANGUAGES.map((infos: InfosLanguage, index: number) => {
                        return <ToogleLanguage key={"TT" + index} useTooltip={false} language={infos} callBack={callBack} />
                    })
                }
            </div>
        </div>
    )
}

export default TooltipLanguage