import React, { useEffect, useRef, useState } from 'react'
import { DefaultRenderingPipeline, Engine, Light, Nullable, Scene } from "@babylonjs/core";
import DataManager from '../manager/DataManager';
import { SoundManager } from '../manager/SoundManager';
import ReactDOM from 'react-dom';
import TooltipSound from './TooltipSound';
import { log } from '../manager/FirebaseManager';

type Props = {
    className: string;
    track: string;
}


const ToogleSound = (props: Props) => {

    const [sound, setSound] = useState(false);
    let Target = useRef<HTMLAnchorElement>(null);

    const [coordinates, setCoordinates] = useState(Target.current?.getBoundingClientRect());

    useEffect(() => {

        if (props.track == "sfx") {
            setSound(SoundManager.muteSfx);
        } else {
            setSound(SoundManager.mute);
        }

        return () => {

        }
    }, [props.track])

    //var coordinates:DOMRect|undefined;

    //console.log(rect?.top, rect?.right, rect?.bottom, rect?.left);

    function onRollOver() {
        if (props.track != "sfx")
            setCoordinates(Target.current?.getBoundingClientRect());
    }

    function onRollOut() {
        setCoordinates(undefined);
    }


    function clickSoundManager(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {

        if (e.target != e.currentTarget) return;

        if (!Engine.audioEngine?.unlocked)
            Engine.audioEngine?.unlock();

        if (props.track == "sfx") {
            SoundManager.muteSfx = !SoundManager.muteSfx;
            //console.log("SoundManager.muteSfx " + SoundManager.muteSfx + "   " + !SoundManager.muteSfx);
            setSound(SoundManager.muteSfx);
            SoundManager.play(SoundManager.CAMERA_MOVE);
        } else {
            SoundManager.mute = !SoundManager.mute;
            setSound(SoundManager.mute);
            SoundManager.play(SoundManager.CAMERA_MOVE);
        }
        log("sound_sfx", { mute: SoundManager.muteSfx ? "off" : "on" })

    }


    return (
        <>
            <span ref={Target} className={props.className + ' icon-son' + ((sound) ? "-on" : "")} onMouseOver={onRollOver} onMouseLeave={onRollOut} onClick={clickSoundManager}>
                {
                    (coordinates != undefined) ? <TooltipSound coordinates={coordinates}></TooltipSound> : <></>
                }

            </span>

        </>
    )



}

export default ToogleSound