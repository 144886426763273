import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';
import IndicateurValidation from '../../componement/IndicateurValidation';
import Panel from '../../componement/Panel';
import { useUser } from '../../context/UserProvider';
import { SoundManager } from '../../manager/SoundManager';
import validator from 'validator';


import './PanelForgotPassword.css';
import useRondaFetch from '../../hook/useRondaFetch';
import { useRondaModal } from '../../context/ModalProvider';

type Props = {}

function PanelForgotPassword({ }: Props) {

    let navigate = useNavigate();
    let userInfos = useUser()!;
    const { t } = useTranslation();
    let emailRef = useRef<HTMLInputElement | null>(null);
    const { handleModal } = useRondaModal()!;


    const [errorEmailMessage, setErrorEmailMessage] = useState('')


    function validateForm() {
        if (errorEmailMessage == 'ok')
            ForgotUserPassword((emailRef.current as HTMLInputElement).value);
        else
            SoundManager.play(SoundManager.CANCEL_DISMISS);

    }

    const { post, get, cache, response } = useRondaFetch()


    async function ForgotUserPassword(email: string) {
        cache.clear();
        cache.delete();

        const newTodo = await post('/api/forgotten-password', {
            email: email,
        })
        if (response.ok) {


            handleModal(`Please check your email to change your password`, "green");

            navigate("/confirmation", { state: { message: t('An email has been sent to you with a link that allows you to change your password.') } });

        }
    }




    const validateEmail = (value: string) => {

        if (validator.isEmail(value)) {
            setErrorEmailMessage('ok');
        } else {
            setErrorEmailMessage('cancel')
        }
    }
    return (
        <>
            <Outlet />
            <Panel className='forgot' title={t('Forgot password')} returnTo="/login">
                <div className='layer'>
                    <div className='layerContainer'>
                        <span className="sousTitre">{t('Provide your email if you wish to change your password')}</span>

                        <form action="">

                            <i className="formIcon icon-email"></i>
                            <IndicateurValidation validationType={errorEmailMessage} messages={[
                                { name: "ok", value: t('Valid field!') },
                                { name: "cancel", value: <p>{t('The field must be a valid email.')}</p> }
                            ]} />
                            <input ref={emailRef} onChange={e => validateEmail(e.target.value)} placeholder={t('Email')} ></input>
                            <a href='#' className='bouton red' onClick={(e) => { validateForm() }}>{t('Send')}</a>

                        </form>
                    </div>
                </div>
                <div className='perso'></div>
            </Panel>
        </>
    )
}

export default PanelForgotPassword