export class InfosSettings {

    public id: number;
    public idInfosLanguage: number;
    public idInfosCardTheme: number;
    public idInfosTheme: number;
    public idInfosStyle: undefined | number;
    public idInfosMode: number;
    public idInfosTournamentMode: number;
    public idInfosGame: number;
    public idInfosVoice: number;
    public idInfosEmoji: number;

    public constructor(details?: any) {
        if (details != null) {
            this.id = details.id;
            this.idInfosLanguage = details.idInfosLanguage ? details.idInfosLanguage : 1;
            this.idInfosCardTheme = details.idInfosCardTheme ? details.idInfosCardTheme : 1;
            this.idInfosTheme = details.idInfosTheme ? details.idInfosTheme : 1;
            this.idInfosStyle = details.idInfosStyle;
            this.idInfosMode = details.idInfosMode ? details.idInfosMode : 1;
            this.idInfosTournamentMode = details.idInfosTournamentMode;
            this.idInfosGame = details.idInfosGame ? details.idInfosGame : 1;
            this.idInfosVoice = details.idInfosVoice ? details.idInfosVoice : 1;
            this.idInfosEmoji = details.idInfosEmoji ? details.idInfosEmoji : 1;

        } else {
            this.id = 0;
            this.idInfosLanguage = 1;
            this.idInfosCardTheme = 1;
            this.idInfosTheme = 1;
            this.idInfosStyle = undefined;
            this.idInfosMode = 1;
            this.idInfosTournamentMode = 1;
            this.idInfosGame = 1;
            this.idInfosVoice = 1;
            this.idInfosEmoji = 1;

        }
    }

}
