
import i18next, { init } from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { InfosLanguage } from "../data/InfosLanguage";

function initInternationalization(dataLanguages: Array<InfosLanguage>, onInit: Function) {



  var supportedLngs: Array<string> = [];

  dataLanguages.forEach((e) => supportedLngs.push(e.locale))

  function getPath(lngs: Array<string>, namespaces: Array<string>) {
    let infosLanguages = dataLanguages.find((e) => e.locale === lngs[0])
    return infosLanguages?.url + "";
    //return "/assets/langues/" + namespaces + "/" + lngs + ".json"; 

  }


  i18next.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      backend: {
        // translation file path
        loadPath: getPath//"/assets/langues/{{ns}}/{{lng}}.json",
      },
      supportedLngs: supportedLngs,
      fallbackLng: 'en',
      // disabled in production
      debug: false,
      // can have multiple namespaces, in case you want to divide a huge
      // translation into smaller pieces and load them on demand
      ns: ["default"],
      defaultNS: 'default',
      interpolation: {
        escapeValue: false,
        formatSeparator: ",",
      },
      detection: {
        order: ['cookie'],
        caches: ['cookie'],
      },
    }, (err, t) => {
      if (err) return console.log('something went wrong loading', err);
      t('key'); // -> same as i18next.t
      onInit();
    });
  return i18next;
}
export default initInternationalization;
//export default i18next;




/* export class InternationalizationManager{
 import { useTranslation } from "react-i18next";

 const { t } = useTranslation(["home"]);



   i18next.init({
 fallbackLng: 'en',
 ns: ['file1', 'file2'],
 defaultNS: 'file1',
 debug: true
}, (err, t) => {
 if (err) return console.log('something went wrong loading', err);
 t('key'); // -> same as i18next.t
});

// with only callback
i18next.init((err, t) => {
 if (err) return console.log('something went wrong loading', err);
 t('key'); // -> same as i18next.t
});

// using Promises
i18next
 .init({  })
 .then(function(t) { t('key'); });
}*/