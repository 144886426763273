import React, { ReactNode } from 'react'
import { Provider } from 'use-http'
import useLocalStorage, { isExpired } from '../hook/useLocalStorage'

type Props = {
    children: ReactNode,
}

function DataProvider({ children }: Props) {


    let [token, setToken] = useLocalStorage("RondaToken");
    //let [locale, setLocale] = useLocalStorage("RondaLocale");

    //console.log("useLocalStorage Token " + token);


    const globalOptions = {
        interceptors: {
            request: async ({ options, url, path, route }: { options: any, url?: string, path?: string, route?: string }) => {
                if (token && isExpired(token + "")) {
                    localStorage.removeItem("RondaToken"); //clear()
                } else {
                    options.headers.Authorization = `Bearer ${token}`
                }
                //console.log("route " + route);

                //route += `?locale=fr&`;

                // console.log(" options.url " + options.url);

                // options.url += options.url.includes("?") ? "&" : "?";
                // options.url += `locale=fr`;

                return options
            },
            response: async ({ response }: { response: any }) => {
                const res = response
                if (res.data && res.data.jwt) {
                    setToken(res.data.jwt);
                    //console.log("setToken(res.data.jwt) " + res.data.jwt);

                }
                return res
            }
        }
    }
    //console.log("DataProvider is Updating " + globalOptions?.options.headers.Authorization);




    return (
        <Provider url={process.env.REACT_APP_API_PATH} options={globalOptions}>
            {
                children
            }
        </Provider>
    )
}

export default DataProvider