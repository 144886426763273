import React, { createContext, ReactNode, useContext, useState } from 'react'
import { InfosUser } from '../data/InfosUser';


const UserContext = createContext<InfosUser | undefined>(undefined);
const UserUpdateContext = createContext<Function | undefined>(undefined);

export function useUser() {
    return useContext(UserContext);
}

export function useUserUpdate() {
    return useContext(UserUpdateContext);
}

type Props = {
    children: ReactNode;
}

function UserProvider({ children }: Props) {

    const [infosUser, setInfosUser] = useState<InfosUser>(new InfosUser());

    return (
        <UserContext.Provider value={infosUser}>
            <UserUpdateContext.Provider value={setInfosUser}>
                {
                    children
                }
            </UserUpdateContext.Provider>
        </UserContext.Provider>
    )
}

export default UserProvider