import React, { useEffect, useState } from 'react'

import "./IndicateurLatency.css";

type Props = {
    latency: number
    multiplayer: boolean
}

function IndicateurLatency({ latency, multiplayer }: Props) {

    let className = 'online ' + ('latency' + latency);



    return (
        <div className='IndicateurLatency'>
            {
                multiplayer && <span className={className}></span>
            }
        </div>
    )
}

export default IndicateurLatency