import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useUser, useUserUpdate } from '../../context/UserProvider';
import DataManager from '../../manager/DataManager';
import { SoundManager } from '../../manager/SoundManager';
import ToogleLanguage from '../../componement/ToogleLanguage';

import { InfosLanguage } from '../../data/InfosLanguage';
import i18next from 'i18next';

import validator from 'validator';
import IndicateurValidation from '../../componement/IndicateurValidation';

import { InfosUser } from '../../data/InfosUser';
import { useRondaModal } from '../../context/ModalProvider';
import useRondaFetch from '../../hook/useRondaFetch';
import { log } from '../../manager/FirebaseManager';

type Props = {}

function TabProfilSettings({ }: Props) {

    const { post, response } = useRondaFetch();


    let navigate = useNavigate();
    const { t } = useTranslation();

    let userInfos = useUser()!;
    let setUserInfos = useUserUpdate()!;



    let { type } = useParams();




    const [selectedLanguage, setSelectedLanguage] = useState<InfosLanguage | undefined>(DataManager.DATA_LANGUAGES.find(infos => infos.id == userInfos.infosProfil.infosSettings.idInfosLanguage))

    const onSelectLanguage = async (e: InfosLanguage) => {
        await post("api/updatelanguage", {
            data: {
                Settings: {
                    id: userInfos.infosProfil.infosSettings.id,
                    idInfosLanguage: e.id
                }
            }
        })
        if (response.ok) {

            let infos = new InfosUser(response.data);
            setUserInfos(infos);

            i18next.changeLanguage(e.locale);
            setSelectedLanguage(e);

            // console.log(infos);
            // console.log(infos.infosProfil);

            //userInfos.infosProfil.infosSettings.idInfosLanguage = e.id;
            handleModal("Language is updated", "green");
        }
    }
















    let nameRef = useRef<HTMLInputElement | null>(null);
    let passwordRef = useRef<HTMLInputElement | null>(null);


    let name: string = userInfos.infosProfil.name;
    let password: string = userInfos.infosProfil.password;


    const [errorMessage, setErrorMessage] = useState('')
    const [errorNameMessage, setErrorNameMessage] = useState('')

    const fetchName = useRondaFetch()

    async function checktUserName(name: string) {
        fetchName.cache.delete();
        const newTodo = await fetchName.post('api/checkuser/', {
            username: name,
        })
        if (fetchName.response.ok) {
            console.log("is User Name availble ?" + fetchName.response.data);
            if (!fetchName.response.data) {
                setErrorNameMessage('notavailable')
            }
        }
    }



    const validateName = (value: string) => {

        if (validator.isLength(value, { min: 3, max: 20 }) && validator.matches(value, /^[a-zA-Z0-9 ]*$/)) {
            setErrorNameMessage('ok');
            if (value != userInfos.infosProfil.name) checktUserName(value);
        } else {
            setErrorNameMessage('cancel')
        }
    }

    const validatePassword = (value: string) => {

        if (validator.isStrongPassword(value, {
            minLength: 6,
            minUppercase: 1, minNumbers: 1
        })) {
            setErrorMessage('ok');
        } else {
            setErrorMessage('cancel')
        }
    }

    async function saveInfos() {

        let data: any = { password: null, username: null };

        log("update_profil_infos", { value: "" })

        if (errorMessage == 'ok') {
            data.password = (passwordRef.current as HTMLInputElement).value;
        }


        if (errorNameMessage == 'ok') {
            data.username = (nameRef.current as HTMLInputElement).value;
        }

        if (data.password != null || data.username != null)
            await post("api/updateuser/",
                data
            );

        if (response.ok) {
            userInfos.infosProfil.name = response.data.username;
            //SoundManager.play(SoundManager.VALIDATE_STANDARD);
            handleModal("User information is updated", "green");
        }
    }





















    const [entry, setEntry] = useState<any>(DataManager.DATA_COUNTRIES.find((e) => e.code === userInfos.infosProfil.country));

    async function onSelect(event: React.ChangeEvent<HTMLSelectElement>) {
        event.preventDefault();
        let entry = DataManager.DATA_COUNTRIES.find((e) => e.code === event.target.value);

        log("update_country", { value: event.target.value })

        await post("api/updateprofil", {
            data: {
                country: {
                    id: entry.id
                }
            }
        })
        if (response.ok) {
            setEntry(entry);
            userInfos.infosProfil.country = event.target.value;
            handleModal("Country is updated", "green");
        }
    }




























    const uploadFetch = useRondaFetch('api/upload/');

    // const [file, setFile] = useState<any>()

    const uploadFile = async (e: any) => {

        log("update_profil_picture", { value: "" })

        e.preventDefault();

        const data = new FormData();
        data.append('files', e.target.files[0]);


        if (data instanceof FormData) {
            await uploadFetch.post(data);
        }

        if (uploadFetch.response.ok) {

            let filename = uploadFetch.response.data[0];
            setTimeout(() => { updateUserInfos(filename); }, 1000);

            uploadFetch.cache.delete();
            uploadFetch.cache.clear();
        }
    }

    async function updateUserInfos(imgData: any) {
        await post("api/updateprofil", {
            data: {
                img: {
                    id: imgData.id
                }
            }
        })
        if (response.ok) {

            setUserInfos((prevIU: InfosUser) => ({
                ...prevIU,
                infosProfil: {
                    ...prevIU.infosProfil,
                    img: DataManager.API_PATH + imgData.url
                }

            }));
            handleModal("Profil's image  is updated", "green");

        }
    }












    const { handleModal } = useRondaModal()!;

















    return (
        <div className='layer'>
            <div className='layerContainer'>
                <div className='calque form'>
                    <span className='sousTitre'>{t("Profil's information")}</span>
                    <hr />

                    <div className='formContainer'>
                        <form action="">
                            <i className="formIcon icon-user"></i>
                            <IndicateurValidation validationType={errorNameMessage} messages={[
                                { name: "ok", value: t('Valid Field !') },
                                {
                                    name: "cancel", value: <><p>{t("The field must contain:")}</p>
                                        <ol>
                                            <li>{t("At least 3 characters,")}</li>
                                            <li>{t("No special character,")}</li>
                                        </ol>
                                    </>
                                },
                                { name: "notavailable", value: t('Name is already taken!') }]} />

                            <input ref={nameRef} placeholder={t('Nick name')} onChange={e => validateName(e.target.value)} defaultValue={userInfos.infosProfil.name} ></input>

                            <i className="formIcon icon-email"></i>
                            <span>{userInfos.infosProfil.email}</span>

                            <i className="formIcon icon-password"></i>
                            <IndicateurValidation validationType={errorMessage} messages={[
                                { name: "ok", value: t('Valid Field !') },
                                {
                                    name: "cancel", value: <><p>{t("The field must contain:")}</p>
                                        <ol>
                                            <li>{t("At least 4 characters,")}</li>
                                            <li>{t("One uppercase letter,")}</li>
                                            <li>{t("One number")}</li>
                                        </ol>
                                    </>
                                }
                            ]} />
                            <input ref={passwordRef} type="password" placeholder={t('Password')} onChange={e => validatePassword(e.target.value)} defaultValue={userInfos.infosProfil.password} ></input>
                        </form>
                        <a href='#' className='bouton small bleu' onClick={() => saveInfos()}>{t('Update informations')}</a>
                    </div>
                </div>

                <div className='calque langue'>
                    <span className='sousTitre'>{t('Language')}</span>
                    <hr />
                    <span className='toogleContainer'><ToogleLanguage useTooltip={true} callBack={onSelectLanguage} language={selectedLanguage} /></span>
                </div>

                <div className='calque profil'>
                    <span className='sousTitre'>{t('Avatar')}</span>
                    <hr />
                    <div className='profilContainer'>
                        <span className="refrac"></span>
                        <img className="image" src={userInfos.infosProfil.img} onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = DataManager.DATA_PRELOAD_IMAGES[0];;
                        }} />
                        <label className='bouton-icone upload bleu icon-edit'>
                            <input type="file" accept='*.jpg, *.png' onChange={e => { uploadFile(e) }} />
                        </label>
                    </div>
                </div>

                <div className='calque country'>
                    <span className='sousTitre'>{t('Country')}</span>
                    <hr />
                    <div className='flagSelector'>
                        {
                            <img src={entry?.flag_4x3} onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = DataManager.DATA_PRELOAD_IMAGES[0];
                            }} />
                        }
                        <select id={"country"} onChange={(e) => onSelect(e)} defaultValue={entry?.code}>
                            {
                                DataManager.DATA_COUNTRIES.map((entry: any, index) => {
                                    return <option key={index} value={entry.code}>{entry.name}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TabProfilSettings