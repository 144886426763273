import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import InfosProfil from '../../data/InfosProfil';

import ItemFriends from './ItemFriends';
import Stepper from '../../componement/Stepper';

import './Footer.css';
import { useUser, useUserUpdate } from '../../context/UserProvider';
import { useRondaServer } from '../../context/ServerProvider';
import useRondaFetch from '../../hook/useRondaFetch';
import { InfosUser } from '../../data/InfosUser';
import { SoundManager } from '../../manager/SoundManager';
import DataManager from '../../manager/DataManager';
import { useRondaModal } from '../../context/ModalProvider';

type Props = {}

const Footer = (props: Props) => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const { get, response } = useRondaFetch();

  let userInfos = useUser()!;
  let setUserInfos = useUserUpdate()!;

  const { handleModal } = useRondaModal()!;

  async function updateFriends() {
    await get('api/friends/');

    if (response.ok && response.data) {
      setUserInfos((prevIU: InfosUser) => ({
        ...prevIU,
        dataFriends: response.data
      }));
    }
  }


  useEffect(() => {
    updateFriends();
  }, [])


  function callBack(infos: InfosProfil) {



    let infosGame = userInfos.dataGames.find((ig) => ig.id == userInfos.infosProfil.infosSettings.idInfosGame);
    if (infosGame?.state) {
      navigate('/battle', { state: { infosGame: infosGame, infosChallenger: infos } });
      SoundManager.play(SoundManager.TCHAT_OPEN);
    } else {
      handleModal("This game is still blocked, choose an unlocked game for inviting your friends!", "red");
      SoundManager.play(SoundManager.TCHAT_CLOSE);
    }


  }
  return (
    <div className='basDePage'>
      <div className='basDePageContainer'>

        <Stepper className="friends" arrowStyle={"green"} max={7} step={7} initial={0}>
          {
            userInfos.dataFriends?.map((infos: InfosProfil, index: number) => {
              return <ItemFriends key={"if" + index} infos={infos} callBack={callBack}></ItemFriends>
            })
          }
        </Stepper>


        <a href='#' className='bouton small bleu-green' onClick={() => { navigate('/ranking/topfriends'); }}>{t('Top Friends')}</a>
        <a href='#' className='bouton small gold' onClick={() => { navigate('/ranking/top40'); }}>{t('Top 40')}</a>

      </div>
    </div>)
}

export default Footer

