import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import IndicateurValidation from '../../componement/IndicateurValidation';
import Panel from '../../componement/Panel'
import { useRondaModal } from '../../context/ModalProvider';
import { useUser, useUserUpdate } from '../../context/UserProvider';
import { LocationState } from '../../data/CustomInterfaces';
import InfosPlayer from '../../data/InfosPlayer';
import InfosProfil from '../../data/InfosProfil';
import { InfosUser } from '../../data/InfosUser';
import Player from '../../games/core/Player';
import useRondaFetch from '../../hook/useRondaFetch';
import { SoundManager } from '../../manager/SoundManager';

import "./PanelReport.css";
import i18next from 'i18next';

type Props = {
    animation?: boolean,
    path?: string;
}

const PanelReport = ({ animation = false, path = "" }: Props) => {

    let navigate = useNavigate();
    let userInfos = useUser()!;
    let setUserInfos = useUserUpdate()!;

    const { t } = useTranslation();
    let location = useLocation();

    let { id } = useParams();

    const [infos, setInfos] = useState<InfosProfil | undefined>();


    const [dataIncidentsList, setDataIncidentsList] = useState<Array<any>>([{ id: 0, type: t("Specify the nature of the incident:") }]);
    const [errorMessage, setErrorMessage] = useState('')

    const { handleModal } = useRondaModal()!;

    let returnTo: string | number = path
    if (returnTo == "") returnTo = -1

    let checkboxref = useRef<HTMLInputElement | null>(null);

    const [incident, setIncident] = useState('')

    const { get, post, response } = useRondaFetch();

    async function getIncidentsList() {
        await get('api/incidents?locale=' + i18next.language)

        if (response.ok) {
            setDataIncidentsList(dataIncidentsList.concat(response.data));
        }
    }

    async function getUser(name: string) {
        await post('api/profiluser/', {
            username: name
        })

        if (response.ok) {
            getIncidentsList();
            setInfos(new InfosProfil(response.data));
        }
    }


    useEffect(() => {
        getUser(String(id));
    }, [id])


    async function onSelect(event: React.ChangeEvent<HTMLSelectElement>) {
        event.preventDefault();
        if (event.target.value != "0") {
            setIncident(event.target.value);
            setErrorMessage('ok');
        } else {
            setErrorMessage('cancel');
        }

    }



    async function validateForm() {
        if (errorMessage == "ok") {
            await post("api/report", {
                offender: infos?.id,
                incident: incident
            })
            if (response.ok) {
                navigate(-2);
                handleModal("The incident had been reported", "green");
            }

        }
    }

    async function blockPlayer() {
        SoundManager.play(SoundManager.BUTTON_CLICK);
        if (infos?.friendshipStatus == "blockedByHim") {
            handleModal("This player already blocked you", "red");
        } else {
            await post("api/blockfriendship", {
                offender: infos?.id,
            })
            if (response.ok) {
                if (response.data.status === true)
                    handleModal("The user has been blocked", "red");
                else
                    handleModal("The user has been unblocked", "green");

                let dataFriends: Array<InfosProfil> = [];
                for (let element of response.data.dataFriends)
                    dataFriends.push(new InfosProfil(element));

                setUserInfos((prevIU: InfosUser) => ({
                    ...prevIU,
                    dataFriends: dataFriends
                }));
            }
        }
    }
    return (
        <Panel className='report' title={t('Report')} animation={animation} returnTo={returnTo}>
            <div className='layer'>
                {
                    infos &&
                    <div className='layerContainer'>
                        <span className="sousTitre">{t('Report a behavior of') + ' ' + infos.name + ' ?'}</span>

                        <form action="">

                            <i className="formIcon icon-report"></i>
                            <IndicateurValidation validationType={errorMessage} messages={[
                                { name: "ok", value: t('Valid Field !') },
                                { name: "cancel", value: t('Invalid Field !') }
                            ]} />
                            <select onChange={(e) => onSelect(e)} >
                                {
                                    dataIncidentsList.map((entry: any, index) => {
                                        return <option key={index} value={entry.id}>{entry.type}</option>
                                    })
                                }
                            </select>

                            <a href='#' className='bouton red' onClick={(e) => { validateForm() }}>{t('Send')}</a>

                        </form>
                        <hr />
                        <span className="sousTitre">{t('Block') + ' ' + infos.name + ' ?'}</span>

                        <label className='checkBox' >
                            <input ref={checkboxref} onClick={(e) => { blockPlayer(); }} type="checkbox" disabled={(infos.friendshipStatus == "blockedByHim") ? true : false} defaultChecked={(infos.friendshipStatus == "blockedByYou" || infos.friendshipStatus == "blockedByHim") ? true : false} />
                            <span className="knob">{(infos.friendshipStatus == "blockedByHim") ? t('Blocked !') : t('Block ?')}</span>
                            <span className="slideDeck"></span>
                        </label>
                    </div>
                }
            </div>
            <div className='perso'></div>
        </Panel>
    )
}

export default PanelReport