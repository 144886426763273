import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useUser, useUserUpdate } from '../../context/UserProvider';
import { InfosGame } from '../../data/InfosGame';
import ItemGame from './ItemGame';
import ItemGameMenu from './ItemGameMenu';
import Panel from '../../componement/Panel'
import Stepper from '../../componement/Stepper';


import "./PanelHome.css"
import DataManager from '../../manager/DataManager';
import useRondaFetch from '../../hook/useRondaFetch';
import { useRondaModal } from '../../context/ModalProvider';
import { InfosTheme } from '../../data/InfosTheme';

type Props = {}

function PanelHome({ }: Props) {

    const { post, response } = useRondaFetch();
    const { handleModal } = useRondaModal()!;

    let { type } = useParams();
    const { t } = useTranslation();
    let userInfos = useUser()!;
    const Carousel = useRef<HTMLDivElement>(null)

    const infosTheme = userInfos.dataSceneThemes.find((e: InfosTheme) => { return e.id == userInfos.infosProfil.infosSettings.idInfosTheme });

    const onSelectGame = (index: number) => {
        Carousel.current!.scrollLeft = Carousel.current!.clientWidth * (index * DataManager.DIRECTION)
        onGetGame(index);
        setGameIndex(index);
    };

    const [gameIndex, setGameIndex] = useState<number>(0);
    const [cursor, setCursor] = useState<number>(0);

    useEffect(() => {
        //let index: number = userInfos.dataGames.findIndex((ig) => ig.name.toLowerCase() === String(type).toLowerCase());

        let index = findIndexFromID(userInfos.infosProfil.infosSettings.idInfosGame, userInfos.dataGames)
        Carousel.current!.scrollLeft = Carousel.current!.clientWidth * (index * DataManager.DIRECTION)
        setGameIndex(index);

        //console.log("userInfos.infosProfil.infosSettings.IdInfosGame " + userInfos.infosProfil.infosSettings.idInfosGame + "   " + index);
        return () => {
        }
    }, [type])

    function findIndexFromID(id: number, arr: Array<any>) {
        let index = arr.findIndex((obj: any) => obj.id === id);
        if (index < 0) index = 0;
        return index;
    }

    const onGetGame = (id: number) => {
        let game = userInfos.dataGames[id];
        if (game) save(game.id);
    }

    async function save(id: number) {
        await post("api/updateprofil", {
            data: {
                Settings: {
                    id: userInfos.infosProfil.infosSettings.id,
                    idInfosGame: id,
                }
            }
        })
        if (response.ok) {
            //handleModal("Game preference Updated", "green");
            userInfos.infosProfil.infosSettings.idInfosGame = id;
        }
    }

    const onGetCursorNumber = (id: number) => { setCursor(id) }

    return (
        <>
            <Panel className='home' title={t('Home')} closeButton={false}>
                <div className='gameMenu'>
                    <div ref={Carousel} className='gameMenuCounter'>
                        <div className='gameMenuContainer'>
                            {
                                userInfos.dataGames.map((infos: InfosGame, index: number) => {
                                    return <ItemGameMenu key={"iGg" + index} infos={infos} background={infosTheme?.image} ></ItemGameMenu>
                                })
                            }
                        </div>

                    </div>
                </div>
                <Stepper className="games" step={1} getID={onGetCursorNumber} initial={findIndexFromID(userInfos.infosProfil.infosSettings.idInfosGame, userInfos.dataGames)} >
                    {
                        userInfos.dataGames.map((infos: InfosGame, index: number) => {
                            return <ItemGame key={"ig" + index} cursor={index == cursor} selected={index == gameIndex} index={index} callBack={onSelectGame} infos={infos} background={infosTheme?.image} ></ItemGame>
                        })
                    }
                </Stepper>
            </Panel>
            <Outlet />
        </>

    )
}

export default PanelHome