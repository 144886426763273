import { Scene } from '@babylonjs/core/scene';
import React, { LazyExoticComponent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { InfosModes } from '../../data/InfosModes';
import { LocationState } from '../../data/CustomInterfaces';
import { useRondaServer } from '../../context/ServerProvider';
import { log } from '../../manager/FirebaseManager';
import { InfosParametres } from '../../data/InfosParametres';
import PanelOffline from '../../layout/offline/PanelOffline';

type Props = {
    scene: Scene;
    game: string;
    demoMode?: boolean;
    children?: React.ReactNode;
}

const gameComponents: Record<string, LazyExoticComponent<React.ComponentType<any>>> = {
    ronda: React.lazy(() => import('../ronda/RondaComponement')),
    quinza: React.lazy(() => import('../quinza/QuinzaComponement')),
    hazzouj: React.lazy(() => import('../hazzouj/HazzoujComponement')),
    kdoub: React.lazy(() => import('../kdoub/KdoubComponement')),
    kbirsghir: React.lazy(() => import('../kbirsghir/KbirSghirComponement')),
    sota: React.lazy(() => import('../sota/SotaComponement')),
    rondaserka: React.lazy(() => import('../rondaserka/RondaSerkaComponement')),
    rondarchim: React.lazy(() => import('../rondarchim/RondaRchimComponement')),
    touti: React.lazy(() => import('../touti/ToutiComponement')),
    kappilla: React.lazy(() => import('../kappilla/KappillaComponement')),
    ribis: React.lazy(() => import('../ribis/RibisComponement')),
    shkanba: React.lazy(() => import('../shkanba/ShkanbaComponement')),
    triss: React.lazy(() => import('../triss/TrissComponement')),
    attkozer: React.lazy(() => import('../attkozer/AttkozerComponement')),
    bonjour: React.lazy(() => import('../bonjour/BonjourComponement')),
    cartecopie: React.lazy(() => import('../cartecopie/CarteCopieComponement')),
};



export default function GameComponement(props: Props) {
    const GameComponent = gameComponents[props.game];

    const location = useLocation();
    let navigate = useNavigate();

    const modeDemo = new InfosModes({
        type: "speakpeak",
        Parametres: new InfosParametres({
            numberOfRounds: 1,
            numberOfPlayers: 3,
            cheatMode: true,
            demoMode: true,
            multiplayer: false
        })
    });

    const [infosModes] = useState<InfosModes>(props.demoMode ? modeDemo : (location.state as LocationState)?.infosMode);
    const { room } = useRondaServer()!;


    useEffect(() => {
        if (!infosModes || (infosModes.multiplayer && !room)) {
            if (props.demoMode)
                navigate('/');
            else
                navigate('/home');
        } else {
            log("launch_game", { type: infosModes.type, game: props.game });
        }
        return () => {
        }
    }, [infosModes, room]);

    return GameComponent ? infosModes && <GameComponent scene={props.scene} room={room} infosModes={infosModes} /> : <PanelOffline />;
}
