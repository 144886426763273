import React, { useEffect, useRef, useState } from 'react'
import Alert from '../../componement/Alert'
import { useTranslation } from 'react-i18next';

import "./AlertGift.css";
import { useNavigate, useParams } from 'react-router-dom';
import validator from 'validator';

import { SoundManager } from '../../manager/SoundManager';
import { useUser, useUserUpdate } from '../../context/UserProvider';
import { useRondaModal } from '../../context/ModalProvider';
import useRondaFetch from '../../hook/useRondaFetch';
import IndicateurValidation from '../../componement/IndicateurValidation';
import { InfosUser } from '../../data/InfosUser';
import { TextManager } from '../../manager/TextManager';
import { log } from '../../manager/FirebaseManager';

type Props = {
    path?: string
}

interface InfosGift {
    type: string,
    value: number
}

function AlertGift({ path = "" }: Props) {
    let navigate = useNavigate();
    let userInfos = useUser()!;
    let setUserInfos = useUserUpdate()!;
    const { t } = useTranslation();
    let emailRef = useRef<HTMLInputElement | null>(null);
    const { handleModal } = useRondaModal()!;

    let { type, id } = useParams();

    const [errorTokenMessage, setErrorTokenMessage] = useState('')

    const [gift, setGift] = useState<InfosGift | undefined>()

    function validateForm() {
        if (errorTokenMessage == 'ok')
            getGift((emailRef.current as HTMLInputElement).value);
        else
            SoundManager.play(SoundManager.CANCEL_DISMISS);

    }

    const { post, get, cache, response } = useRondaFetch()


    async function getGift(code: string) {
        cache.clear();
        cache.delete();


        const newTodo = await post('/api/getgift', {
            code,
        })
        if (response.ok) {


            handleModal(`Code is valid !`, "green");

            userInfos.infosProfil.coin = response.data.profil.coin;
            userInfos.infosProfil.buck = response.data.profil.buck;

            log("get_gift", { coin_gift: String(response.data.profil.coin), buck_gift: String(response.data.profil.buck) })

            setGift(response.data.gift)
            setUserInfos((prevIU: InfosUser) => ({
                ...prevIU
            }));

            SoundManager.play(SoundManager.COIN_UP);

            //navigate(path + "/store/" + type + "/congrats")


        }
    }




    const validatetoken = (value: string) => {

        if (validator.isAlphanumeric(value) && validator.isLength(value, { min: 10, max: 10 })) {
            setErrorTokenMessage('ok');
        } else {
            setErrorTokenMessage('cancel')
        }
    }
    return (
        <Alert title={t('Coupon')} className='gift' callBack={() => navigate(path + "/store/" + type)}>
            <div className='layer'>
                <div className='layerContainer'>
                    {
                        gift
                            ? <>
                                <span className="sousTitre">{t('Your coupon is valid!')}</span>

                                <div className='container'>
                                    <div className='calque'>
                                        <span className={'reward ' + ((gift.type == "Coin") ? 'coin' : 'buck')}>{"+ " + TextManager.formatScore(gift.value)}<span className={(gift.type == "Coin") ? 'iconCoin' : 'iconBuck'} /></span>

                                    </div>

                                </div>

                            </>
                            : <>
                                <span className="sousTitre">{t("Enter your code!")}</span>
                                <form action="">

                                    <i className="formIcon icon-plus-circle"></i>
                                    <IndicateurValidation validationType={errorTokenMessage} messages={[
                                        { name: "ok", value: t('Valid field!') },
                                        { name: "cancel", value: <p>{t('Le champs doit être un email valide')}</p> }
                                    ]} />
                                    <input ref={emailRef} onChange={e => validatetoken(e.target.value)} maxLength={10} placeholder={t('#Code')} ></input>
                                    <a href='#' className='bouton green' onClick={(e) => { validateForm() }}>{t('Validate')}</a>

                                </form>
                            </>
                    }
                </div>
            </div>
            <div className='perso'></div>

        </Alert>
    )
}

export default AlertGift