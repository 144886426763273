import React, { useEffect, useLayoutEffect, useRef } from 'react'
import InfosPlayer from '../../data/InfosPlayer'
import DataManager from '../../manager/DataManager';
import { TextManager } from '../../manager/TextManager';
import IndicateurLatency from '../../componement/IndicateurLatency';
import gsap from "gsap";
import "./IndicateurMultiPlayer.css";
import IndicateurPremium from '../../componement/IndicateurPremium';
import { useTranslation } from 'react-i18next';
import { getPercentFromRate } from '../../manager/Utils';

type Props = {
    infos: InfosPlayer,
    callBack: Function,
    owner: boolean

}

function IndicateurPlayer({ infos, owner, callBack }: Props) {

    let percent = getPercentFromRate(infos.rate);
    const { t } = useTranslation();

    let flag = (DataManager.DATA_COUNTRIES.find((e) => e.code === infos?.country));


    const Slide = useRef<HTMLSpanElement>(null)
    useLayoutEffect(() => {
        gsap.set(Slide.current, { scaleX: percent / 100, transformOrigin: "0px 0px" });
        return () => {
        };
    }, []);
    return (
        <div className='indicateurMultiPlayer'>
            <IndicateurPremium id={infos.style}></IndicateurPremium>
            {
                owner && <a className='bouton-icone red icon-cancel' onClick={() => { callBack(infos.sessionId) }} />
            }
            <div className='imageContainer'>
                <IndicateurLatency latency={infos.latency} multiplayer={true} />
                <span className="refrac"></span>

                <img className="image" src={infos.img} onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = DataManager.DATA_PRELOAD_IMAGES[0];;
                }} />
                <img className='flag' src={flag!.flag_4x3}></img>
                <span className='level'>{infos.level}</span>
                <span className='percent'>{percent + "%"}</span>
                <span ref={Slide} className='slide' />
            </div>
            <span className='name'>{infos.name}</span>

            <span className={'bouton-ready ' + ((infos.replay) ? "start" : "idle")}>{(infos.replay) ? t("Ready") : t("Waiting")}<span className='iconCoin' /></span>
        </div>
    )
}

export default IndicateurPlayer