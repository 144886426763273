import React from 'react'
import { useUser } from '../context/UserProvider';
import "./IndicateurPremium.css";
import DataManager from '../manager/DataManager';

type Props = {
  id: number | undefined
}

function IndicateurPremium({ id }: Props) {

  const userInfos = useUser()!;

  let infosStyle = userInfos.dataStyleThemes.find((is) => is.id === id);
  return (
    (infosStyle) ?
      <span className="indicateurPremium">
        <img src={infosStyle?.image} onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = DataManager.DATA_PRELOAD_IMAGES[0];;
        }} />
      </span>
      : <></>
  )
}

export default IndicateurPremium