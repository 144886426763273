import React, { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { InfosStore } from '../../data/InfosStore'
import { TextManager } from '../../manager/TextManager'

import "./ItemStore.css"
import TooltipStore from '../../componement/TooltipStore'

import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { useUser, useUserUpdate } from '../../context/UserProvider'
import { InfosCoin } from '../../data/InfosCoin'
import useRondaFetch from '../../hook/useRondaFetch'
import { useRondaModal } from '../../context/ModalProvider'
import { SoundManager } from '../../manager/SoundManager'
import { InfosUser } from '../../data/InfosUser'
import InfosProfil from '../../data/InfosProfil'
import DataManager from '../../manager/DataManager'
import { log } from '../../manager/FirebaseManager'

type Props = {
    infos: InfosCoin;
}

function ItemCoin({ infos }: Props) {
    let navigate = useNavigate();
    let { t } = useTranslation();

    let Target = useRef<HTMLSpanElement>(null);
    let userInfos = useUser()!;
    let setUserInfos = useUserUpdate()!;

    const [bonus, setBonus] = useState<number>(userInfos.infosProfil.bonus);
    const [coordinates, setCoordinates] = useState(Target.current?.getBoundingClientRect());

    const { get, post, response, cache } = useRondaFetch();

    const { handleModal } = useRondaModal()!;

    async function getUser(name: string) {
        await post('api/profiluser/', {
            username: name
        })

        if (response.ok) {
            let profil = new InfosProfil(response.data);
            setUserInfos((prevIU: InfosUser) => ({
                ...prevIU,
                infosProfil: profil,
            }));
            setBonus(profil.bonus);
        }
    }


    useEffect(() => {
        if (infos.type == "Free") {
            const maintenant = Date.now();
            const dateBonusUtilisateur = userInfos.dateBonus.getTime();
            const delaiMax = 2 * 60 * 60 * 1000; // 2 heures en millisecondes
            const deuxHeuresSontPassees = maintenant - dateBonusUtilisateur >= delaiMax;

            if (deuxHeuresSontPassees) {
                console.log("Le délai de deux heures est dépassé.");
                getUser(userInfos.infosProfil.name);
            } else {
                console.log("Le délai de deux heures n'est pas dépassé.");

            }
        }

        return () => {

        }
    }, [])



    function buyCoins() {
        navigate("coins/checkout", { state: { infosCoin: infos } });
    }


    async function getFreeCoins() {
        cache.delete();
        cache.clear();
        await get('api/bonususer/')
        if (response.ok) {

            userInfos.dateBonus = new Date();
            userInfos.infosProfil.coin = response.data.coin;

            userInfos.infosProfil.bonus = response.data.bonus;

            log("get_bonus", { bonus: String(response.data.bonus) })

            setUserInfos((prevIU: InfosUser) => ({
                ...prevIU
            }));

            setBonus(response.data.bonus);

            handleModal("Free coin received succefully", "green");

            SoundManager.play(SoundManager.COIN_UP);
        }
    }

    function getGift() {
        navigate("coins/gift");
    }


    function onRollOver() {
        setCoordinates(Target.current?.getBoundingClientRect());

    }

    function onRollOut() {
        setCoordinates(undefined);
    }

    //((infos.constructor) == InfosCoin)

    return (
        <>
            <span ref={Target} className="itemStore" onMouseOver={onRollOver} onMouseLeave={onRollOut} >
                {
                    (infos.type == "Free")
                        ? <a className={'bouton small blue simple ' + ((bonus > 0) ? "" : "desactivate")} onClick={() => getFreeCoins()}>{t('Collect')}</a>
                        : (infos.type == "Gift")
                            ? <a className={'bouton small gold simple '} onClick={() => getGift()}>{t('Coupon')}</a>
                            : <a className='bouton small green simple' onClick={() => buyCoins()}>{t('Buy')}</a>
                }
                <img src={infos.image} onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = DataManager.DATA_PRELOAD_IMAGES[0];;
                }} />
                {/* {
                    (infos.infinite)
                        ? <span className='count' style={{ display: (infos.count) ? 'block' : 'none' }}>{infos.count}</span>
                        : <></>
                } */}
                {
                    (document.getElementById("App-Container")!)
                        ? createPortal((coordinates) ? <TooltipStore coordinates={coordinates} message={infos.description} title={infos.title} ></TooltipStore> : <></>, document.getElementById("App-Container")!)
                        : <></>
                }
            </span>


        </>
    )
}

export default ItemCoin