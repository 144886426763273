import React, { useLayoutEffect, useState } from 'react'
import './PanelNotFound.css';

import { Scene } from '@babylonjs/core/scene';

import { useNavigate } from "react-router-dom";

import { useTranslation } from 'react-i18next';
import Alert from '../../componement/Alert';

type Props = {

}

export default function PageNotFound(props: Props) {
  let navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <Alert className='notfound' title={t('Page not found')} autoClose={60} animation={true} callBack={() => navigate("/", { replace: true })}>
      <div className='layer'>
        <div className='layerContainer'>
          <span className="sousTitre">{t("Uh-oh! You've stumbled upon a dead end. Head back to the gaming table and try your luck again.")}</span>
          <a className='bouton bleu' onClick={() => navigate("/", { replace: true })} >{t('Go back !')}</a>
        </div> </div>
      <div className='perso'></div>
    </Alert>
  )
}