import React, { Suspense, useState, useEffect, useRef, useCallback } from 'react';
import { Engine, Html, Scene, SceneEventArgs } from "react-babylonjs";
import { Engine as EngineCore, Camera, Nullable, DefaultRenderingPipeline, DepthOfFieldEffectBlurLevel, Color3, Color4, Vector3, DefaultLoadingScreen, Mesh } from "@babylonjs/core";

import { Scene as SceneCore } from "@babylonjs/core";

import './App.css';
import Repere from './games/core/Repere';
import Routing from './routing/Routing';
import CartaBackground from './games/core/CartaBackground';

import ShowReelManager from './manager/ShowReelManager';
import DataManager from './manager/DataManager';
import FPS from './games/core/FPS';
import { LoadingScreenManager } from './manager/LoadingScreenManager';
import LoadingScreen from './componement/LoadingScreen';
import { SoundManager } from './manager/SoundManager';
import { InfosSound } from './data/InfosSound';
import { SceneManager } from './manager/SceneManager';

import i18next, { i18n } from 'i18next';

import { useUser, useUserUpdate } from './context/UserProvider';
import { InfosUser } from './data/InfosUser';
import { useRondaModal } from './context/ModalProvider';
import useRondaFetch from './hook/useRondaFetch';
import { useRondaLoader } from './context/LoaderProvider';
import { log, onMessageListener } from './manager/FirebaseManager';
import { useRondaMessenger } from './context/MessengerProvider';
import InfosProfil from './data/InfosProfil';
import { useMainScreenPrompt } from './context/PromptProvider';
import useProperty from './hook/useProperty';

const App: React.FC = () => {

  const { prompt, promptToInstall } = useMainScreenPrompt()!;

  const { updateProperty } = useProperty()!;

  useEffect(() => {

    if (prompt) {
      showInstallPromotion();
      updateProperty("WEB");
    }

  }, [prompt]);


  const showInstallPromotion = () => {
    handleMessage({
      title: "Install the app ?",
      message: "You can install this game on your device",
      type: "installation",
      callback: () => {
        promptToInstall();
        log("install_app", { version: "v1" });
        updateProperty("APP");
      }
    });
  };















  const { handleModal } = useRondaModal()!;
  const { handleMessage } = useRondaMessenger()!;

  const { get, response } = useRondaFetch(undefined, undefined, undefined, "Full");

  const setUserInfos = useUserUpdate()!;

  const unlocksoud = () => {
    if (!EngineCore.audioEngine?.unlocked)
      EngineCore.audioEngine?.unlock();
    document.onclick = null;
  }
  useEffect(() => {
    //onInit();
    //getDataInfos();
    document.onclick = unlocksoud
  }, []);

  onMessageListener().then(payload => {

    let payloadData = (payload as any);

    let link = payloadData.data?.link;
    if (link) link = link.replace(DataManager.ABSOLUT_PATH, '');

    let from = new InfosProfil();
    if (payloadData.data?.sender)
      from = new InfosProfil(JSON.parse(payloadData.data?.sender));

    let type = payloadData.data?.type ? payloadData.data?.type : "notification";

    handleMessage({
      title: payloadData.notification.title,
      message: payloadData.notification.body,
      from: from,
      type: type,
      link: link
    });

  }).catch(err => console.log('failed: ', err));

  // async function getDataInfos() {
  //   const newTodo = await get('api/getdatainfos/')
  //   if (response.ok) {
  //     DataManager.parse(response.data);
  //     i18next = initInternationalization(DataManager.DATA_LANGUAGES, onIniti18next);
  //     handleModal("Data uploaded succefully", "green");
  //   }
  // }

  const [preloadedImages] = useState<Array<HTMLImageElement>>([]);


  function configureData() {

    let idInfosLanguage = DataManager.DATA_LANGUAGES.find(i => i.locale == i18next.language)?.id;
    let dataGames = DataManager.DATA_GAMES;

    setUserInfos((prevIU: InfosUser) => ({
      ...prevIU,
      dataGames: dataGames,
      infosProfil: {
        ...prevIU.infosProfil,
        infosSettings: {
          ...prevIU.infosProfil.infosSettings,
          idInfosLanguage: idInfosLanguage
        }
      }
    }));


    DataManager.DATA_PRELOAD_IMAGES.forEach((fileName: any, index) => {
      DataManager.DATA_PRELOAD_IMAGES[index] = DataManager.ABSOLUT_PATH + fileName;
      fileName = DataManager.DATA_PRELOAD_IMAGES[index];
      const img = new Image();
      img.src = fileName;
      //console.log("+++++++++++++++++++> " + fileName)
      preloadedImages.push(img);
    });
  }











  const countFetch = useRondaFetch();

  const updateAdCount = (element: any) => {
    if (element.video)
      countFetch.put('api/ad-videos/' + element.id, { data: { count: element.count + 1 } });
    else
      countFetch.put('api/ad-versos/' + element.id, { data: { count: element.count + 1 } });
  }



  const [scene, setScene] = useState<Nullable<SceneCore>>(null);
  const [pipeline, setPipeline] = useState<DefaultRenderingPipeline>();
  const [srm, setSRM] = useState<ShowReelManager>();

  const showRepere: boolean = DataManager.TEST_MODE;

  var loadingScreen = new LoadingScreenManager(" ");
  DefaultLoadingScreen.prototype.displayLoadingUI = loadingScreen.displayLoadingUI;
  //DefaultLoadingScreen.prototype.hideLoadingUI = loadingScreen.hideLoadingUI;

  // window.screen.orientation.lock('landscape');

  const { handleLoader } = useRondaLoader()!;

  function onSceneMount(e: SceneEventArgs) {

    let engine = e.scene.getEngine();
    engine.displayLoadingUI();
    engine.disableUniformBuffers = true;

    //e.scene.executeOnceBeforeRender


    e.scene.executeOnceBeforeRender(() => {


      engine.hideLoadingUI();

      console.log(e.scene.getCameraByName("Camera"));


      let mycamera = e.scene.getCameraByName("Camera") as Camera;
      mycamera.inputs.clear();

      let pipeline = new DefaultRenderingPipeline('default', false, e.scene, [mycamera])
      setPipeline(pipeline);

      let cartaScene = new SceneManager(e.scene, onReady, handleLoader, updateAdCount);
      SceneManager.self = cartaScene;

      function onReady(cardsList: Array<Mesh>) {
        setSRM(new ShowReelManager(cardsList));
        DataManager.CARDS_LIST = cardsList;

        console.log("Loading Sound");
        handleLoader(true, "full sound");
        //handleLoader(true, "full");

        SoundManager.init(e.scene);
        loadNextSound(0);


      }

    });
    function loadNextSound(index: number) {
      if (index < DataManager.DATA_SOUNDS.length) {
        let nextSound = DataManager.DATA_SOUNDS[index];
        setSound(nextSound, () => {
          // Load the next sound recursively when the current one is loaded.
          loadNextSound(index + 1);
        });
      } else {
        loadedSound();
      }
    }

    function setSound(e: InfosSound, onSoundLoaded?: () => void) {
      SoundManager.add(e.name, e.link, e.group, e.type, onSoundLoaded);
    }
    function loadedSound(): void {


      if (engine.getFps() < 30) {
        //console.log("Can't Handle HD " + engine.getFps());
        DataManager.RESOLUTION = "sd";
      }
      handleLoader(false);
      configureData();
      setScene(e.scene);


      e.scene.autoClear = false; // Color buffer
      e.scene.autoClearDepthAndStencil = false; // Depth and stencil, obviously

      //DataManager.DATA_SOUNDS.forEach(setSound);

      SoundManager.updateVolume(0.5, 0.5);
      SoundManager.ready = true;
      //SoundManager.switchMusic(0);
    }
  }

  useEffect(() => {
    if (pipeline?.isSupported) {
      pipeline.depthOfFieldBlurLevel = DepthOfFieldEffectBlurLevel.Medium;
      // pipeline.depthOfFieldEnabled = true;
      // pipeline.sharpenEnabled = true;
      pipeline.depthOfField.focalLength = 0;
    }
  }, [pipeline]);
















  return (
    <>

      {
        scene &&
        <Routing scene={scene as SceneCore} srm={srm} pipeline={pipeline}></Routing>
      }
      {

        <Engine antialias={true} adaptToDeviceRatio={true} canvasId="sample-canvas"   >
          <Scene onSceneMount={onSceneMount} hoverCursor="pointer" ambientColor={Color3.White()} clearColor={new Color4(64 / 255, 102 / 255, 35 / 255, 1)}>
            <Suspense fallback={
              <Html name="html" center occlude={false} >
                <LoadingScreen />
              </Html>
            } >
              <arcRotateCamera name="Camera" fov={0.8} radius={55} useAutoRotationBehavior={false} noPreventDefault={true} fovMode={Camera.FOVMODE_HORIZONTAL_FIXED} target={Vector3.Up()} alpha={-Math.PI / 2} beta={Math.PI / 8} minZ={1} />

              <hemisphericLight name="Ambilight" intensity={0.3} direction={Vector3.Up()} diffuse={new Color3(1, 0.96, 0.96)} specular={new Color3(0, 0, 0)} />

              <directionalLight name="ShadowLight" shadowEnabled={false} setDirectionToTarget={[Vector3.Zero()]} direction={Vector3.Zero()} position={new Vector3(0, 60, 10)} specular={new Color3(0, 0, 0)} intensity={0.6} shadowMinZ={1} shadowMaxZ={2500}>
                <shadowGenerator mapSize={256} useBlurExponentialShadowMap={true} blurKernel={16} darkness={0.3}  /*shadowCasters={["card39"]} */ forceBackFacesOnly={true} depthScale={10} />
              </directionalLight>

              <pointLight name="Spotlight" position={new Vector3(0, 80, 10)} specular={new Color3(1.2, 1.2, 1.2)} intensity={0.3} />

              <highlightLayer name='h1' /*ref={HightLightRef}*/ isEnabled={true} />

              <CartaBackground />
              <FPS visibility={showRepere} />
              <Repere visibility={showRepere} />

            </Suspense>
          </Scene>
        </Engine>
      }
    </>
  );
}

export default App;
