import React, { useEffect, useRef } from 'react'
import Alert from '../../componement/Alert'
import { useTranslation } from 'react-i18next';

import "./AlertInvitation.css";
import { useNavigate, useParams } from 'react-router-dom';
import { useUser, useUserUpdate } from '../../context/UserProvider';
import Fleche from '../../componement/Fleche';
import InfosProfil from '../../data/InfosProfil';
import ItemInvitation from './ItemInvitation';
import { useRondaServer } from '../../context/ServerProvider';
import { useRondaModal } from '../../context/ModalProvider';
import useRondaFetch from '../../hook/useRondaFetch';

type Props = {
    path?: string
}

function AlertInvitation({ path = "" }: Props) {
    const { t } = useTranslation();
    let navigate = useNavigate();
    let { invitation } = useParams();

    let userInfos = useUser()!;
    let setUserInfos = useUserUpdate()!;

    const Carousel = useRef<HTMLDivElement>(null)

    let index = 0;
    let { lounge, updateOnlineFriends } = useRondaServer()!;
    let data = userInfos.dataFriends//.filter((ip) => ip.online == true);

    const handleCarouselAction = (e: MouseEvent) => {
        e.preventDefault();

        //let indexRef = index;
        switch ((e.currentTarget as HTMLElement).id) {
            case "next":
                index += 1;
                break;
            case "prev":
                index -= 1;
                break;
            default:
                break;
        }

        if (index < 0) index = 0;
        Carousel.current!.scrollTop = (Carousel.current?.children.item(0)?.clientHeight!) * index;
        //console.log(index);
    }
    const { handleModal } = useRondaModal()!;
    const { post, response } = useRondaFetch();

    async function sendNotification(infos: InfosProfil) {
        await post('api/sendnotification/', {
            to: infos,
            type: "invitation",
            link: "/friendly/" + invitation
        });

        if (response.ok) {
            handleModal("Notification sent", "green");
        }
    }

    const handleClickProfil = (infos: InfosProfil, type: string = "invitation") => {
        switch (type) {
            case "invitation":
                lounge?.send("invitation", {
                    from: userInfos.infosProfil,
                    to: infos,
                    type: "invitation",
                    link: "/friendly/" + invitation,
                });
                handleModal("Invitation sent", "green");
                break;
            case "notification":
                sendNotification(infos);
                break;
        }
    };


    useEffect(() => {
        updateOnlineFriends();

    }, [])




    return (
        <Alert title={t('Invite friends')} className='invitation' callBack={() => navigate(path + "/friendly")}>

            <div className='layer'>
                <div className='layerContainer'>


                    <div ref={Carousel} className='rankScroll'>
                        {
                            data.map((infos: InfosProfil, index: number) => {
                                return <ItemInvitation key={"ipr" + index} infos={infos} callBack={handleClickProfil}></ItemInvitation>
                            })
                        }
                    </div>
                    <div className='fleches'>
                        <Fleche name="next" href="#" styleName="green" callBack={handleCarouselAction} > </Fleche>
                        <Fleche name="prev" href="#" styleName="green" callBack={handleCarouselAction} direction="left"> </Fleche>
                    </div>
                </div>

            </div>
            <div className='perso'></div>

        </Alert>
    )
}

export default AlertInvitation