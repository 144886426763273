import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useRondaModal } from '../../context/ModalProvider';
import useRondaFetch from '../../hook/useRondaFetch';

type Props = {}

function PanelVerification({ }: Props) {
    const [searchParams] = useSearchParams();

    const code = searchParams.get("code")
    let navigate = useNavigate();


    const { get, error, response } = useRondaFetch()
    const { handleModal } = useRondaModal()!;
    const { t } = useTranslation();


    useEffect(() => {
        if (code)
            confirmAccount(code)

    }, [code])

    async function confirmAccount(code: string) {

        if (code)
            await get('/api/auth/email-confirmation/?confirmation=' + code);
        if (response.ok) {
            handleModal(`Successful verification`, "green");
            navigate("/confirmation", { state: { message: t('Your email has been successfully verified.') } });
        }
        else {
            navigate("/", { replace: true });

        }
        //navigate("/", { replace: true });
    }

    useEffect(() => {
        if (error) {
            handleModal(`Faild verification`, "red");
            navigate("/confirmation", { state: { message: t("Your email hasn't been verified.") } });
        }
    }, [error])

    return (
        <></>
    )
}

export default PanelVerification