import { Client, Room } from "colyseus.js";
import Peer, { MediaConnection } from "peerjs";
import React, { useEffect, useState } from "react";
import { useRondaMessenger } from "../context/MessengerProvider";
import { useRondaModal } from "../context/ModalProvider";
import { useUser, useUserUpdate } from "../context/UserProvider";
import InfosProfil from "../data/InfosProfil";
import { InfosUser } from "../data/InfosUser";
import DataManager from "../manager/DataManager";
import useRondaFetch from "./useRondaFetch";

export default () => {
    const [client, setClient] = useState<Client>(new Client(DataManager.ENDPOINT));
    const [connected, setConnected] = useState(false);

    //const [stream, setStream] = useState<MediaStream|undefined>();

    let [lobby, setLobby] = useState<Room | undefined>(undefined);

    let [lounge, setLounge] = useState<Room | undefined>(undefined);

    //let [peer, setPeer] = useState<Peer | undefined>(undefined);

    const [room, setRoom] = useState<Room | undefined>(undefined);

    const { handleMessage } = useRondaMessenger()!;

    //const [onCalledPeers] = useState<Map<string, { call: MediaConnection }>>(new Map());


    //const userInfos = useUser()!;

    let userInfos: InfosUser;
    const updateUserInfos = useUserUpdate()!;
    const { handleModal } = useRondaModal()!;

    //let dataFriends: Array<InfosProfil> = [];

    // let setRoom = (room: Room | undefined) => {
    //     if (room) {
    //         let peer = new Peer(replaceInvalidId(room.sessionId), {
    //             host: DataManager.WEBRTC_HOST,
    //             port: DataManager.WEBRTC_PORT,
    //             secure: DataManager.WEBRTC_SECURE,
    //         });
    //         peer.on('call', (call) => {
    //             if (!onCalledPeers.has(call.peer)) {
    //               call.answer(stream)
    //               onCalledPeers.set(call.peer, { call })

    //               call.on('stream', (userVideoStream) => {
    //                 this.addVideoStream(video, userVideoStream)
    //               })
    //             }
    //             // on close is triggered manually with deleteOnCalledVideoStream()
    //           })
    //         setroom(room);
    //         setPeer(peer);
    //     }
    // }

    // function replaceInvalidId(userId: string) {
    //     return userId.replace(/[^0-9a-z]/gi, 'A')
    // }

    let connect = async (puserInfos: InfosUser) => {


        userInfos = puserInfos;
        //lounge?.leave();
        //lounge = undefined;
        //setConnected(false);

        // console.log("##############################");
        // console.log("infos.infosProfil " + JSON.stringify(userInfos.infosProfil));
        // console.log("connected " + connected);
        // console.log("client " + client);
        // console.log("##############################");

        if (!connected) {

            if (!client)
                setClient(new Client(DataManager.ENDPOINT));
            try {
                setConnected(true);

                lounge = await client.joinOrCreate<any>("Lounge", { id: userInfos.infosProfil.id });



                lounge.onLeave(() => {
                    disconnect();
                });

                handleModal('You are connected to the Server', "green");

                lounge.onMessage("DOUBLECONNECTION", (data) => {
                    handleModal("A new connection has been detected, this session will be closed", "red");
                });

                lounge.onMessage("ONLINEFRIENDS", (data) => {
                    let dataFriends: Array<InfosProfil> = [];
                    for (let friend of data.friends) {
                        dataFriends.push(friend as InfosProfil);
                    }
                    //console.log("ONLINEFRIENDS ");

                    // console.log("ONLINEFRIENDS " + JSON.stringify(data));
                    updateUserInfos((prevIU: InfosUser) => ({
                        ...prevIU,
                        dataFriends: dataFriends
                    }));
                });

                lounge.onMessage("INVITATION", (data) => {
                    let friend = userInfos.dataFriends.find((f) => f.id === Number(data.from));
                    //console.log("receivingInvitation " + data.from + " " + friend?.name + "  " + JSON.stringify(dataFriends));

                    handleModal(`|${friend?.name} |sent you an invitation for a game !`, "green");

                    handleMessage({
                        from: data.from,
                        to: data.to,
                        type: data.type,
                        link: data.link
                    });

                });

                lounge.onMessage("FRIENDSHIP", (data) => {
                    let friend = userInfos.dataFriends.find((f) => f.id === Number(data.from));
                    //console.log("receivingInvitation " + data.from + " " + friend?.name + "  " + JSON.stringify(dataFriends));

                    handleModal(`|${friend?.name}| want to be your friend !`, "green");

                    handleMessage({
                        from: data.from,
                        to: data.to,
                        type: data.type,
                        link: data.link
                    });
                });


                updateUserInfos((prevIU: InfosUser) => ({
                    ...prevIU,
                    infosProfil: {
                        ...prevIU.infosProfil,
                        online: true,
                    }
                }));

                //updateOnlineFriends();

                //update(infos);
                setLounge(lounge);
                return lounge;
            } catch (e) {
                handleModal('Error on Connection', "red")
                console.log(`Error: ${(e as any).message}`)
                throw e
            }
        }
    }
    let disconnect = () => {
        handleModal('Connection lost, try to reconnect', "red");
        setConnected(false);

        updateUserInfos((prevIU: InfosUser) => ({
            ...prevIU,
            infosProfil: {
                ...prevIU.infosProfil,
                online: false,
            }
        }));

        lounge?.leave();
    }

    let updateOnlineFriends = () => {
        let friends = userInfos?.dataFriends;
        //console.log("updateOnlineFriends updateOnlineFriends");
        if (friends) lounge?.send("onlineFriends", { friends: friends });
    }

    const { post, response } = useRondaFetch();




    return { client, room, lounge, lobby, setLobby, setRoom, connect, disconnect, updateOnlineFriends };
};
