import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useUser } from '../../context/UserProvider';
import { InfosGame } from '../../data/InfosGame'
import Panel from '../../componement/Panel';

import YouTube from 'react-youtube';

import "./PanelHelp.css"
import { LocationState } from '../../data/CustomInterfaces';

type Props = {
    path?: string,
    animation?: boolean,
}

function PanelHelp({ path = "/home", animation = false }: Props) {

    const { t } = useTranslation();
    const userInfos = useUser()!;
    const navigate = useNavigate();

    const location = useLocation();
    const infosGame = (location.state as LocationState)?.infosGame;
    useEffect(() => {

        if (!infosGame) {
            navigate(path);
        }
    }, []);


    return (
        <Panel className='help' animation={animation} title={t('Help')} returnTo={path}>
            <div className='layer'>
                <div className='layerContainer'>
                    <YouTube
                        videoId={infosGame?.help}                  // defaults -> ''
                        className={"Youtube"}                // defaults -> ''
                        iframeClassName={"Youtube"}          // defaults -> ''
                        title={infosGame?.title}
                        opts={{
                            height: '210',
                            width: '100%',
                            playerVars: {
                                autoplay: 1,
                                rel: 0,
                                showinfo: 0,
                                modestbranding: 1,
                                controls: 0,
                                color: "red"
                            }
                        }}               // defaults -> ''
                    /*
                    loading={string}                  // defaults -> undefined
                    opts={obj}                        // defaults -> {}
                    onReady={func}                    // defaults -> noop
                    onPlay={func}                     // defaults -> noop
                    onPause={func}                    // defaults -> noop
                    onEnd={func}                      // defaults -> noop
                    onError={func}                    // defaults -> noop
                    onStateChange={func}              // defaults -> noop
                    onPlaybackRateChange={func}       // defaults -> noop
                    onPlaybackQualityChange={func}    // defaults -> noop
                    */
                    />
                </div>
            </div>
        </Panel>
    )
}

export default PanelHelp