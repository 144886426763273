import React, { useEffect, useState } from 'react'
import Panel from '../../componement/Panel'
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { LocationState } from '../../data/CustomInterfaces';

import "./PanelLog.css"
import DataManager from '../../manager/DataManager';
import useRondaFetch from '../../hook/useRondaFetch';

type Props = {
    animation?: boolean,
    path?: string;
}

function PanelLog({ animation = false, path = "" }: Props) {

    const { t } = useTranslation();

    let { id } = useParams();

    const { get, response } = useRondaFetch();

    const [log, setLog] = useState<Array<any>>([]);

    async function getLog(id: number) {
        await get('api/logs/' + id)

        if (response.ok) {
            if (response.data) {
                setLog(response.data);
                timeBegining = Number(response.data[0]?.timestamp);
            }
        }
    }



    useEffect(() => {
        getLog(Number(id));
    }, [id])

    let location = useLocation();
    const infosArchives = (location.state as LocationState)?.infosArchives;

    let timeBegining = 0;

    // if (log) {
    //     timeBegining = Number(infosArchives.log[0]?.timestamp);
    // }

    const formatNumber = (num: number) => {
        // Utilisation de padStart pour ajouter un zéro au besoin
        return String(num).padStart(2, '0');
    }

    const getPlayerName = (id: string) => {
        let player = infosArchives.results.find((user) => String(user?.index) === String(id));
        let name = undefined
        if (player) name = "<b style='color:" + DataManager.DATA_PLAYER_COLOR[player?.index] + "'>" + player?.profil?.name + "</b>";
        return name;
    }

    const getCardName = (id: string) => {
        let card = DataManager.CARDS_LIST[Number(id) - 1];
        let name = id;
        if (card) name = "<b class='cardnumber'>" + card?.metadata.number + "<i class='icon-" + t(card?.metadata.gender) + "'></i></b>";
        return name;
    }

    const getCardsName = (ids: string) => {
        let names = "";
        ids.split(',').forEach((id) => {
            names += getCardName(id) + ", ";
        })
        return names;
    }


    return (
        <>
            <Panel className='log' title={t('Log')} animation={animation} >
                <div className='layer'>
                    <div className='layerContainer'>
                        <div className='black'>
                            <ol>
                                {
                                    log.map((element, index) => {
                                        let timeValue = new Date(element?.timestamp - timeBegining);
                                        let timer = "+" + (timeValue.getSeconds()) + ":" + formatNumber(Math.floor(timeValue.getMilliseconds() / 10));
                                        let player = getPlayerName(element?.player);
                                        let card = getCardName(element?.value);
                                        let row;
                                        let event = element.event.split(".")[1];
                                        switch (event) {
                                            case "PLAYING_CARD":
                                                row = player + " " + t('played') + " " + card;
                                                break;
                                            case "DECLARE_RONDA":
                                                let benefitPlayer = getPlayerName(element?.value);
                                                row = (benefitPlayer)
                                                    ? player + " " + t('declared Ronda for') + " " + benefitPlayer
                                                    : row = player + " " + t("declared a Ronda");
                                                break;
                                            case "DECLARE_QUINZA":
                                                row = player + " " + t("declared a Quinza");
                                                break;
                                            case "PASS_PLAYER":
                                                row = player + " " + t("skipped the next turn");
                                                break;
                                            case "CHOOSE_GENDER":
                                                let gender = element?.value;
                                                row = player + " " + t("changed the game color to") + " " + gender;
                                                break;
                                            case "TAKING_CARD":
                                                row = player + " " + t("took the card") + " " + card;
                                                break;
                                            case "TAKING_CARDS":
                                                let cards = getCardsName(element?.value);
                                                row = player + " " + t("took cards from the deck:") + " " + cards;
                                                break;
                                            case "HIDING_CARD":
                                                row = player + " " + t("hid the card") + card + " " + t("in the deck");
                                                break;
                                            case "SAY_KDOUB":
                                                row = player + " " + t("denounces Kdoub");
                                                break;
                                            case "DECLARE_KDOUB":
                                                let values = String(element?.value).split(',');
                                                if (values.length > 1) {
                                                    row = player + " " + t("announces") + " " + values[0] + " " + t("cards") + " " + values[1];
                                                } else {
                                                    row = player + " " + t("announces") + " " + values[0] + " " + t("more");
                                                }
                                                break;
                                            case "DECLARE_TARTAK":
                                                row = player + " " + t("requests Tartak");
                                                break;
                                            case "DECLARE_MZAYDA":
                                                let valuesMzayda = String(element?.value).split(',');
                                                row = player + " " + t("declares") + " " + valuesMzayda[1] + " " + t("with the bid") + " " + valuesMzayda[0];
                                                break;
                                            case "DECLARE_GHNA":
                                                row = player + " " + t("announces Ghna of") + " " + element?.value;
                                                break;
                                            case "DECLARE_ATOUT":
                                                row = player + " " + t("declares the Trump") + " " + element?.value;
                                                break;
                                            case "CHANGE_ATOUT":
                                                row = player + " " + t("changes the Trump with the card") + " " + card;
                                                break;
                                            case "DECLARE_DOUBLE":
                                                row = player + " " + t("announces the Double");
                                                break;
                                            case "DECLARE_ANNONCE":
                                                row = player + " " + t("declares the announcement of") + " " + card;
                                                break;
                                            case "PLAY_CARD":
                                                row = player + " " + t("plays the card") + " " + card;
                                                break;
                                            case "SAY_DECLARE":
                                                row = player + " " + t("says") + " " + element?.value;
                                                break;



                                            case "DECLARE_CARTE_COPIE":
                                                let targetPlayer = getPlayerName(element?.value);
                                                row = player + " " + t("declare CarteCopie of") + " " + targetPlayer;
                                                break;
                                            case "DECLARE_SIGN":
                                                row = player + " " + t("choose sign");
                                                break;
                                            case "CHANGE_CARDS":
                                                row = player + " " + t("change the cards");
                                                break;


                                            case "REPLAY":
                                                row = player + " " + t("requests to replay");
                                                break;
                                            case "DELETE":
                                                row = player + " " + t("has been removed from the game");
                                                break;
                                            case "STATE_INITIATLISE":
                                                row = t("The game has started");
                                                break;
                                            case "STATE_END":
                                                row = t("The game has ended");
                                                break;
                                            case "LEAVE":
                                                row = player + " " + t("has left the game");
                                                break;
                                            case "SETSCORE":
                                                row = t("The scores of the game have been displayed to the players");
                                                break;
                                            case "POINT":
                                                row = player + " " + t("scored") + " " + element?.value + " " + t("points");
                                                break;
                                            case "TURN":
                                                row = t("It's") + " " + player + " " + t("turn to play");
                                                break;
                                            case "FINISHPARTY":
                                                row = t("You left the game before it ended");
                                                break;
                                        }

                                        if (row) {
                                            timeBegining = element?.timestamp;
                                            return <li key={"li" + index} dangerouslySetInnerHTML={{ __html: row + "<span>" + timer + "</span>" }} />;
                                        }
                                    })
                                }
                            </ol>
                        </div>
                    </div>
                </div>
            </Panel>
        </>
    )
}

export default PanelLog