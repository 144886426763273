import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Alert from '../../componement/Alert';

import "./AlertAchievement.css";
import { LocationState } from '../../data/CustomInterfaces';
import DataManager from '../../manager/DataManager';
import { log } from '../../manager/FirebaseManager';
import { InfosAchievement } from '../../data/InfosAchievement';

type Props = {}

function AlertAchievement({ }: Props) {
    const { t } = useTranslation();
    let navigate = useNavigate();

    //let userInfos = useUser()!;
    //let infos: InfosAchievement | undefined = userInfos.dataAchievements.find((ia) => ia.id == Number(id));

    let location = useLocation();
    const infosAchievement = (location.state as LocationState)?.infosAchievement;

    useEffect(() => {
        if (!infosAchievement)
            navigate(-1)
        return () => {

        }
    }, [infosAchievement])

    const share = (infosAchievement: InfosAchievement) => {
        //'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(urlDuVisuel)
        //window.open('https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdevelopers.facebook.com%2Fdocs%2Fplugins%2F&amp;src=sdkpreparse', 'Partager sur Facebook', 'width=600,height=400');

        log("share_achievement", { achievement: String(infosAchievement.id) })

        window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(infosAchievement.image), 'Partager sur Facebook', 'width=600,height=400');

    }

    return (
        <Alert title={t('Achievement')} className='AlertAchievement' callBack={() => navigate(-1)}>

            <div className='layer'>
                <div className='layerContainer'>
                    <span className='description'>{infosAchievement?.description}</span>
                    <div className='itemAchievement'>
                        <img src={infosAchievement?.image} onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = DataManager.DATA_PRELOAD_IMAGES[0];;
                        }} />
                    </div>
                    <a className='bouton bleu-green' onClick={() => share(infosAchievement)}>{t('Share')}</a>

                    <span className='shine'></span>

                </div>
            </div>
            <div className='perso'></div>


        </Alert >
    )
}

export default AlertAchievement