import { InfosModes } from "./InfosModes";
import { InfosParametres } from "./InfosParametres";

export class InfosRoom extends InfosModes {
    public name: string;
    public password: string;
    public profil: string;
    public country: number;

    public clients: number;
    public roomId: string;

    public constructor(details: any = null) {
        super(details);
        this.name = details?.name;
        this.password = details?.password ? details.password : "";
        this.profil = details?.profil;
        this.country = details?.country;

        this.clients = details?.clients;
        this.roomId = details?.roomId;

        this.multiplayer = true;

    }
}
