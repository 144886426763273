import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useUser, useUserUpdate } from '../../context/UserProvider';
import InfosProfil from '../../data/InfosProfil';

import { SoundManager } from '../../manager/SoundManager';
import Fleche from '../../componement/Fleche';
import ItemRank from './ItemRank';
import Panel from '../../componement/Panel'


import "./PanelRanking.css"
import useRondaFetch from '../../hook/useRondaFetch';
import { InfosUser } from '../../data/InfosUser';

type Props = {

}

function PanelRanking({ }: Props) {
    let navigate = useNavigate();

    const { t } = useTranslation();
    let { type } = useParams();

    let userInfos = useUser()!;
    let setUserInfos = useUserUpdate()!;

    const [data, setData] = useState<Array<InfosProfil>>(userInfos.dataRank);

    const tabs: Array<string> = ["topfriends", "top40"]
    const [loading, isLoading] = useState<boolean>(true);

    const tabsTranslated: Array<string> = ["Top Friends", "Top 40"]


    const [active, setActive] = useState<string>(tabs[1]);

    const Carousel = useRef<HTMLDivElement>(null)

    const { get, cache, response } = useRondaFetch();


    async function updateFriends() {
        await get('api/friends/');

        if (response.ok) {
            setUserInfos((prevIU: InfosUser) => ({
                ...prevIU,
                dataFriends: response.data
            }));
            setData(userInfos.dataFriends);
            isLoading(false);

        }
    }

    async function updateRank() {
        await get('api/profils/');

        if (response.ok) {
            setUserInfos((prevIU: InfosUser) => ({
                ...prevIU,
                dataRank: response.data
            }));
            setData(response.data);
            isLoading(false);

        }
    }


    function openTabs(tabsName: string) {

        setActive(tabsName);

        switch (tabsName) {
            default:
            case 'topfriends':
                updateFriends();
                break;
            case 'top40':
                updateRank();
                break;
        }
        SoundManager.play(SoundManager.BUTTON_CLICK);
        index = 0;
        Carousel.current!.scrollTop = 0;
    }

    useEffect(() => {
        if (type == undefined) type = tabs[1];
        openTabs(type);

        return () => {

        }
    }, [type])

    let index = 0;


    const handleClickProfil = (id: string) => navigate("/profil/" + id);
    const handleCarouselAction = (e: MouseEvent) => {
        e.preventDefault();

        //let indexRef = index;
        switch ((e.currentTarget as HTMLElement).id) {
            case "next":
                index += 1;
                break;
            case "prev":
                index -= 1;
                break;
            default:
                break;
        }

        if (index < 0) index = 0;
        Carousel.current!.scrollTop = (Carousel.current?.children.item(0)?.clientHeight!) * index;
        //console.log(index);
    }

    return (
        <Panel className='ranking' title={t('Ranking')} returnTo="/home">
            <ol className='onglet'>
                {
                    tabs.map((name: string, index: number) => {
                        return (
                            <li key={name} className={active === name ? "active" : ""} onClick={() => /*openTabs(name)*/ navigate('/ranking/' + name)}>{t(tabsTranslated[index])}</li>
                        );
                    })
                }
            </ol>
            <Fleche name="next" href="#" styleName="green" callBack={handleCarouselAction} > </Fleche>
            <Fleche name="prev" href="#" styleName="green" callBack={handleCarouselAction} direction="left"> </Fleche>

            <div className='layer'>
                <div className='layerContainer'>
                    {
                        <div ref={Carousel} className='rankScroll'>
                            {
                                (loading === true)
                                    ? <>
                                        <div className="dummyItem animated-background "></div>
                                        <div className="dummyItem animated-background "></div>
                                        <div className="dummyItem animated-background "></div>
                                        <div className="dummyItem animated-background "></div>
                                        <div className="dummyItem animated-background "></div>
                                    </>
                                    : (data.length != 0)
                                        ? data.map((infos: InfosProfil, index: number) => {
                                            return <ItemRank key={"ipr" + index} index={index + 1} infos={infos} callBack={handleClickProfil}></ItemRank>
                                        })
                                        : <div className="nodata">
                                            <div className="perso"></div>
                                            <span className="sousTitre">{t("No data available.")}</span>
                                        </div>
                            }
                        </div>
                    }
                </div>
            </div>
        </Panel>
    )
}

export default PanelRanking