import gsap from 'gsap';
import React, { useRef, useLayoutEffect, useState, useEffect } from 'react'

import { Coordinates } from '../../manager/CoordinatesManager';
import DataManager from '../../manager/DataManager';
import CircleProgressBar from '../../componement/CircleProgressBar';
import HUD from '../../games/core/HUD';
import "./HUDComponement.css";
import Game from '../../games/core/Game';

import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import IndicateurPremium from '../../componement/IndicateurPremium';
import IndicateurLatency from '../../componement/IndicateurLatency';

type Props = {
    hud: HUD;
    game: Game;
    deltaLeft?: number;
    deltaBottom?: number;
    children?: any;
}

export default function HUDComponement(props: Props) {

    const [coordinates, setCoordinates] = useState<Coordinates>(props.hud.coordinates);
    const [chrono, setChrono] = useState<number>(props.hud.chrono);
    const [focus, setFocus] = useState<boolean>(props.hud.activated);
    const [point, setPoint] = useState<number>(props.hud.point);

    const mainType: string = (props.hud.main) ? 'main' : 'stand';

    const { t } = useTranslation();
    let navigate = useNavigate();


    const infos = props.hud.player.infosPlayer;
    const color = DataManager.DATA_PLAYER_COLOR[props.hud.player.ID];

    const [flag] = useState<any>(DataManager.DATA_COUNTRIES.find((e) => e.code === infos?.country));

    const hudContainer = useRef<HTMLDivElement>(null);
    const ProgressBar = useRef<HTMLSpanElement>(null)

    useEffect(() => {
        if (infos.pauseEvent > 0)
            gsap.to(ProgressBar.current, { duration: 0.2, scaleX: (30 - infos.pauseEvent) / 30, transformOrigin: "0px 0px" });
    }, [infos.pauseEvent])

    useLayoutEffect(() => {
        gsap.from(hudContainer.current, { duration: 2, delay: 1 + (props.hud.id * 0.1), scale: 0, ease: "elastic.out(1, 0.3)" });
    }, [])

    useEffect(() => {
        props.hud.setChrono = setChrono;
        props.hud.setCoordinates = setCoordinates;
        props.hud.setFocus = setFocus;
        props.hud.setPoint = setPoint;
    }, [])


    function onSetCoordinates(pcoordinates: Coordinates): any {

        let top: number = pcoordinates.screenPosition.y;
        let bottom: number = props.deltaBottom ? props.deltaBottom : 20;
        let left: number = pcoordinates.screenPosition.x;

        let deltaLeft = props.deltaLeft ? props.deltaLeft : 0;

        if (left < 135 + deltaLeft) left = 135 + deltaLeft;


        if (props.hud.player.main) {
            return { bottom: bottom + 'px', left: left + 'px', transform: 'translate(-50%, 0%)' };
        } else {
            if (top < 120) top = 120;
            return { top: top + 'px', left: left + 'px', transform: 'translate(-50%, -200%)' };
        }
    }

    function onFocus(value: boolean): any {
        if (value)
            return "visible";
        else
            return "hidden";
    }

    return (

        <div ref={hudContainer} className={'hudContainer ' + mainType} style={onSetCoordinates(coordinates)}>
            <div className='hudContainerInterne' >

                <div className='hud' >
                    {
                        (infos.pauseEvent > 0) && <span ref={ProgressBar} className='pauseProgressBar'></span>
                    }
                    <IndicateurPremium id={infos.style}></IndicateurPremium>
                    <div className='buttons'>
                        {
                            !props.hud.player.main && <a className='bouton-icone red icon-report' onClick={() => navigate("report/" + infos.name)}></a>
                        }
                        {
                            <a className='bouton-icone bleu icon-profil' style={{ backgroundColor: color }} onClick={() => navigate('profil/' + infos.name)}></a>
                            //<a className='bouton-icone red icon-cancel' style={{ backgroundColor: color }} onClick={() => navigate('report/' + infos.name)}></a>
                        }
                        {
                            // props.hud.player.main && <a className='bouton-icone copper icon-chat' onClick={() => { }}></a>
                        }
                        {
                            infos.camera && <span className='bouton-icone copper circular icon-cam-on'></span>
                        }
                    </div>
                    <span className='imageContainer'>
                        {
                            infos.pause && <span className='bouton-icone yellow circular icon-pause'></span>
                        }
                        {
                            infos.disconnection && <span className='bouton-icone red circular icon-disc'></span>
                        }
                        <IndicateurLatency latency={infos.latency} multiplayer={props.game.multiplayer} />
                        {
                            infos.showbot &&
                            <span className='showbot' />
                        }
                        <span className="focus" style={{ borderColor: color, visibility: onFocus(focus) }}></span>
                        <img className="image" src={infos.img} onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = DataManager.DATA_PRELOAD_IMAGES[0];;
                        }} />

                    </span>
                    <span className="name cardige">
                        <span className="text">{infos.name}</span>
                        <img className='flag' src={flag?.flag_4x3}></img>

                        <span className="scoreContainer">
                            <svg className="icon" version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 242 242" width="100%" height="100%">
                                <path style={{ fill: DataManager.DATA_PLAYER_COLOR[props.hud.player.ID] }} d="m41.4 39.3h47.4l34.5-34.4 34.4 34.4h48.8v48.8l33.5 33.5-33.5 33.5v47.4h-47.4l-34.5 34.5-34.5-34.5h-48.7v-48.7l-33.5-33.5 33.5-33.6z" />
                                {/*<text className="score" fill="#ffffff" x="35%" y="65%">{infos.score}</text>*/}
                            </svg>
                            <span className="round" >{infos.win_in_serie}</span>
                        </span>
                    </span>
                    <span className="bonus cardige">{Math.floor(point) + ' ' + t('pts')}</span>

                    <CircleProgressBar color={color} percent={(chrono / props.hud.player.playingDelayTimer) * 100} />
                    <span className="level cardige" >{infos.level + ' ' + t('lvl')}</span>
                </div>
                {
                    props.children
                }
            </div>
        </div>

    )
}