
	export class InfosAssets
	{
		public url:string;
		public id:string;
		
		constructor(details?:any) {
				this.id = details?.id;
				this.url = details?.url;
			
		}
	}
