import React, { useEffect } from 'react'
import { CameraManager } from '../manager/CameraManager';
import { Scene, ArcRotateCamera } from "@babylonjs/core";
import { SoundManager } from '../manager/SoundManager';
import { log } from '../manager/FirebaseManager';

type Props = {
  className: string;
  scene: Scene;

}

const ToogleViews = (props: Props) => {
  const scene = props.scene;
  let cameraManager: CameraManager;

  function clickCameraManager() {
    if (scene) {
      if (cameraManager == null) cameraManager = new CameraManager(scene.getCameraByName("Camera") as ArcRotateCamera);
      cameraManager.switch();

      log("screen_view", { camera: cameraManager.views[CameraManager.index] })

      SoundManager.play(SoundManager.CAMERA_MOVE);

    }
  }
  return (
    <>
      <a className={props.className} onClick={() => clickCameraManager()}></a>
    </>
  )
}

export default ToogleViews