import React, { useEffect, useLayoutEffect, useRef } from 'react'
import InfosPlayer from '../../data/InfosPlayer'
import DataManager from '../../manager/DataManager';
import { TextManager } from '../../manager/TextManager';
import IndicateurLatency from '../../componement/IndicateurLatency';
import gsap from "gsap";
import "./IndicateurTournamentPlayer.css";
import IndicateurPremium from '../../componement/IndicateurPremium';

type Props = {
    infos: InfosPlayer,
    bet: number,
    reward: number,
    state: string,
    callBack?: Function,
    final?: boolean
}

function IndicateurTournamentPlayer({ bet, final, reward, state, infos, callBack }: Props) {


    let flag = (DataManager.DATA_COUNTRIES.find((e) => e.code === infos?.country));

    function getBetValue() {
        if (state == "end" && infos.result == "Win") {
            return reward;
        }
        else
            return bet;
    }

    function isDesactivated(state: string) {
        // if (state == "idle")
        //     return '';
        // else 
        if (state != "idle" && infos.result != "Win")
            return 'desactivate';
        else
            return '';
    }
    return (
        <div className={'indicateurTournamentPlayer ' + isDesactivated(state)} onClick={(e) => { if (callBack) callBack(infos.sessionId) }}>
            <IndicateurPremium id={infos.style}></IndicateurPremium>
            <span className={'result ' + infos.result} />

            <div className='imageContainer'>
                {
                    infos.disconnection && <span className='bouton-icone red icon-disc'></span>
                }
                <IndicateurLatency latency={infos.latency} multiplayer={true} />
                <span className="refrac"></span>

                <img className="image" src={infos.img} onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = DataManager.DATA_PRELOAD_IMAGES[0];;
                }} />
                {
                    infos.showbot &&
                    <span className='showbot' />
                }
                <img className='flag' src={flag?.flag_4x3}></img>

            </div>
            <span className='name'>{infos.name}</span>
            <span className='level'>{infos.level}</span>
            <span className={'bouton-bet '}>{TextManager.formatScore(getBetValue(), 7)}<span className={'iconCoin ' + ((final) ? 'buck' : '')} /></span>
        </div>
    )
}

export default IndicateurTournamentPlayer