import React, { Component, useLayoutEffect, useRef } from 'react'
import Fleche from './Fleche';
import gsap from 'gsap';

import { useNavigate } from "react-router-dom";
import ToogleDrag from './ToogleDrag';
import { SoundManager } from '../manager/SoundManager';
type Props = {
  title?: string;
  className?: string;
  children?: React.ReactNode;
  animation?: boolean,
  closeButton?: boolean,
  dragButton?: boolean,
  returnTo?: any,
}


export default function Panel({ title, children, returnTo = -1, closeButton = true, dragButton = true, animation = false, className }: Props) {

  let navigate = useNavigate();
  let Target = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    SoundManager.play(SoundManager.PANEL_SHOW);
    if (animation) {
      gsap.set(Target.current, {
        y: -1000
      });
      gsap.to(Target.current, {
        position: "absolute",
        duration: 1,
        y: 0,
        ease: "elastic.out(0.8, 0.8)",
      });
    }
    return () => {
      //SoundManager.play(SoundManager.PANEL_HIDE);
    };
  }, []);

  function closeMe() {
    SoundManager.play(SoundManager.PANEL_HIDE);

    if (animation) {
      gsap.to(Target.current, {
        ease: "elastic.out(0.7, 0.8)",
        duration: 1,
        y: 1000,
        onComplete: () => navigate(returnTo)
      });
    } else {
      navigate(returnTo);
    }
  }



  return (
    <>
      <div ref={Target} className={'panel ' + ((className != undefined) ? className : "")} >
        <div className='panelContainer'>
          <span className="titre">{title}</span>
          {closeButton && <a className='bouton-icone bleu icon-fermer' onClick={() => closeMe()}></a>}
          {dragButton && <ToogleDrag target={Target.current} className='bouton-icone green icon-glisser' />}

          {
            /**
             * 
             * 
             * 
                <a className='bouton-icone red icon-acheter'></a>
                <a className='bouton-icone green icon-agrandir'></a>
                <a className='bouton-icone violet icon-search'></a>
                <a className='bouton-icone bleu-green icon-aider'></a>
                <a className='bouton-icone yellow icon-classement'></a>
             * 
             * 
             * 
             */




            children
          }
        </div>
      </div>
    </>
  )
}
