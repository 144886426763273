import gsap from 'gsap';

import React, { ReactNode } from 'react'
import { Transition, TransitionGroup } from 'react-transition-group';

type Props = {
    id: string;

    children?: ReactNode
}

function TransitionHeader({ children, id }: Props) {


    const completeCall = (target: Node, parent: Node) => {
        gsap.set(target, { clearProps: "position," });
        parent && gsap.set(parent, { clearProps: "overflow" });
    };


    const onEnterHandler2 = (node: Node) => {
        if (node == null) return;
        gsap.killTweensOf(node);
        // Set initial position and styles
        gsap.set(node, {
            y: -100, opacity: 1
        });
        // Create the animation for the incoming component
        gsap.to(node, {
            ease: "power3.InOut",
            position: "absolute",
            onComplete: completeCall,
            onCompleteParams: [node],
            duration: 1, opacity: 1,
            y: 0
        });

    };

    const onExitHandler2 = (node: Node) => {
        if (node == null) return;
        gsap.killTweensOf(node);
        // Set initial position and styles
        gsap.set(node, {
            position: "absolute"
        });
        // Create the animation for the incoming component
        gsap.to(node, {
            ease: "power3.InOut",
            duration: 1, opacity: 1,

            y: -100
        });
    };
    return (
        <TransitionGroup component={null}>

            <Transition
                timeout={1000}
                key={id}
                onEnter={onEnterHandler2}
                onExit={onExitHandler2}
            >
                {
                    children
                }

            </Transition>
        </TransitionGroup>
    )
}

export default TransitionHeader