import { InfosSettings } from "./InfosSettings";

export default class InfosProfil {

    public rate: number;
    public bestrate: number;
    public achievements: number;
    public win: number;
    public coin: number;

    public bot: boolean;

    public rounds: number;

    public luck: number;
    public bonus: number;

    public rank: number;
    public premium: boolean;
    public confirmed: boolean;

    public buck: number;

    public style: number;
    public id: number;

    public level: number;
    public levelTitle: string;

    public online: boolean;
    notifiable: boolean;
    public block: boolean;


    public country: string;
    public password: string;
    public name: string;
    public img: string;
    public email: string;

    public friendshipStatus: string;

    public infosSettings: InfosSettings;

    public constructor(details?: any) {

        if (details != undefined) {
            this.id = details.id;
            this.name = details.name;
            this.img = details.img;
            this.rounds = details.rounds;
            this.levelTitle = details.levelTitle;
            this.coin = details.coin;
            this.buck = details.buck;
            this.win = details.win;
            this.achievements = details.achievements;
            this.notifiable = details.notifiable ? true : false;
            this.bot = details.bot ? true : false;
            this.level = details.level;
            this.rate = details.rate;

            this.bestrate = details.bestrate;


            this.bonus = details.bonus;
            this.luck = details.luck;

            this.rank = details.rank;

            this.block = details.block ? true : false;
            this.premium = details.premium ? true : false;
            this.confirmed = details.confirmed ? true : false;;

            this.online = details.online ? true : false;

            this.country = details.country;
            this.password = details.password;
            this.email = details.email;

            this.friendshipStatus = details.friendshipStatus;

            this.infosSettings = new InfosSettings(details.infosSettings);

            this.style = this.infosSettings.idInfosStyle ? this.infosSettings.idInfosStyle : 0;
            /**
             * 
             * id": 2,
                    "online": true,
                    "premium": true,
                    "coin": 8,
                    "win": 3,
                    "rate": 1200,
                    "buck": 2,
                    "rank": 3,
                    "rounds": 8,
                    "img": "/uploads/assets_games_game_15_a40b86b24d.png",
                    "country": "ax",
                    "name": "amine ",
                    "block": false,
                    "achievements": 1,
                    "email": "aminehsissi@msn.com",
                    "level": 12,
                    "levelTitle": "Wazir"
             * 
             */
        } else {
            this.id = 0;
            this.name = " ";
            this.img = "/assets/interfaces/mockup.png";
            this.rounds = 0;
            this.levelTitle = " ";
            this.coin = 0;
            this.buck = 0;
            this.win = 0;
            this.level = 0;
            this.achievements = 0;
            this.rate = 0;//				rate = details.rate;
            this.bestrate = 0
            this.bonus = 0;//bonus = details.bonus;
            this.luck = 0;
            this.bonus = 0;
            this.rank = 0;
            this.block = false;
            this.premium = false;
            this.confirmed = false;
            this.online = false;
            this.notifiable = false;
            this.country = "";
            this.password = "";
            this.email = "x@x.x";
            this.infosSettings = new InfosSettings();
            this.style = this.infosSettings.idInfosStyle ? this.infosSettings.idInfosStyle : 0;
            this.bot = false;
            this.friendshipStatus = "";

        }
    }
}