import React from 'react'
import ToogleMusic from './ToogleMusic'
import ToogleSound from './ToogleSound'
import "./TooltipSound.css"

type Props = {
    coordinates:DOMRect|undefined
}

function TooltipSound({coordinates}: Props) {
  return (
    <div className="soundToolTip" /*style={{top:coordinates?.top ,inset-inline-end:coordinates?.right}}*/>
      <span className="triangle"></span>
      <span className="triangleMosaique"></span>
      <ToogleSound className='bouton-icone bleu-green ' track="sfx"></ToogleSound>
      <ToogleMusic></ToogleMusic>
      {
       // <a className='bouton-icone bleu-green icon-parametre' onClick={ ()=>{} }></a>
      }
    </div>
  )
}

export default TooltipSound