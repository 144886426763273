import React, { useState } from 'react'
import Alert from '../../componement/Alert'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRondaModal } from '../../context/ModalProvider';

import "./AlertNotificationPermission.css";
import useRondaFetch from '../../hook/useRondaFetch';
import { SoundManager } from '../../manager/SoundManager';
import { fetchToken, log } from '../../manager/FirebaseManager';
import DataManager from '../../manager/DataManager';


type Props = {
    path?: string,
}

const AlertNotificationPermission = ({ path = "/" }: Props) => {
    const { t } = useTranslation();
    let navigate = useNavigate();

    //const { firebaseManager } = useFirebase();
    const { handleModal } = useRondaModal()!;

    const [close, setClose] = useState<boolean>(false);

    const { post, response } = useRondaFetch();


    async function setNotificationToken(notificationToken: string) {
        await post("api/setnotificationtoken/", {
            notificationToken
        });

        if (response.ok) {
            SoundManager.play(SoundManager.VALIDATE_STANDARD);
            handleModal("You have succefully grant permission for notfications", "green");
            setClose(true);
        }
    }

    return (
        <Alert title={t("Notification ?")} close={close} className='notificationpermission' callBack={() => {
            //navigate(path);
            setClose(true);
            DataManager.SHOW_NOTIFICATION = false;
            log("notification", { permission: "user declined" });
        }}>
            <div className='layer'>

                <div className='layerContainer'>
                    <span className="sousTitre">{t('Stay in the loop and enable notifications for game events!')}</span>
                    <a className='bouton small bleu-green' onClick={() => {
                        setClose(true);
                        DataManager.SHOW_NOTIFICATION = false;
                        log("notification", { permission: "user declined" });
                    }} >{t('No, thank you !')}</a>
                    <a className='bouton bleu' onClick={() =>
                        Notification.requestPermission().then((permission) => {

                            log("notification", { permission: permission })

                            if (permission === 'granted') {

                                const onResponse = (response: string, ok: boolean) => {
                                    if (ok) setNotificationToken(response + "")
                                    else handleModal(response, "red")
                                } //).catch((err) => handleModal(err, "red"))
                                fetchToken(onResponse)
                            } else {
                                handleModal("Permision Deniede", "red")
                            }
                        }
                        )} >{t('Enable now !')}</a>
                </div>



            </div>
            <div className='perso'></div>


        </Alert>
    )
}

export default AlertNotificationPermission