import React, { useEffect, useState } from 'react'
import Alert from '../../componement/Alert'
import { useTranslation } from 'react-i18next';

import "./AlertCheckOut.css";

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { TextManager } from '../../manager/TextManager';

import { SoundManager } from '../../manager/SoundManager';
import { useUser, useUserUpdate } from '../../context/UserProvider';
import { useRondaModal } from '../../context/ModalProvider';
import { InfosCoin } from '../../data/InfosCoin';
import useRondaFetch from '../../hook/useRondaFetch';
import { LocationState } from '../../data/CustomInterfaces';
import { PayPalButtons, PayPalScriptProvider, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import { InfosUser } from '../../data/InfosUser';
import DataManager from '../../manager/DataManager';
import i18next from 'i18next';

type Props = {
    path?: string
    moneyType?: string
}

function AlertCheckOut({ path = "", moneyType = "coin" }: Props) {
    const { t } = useTranslation();
    let navigate = useNavigate();
    let { type } = useParams();
    let location = useLocation();



    let userInfos = useUser()!;
    let setUserInfos = useUserUpdate()!;

    const [infos] = useState<InfosCoin>((location.state as LocationState)?.infosCoin);

    const { post, cache, response } = useRondaFetch();





    const { handleModal } = useRondaModal()!;


    useEffect(() => {
        if (!infos)
            navigate(path + "/store/" + type);
    }, [])

    let locale = i18next.language;

    const createOrder = async (data: any, actions: any) => {

        // Order is created on the server and the order id is returned
        //console.log("### createOrder ##################################");

        await post('api/createorder/', {
            infosCoin: infos,
            locale
        })

        if (response.ok) {
            //SoundManager.play(SoundManager.VALIDATE_STANDARD);
            //console.log("#### response #################################");
            //console.log(response.data);

            handleModal("Order created succefully", "green");

            return response.data.id;
        }

    };

    const onApprove = async (data: any, actions: any) => {

        await post('api/captureorder/', {
            orderID: data.orderID
        })

        if (response.ok) {



            const orderData = await response.data.order;
            // Three cases to handle:
            //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
            //   (2) Other non-recoverable errors -> Show a failure message
            //   (3) Successful transaction -> Show confirmation or thank you message
            const errorDetail = orderData?.details?.[0];
            if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                return actions.restart();
            } else if (errorDetail) {
                // (2) Other non-recoverable errors -> Show a failure message
                handleModal(`${errorDetail.description} (${orderData.debug_id})`, "red");
            } else if (!orderData.purchase_units) {
                handleModal(JSON.stringify(orderData), "red");
            } else {
                // (3) Successful transaction -> Show confirmation or thank you message
                // Or go to another URL:  actions.redirect('thank_you.html');
                const transaction =
                    orderData?.purchase_units?.[0]?.payments?.captures?.[0] ||
                    orderData?.purchase_units?.[0]?.payments?.authorizations?.[0];

                if (response.data.infosProfil) {
                    SoundManager.play(SoundManager.VALIDATE_STANDARD);

                    setUserInfos((prevIU: InfosUser) => ({
                        ...prevIU,
                        infosProfil: response.data.infosProfil,
                    }));

                    //handleModal(`Transaction ${transaction.status}: ${transaction.id}<br><br>See console for all available details`, "green");

                    //handleModal(`Congrats your ${transaction.status}: ${transaction.id} payment was successful !`, "green");

                    handleModal(`Congrats your| ${transaction.id} |payment was successful !`, "green");

                    navigate(path + "/store/" + type);

                }

                //console.log("Capture result", orderData, JSON.stringify(orderData, null, 2),);
            }
        }
    };

    const initialOptions = {
        clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID ? process.env.REACT_APP_PAYPAL_CLIENT_ID : "",
        currency: "USD",
        intent: "capture",
        locale: locale + "_MA"
    }

    return (
        <Alert title={t('Buy with Paypal')} className='checkout' callBack={() => navigate(path + "/store/" + type)}>

            <div className='layer'>
                <div className='layerContainer'>

                    <span className='description'>{t("Confirm the purchase !")}</span>


                    <span className={"itemStore " + moneyType}>
                        <img src={infos?.image} onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = DataManager.DATA_PRELOAD_IMAGES[0];;
                        }} />
                    </span>
                    <span className={"titre"}> {infos?.title}</span>
                    <span className={"sousTitre"}> {infos?.description}</span>


                    <a className={'bouton gold'} >{TextManager.formatScore(infos?.price) + " " + infos?.devise}</a>

                    <span className='shine'></span>

                </div>
            </div>
            <div className='layer paypal'>
                <PayPalScriptProvider options={initialOptions}>

                    <PayPalButtons

                        className='form'
                        style={{

                            layout: 'vertical',

                            color: 'gold',

                            shape: 'pill',

                            label: 'paypal',
                            tagline: false,
                            height: 30,

                        }}

                        createOrder={(data, actions) => createOrder(data, actions)}

                        onApprove={(data, actions) => onApprove(data, actions)}

                        onCancel={() => handleModal("You have just canceled the payment !", "red")}

                    />
                </PayPalScriptProvider>

            </div>

        </Alert>
    )
}

export default AlertCheckOut