import React, { useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useNavigate } from 'react-router-dom'
import InfosProfil from '../../data/InfosProfil'
import IndicateurPremium from '../../componement/IndicateurPremium'

import "./ItemFriends.css"
import TooltipInvite from '../../componement/TooltipInvite'
import { useRondaServer } from '../../context/ServerProvider'
import { useUser } from '../../context/UserProvider'
import { callbackify } from 'util'
import DataManager from '../../manager/DataManager'

type Props = {
  infos: InfosProfil;
  callBack: Function;
}

function ItemFriends({ infos, callBack }: Props) {

  const userInfos = useUser()!;

  let Target = useRef<HTMLDivElement>(null);
  let navigate = useNavigate();

  const [coordinates, setCoordinates] = useState(Target.current?.getBoundingClientRect());

  function onRollOver() {
    setCoordinates(Target.current?.getBoundingClientRect());

  }
  function onRollOut() {
    setCoordinates(undefined);
  }

  return (
    <div ref={Target} className='itemFriends' onMouseLeave={onRollOut} onMouseOver={onRollOver} >
      <div className='itemFriendsContainer' onClick={() => navigate("/profil/" + infos.name)}>

        <IndicateurPremium id={infos.infosSettings.idInfosStyle}></IndicateurPremium>

        <div className='profilContainer'>
          <span className={'online ' + ((infos.online) ? 'on' : 'off')}></span>
          <span className="refrac"></span>
          <img className="image" src={infos.img} onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = DataManager.DATA_PRELOAD_IMAGES[0];;
          }} />
        </div>
        <span className="name">{infos.name}</span>
      </div>
      {
        (document.getElementById("App-Container"))
        && createPortal(
          (coordinates)
            ? infos.online && <TooltipInvite coordinates={coordinates} title={infos.name} callBack={() => callBack(infos)}></TooltipInvite>
            : <></>
          , document.getElementById("App-Container")!)
      }
    </div>
  )
}

export default ItemFriends