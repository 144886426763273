import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Panel from '../../componement/Panel';
import { useRondaModal } from '../../context/ModalProvider';
import { useRondaServer } from '../../context/ServerProvider';
import { useUser } from '../../context/UserProvider';
import { LocationState } from '../../data/CustomInterfaces';
import { InfosGame } from '../../data/InfosGame';
import { InfosModes } from '../../data/InfosModes';
import InfosPlayer from '../../data/InfosPlayer';
import { InfosRoom } from '../../data/InfosRoom';
import DataManager from '../../manager/DataManager';
import { SoundManager } from '../../manager/SoundManager';
import IndicateurTournamentPlayer from './IndicateurTournamentPlayer';
import gsap from 'gsap';

import "./PanelTournament.css";
import { TextManager } from '../../manager/TextManager';

type Props = {}

interface State {
    event: string,
    card?: number
}

function PanelTournament({ }: Props) {

    let state = { event: "idle" };

    let navigate = useNavigate();
    let userInfos = useUser()!;
    let location = useLocation();
    let { invitation } = useParams();

    const { handleModal } = useRondaModal()!;

    const [infosGame, setInfosGame] = useState<InfosGame>((location.state as LocationState)?.infosGame);
    const [players, setPlayers] = useState<Array<InfosPlayer>>([]);

    const [groups, setGroups] = useState<Array<any>>([]);

    const [finalGroup, setFinalGroup] = useState<any>();

    const [semiFinalGroup, setSemiFinalGroup] = useState<any>();

    const mode: InfosModes = DataManager.DATA_MODES.find((obj: any) => obj.id === userInfos.infosProfil.infosSettings.idInfosTournamentMode)!;
    //console.log("mode " + mode.bet + " " + userInfos.infosProfil.infosSettings.idInfosTournamentMode)
    let infosRoom: InfosRoom = new InfosRoom(mode);

    const [bet, setBet] = useState<number>(infosRoom.bet);
    const [reward, setReward] = useState<number>(0);


    let values = { bet: infosRoom.bet, reward: 0 }

    const [dummy, setDummy] = useState<InfosPlayer>(DataManager.DUMMY_INFOSPLAYER);
    const { t } = useTranslation();
    let { client, room, setRoom, lobby, setLobby } = useRondaServer()!;

    const [finalReward, setFinalReward] = useState<number>(0);

    function refreshData() {
        updateData();
        //console.log("lobby?.state.state.event " + lobby?.state.state.event);

        // lobby?.state.groups.forEach((group: any) => {
        //     console.log("Group " + group.id);
        //     group.players.forEach((player: InfosPlayer) => {
        //         console.log("Player " + player.name + "  " + player.result);
        //     });
        // });
    }

    function updateData(state?: State) {
        //setPlayers([]);
        //setGroups([]);

        // let arr: Array<InfosPlayer> = [];
        // room?.state.players.forEach((element: any) => {
        //     arr.push(new InfosPlayer(element));
        // });
        // setPlayers([...arr]);
        state = lobby?.state.state;

        //console.log("lobby.state.state.event " + lobby?.state.state.event);

        setFinalGroup(lobby?.state.final);
        setSemiFinalGroup(lobby?.state.semiFinal);
        let groupsArr: Array<any> = [];
        lobby?.state.groups.forEach((element: any) => {
            groupsArr.push(element);
        });
        setGroups(groupsArr);


    }


    useEffect(() => {
        let isMounted = true;

        state = lobby?.state.state;

        setFinalReward(infosRoom.reward / lobby?.state.final.players.filter((p: InfosPlayer) => p.result == "Win").length);

        if (!infosGame && !invitation) {
            navigate('/home');
        } else if (infosRoom && userInfos.infosProfil.coin < infosRoom.bet) {
            navigate('/tournamentmode', { state: { infosGame: infosGame } });
        } else if (userInfos.infosProfil.id != 0) {
            if (infosGame) {
                init().then(() => {
                    setLobby(lobby);
                    if (isMounted) {
                        connectToLobby();
                    }

                })
            };
        }

        return () => {
            if (state?.event == "idle" || state?.event == "end") {
                lobby?.leave();
                setLobby(undefined);
            }
            room?.onError.remove(errorRoom);
            room?.onLeave.remove(leaveRoom);
            lobby?.onStateChange.remove(updateData);
            // clearTimeout(pingInterval);
            isMounted = false;
        }
    }, [])

    function errorRoom() {
        handleModal("Someting went wrong, you are exiting the Tournament Mode", "red", false)
    }

    function leaveRoom(code: number) {
        switch (code) {
            case 4001:
                handleModal("Time's Up ! You didn't play on time, you were kicked from the room", "red", false)
                break;
            default:
                handleModal("You are quitting the Tournament Mode", "green");
                break;
        }
    }

    function connectToLobby() {
        if (lobby == undefined) return;


        //refreshData();

        lobby.onStateChange.once(updateData);


        lobby.onStateChange(updateData);
        //(state: State) => {
        // let i = 0;
        // lobby?.state.players.forEach((player: any) => {
        //     //updateData();
        //     i++;
        // })
        // console.log(i + "  ####################")
        //   updateData();
        // });



        const update = (infosPlayer: any) => {
            SoundManager.play(SoundManager.EVENT_ADD_PLAYER);
            let arr: Array<InfosPlayer> = [];
            lobby?.state.players.forEach((element: any) => {
                arr.push(new InfosPlayer(element));
            });
            setPlayers(arr);
        }

        lobby.onMessage("ping", (timeStamp) => {
            lobby?.send("pong", timeStamp);
        });


        lobby.onMessage("state", (data: any) => {
            if (room) {
                room.leave();
                room = undefined;
                console.log("reset and creating new room ##### #### ###" + room)
                //setRoom(undefined);
            }
            if (data.state.event == "selection") {
                //console.log("##################### GAME SELECTION ############################");

                state = data.state;

                let r = infosRoom.reward;
                SoundManager.play(SoundManager.COIN_DOWN);

                gsap.to(values, {
                    duration: 1, bet: 0, reward: r, onComplete: () => {
                        setTimeout(() => {
                            processReservation(data.reservation);
                        }, 1000);
                    }
                    , onUpdate: () => { setReward(Math.round(values.reward)); setBet(Math.round(values.bet)) }
                });

            } else if (data.state.event == "semifinal") {
                state = data.state;
                //console.log("##################### GAME SEMIFINAL ############################");
                setTimeout(() => {
                    processReservation(data.reservation, "semifinal");
                }, 4000);
                updateData();

            } else if (data.state.event == "final") {
                state = data.state;
                //console.log("##################### GAME FINAL ############################");
                setTimeout(() => {
                    processReservation(data.reservation, "final");
                }, 8000);
                updateData();
            } else if (data.state.event == "end") {
                //console.log("##################### CALL THE WINNER ############################");

                state = data.state;
                updateData();
                setTimeout(() => {
                    navigate('/tournament', { state: { infosGame: infosGame }, replace: true });
                }, 8000);


            }
            console.log("################## " + state.event + " ###################");
        });

        lobby.state.players.onAdd = update;
        lobby.state.players.onRemove = update;

        //room.state.groups.onChange = updateData;


        lobby.onError(errorRoom);
        lobby.onLeave(leaveRoom);
    }

    function processReservation(reservation: any, type: string = "") {
        joinTheGame(reservation).then(() => {
            if (room == undefined) return;

            console.log("processReservation... " + room)
            let infosMode: InfosModes = new InfosModes(room.state.mode);
            setRoom(room);
            room.onStateChange.once((state) => {
                infosMode = new InfosModes(state.mode);
            });
            room.onMessage("ping", (timeStamp) => {
                room?.send("pong", timeStamp);
            });
            room.onMessage("state", (stateValue: State) => {
                if (stateValue.event == "start") {
                    console.log("state start... " + stateValue.event)
                    if (type == "final" || type == "semifinal") {
                        navigate('/tournament', { state: { infosGame: infosGame }, replace: true });

                        setTimeout(() => {
                            console.log("infosGame.name infosMode.type)" + infosGame.name + " " + infosMode.type + "  " + room);
                            console.log(room);
                            navigate("/" + infosGame.name + "", { state: { infosMode: infosMode } });
                        }, 4000);

                    } else
                        navigate("/" + infosGame.name + "", { state: { infosMode: infosMode } });

                }
            });
        })
        SoundManager.play(SoundManager.VALIDATE_STARTGAME);
    }

    async function joinTheGame(reservation: any) {
        try {
            if (room) {
                room.leave();
                room = undefined;
                console.log("reset and creating new room ##### #### ###" + room)
                //setRoom(undefined);
            }
            room = await client.consumeSeatReservation(reservation);
            return;
        } catch (e) {
            console.error("join error", e);
            //setLobby(undefined);
            //state.event = "timeUp";
            handleModal("Time's Up ! You didn't join the party on time, you are leaving the Tournament Mode", "red", false);
            //navigate("/home");
        }
    }

    function kickPlayer(sessionId: string) {
        SoundManager.play(SoundManager.EVENT_KICK_PLAYER);
        lobby?.send("kick", { sessionId: sessionId });
    }

    async function init() {

        try {
            if (lobby && lobby.state.mode.id == mode.id) {
                console.log(lobby)
                //connectToLobby();
                updateData();
                setBet(0);
                if (lobby.state.state.event != "end") setReward(infosRoom.reward);

            } else {
                lobby = await client.joinOrCreate<any>(infosGame.name + "Lobby", { game: infosGame, mode: infosRoom, infosPlayer: new InfosPlayer(userInfos.infosProfil), dummy: dummy });
            }
            return;
        } catch (e) {
            errorRoom();
            navigate('/home');
            console.log(`Error: ${(e as any).message}`)
            throw e
        }
    }
    return (
        <>
            <Panel className='tournament' title={t('Tournament')} returnTo="/home">
                <a className='bouton-icone red icon-recharger' onClick={() => { refreshData() }}></a>
                <div className='titleIcon' >
                    <img src={infosRoom.image} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = DataManager.DATA_PRELOAD_IMAGES[0];;
                    }} />
                    <div className="mode"></div>

                </div>
                <div className='tournamentContainer'>
                    <div className='vs calque'>
                        <span className='reward'>{TextManager.formatScore(reward)}<span className='iconBuck' /></span>
                    </div>
                </div>
                <div className='layer'>
                    <div className='layerContainer'>
                        {
                            (finalGroup && finalGroup.ready) &&
                            <div className='finalist'>
                                <div key={"G" + finalGroup.id} className={'groupPlayer ' + (finalGroup.ready ? "ready" : "")}>
                                    <span className={'index ' + finalGroup.state}>{t("FINAL")}</span>
                                    {
                                        finalGroup.players.map((infos: InfosPlayer, index: number) => {
                                            return <IndicateurTournamentPlayer key={infos.sessionId + "-" + index} callBack={kickPlayer} state={finalGroup.state} reward={finalReward} bet={bet} infos={infos} final={true} />
                                        })
                                    }
                                </div>
                            </div>
                        }
                        {

                            (semiFinalGroup && semiFinalGroup[0].ready) && <div className='semiFinalist'>
                                {

                                    semiFinalGroup.map((group: any, index: number) => {
                                        return <div key={"G" + group.id} className={'groupPlayer ' + (group.ready ? "ready" : "")}>
                                            <span className={'index ' + group.state}>{t("SEMI-FINAL") + " " + (group.id - 5)}</span>
                                            {
                                                group.players.map((infos: InfosPlayer, index: number) => {
                                                    return <IndicateurTournamentPlayer key={infos.sessionId + "-" + index} callBack={kickPlayer} state={group.state} reward={finalReward} bet={bet} infos={infos} />
                                                })
                                            }
                                        </div>
                                    })

                                }
                            </div>
                        }
                        <div className='containders'>
                            {

                                groups.map((group: any, index: number) => {
                                    return <div key={"G" + group.id} className={'groupPlayer ' + (group.ready ? "ready" : "")}>
                                        <span className={'index ' + group.state}>{t("GROUP") + " " + group.id}</span>
                                        {
                                            group.players.map((infos: InfosPlayer, index: number) => {
                                                return <IndicateurTournamentPlayer key={infos.sessionId + "-" + index} callBack={kickPlayer} state={group.state} reward={finalReward} bet={bet} infos={infos} />
                                            })
                                        }
                                    </div>
                                })

                            }
                        </div>
                    </div>
                </div>
                <a href='#' className={'bouton ' + ((reward == 0) ? "red" : "desactivate")} onClick={(e) => { navigate("/home"); }}>{t('Cancel the game')}</a>


            </Panel>
        </>
    )
}

export default PanelTournament