import DataManager from "../manager/DataManager";
import { InfosAchievement } from "./InfosAchievement";
import { InfosCardTheme } from "./InfosCardTheme";
import { InfosCoin } from "./InfosCoin";
import { InfosGame } from "./InfosGame";
import InfosPlayer from "./InfosPlayer";
import { InfosPowerUp } from "./InfosPowerUp";
import { InfosParametres } from "./InfosParametres";
import InfosProfil from "./InfosProfil";
import { InfosProperty } from "./InfosProperty";
import { InfosPreferences } from "./InfosPreferences";
import { InfosStyle } from "./InfosStyle";
import { InfosTheme } from "./InfosTheme";
import { InfosVoice } from "./InfosVoice";
import { InfosEmojis } from "./InfosEmojis";

export class InfosUser {

    public TOKEN: string = "";

    public infosParametres: InfosParametres;

    public infosProfil: InfosProfil;
    public infosPreferences: InfosPreferences;

    //public dataAchievements: Array<InfosAchievement>;
    //public dataMetrics: Array<InfosProperty>;

    public dataStyleThemes: Array<InfosStyle>;
    public dataCardThemes: Array<InfosCardTheme>;
    public dataSceneThemes: Array<InfosTheme>;

    public dataFriends: Array<InfosProfil>;
    public dataPowerUps: Array<InfosPowerUp>;
    public dataRank: Array<InfosProfil>;
    public dataGames: Array<InfosGame>;
    public dataCoins: Array<InfosCoin>;
    public dataVoices: Array<InfosVoice>;
    public dataEmojis: Array<InfosEmojis>;

    public dateBonus: Date;



    public constructor(details?: any) {
        if (details != undefined) {
            this.infosProfil = new InfosProfil(details.infosProfil);
            this.infosParametres = new InfosParametres(details.infosParametres);

            this.infosPreferences = new InfosPreferences(details.infosPreferences);

            this.dataRank = [];
            if (details.dataRank)
                for (let element of details.dataRank)
                    this.dataRank.push(new InfosProfil(element));

            this.dataFriends = [];
            if (details.dataFriends)
                for (let element of details.dataFriends)
                    this.dataFriends.push(new InfosProfil(element));

            this.dataCardThemes = [];
            if (details.dataCardThemes)
                for (let element of details.dataCardThemes)
                    this.dataCardThemes.push(new InfosCardTheme(element));

            this.dataSceneThemes = [];
            if (details.dataSceneThemes)
                for (let element of details.dataSceneThemes)
                    this.dataSceneThemes.push(new InfosTheme(element));

            this.dataPowerUps = [];
            if (details.dataPowerUps)
                for (let element of details.dataPowerUps)
                    this.dataPowerUps.push(new InfosPowerUp(element));

            this.dataGames = [];
            if (details.dataGames)
                for (let element of details.dataGames)
                    this.dataGames.push(new InfosGame(element));

            this.dataCoins = [];
            if (details.dataCoins)
                for (let element of details.dataCoins)
                    this.dataCoins.push(new InfosCoin(element));

            this.dataStyleThemes = [];
            if (details.dataStyleThemes)
                for (let element of details.dataStyleThemes)
                    this.dataStyleThemes.push(new InfosStyle(element));

            this.dataVoices = [];
            if (details.dataVoices)
                for (let element of details.dataVoices)
                    this.dataVoices.push(new InfosVoice(element));

            this.dataEmojis = [];
            if (details.dataEmojis)
                for (let element of details.dataEmojis)
                    this.dataEmojis.push(new InfosEmojis(element));



        } else {
            this.infosProfil = new InfosProfil();
            this.infosParametres = new InfosParametres();
            this.infosPreferences = new InfosPreferences();

            this.dataRank = [];
            this.dataFriends = [];
            this.dataCardThemes = [];
            this.dataSceneThemes = [];
            this.dataPowerUps = [];
            this.dataGames = [];
            this.dataCoins = [];
            this.dataStyleThemes = [];
            this.dataVoices = [];
            this.dataEmojis = [];

        }
        this.dateBonus = new Date();
    }


    get infosPlayer() {
        return new InfosPlayer(this.infosProfil);
    }

}