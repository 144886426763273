import React, { ReactNode, useState } from 'react'
import Tooltip from './Tooltip';

type Props = {
    validationType: string;
    messages: Array<message>;

    children?: ReactNode;
}

type message = {
    name: string,
    value: any
}

const IndicateurValidation = ({ validationType, messages, children }: Props) => {

    const [showToolTip, setShowToolTip] = useState(false);

    function onRollOver() {
        setShowToolTip(true);
    }

    function onRollOut() {
        setShowToolTip(false);
    }

    let msg;

    if (validationType)
        msg = messages.find(m => m.name === validationType)?.value;

    return (
        <i className={'icon-' + validationType} onMouseOver={onRollOver} onMouseLeave={onRollOut}>
            {
                (showToolTip && validationType != "") ? <Tooltip color={validationType}>
                    {
                        msg
                    }
                </Tooltip> : <></>
            }
        </i>)
}

export default IndicateurValidation