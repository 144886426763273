import { t } from 'i18next'
import React from 'react'
import InfosProfil from '../../data/InfosProfil'
import DataManager from '../../manager/DataManager'

import "./ItemPendingInvitation.css"

type Props = {
    infos: InfosProfil
    callBack: Function
}

function ItemPendingInvitation({ infos, callBack }: Props) {

    let flag = (DataManager.DATA_COUNTRIES.find((e) => e.code === infos?.country));

    return (
        <div className='itemPendingInvitation'>
            <div className='itemPendingInvitationContainer'>


                <span className='imageContainer'>
                    <span className={'online ' + ((infos.online) ? 'on' : 'off')}></span>
                    <img className="image" src={infos.img} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = DataManager.DATA_PRELOAD_IMAGES[0];;
                    }} />
                    <img className='flag' src={flag!.flag_4x3}></img>
                </span>

                <span className='name'>{infos.name}</span>

                <div className='vr'></div>
                <a className={'bouton small bleu simple'} onClick={() => callBack(true, infos)}>{t("Confirm")}</a>
                <div className='vr'></div>
                <a className={'bouton small red simple'} onClick={() => callBack(false, infos)}>{t("Ignore")}</a>


            </div>
        </div>
    )
}

export default ItemPendingInvitation