import { InfosStore } from "./InfosStore";

export class InfosEmojis extends InfosStore {

    public set: Array<any>;
    public static parse: any;

    public constructor(details: any = null) {
        super(details);
        this.set = [];
        if (details.set)
            for (let element of details.set) {
                this.set.push({ image: element });
                //console.log(element)
            }
        this.infinite = false;
    }

}
