import React, { useState } from 'react'
import { DefaultRenderingPipeline, Light, Nullable, Scene } from "@babylonjs/core";
import DataManager from '../manager/DataManager';
import { SoundManager } from '../manager/SoundManager';
import { log } from '../manager/FirebaseManager';

type Props = {
    className: string;
    scene: Scene;
}


const ToogleResolution = (props: Props) => {

    const [resolution, setResolution] = useState(DataManager.RESOLUTION);

    let light: Nullable<Light>;

    light = props.scene.getLightByName("ShadowLight");

    let engine = props.scene.getEngine();
    let pipeline = props.scene.postProcessRenderPipelineManager.supportedPipelines.find(p => p.name === 'default') as DefaultRenderingPipeline;


    function clickResolutionManager() {
        DataManager.RESOLUTION = (resolution == 'hd') ? 'sd' : 'hd';
        setResolution(DataManager.RESOLUTION);
        SoundManager.play(SoundManager.CAMERA_MOVE);
        log("screen_resolution", { resolution: DataManager.RESOLUTION })

    }

    if (resolution == 'hd') {
        if (light) light.shadowEnabled = true;
        engine.setHardwareScalingLevel(0.6);
        if (pipeline?.isSupported) {
            pipeline.glowLayerEnabled = true;
            pipeline.depthOfFieldEnabled = true;
            pipeline.sharpenEnabled = true;
        }
    } else {
        if (light) light.shadowEnabled = false;
        engine.setHardwareScalingLevel(1);

        if (pipeline?.isSupported) {
            pipeline.glowLayerEnabled = false;
            pipeline.depthOfFieldEnabled = false;
            pipeline.sharpenEnabled = false;
        }
    }

    return (
        <a className={props.className + ' icon-' + resolution} onClick={() => clickResolutionManager()}></a>
    )

}

export default ToogleResolution