import React, { createContext, useContext, ReactNode } from "react";
import { IBeforeInstallPromptEvent, useAddToHomescreenPrompt } from '../hook/useAddToHomescreenPrompt';

interface PromptContextProps {
    prompt: IBeforeInstallPromptEvent | null;
    promptToInstall: () => void;
}

const PromptContext = createContext<PromptContextProps | undefined>(undefined);

export function useMainScreenPrompt() {
    return useContext(PromptContext);
}

type Props = {
    children: ReactNode;
}

let PromptProvider = ({ children }: Props) => {
    let [prompt, promptToInstall] = useAddToHomescreenPrompt();

    return (
        <PromptContext.Provider value={{ prompt, promptToInstall }}>
            {children}
        </PromptContext.Provider>
    );
};

export { PromptContext, PromptProvider };
