import React from 'react'
import InfosProfil from '../../data/InfosProfil'
import IndicateurPremium from '../../componement/IndicateurPremium'

import "./ItemArchives.css"
import DataManager from '../../manager/DataManager'
import { InfosArchives } from '../../data/InfosArchives'

type Props = {
  index: number
  infos: InfosArchives
  self: boolean
  handleClickProfil: Function,
  handleClickLog: Function
}





function ItemArchives({ index, infos, self, handleClickProfil, handleClickLog }: Props) {

  const getFlag = (country: string) => (DataManager.DATA_COUNTRIES.find((e) => e.code === country));

  return (
    <div className='itemArchives'>
      <div className='itemArchivesContainer'>
        <span className='index'>{infos.date.getDate() + "/" + infos.date.getMonth()}</span>
        <img className="gameImage" src={infos.game.image} />
        <div className='vr'></div>

        <img className="modeImage" src={infos.mode.image} />
        <span className={"modeType " + infos.mode.type} />

        {
          infos?.results.map((infosResults: any) => {
            return <>
              <div className='vr'></div>
              <span className='imageContainer' onClick={() => { handleClickProfil(infosResults.profil?.name) }} >
                {/* <span className={'online ' + ((infosResults.profil?.online) ? 'on' : 'off')}></span> */}
                <img className="image" src={infosResults.profil?.img} onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = DataManager.DATA_PRELOAD_IMAGES[0];;
                }} />
                {/* <img className='flag' src={getFlag(infosResults.profil.country ? infosResults.profil.country : "")!.flag_4x3}></img> */}
              </span>
              <span className={'Results ' + infosResults.result}>{infosResults.score}</span>
            </>
          })
        }
        <div className='vr'></div>
        {
          infos.log ? <a className='bouton-icone green icon-log' onClick={() => { handleClickLog(infos) }}></a>
            : <div className='spacer'></div>
        }
        <div className='vr right'></div>

        {
          (infos.mode.type != "Friendly") && <span className='Coin'><span className='ico' />{"-" + infos.mode.bet}</span>
        }
        <div className='vr right'></div>

        {
          (infos.mode.reward != 0 && self) && <span className='Buck'><span className='ico' />{"+" + infos.mode.reward}</span>

        }

      </div>
    </div>
  )
}

export default ItemArchives