import React, { useRef } from 'react'
import IndicateurPremium from '../../componement/IndicateurPremium'

import "./ItemRoom.css"
import DataManager from '../../manager/DataManager'
import { InfosRoom } from '../../data/InfosRoom'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { SoundManager } from '../../manager/SoundManager'

type Props = {
  index: number
  infos: InfosRoom
  callBack: Function
}



function ItemRoom({ index, infos, callBack }: Props) {

  const { t } = useTranslation();
  const navigate = useNavigate();

  const flag = (DataManager.DATA_COUNTRIES.find((e) => e.code === infos?.country));
  const passwordRef = useRef<HTMLInputElement | null>(null);

  function checkPassword() {
    if ((passwordRef.current as HTMLInputElement).value == infos.password) {
      callBack(infos);
      SoundManager.play(SoundManager.VALIDATE_STANDARD);
    } else {
      SoundManager.play(SoundManager.CANCEL_DISMISS);
    }
  }
  return (
    <div className='itemRoom'>
      <div className='itemRoomContainer'>
        <span className='index'>{index}</span>



        <span className='name'>{infos.name}         <img className='flag' src={flag?.flag_4x3}></img>
        </span>

        <a className='bouton-icone bleu icon-profil' onClick={() => { navigate("/profil/" + infos.profil) }}></a>




        <div className='vr'></div>
        <span className='Players'><span className='ico' />{infos.clients + "/" + infos.numberOfPlayers}</span>
        <div className='vr'></div>
        <span className='Rounds'><span className='ico' />{infos.numberOfRounds}</span>
        <div className='vr'></div>


        <label className='checkBox' >
          <input type="checkbox" disabled={true} defaultChecked={infos.cheatMode} />
          <span className="knob">{t('Special')}</span>
          <span className="slideDeck"></span>
        </label>

        <div className='vr'></div>
        {
          ((infos.password != "")
            ? <span className='Password'>
              <input ref={passwordRef} className='inputPassword' type="input" maxLength={4} placeholder={"••••"} defaultValue={""} ></input>
              <a className='bouton-icone red icon-password' onClick={() => { checkPassword(); }}></a>
            </span>
            : <a className='bouton small red simple' onClick={() => { callBack(infos) }}>{t('Join')}</a>)
        }


      </div>
    </div>
  )
}

export default ItemRoom