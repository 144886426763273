import React, { useLayoutEffect, useRef } from 'react'
import gsap from "gsap";

import "./Indicateur.css";
import { getLevelFromRate, getPercentFromRate } from '../manager/Utils';

type Props = {
  rate: number;
}


function IndicateurLevel({ rate }: Props) {


  const Slide = useRef<HTMLSpanElement>(null)

  let level = getLevelFromRate(rate);
  let percent = getPercentFromRate(rate);





  useLayoutEffect(() => {
    gsap.set(Slide.current, { scaleX: percent / 100, transformOrigin: "0px 0px" });
    return () => {
    };
  }, []);


  return (
    <div className='indicateur level'>
      <span ref={Slide} className='slide' />
      <span className='percent'>{percent + "%"}</span>
      <span className='iconLevel'>{level}</span>
    </div>
  )
}

export default IndicateurLevel