import React, { ReactEventHandler, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { InfosStore } from '../../data/InfosStore'
import { TextManager } from '../../manager/TextManager'

import "./ItemStore.css"
import TooltipStore from '../../componement/TooltipStore'

import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { useUser } from '../../context/UserProvider'
import DataManager from '../../manager/DataManager'
import { log } from '../../manager/FirebaseManager'

type Props = {
    type: string;
    infos: InfosStore;
    callBack: Function;
    useButton?: boolean,
}

function ItemStore({ useButton = true, type, infos, callBack }: Props) {
    let navigate = useNavigate();
    let { t } = useTranslation();

    let Target = useRef<HTMLSpanElement>(null);
    let userInfos = useUser()!;

    let canBuy: boolean = true;

    const [coordinates, setCoordinates] = useState(Target.current?.getBoundingClientRect());

    if (userInfos.infosProfil.coin < infos.price) {
        canBuy = false;
    }

    function onClick() {
        if (canBuy) callBack(infos);
    }
    function onRollOver() {
        setCoordinates(Target.current?.getBoundingClientRect());

    }

    function onRollOut() {
        setCoordinates(undefined);
    }

    //((infos.constructor) == InfosCoin)

    return (
        <>
            <span ref={Target} className="itemStore" onMouseOver={onRollOver} onMouseLeave={onRollOut} >
                {
                    (infos.infinite || infos.count <= 0)
                        ? <a className={'bouton-store green ' + (canBuy ? "" : "desactivate")} onClick={() => onClick()}>{TextManager.formatScore(infos.price, 7)}<span className='iconCoin' /></a>
                        : (useButton) ? <a className='bouton small blue simple' onClick={() => {
                            navigate('/options');
                            log("use_item", { type: type })
                        }}>{t('Use')}</a> : <></>
                }
                <img src={infos.image} onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = DataManager.DATA_PRELOAD_IMAGES[0];;
                }} />
                {
                    (infos.infinite)
                        ? <span className='count' style={{ display: (infos.count) ? 'block' : 'none' }}>{infos.count}</span>
                        : <></>
                }
                {
                    (document.getElementById("App-Container")!)
                        ? createPortal((coordinates) ? <TooltipStore coordinates={coordinates} message={infos.description} title={infos.title} ></TooltipStore> : <></>, document.getElementById("App-Container")!)
                        : <></>
                }
            </span>


        </>
    )
}

export default ItemStore