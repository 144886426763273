import React, { useEffect, useLayoutEffect, useRef } from 'react'
import InfosPlayer from '../data/InfosPlayer'
import DataManager from '../manager/DataManager';
import { TextManager } from '../manager/TextManager';
import IndicateurLatency from './IndicateurLatency';
import gsap from "gsap";
import "./IndicateurPlayer.css";
import IndicateurPremium from './IndicateurPremium';
import { getPercentFromRate } from '../manager/Utils';

type Props = {
    infos: InfosPlayer,
    bet: number
}

function IndicateurPlayer({ bet, infos }: Props) {

    let percent = getPercentFromRate(infos.rate);

    let flag = (DataManager.DATA_COUNTRIES.find((e) => e.code === infos?.country));


    const Slide = useRef<HTMLSpanElement>(null)
    useLayoutEffect(() => {
        gsap.set(Slide.current, { scaleX: percent / 100, transformOrigin: "0px 0px" });
        return () => {
        };
    }, []);
    return (
        <div className='indicateurPlayer'>
            <IndicateurPremium id={infos.style}></IndicateurPremium>

            <div className='imageContainer'>
                <IndicateurLatency latency={infos.latency} multiplayer={true} />
                <span className="refrac"></span>

                <img className="image" src={infos.img} onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = DataManager.DATA_PRELOAD_IMAGES[0];;

                }} />
                {
                    infos.showbot &&
                    <span className='showbot' />
                }
                <img className='flag' src={flag!.flag_4x3}></img>
                <span className='level'>{infos.level}</span>
                <span className='percent'>{percent + "%"}</span>
                <span ref={Slide} className='slide' />
            </div>
            <span className='name'>{infos.name}</span>

            <span className={'bouton-bet '}>{TextManager.formatScore(bet, 7)}<span className='iconCoin' /></span>
        </div>
    )
}

export default IndicateurPlayer