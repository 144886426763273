import React from 'react'
import DataManager from '../manager/DataManager';
import "./TooltipElement.css"

import { useTranslation } from 'react-i18next';
import { useUser, useUserUpdate } from '../context/UserProvider';


type Props = {
    title: string
    data: Array<any>
    callBack: Function
}

function TooltipElement({ title, data, callBack }: Props) {
    const { t } = useTranslation();

    function sizeCSS(lng: number) {
        if (lng < 12) {
            return "small";
        } else if (lng >= 12 && lng <= 28) {
            return "";
        } else if (lng > 28 && lng <= 40) {
            return "medium";
        } else if (lng > 40) {
            return "big";
        }
    }

    return (
        <div className={"smileyToolTip " + sizeCSS(data.length)} /*style={{top:coordinates?.top ,inset-inline-end:coordinates?.right}}*/>
            <span className="triangle" />
            <span className="triangleMosaique"></span>
            <span className='titre'>{t(title)}</span>
            <div className='smileyContainer'>
                {
                    data.map((infos: any, index: number) => {
                        return <img className={"element"} onClick={() => callBack(infos)} src={infos.image} />
                    })
                }
            </div>
        </div>
    )
}

export default TooltipElement