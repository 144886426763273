import React, { useState } from 'react'
import { SoundManager } from '../manager/SoundManager';
import Fleche from './Fleche'

type Props = {}

function ToogleMusic({}: Props) {
    const [sound, setSound] = useState(SoundManager.muteMusic);
    const [loop, setLoop] = useState(SoundManager.isLoop);

    

    function clickSoundManager(){
        SoundManager.muteMusic = !SoundManager.muteMusic;
        setSound(SoundManager.muteMusic);
        SoundManager.play(SoundManager.CAMERA_MOVE);
    }

    function clickLoop(){
        SoundManager.isLoop = !SoundManager.isLoop;
        setLoop(SoundManager.isLoop)
        SoundManager.play(SoundManager.CAMERA_MOVE);
    }


  return (
      <>
      <Fleche href="#" styleName="bleue" callBack={()=>SoundManager.switchMusic(-1)}></Fleche>
      <a className={'bouton-icone bleu icon-music'+((sound)?"":"-on")} onClick={clickSoundManager} ></a>
      <Fleche href="#" styleName="bleue" direction="left" callBack={()=>SoundManager.switchMusic(1)}></Fleche>
      <a className={'bouton-icone bleu-green icon-loop'+((loop)?"":"-on")} onClick={clickLoop}></a>  
      </>
    )
}

export default ToogleMusic