import { InfosGame } from "./InfosGame";
import { InfosModes } from "./InfosModes";
import InfosProfil from "./InfosProfil";

export class InfosArchives {
    public id: number;
    public users: Array<InfosProfil> = [];
    public date: Date;
    public mode: InfosModes;
    public log: number;
    public results: Array<any> = [];

    public game: InfosGame;
    constructor(details?: any) {
        this.id = details?.id;
        this.date = details.date ? new Date(details.date) : new Date();
        this.mode = details.mode ? new InfosModes(details.mode) : new InfosModes();
        this.log = details?.log;
        this.game = new InfosGame(details?.game);

        details?.users.forEach((element: any) => {
            this.users.push(new InfosProfil(element.profil));
        });

        details?.users.forEach((element: any) => {
            this.results.push(element);
        });
    }
}
