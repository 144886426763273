import React, { useEffect, useState } from 'react'
import Panel from '../../componement/Panel'
import { useTranslation } from 'react-i18next';

import "./PanelStore.css"
import Stepper from '../../componement/Stepper';
import DataManager from '../../manager/DataManager';
import { InfosStore } from '../../data/InfosStore';
import ItemStore from './ItemStore';
import { SoundManager } from '../../manager/SoundManager';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useUser, useUserUpdate } from '../../context/UserProvider';
import ItemCoin from './ItemCoin';
import { InfosCoin } from '../../data/InfosCoin';

type Props = {
    animation?: boolean,
    path?: string,
    useButton?: boolean
}

function PanelStore({ useButton = false, animation = false, path = "" }: Props) {

    const { t } = useTranslation();
    let { type } = useParams();
    let navigate = useNavigate();

    let userInfos = useUser()!;
    let setUserInfos = useUserUpdate();

    const onGetItem = (id: number) => { }
    const [data, setData] = useState<Array<InfosStore>>(userInfos.dataPowerUps);
    const tabs: Array<string> = ["coins", "emojis", "voices", "styles", "themes", "cards-themes", "boosters"]
    const tabsTranslated: Array<string> = ["Coins & bucks", "Emojis", "Voices", "Styles", "Themes", "Cards Themes", "Boosters"]
    const [active, setActive] = useState<string>(tabs[tabs.length - 1]);


    let location = useLocation();

    function openTabs(tabsName: string) {
        setActive(tabsName);
        switch (tabsName) {
            case 'boosters':
                setData(userInfos.dataPowerUps);
                break;
            case 'themes':
                setData(userInfos.dataSceneThemes);
                break;
            case 'cards-themes':
                setData(userInfos.dataCardThemes);
                break;
            case 'styles':
                setData(userInfos.dataStyleThemes);
                break;
            case 'voices':
                setData(userInfos.dataVoices);
                break;
            case 'emojis':
                setData(userInfos.dataEmojis);
                break;
            case 'coins':
                setData(userInfos.dataCoins);
                break;
            default:
                setData(userInfos.dataPowerUps);
                break;
        }
        SoundManager.play(SoundManager.BUTTON_CLICK);
    }

    useEffect(() => {
        if (!type) {
            type = tabs[tabs.length - 1];
        }
        openTabs(type);
        return () => {
        }
    }, [type, userInfos])


    let returnTo = path
    if (returnTo == "") returnTo = "/home"

    return (
        <>
            <Panel className='store' title={t('Store')} animation={animation} returnTo={returnTo}>
                <ol className='onglet'>
                    {tabs.map((name: string, index: number) => {
                        return (
                            <li key={name} className={active === name ? "active" : ""} onClick={() => /*openTabs(name)*/ navigate(path + "/store/" + name)}>{t(tabsTranslated[index])}</li>
                        );
                    })}
                </ol>
                <div className='layer'>
                    <div className='layerContainer'>
                        {
                            <Stepper className="store " initial={0} max={8} step={4.78} getID={onGetItem} >
                                {
                                    (active == "coins")
                                        ? data.map((infos: InfosStore, index: number) => {
                                            return <ItemCoin key={"ipu" + index} infos={infos as InfosCoin}></ItemCoin>
                                        })
                                        : data.map((infos: InfosStore, index: number) => {
                                            return <ItemStore key={"ipu" + index} useButton={useButton} type={active} infos={infos} callBack={(infos: InfosStore) => navigate(path + "/store/" + active + "/" + infos.id)}></ItemStore>
                                        })
                                }
                            </Stepper>
                        }
                    </div>
                </div>
            </Panel>
            <Outlet />

        </>
    )
}

export default PanelStore