import React, { createContext, useContext, ReactNode } from "react";
import LoadingScreen from "../componement/LoadingScreen";
import useLoader from "../hook/useLoader";



const LoaderContext = createContext<{ type: string, triggerLoader: boolean, handleLoader: (triggerLoader: boolean, type?: string) => void } | undefined>(undefined);


export function useRondaLoader() {
    return useContext(LoaderContext);
}


type Props = {
    children: ReactNode;
}

let LoaderProvider = ({ children }: Props) => {
    let { type, triggerLoader, handleLoader } = useLoader();

    return (
        <LoaderContext.Provider value={{ triggerLoader, type, handleLoader }}>
            <LoadingScreen type={type} trigger={triggerLoader} />
            {children}
        </LoaderContext.Provider>
    );
};

export { LoaderProvider };
