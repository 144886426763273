import React, { Suspense } from 'react';
import ReactDOM from "react-dom/client";
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import LoadingScreen from './componement/LoadingScreen';
import UserProvider from './context/UserProvider';
import DataProvider from './context/DataProvider';
import { ModalProvider } from './context/ModalProvider';
import { LoaderProvider } from './context/LoaderProvider';
import { ServerProvider } from './context/ServerProvider';
import { MessengerProvider } from './context/MessengerProvider';
import { PropertyProvider } from './context/PropertyProvider';
import InitLocalization from './InitLocalization';
import ServiceWorkerManager from './manager/ServiceWorkerManager';
import { PromptProvider } from './context/PromptProvider';

//const App = React.lazy(() => { return import('./App')});


const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <BrowserRouter>
    <PromptProvider>
      <Suspense fallback={<LoadingScreen />}>
        <LoaderProvider>
          <DataProvider >
            <InitLocalization>
              <ModalProvider>
                <UserProvider>
                  <MessengerProvider>
                    <ServerProvider>
                      <App />
                    </ServerProvider>
                  </MessengerProvider>
                </UserProvider>
              </ModalProvider>
            </InitLocalization>
          </DataProvider>
        </LoaderProvider>
      </Suspense>
    </PromptProvider>
  </BrowserRouter>

);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
ServiceWorkerManager();