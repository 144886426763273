import { useRondaModal } from "../context/ModalProvider";
import { useUser } from "../context/UserProvider";
import { InfosUser } from "../data/InfosUser";
import useRondaFetch from "./useRondaFetch";

export default function useProperty() {
    let userInfos: InfosUser = useUser()!;
    const { handleModal } = useRondaModal()!;

    const { post, response } = useRondaFetch();

    const updateProperty = (propertyName: string, value?: number) => {

        if (userInfos.infosProfil.id == 0) return;

        let property = propertyName//userInfos.dataMetrics.find((ip) => ip.name === propertyName)?.id;

        post("api/updateproperty", {
            property, value
        })

        if (response.ok) {
            handleModal("Property Updated", "green");
        }
    }


    return { updateProperty };
};
