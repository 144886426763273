import React, { useRef, useState } from 'react'
import { InfosEmojis } from '../data/InfosEmojis';
import DataManager from '../manager/DataManager';

import "./ToogleEmoji.css";
import TooltipElement from './TooltipElement';
import { useUser } from '../context/UserProvider';


type Props = {
    infosEmoji: InfosEmojis;
    useTooltip?: boolean;
    callBack?: Function;
}

function ToogleEmoji({ infosEmoji, useTooltip, callBack }: Props) {

    let Target = useRef<HTMLAnchorElement>(null);

    const [showToolTip, setShowToolTip] = useState(false);


    function onRollOver() {
        if (useTooltip)
            setShowToolTip(true);
    }

    function onRollOut() {
        setShowToolTip(false);
    }

    function clickEmojiManager(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {

        if (callBack != undefined)
            callBack(infosEmoji);

    }
    let userInfos = useUser()!;

    return (
        <>
            <div className='toogleEmoji' onMouseOver={onRollOver} onMouseLeave={onRollOut} >

                <a ref={Target} href='#' className='bouton-style' onClick={clickEmojiManager}>
                    <span />
                    <img src={(infosEmoji?.image)} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = DataManager.DATA_PRELOAD_IMAGES[0];
                    }} />

                </a>
                {

                    ((showToolTip && useTooltip)) ? <TooltipElement title={"Emojis"} data={userInfos.dataEmojis.filter((i: InfosEmojis) => { return i.count > 0 })} callBack={callBack!}></TooltipElement> : <></>
                }

            </div>

        </>
    )
}

export default ToogleEmoji