import { ArcRotateCamera, Camera } from '@babylonjs/core';
import React from 'react';
import gsap from "gsap";

export class CameraManager {

    static index: number = 0;
    views: Array<string> = new Array("normalView", "topView", "perspectiveView", "longViews");
    camera!: Camera;

    constructor(pcamera: Camera) {
        this.camera = pcamera;
    }

    switch(): void {
        CameraManager.index += 1;
        if (CameraManager.index > this.views.length - 1) CameraManager.index = 0;

        this.switchPositions(this.views[CameraManager.index]);
    }

    switchPositions(state: string): void {
        if (this.camera) {
            switch (state) {
                case this.views[0]:
                    gsap.to(this.camera, { duration: 1, fov: 0.8, beta: Math.PI / 8, overwrite: 'auto', ease: "power2.out" });
                    break;
                case this.views[1]:
                    gsap.to(this.camera, { duration: 1, fov: 0.9, beta: Math.PI / 20, overwrite: 'auto', ease: "power2.out" });
                    break;
                case this.views[2]:
                    gsap.to(this.camera, { duration: 1, fov: 1.1, beta: Math.PI / 100, overwrite: 'auto', ease: "power2.out" });
                    break;
                case this.views[3]:
                    gsap.to(this.camera, { duration: 1, fov: 1, beta: Math.PI / 5, overwrite: 'auto', ease: "power2.out" });
                    break;
            }
        }
    }

    shakeIt(strength: number = 20) {
        if (!this.camera) return;
        let fov = this.camera.fov;
        gsap.fromTo(this.camera, { fov: fov - 0.1 }, { duration: 0.01, fov: fov, repeat: strength });
    }

}
