export default function ServiceWorkerManager() {


    if ('serviceWorker' in navigator) {
        window.addEventListener('load', () => {

            navigator.serviceWorker.register(process.env.REACT_APP_PUBLIC_PATH + 'firebase-messaging-sw.js')//, { type: 'module', scope: '__' })
                .then(function (registration) {
                    console.log('Messaging Registration successful, scope is:', registration.scope);
                }).catch(function (err) {
                    console.log('Messaging Service worker registration failed, error:', err);
                });

            navigator.serviceWorker.register(process.env.REACT_APP_PUBLIC_PATH + 'main-sw.js')
                .then((registration) => {
                    console.log('Main Registration successful, scope is:', registration.scope);
                }).catch((err) => {
                    console.log('Main Service worker registration failed, error:', err);
                })

        })
    }
}