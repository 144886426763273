import { t } from 'i18next'
import React from 'react'
import InfosProfil from '../../data/InfosProfil'
import DataManager from '../../manager/DataManager'

import "./ItemInvitation.css"

type Props = {
    infos: InfosProfil
    callBack: Function
}

function ItemInvitation({ infos, callBack }: Props) {

    let flag = (DataManager.DATA_COUNTRIES.find((e) => e.code === infos?.country));

    return (
        <div className='itemInvitation'>
            <div className='itemInvitationContainer'>


                <span className='imageContainer'>
                    <span className={'online ' + ((infos.online) ? 'on' : 'off')}></span>
                    <img className="image" src={infos.img} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = DataManager.DATA_PRELOAD_IMAGES[0];;
                    }} />
                    <img className='flag' src={flag!.flag_4x3}></img>

                </span>
                <span className='name'>{infos.name}
                </span>

                <div className='vr'></div>
                {
                    infos.online
                        ? <a className={'bouton small bleu-green simple ' + ((infos.online) ? "" : "desactivated")} onClick={() => callBack(infos)}>{t("Invite")}</a>
                        : infos.notifiable
                            ? <a className={'bouton small green simple ' + ((infos.notifiable) ? "" : "desactivated")} onClick={() => callBack(infos, "notification")}>{t("Notify")}</a>
                            : <></>
                }



            </div>
        </div>
    )
}

export default ItemInvitation