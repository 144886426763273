import { InfosAssets } from "./InfosAssets";
import { InfosStore } from "./InfosStore";

export class InfosTheme extends InfosStore {

	private _assets: Array<InfosAssets> = [];

	public mosaic: string = "";
	public floor: string = "";
	public isPub: boolean = false;

	constructor(details?: any) {
		super(details);
		if (details != undefined) {
			this.floor = details.floor;
			this.mosaic = details.mosaic;
			this.isPub = (details.is_pub == "1") ? true : false;
			this.infinite = false;
			this._assets = [];
		}
	}

	public get assets(): Array<InfosAssets> {
		this._assets = [];
		this._assets.push(new InfosAssets({ id: "floor", url: this.floor }));
		this._assets.push(new InfosAssets({ id: "mosaic", url: this.mosaic }));

		return this._assets;
	}

	override parse(details?: any): void {
		this.floor = details.floor;
		this.mosaic = details.mosaic;
		this.isPub = (details.is_pub == "1") ? true : false;

		super.parse(details);
	}
}
