import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import Panel from '../../componement/Panel';
import { useRondaModal } from '../../context/ModalProvider';
import { useUser } from '../../context/UserProvider';
import { InfosModes } from '../../data/InfosModes';
import InfosPlayer from '../../data/InfosPlayer';
import { SoundManager } from '../../manager/SoundManager';
import { useRondaServer } from '../../context/ServerProvider';
import IndicateurMultiPlayer from './IndicateurMultiPlayer';
import { InfosRoom } from '../../data/InfosRoom';
import { LocationState } from '../../data/CustomInterfaces';

import "./PanelMulti.css"
import DataManager from '../../manager/DataManager';
import { InfosGame } from '../../data/InfosGame';
import ItemRoom from '../listing/ItemRoom';

type Props = {
}




interface State {
    event: string,
    card?: number
}



function PanelMulti({ }: Props) {

    let navigate = useNavigate();
    let userInfos = useUser()!;
    let location = useLocation();
    const { t } = useTranslation();
    let { client, room, setRoom } = useRondaServer()!;
    let { invitation } = useParams();

    let maxPlayers = 4;
    //let pingInterval: NodeJS.Timeout;
    let isMounted = true;


    const [infosGame, setInfosGame] = useState<InfosGame>((location.state as LocationState)?.infosGame);
    let infosRoom: InfosRoom = (location.state as LocationState)?.infosRoom;


    const [infosServerRoom, setInfosServerRoom] = useState<InfosRoom>(infosRoom);
    const [owner, setOwner] = useState<boolean>(false);

    let state = { event: "idle" };

    const [ready, setReady] = useState<boolean>(false);
    const [players, setPlayers] = useState<Array<InfosPlayer>>([]);
    const { handleModal } = useRondaModal()!;

    const [link, setLink] = useState<string>("");
    const dummy = DataManager.DUMMY_INFOSPLAYER;


    function copyLink() {
        navigator.clipboard.writeText(link);
        handleModal("Link copied", "green");
    }

    function flag(): any {
        return DataManager.DATA_COUNTRIES.find((e) => e.code === infosServerRoom?.country)
    };

    function onInvite() {
        navigate("invite/" + infosGame.name + "|" + infosServerRoom.roomId + "|" + infosServerRoom.password);
    }

    function kickPlayer(sessionId: string) {
        SoundManager.play(SoundManager.EVENT_KICK_PLAYER);
        room?.send("kick", { sessionId: sessionId });
    }

    function updateReady(val: boolean) {
        SoundManager.play(SoundManager.EVENT_REFRESH);
        room?.send("ready", { ready: val });
    }

    function updateData() {
        setPlayers([]);
        let arr: Array<InfosPlayer> = [];
        room?.state.players.forEach((element: any) => {
            let ip = new InfosPlayer(element);
            if (ip.sessionId === room?.sessionId) {
                setReady(ip.replay);
            }
            arr.push(ip);
        });

        while (arr.length < maxPlayers) {
            arr.push(dummy);
        }

        setPlayers([...arr]);
    }

    useEffect(() => {
        let inviteGame: InfosGame = infosGame;
        if (invitation) {
            let invitationInfos: Array<string> = invitation?.split("|");
            let testinviteGame = userInfos.dataGames.find((g) => g.name == invitationInfos[0])

            if (testinviteGame) {//setInfosGame(inviteGame);
                inviteGame = testinviteGame;
                setInfosGame(inviteGame);

                infosRoom = new InfosRoom();
                infosRoom.roomId = invitationInfos[1];
                infosRoom.password = invitationInfos[2];

                console.log("####################################################################");
                console.log("userInfos.invitationInfos " + invitationInfos[0], invitationInfos);
                console.log("infosGame " + JSON.stringify(infosGame) + "   " + JSON.stringify(inviteGame));
                console.log("infosRoom " + JSON.stringify(infosRoom));
                console.log("####################################################################");
            } else {
                invitation = undefined;
            }



        }




        if (!infosGame && !invitation) {
            navigate('/home');
        } else if (userInfos.infosProfil.id != 0) {


            init().then(() => {

                setRoom(room);
                if (room == undefined) return;
                if (isMounted) {



                    if (infosRoom?.profil === userInfos.infosProfil.name) {
                        setOwner(true);
                    }


                    room.onStateChange.once((state) => {
                        let ir = new InfosRoom(state.mode)

                        if (!infosRoom)
                            infosRoom = ir;

                        setInfosServerRoom(ir);
                        setLink(DataManager.ABSOLUT_PATH + "friendly/" + inviteGame.name + "|" + ir.roomId + "|" + ir.password);

                        maxPlayers = infosRoom.numberOfPlayers;
                        updateData();
                    });

                    room.onStateChange((state: State) => {
                        room?.state.players.forEach((player: any) => {
                            updateData();
                        })
                    });
                    // pingInterval = setInterval(() => {
                    //     room?.send("latency");
                    // }, 1000);

                    const update = (infosPlayer: any) => {
                        SoundManager.play(SoundManager.EVENT_REFRESH);

                        let arr: Array<InfosPlayer> = [];
                        room?.state.players.forEach((element: any) => {
                            arr.push(new InfosPlayer(element));
                        });
                        setPlayers(arr);
                    }

                    room.onMessage("state", (newstate: State) => {
                        if (newstate.event == "start") {
                            state = newstate;

                            SoundManager.play(SoundManager.COIN_DOWN);

                            setTimeout(() => {
                                navigate("/" + inviteGame?.name, { state: { infosMode: infosRoom as InfosModes } });
                                SoundManager.play(SoundManager.VALIDATE_STARTGAME);
                            }, 1000);


                        }
                    });

                    room.onMessage("ping", (timeStamp) => {
                        room?.send("pong", timeStamp);
                    });

                    room.onMessage("kicked", () => {
                        //handleModal("You have been kicked from this party", "red");
                        //navigate('/listing', { state: { infosGame: inviteGame } });
                    });

                    room.state.players.onAdd = update;
                    room.state.players.onRemove = update;

                    room.onError(() => handleModal("Someting went wrong, you are exiting the Friendly Mode", "red"));
                    room.onLeave((code: number) => {
                        // 4001 Pause error
                        // 4002 Password
                        // 4003 Kick

                        switch (code) {
                            case 4001:
                                handleModal("You were kicked from the game for pausing too long", "red", false)
                                break;
                            case 4002:
                                handleModal("This room is private", "red")
                                break;
                            case 4003:
                                handleModal("You were kicked from the game by the host", "red", false)
                                navigate('/listing', { state: { infosGame: inviteGame } });
                                break;
                            default:
                                handleModal("You are quitting the Friendly Mode", "green")
                                break;

                        }
                    });
                }
            });
        }

        return () => {
            if (state.event == "idle") room?.leave();
            //clearTimeout(pingInterval);
            isMounted = false;
        }
    }, [])


    async function init() {
        try {
            if (!infosRoom) {
                room = await client.join<any>(infosGame.name + "Friendly", { game: infosGame, infosPlayer: new InfosPlayer(userInfos.infosProfil) });
            } else if (!infosRoom.roomId || infosRoom.roomId === "") {
                if (!infosRoom.password || infosRoom.password == "")
                    room = await client.create<any>(infosGame.name + "Friendly", { game: infosGame, mode: infosRoom, infosPlayer: new InfosPlayer(userInfos.infosProfil) });
                else
                    room = await client.create<any>(infosGame.name + "Friendly", { game: infosGame, mode: infosRoom, infosPlayer: new InfosPlayer(userInfos.infosProfil) });
            } else if (infosRoom.roomId != "") {
                console.log("try to join by id " + infosRoom.roomId, infosRoom);
                room = await client.joinById(infosRoom.roomId, { game: infosGame, mode: infosRoom, infosPlayer: new InfosPlayer(userInfos.infosProfil) });
            }
            return;
        } catch (e) {
            navigate('/friendlymode', { state: { infosGame: infosGame } });
            handleModal("No available Room, create a new Game", "red");
            //console.log(`Error: ${(e as any).message}`)
            //throw e
        }
    }







    return (
        <>
            <Outlet />
            <Panel className='multi' title={t('Friendly')} returnTo="/home">
                {
                    // owner && <>
                    //<a className='bouton-icone bleu-green icon-ok' onClick={() => { copyLink() }}></a>
                    //<input className='inputLink' type="input" readOnly={true} defaultValue={link} ></input>
                    // </>
                }
                <div className='layer'>
                    <div className='layerContainer'>
                        {
                            infosServerRoom &&
                            <div className='itemRoomContainer'>
                                <span className='name'>{infosServerRoom.name}         <img className='flag' src={flag().flag_4x3}></img>
                                </span>

                                <a className='bouton-icone bleu icon-profil' onClick={() => { navigate("/profil/" + infosServerRoom.profil) }}></a>

                                <div className='vr'></div>
                                <span className='Players'><span className='ico' />{infosServerRoom.clients + "/" + infosServerRoom.numberOfPlayers}</span>
                                <div className='vr'></div>
                                <span className='Rounds'><span className='ico' />{infosServerRoom.numberOfRounds}</span>
                                <div className='vr'></div>


                                <label className='checkBox' >
                                    <input type="checkbox" disabled={true} defaultChecked={infosServerRoom.cheatMode} />
                                    <span className="knob">{t('Special')}</span>
                                    <span className="slideDeck"></span>
                                </label>
                                <div className='vr'></div>

                                <input className='inputLink' type="input" readOnly={true} defaultValue={link} ></input>
                                <a className='bouton-icone bleu-green icon-ok' onClick={() => { copyLink() }}></a>

                            </div>

                        }
                        <div className='contaninders'>
                            {
                                players.map((infos: InfosPlayer, index: number) => {
                                    return <IndicateurMultiPlayer key={infos.sessionId + "-" + index} owner={owner} callBack={kickPlayer} infos={infos}></IndicateurMultiPlayer>
                                })
                            }
                        </div>
                    </div>
                </div>

                {
                    (ready)
                        ? <a href='#' className='bouton red' onClick={(e) => { updateReady(false); }}>{t('Waiting')}</a>
                        : <a href='#' className='bouton ' onClick={(e) => { updateReady(true); }}>{t('Ready')}</a>

                }

                <a href='#' className='bouton bleu-green' onClick={() => onInvite()}>{t('Invite friends !')}</a>

            </Panel>
        </>
    )
}

export default PanelMulti