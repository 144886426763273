import React, { useState } from 'react'
import { SoundManager } from '../manager/SoundManager';
import { log } from '../manager/FirebaseManager';

type Props = {
  className: string;
}
const ToogleFullScreen = (props: Props) => {

  const [isFullscreen, setIsFullscreen] = useState(false);


  function toggleFullscreen() {
    const document: any = window.document;



    const element = document.getElementById('root');

    // Demande le mode plein écran
    function requestFullScreen() {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) { // Safari
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) { // Internet Explorer
        element.msRequestFullscreen();
      }
    }

    // Annule le mode plein écran
    function cancelFullScreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) { // Safari
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { // Internet Explorer
        document.msExitFullscreen();
      }
    }

    // Vérifie si l'écran est en mode plein écran
    function isFullScreen() {
      return (
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElement ||
        false
      );
    }

    // Utilisation
    if (isFullScreen()) {
      cancelFullScreen();
      setIsFullscreen(false);
      log("screen_size", { size: "normal" });
    } else {
      requestFullScreen();
      setIsFullscreen(true);
      log("screen_size", { size: "full" });
    }
    SoundManager.play(SoundManager.EVENT_REFRESH);


    //console.log("document.fullscreenElement "+document.fullscreenElement+ " element.requestFullScreen "+element.requestFullScreen + " element.cancelFullScreen "+element.cancelFullScreen);
    //console.log("isFullscreen "+isFullscreen);

  }


  return (
    <>
      <a className={props.className + " " + (isFullscreen ? 'icon-reduire' : 'icon-agrandir')} onClick={() => toggleFullscreen()}></a>
    </>
  )
}

export default ToogleFullScreen