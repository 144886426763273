import React, { useLayoutEffect, useRef } from 'react'
import "./AlertConfirmation.css"
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Alert from '../../componement/Alert';

type Props = {}

export default function AlertConfirmation({ }: Props) {

    let navigate = useNavigate();
    const { t } = useTranslation();
    const location = useLocation();

    let message = "";

    if (location.state) message = (location.state as any).message;
    return (
        <>
            <Alert title={t('Confirmation')} className={"confirmReset"} autoClose={60} animation={false} callBack={() => navigate("/", { replace: true })} >
                <div className='layer'>
                    <div className='layerContainer'>
                        <span className="sousTitre">{message}</span>
                        <a href='#' className='bouton red' onClick={() => navigate("/", { replace: true })}>{t("Back to the main page")}</a>
                    </div>
                </div>
                <div className='perso'></div>
            </Alert>
        </>
    )
}
