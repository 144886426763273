import { Mesh } from "@babylonjs/core";
import gsap from "gsap";
import { Timer } from "./TimerManager";




export default class ShowReelManager {

    index: number = 0;
    //myTimer:NodeJS.Timer | undefined;

    effect: Array<Function> = [];
    cards: Array<Mesh> = [];
    ready: boolean = false;
    playing: boolean = false;


    get isReady(): boolean {
        return this.ready;
    }

    get isPlaying(): boolean {
        return this.playing;
    }



    private decompte: Timer;
    private isPaused: boolean = false;

    constructor(pcards: Array<Mesh>) {
        this.cards = pcards;
        this.effect = new Array(this.rangeCards, this.superRangeCards, this.rollRangeCards, this.cubicRangeCards, this.cubicReverseRangeCards, this.rollRangeCards);
        this.ready = true;
        this.decompte = new Timer(4000, 1000, this.setEffect.bind(this), () => { console.log("Timer Alive") });

        window.addEventListener("visibilitychange", this.handleVisibilityChange.bind(this));
        this.handleVisibilityChange();
    }

    private handleVisibilityChange() {
        if (document.hidden) {
            // The page is not visible, so pause your animations or processes.
            this.pause();
        } else {
            // The page is visible again, so resume your animations or processes.
            this.resume();
        }
    }
    private pause() {
        //gsap.killTweensOf(window);
        this.isPaused = true;
        // Add logic to pause your animations or processes here.
        this.stopShow();
    }

    private resume() {
        if (this.isPaused && this.playing) {
            this.isPaused = false;
            // Add logic to resume your animations or processes here.
            this.startShow();
        }
    }
    startShow(): void {
        //window.addEventListener("visibilitychange", this.handleVisibilityChange.bind(this));

        this.stopShow();
        this.playing = true;
        var self = this;

        this.decompte.reset();
        if (!document.hidden) this.decompte.start();
    }

    stopShow(): void {
        //window.removeEventListener("visibilitychange", this.handleVisibilityChange.bind(this));

        this.decompte.stop();
        this.killTween();
    }

    killTween() {
        this.cards.forEach((pcarte: Mesh) => { gsap.killTweensOf(pcarte) });
        this.playing = false;
    }

    setEffect(): void {
        let mdl: number = this.index % this.effect.length;
        this.effect[mdl](this.cards);
        this.index++;
    }



    //console.log(" this.myTimer "+this.myTimer+"  "+new Date().getMilliseconds()+"  " + this.index );

    /*
    startShow():void
    {

        //console.log("startShow()")
        this.stopShow();
        this.playing=true;

        var self=this;
        if (this.myTimer) {
            clearInterval(this.myTimer);
        }
        //console.log("initiate myTimer");
        this.myTimer = setInterval(this.setEffect.bind(this), 4000);
        window.addEventListener("focus", this.onFocus.bind(this));

        //this.setEffect();
    }

    onFocus(){
        this.keepPlaying=true;
    }

    stopShow():void {
       console.log("stopShow()");
       if (this.myTimer)
       clearInterval(this.myTimer);
       //ShowReelManager.myTimer = null;
       this.killTween();
       window.removeEventListener("focus", this.onFocus.bind(this));

    }
*/
    rangeCards(_cards: Array<Mesh>): void {
        let lng: number = _cards.length;
        let spaceX: number = Math.max((2 * lng), 2);
        let maxWidth: number = Math.min((spaceX * lng), 20);
        let radiusX: number = Math.min((lng), 10);
        let radiusY: number = Math.min((lng), 2);
        let centerX: number = 0;
        let centerY: number = 0;
        function findCard(pcarte: Mesh, index: number, arr: Array<Mesh>): void {
            let angle: number = index * ((Math.PI) / lng);//+0.3;
            let X: number = (index * (maxWidth / lng)) - (maxWidth / 2);
            let Z: number = (Math.sin(angle) * radiusY + centerY);
            let Y: number = 1 + (index * 0.01);
            let RY: number = (X * 3) * (Math.PI / 180);
            let RZ: number = 0;
            // gsap.to(pcarte.position, { duration: 1, delay: (0.05 * index), x: X, y: Y, z: Z, overwrite: 'auto', ease: "power2.out" });
            // gsap.to(pcarte.rotation, { duration: 1, delay: (0.05 * index), y: RY, z: RZ, x: 0, overwrite: 'auto', ease: "power2.out" });
            // gsap.to(pcarte.scaling, { duration: 1, delay: (0.05 * index), y: 0.25, z: 0.25, x: 0.25, overwrite: 'auto', ease: "power2.out" });
            const timeline = gsap.timeline();
            timeline.to(pcarte.position, {
                duration: 1,
                x: X,
                y: Y,
                z: Z,
                overwrite: 'auto',
                ease: "power2.out",
                delay: 0.05 * index
            })
                .to(pcarte.rotation, {
                    duration: 1,
                    y: RY,
                    z: RZ,
                    x: 0,
                    overwrite: 'auto',
                    ease: "power2.out",
                    delay: 0.05 * index
                }, 0) // 0 sets the position to the start of the timeline
                .to(pcarte.scaling, {
                    duration: 1,
                    y: 0.25,
                    z: 0.25,
                    x: 0.25,
                    overwrite: 'auto',
                    ease: "power2.out",
                    delay: 0.05 * index
                }, 0);
        }
        _cards.forEach(findCard);
    }

    superRangeCards(_cards: Array<Mesh>): void {


        let lng: number = _cards.length;
        let spaceX: number = Math.max((2 * lng), 2);
        let maxWidth: number = Math.min((spaceX * lng), 20);
        let radiusX: number = Math.min((lng), 10);
        let radiusY: number = Math.min((lng), 2);
        let centerX: number = 0;
        let centerY: number = 0;
        function findCard(pcarte: Mesh, index: number, arr: Array<Mesh>): void {
            let angle: number = index * ((Math.PI) / lng);//+0.3;
            let X: number = (index * (maxWidth / lng)) - (maxWidth / 2);
            let Z: number = (Math.sin(angle) * radiusY + centerY);
            let Y: number = 1 + (index * 0.01);
            let RY: number = (X * 3) * (Math.PI / 180);
            let RZ: number = 180 * (Math.PI / 180);
            // gsap.to(pcarte.position, { duration: 1, delay: (0.05 * index), x: X, y: Y, z: Z, overwrite: 'auto', ease: "power2.out" });
            // gsap.to(pcarte.rotation, { duration: 1, delay: (0.05 * index), y: RY, z: RZ, x: 0, overwrite: 'auto', ease: "power2.out" });
            // gsap.to(pcarte.scaling, { duration: 1, delay: (0.05 * index), y: 0.25, z: 0.25, x: 0.25, overwrite: 'auto', ease: "power2.out" });
            const timeline = gsap.timeline();
            timeline.to(pcarte.position, {
                duration: 1,
                x: X,
                y: Y,
                z: Z,
                overwrite: 'auto',
                ease: "power2.out",
                delay: 0.05 * index
            })
                .to(pcarte.rotation, {
                    duration: 1,
                    y: RY,
                    z: RZ,
                    x: 0,
                    overwrite: 'auto',
                    ease: "power2.out",
                    delay: 0.05 * index
                }, 0) // 0 sets the position to the start of the timeline
                .to(pcarte.scaling, {
                    duration: 1,
                    y: 0.25,
                    z: 0.25,
                    x: 0.25,
                    overwrite: 'auto',
                    ease: "power2.out",
                    delay: 0.05 * index
                }, 0);


        }
        _cards.forEach(findCard);
    }

    cubicRangeCards(_cards: Array<Mesh>): void {
        let lng: number = _cards.length;
        let spaceX: number = 2.5;
        let spaceY: number = 4;
        let centerX: number = -11.2;
        let centerY: number = -6;
        let maxSelections: number = 10;
        function findCard(pcarte: Mesh, index: number, arr: Array<Mesh>): void {
            let X: number = centerX + (Math.round(index % maxSelections) * (spaceX));
            let Z: number = centerY + (Math.floor(index / maxSelections) * (spaceY));
            let Y: number = 1;
            let RZ: number = 0;
            let RY: number = 0;
            // gsap.to(pcarte.position, { duration: 1, delay: (0.05 * index), x: X, y: Y, z: Z, overwrite: 'auto', ease: "power2.out" });
            // gsap.to(pcarte.rotation, { duration: 1, delay: (0.05 * index), y: RY, z: RZ, x: 0, overwrite: 'auto', ease: "power2.out" });
            // gsap.to(pcarte.scaling, { duration: 1, delay: (0.05 * index), y: 0.25, z: 0.25, x: 0.25, overwrite: 'auto', ease: "power2.out" });
            const timeline = gsap.timeline();
            timeline.to(pcarte.position, {
                duration: 1,
                x: X,
                y: Y,
                z: Z,
                overwrite: 'auto',
                ease: "power2.out",
                delay: 0.05 * index
            })
                .to(pcarte.rotation, {
                    duration: 1,
                    y: RY,
                    z: RZ,
                    x: 0,
                    overwrite: 'auto',
                    ease: "power2.out",
                    delay: 0.05 * index
                }, 0) // 0 sets the position to the start of the timeline
                .to(pcarte.scaling, {
                    duration: 1,
                    y: 0.25,
                    z: 0.25,
                    x: 0.25,
                    overwrite: 'auto',
                    ease: "power2.out",
                    delay: 0.05 * index
                }, 0);
        }
        _cards.forEach(findCard);
    }


    cubicReverseRangeCards(_cards: Array<Mesh>): void {
        let lng: number = _cards.length;
        let spaceX: number = 2.5;
        let spaceY: number = 4;
        let centerX: number = -11.2;
        let centerY: number = -6;
        let maxSelections: number = 10;
        function findCard(pcarte: Mesh, index: number, arr: Array<Mesh>): void {
            let X: number = centerX + (Math.round(index % maxSelections) * (spaceX));
            let Z: number = centerY + (Math.floor(index / maxSelections) * (spaceY));
            let Y: number = 1;
            let RY: number = 0;
            let RZ: number = -180 * (Math.PI / 180);
            // gsap.to(pcarte.position, { duration: 1, delay: (0.05 * index), x: X, y: Y, z: Z, overwrite: 'auto', ease: "power2.out" });
            // gsap.to(pcarte.rotation, { duration: 1, delay: (0.05 * index), y: RY, z: RZ, x: 0, overwrite: 'auto', ease: "power2.out" });
            // gsap.to(pcarte.scaling, { duration: 1, delay: (0.05 * index), y: 0.25, z: 0.25, x: 0.25, overwrite: 'auto', ease: "power2.out" });
            const timeline = gsap.timeline();
            timeline.to(pcarte.position, {
                duration: 1,
                x: X,
                y: Y,
                z: Z,
                overwrite: 'auto',
                ease: "power2.out",
                delay: 0.05 * index
            })
                .to(pcarte.rotation, {
                    duration: 1,
                    y: RY,
                    z: RZ,
                    x: 0,
                    overwrite: 'auto',
                    ease: "power2.out",
                    delay: 0.05 * index
                }, 0) // 0 sets the position to the start of the timeline
                .to(pcarte.scaling, {
                    duration: 1,
                    y: 0.25,
                    z: 0.25,
                    x: 0.25,
                    overwrite: 'auto',
                    ease: "power2.out",
                    delay: 0.05 * index
                }, 0);
        }
        _cards.forEach(findCard);
    }


    rollRangeCards(_cards: Array<Mesh>): void {
        let lng: number = _cards.length;
        let spaceX: number = Math.max((2 * lng), 2.5);
        let maxWidth: number = Math.min((spaceX * lng), 5);
        let radius: number = 10;
        let centerX: number = 0;
        let centerY: number = -6;
        function findCard(pcarte: Mesh, index: number, arr: Array<Mesh>): void {
            let angle: number = index * (Math.PI / lng);
            let X: number = (Math.cos(angle) * radius + centerX);
            let Z: number = (Math.sin(angle) * radius + centerY);
            let Y: number = 1 + (index * 0.01);
            let RY: number = (X * 6) * (Math.PI / 180);
            let RZ: number = 180 * (Math.PI / 180);

            // gsap.to(pcarte.position, { duration: 1, delay: (0.05 * index), x: X, y: Y, z: Z, overwrite: 'auto', ease: "power2.out" });
            // gsap.to(pcarte.rotation, { duration: 1, delay: (0.05 * index), y: RY, z: RZ, x: 0, overwrite: 'auto', ease: "power2.out" });
            // gsap.to(pcarte.scaling, { duration: 1, delay: (0.05 * index), y: 0.25, z: 0.25, x: 0.25, overwrite: 'auto', ease: "power2.out" });

            const timeline = gsap.timeline();
            timeline.to(pcarte.position, {
                duration: 1,
                x: X,
                y: Y,
                z: Z,
                overwrite: 'auto',
                ease: "power2.out",
                delay: 0.05 * index
            })
                .to(pcarte.rotation, {
                    duration: 1,
                    y: RY,
                    z: RZ,
                    x: 0,
                    overwrite: 'auto',
                    ease: "power2.out",
                    delay: 0.05 * index
                }, 0) // 0 sets the position to the start of the timeline
                .to(pcarte.scaling, {
                    duration: 1,
                    y: 0.25,
                    z: 0.25,
                    x: 0.25,
                    overwrite: 'auto',
                    ease: "power2.out",
                    delay: 0.05 * index
                }, 0);
        }
        _cards.forEach(findCard);
    }
}
