export class InfosLanguage {

    public name: string;
    public title: string;
    public xml: string;
    public url: string;
    public image: string;
    public id: number;
    public locale: string;
    public dir: string;


    constructor(details?: any) {
        this.id = 0;
        this.name = "Fr";
        this.title = "Français";
        this.xml = "language/fr.xml";
        this.image = "images/flags/fr.png";
        this.url = "";
        this.locale = "fr";
        this.dir = "ltr";

        //console.log(JSON.stringify(details) + " details.locale " + details.locale);

        if (details != undefined) {
            this.id = Number(details?.id);
            this.name = details?.name;
            this.title = details?.title;
            this.xml = details?.xml;
            this.url = details?.url;
            this.image = details?.image;
            this.locale = details?.locale.split("_")[0];
            this.dir = details.dir;
        }
    }

}
