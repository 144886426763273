import React, { useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom';
import { InfosModes } from '../../data/InfosModes';
import { LocationState } from '../../data/CustomInterfaces';
import DataManager from '../../manager/DataManager';

import './PanelSneakPeek.css';

type Props = {
}

const PanelSneakPeek = ({ }: Props) => {

    const location = useLocation();
    const infosGame = DataManager.DATA_GAMES.find((e) => e.name == location.pathname.split("/")[2])

    return (
        <div className='sneakpeek'>
            <span className='logo' />
            {infosGame?.image && <img className='game' src={infosGame?.image} />}

            <Outlet />
        </div>
    )
}

export default PanelSneakPeek