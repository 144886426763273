import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import DataManager from '../manager/DataManager';
import { SoundManager } from '../manager/SoundManager';
import Fleche from './Fleche'
import "./Stepper.css"

type Props = {
  children: ReactNode;
  getID?: Function;
  className?: string;
  initial?: number;
  step?: number;
  max?: number;
  arrowStyle?: string;
}

interface StateCaroselParams {
  width_carosel?: number
  width_childrens?: number
  qnt_childrens?: number
  max_width_carousel?: number
}

function Stepper({ children, getID = () => { }, className, max = 0, initial = 0, step = 1, arrowStyle = "bleue" }: Props) {

  const Carousel = useRef<HTMLDivElement>(null)
  const [stateCarosel, setStateCarousel] = useState<StateCaroselParams>()

  const [index, setIndex] = useState<number>(0);

  const [show, setShow] = useState<boolean>(false);

  //var direction = -1;
  getID(index * DataManager.DIRECTION);

  const handleCarousel = useCallback(() => {
    if (Carousel.current) {
      const carousel = Carousel.current
      let qnt_childrens = carousel.children.item(0)?.children.length;
      if (qnt_childrens && qnt_childrens <= max) {
        setShow(false)
      } else {
        setShow(true)
      }
      setStateCarousel(
        {
          ...stateCarosel,
          width_carosel: carousel.clientWidth,
          qnt_childrens: carousel.children.item(0)?.children.length,
          width_childrens: carousel.children.item(0)?.children.item(0)?.clientWidth,
          max_width_carousel: ((carousel.children.length - 1) * carousel.children.item(0)?.clientWidth!)
        }
      )
    }
  }, [setStateCarousel, children])

  useEffect(() => handleCarousel(), [handleCarousel])

  useEffect(() => {
    if (Carousel.current) {
      setIndex(initial * DataManager.DIRECTION);
      Carousel.current!.scrollLeft = stateCarosel?.width_childrens! * index;
    }
  }, [Carousel.current])

  const handleCarouselAction = (e: MouseEvent) => {
    e.preventDefault()


    let indexRef = index;
    switch ((e.currentTarget as HTMLElement).id) {
      case "next":
        indexRef += step;
        break;
      case "prev":
        //return Carousel.current!.scrollLeft -= stateCarosel?.width_childrens!
        indexRef -= step;
        break;
      default:
        //return null
        break;
    }

    if (DataManager.DIRECTION == 1) {
      if (indexRef >= Number(stateCarosel?.qnt_childrens)) indexRef -= step;//(Number(stateCarosel?.qnt_childrens) - step);
      if (indexRef < 0) indexRef = 0;
    } else {
      if (indexRef < (Number(stateCarosel?.qnt_childrens) - step) * DataManager.DIRECTION) indexRef += step // (Number(stateCarosel?.qnt_childrens) - step) * DataManager.DIRECTION;
      if (indexRef > 0) indexRef = 0;
    }

    //if (type == "unit")
    Carousel.current!.scrollLeft = (stateCarosel?.width_childrens!) * indexRef;
    //else if (type == "block")
    //Carousel.current!.scrollLeft = stateCarosel?.width_carosel! * indexRef;

    //console.log("stateCarosel?.width_childrens!"+ stateCarosel?.width_childrens );
    setIndex(indexRef)

    SoundManager.play(SoundManager.BUTTON_CLICK);

    console.log("stateCarosel?.width_childrens " + stateCarosel?.width_childrens);
    console.log("stateCarosel?.qnt_childrens " + stateCarosel?.qnt_childrens);

    console.log("Carousel.current!.scrollLeft " + Carousel.current!.scrollLeft);
    console.log(" indexRef " + indexRef);

  }


  return (
    <div className={'stepper ' + ((className != undefined) ? className : "")}>
      {
        show
          ? <Fleche name="prev" href="#" styleName={arrowStyle} direction="left" callBack={handleCarouselAction}> </Fleche>
          : <div className={"decoy"} style={{ display: "block", width: "55px" }} />
      }
      <div ref={Carousel} className='counter'>
        <div className='container'>
          {children}
        </div>
      </div>
      {
        show
          ? <Fleche name="next" href="#" styleName={arrowStyle} callBack={handleCarouselAction}> </Fleche>
          : <div className={"decoy"} style={{ display: "block", width: "55px" }} />
      }
    </div>
  )
}

export default Stepper